/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IConfirmModalScope extends ng.IScope {
        close: () => void;
        remove: () => void;
        cancel: () => void;
        doRemove: boolean;
    }

    interface IConfirmModal {

    }

    interface ISchoolData { schoolName: string; terms: any; }

    class ConfirmModal implements IConfirmModal {
        static $inject: Array<string> = ["$uibModalInstance", "common", "$scope", "$resource"];
        static controllerId: string = "ConfirmModal";
        private log;
        constructor(private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance, private common: ICommonService, private $scope: IConfirmModalScope, private $resource: ng.resource.IResourceService) {
            this.log = common.logger.getLogFn(ConfirmModal.controllerId);
            $scope.close = () => this.close();
            $scope.remove = () => this.remove();
            $scope.cancel = () => this.cancel();
        }

        close() {
            this.$uibModalInstance.dismiss();
        }

        remove() {
            this.$scope.doRemove = true;
            this.$uibModalInstance.close(this.$scope.doRemove);
        }

        cancel() {
            this.$scope.doRemove = false;
            this.$uibModalInstance.close(this.$scope.doRemove);
        }
    }

    angular.module("app").controller(ConfirmModal.controllerId, ConfirmModal);
}  