/// <reference path="../app.ts" />
declare var tinyMCE: any;
namespace Advant.Crossroads {

    interface INotificationsScope extends angular.IScope {
        vm: Notifications;
        notificationForm: angular.IFormController;
    }

    interface INotifications {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingNotifications: boolean;
        currentSubscription: IApplicationEventSubscription;
        isSaving: boolean;
        inputFields: Array<IInputField>;
        page: number;
        pageSize: number;
        totalCount: number;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        rules: Array<IRuleSet>;
        searchFilter: string;
        subscriptions: any;
        originialSubscriptions: any;
        notifcationSortOptions: any;
        availableFields: Array<any>;

        getRules: () => angular.IPromise<Array<IRuleSet>>;
    }

    class Notifications implements INotifications {
        static controllerId: string = "notifications";
        static $inject: any = ["$scope", "Restangular", "dialogs", "common", "config"];
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingNotifications: boolean;
        currentSubscription: IApplicationEventSubscription;
        isSaving: boolean;
        inputFields: Array<IInputField>;
        page: number;
        pageSize: number;
        totalCount: number;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;
        events: Array<IEventName>;
        currentEvent: IEventName;
        subscriptions: any;
        originialSubscriptions: any;
        rules: Array<IRuleSet>;
        notifcationSortOptions: any;
        tinymceOptions: any;
        availableFields: Array<any>;
        applicationFieldList: Array<any>;
        userFieldList: Array<any>;

        constructor(private $scope: INotificationsScope,
            private Restangular: Restangular.IService,
            private dialogs: angular.dialogs.IDialogService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig) {
            var self = this;
            this.log = common.logger.getLogFn(Notifications.controllerId);
            this.logError = common.logger.getLogFn(Notifications.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(Notifications.controllerId, "success");

            this.page = 1;
            this.pageSize = 128;
            this.sortBy = "Sequence";
            this.sortDirection = "ASC";
            this.searchFilter = "";

            this.createUserFieldList();
            this.availableFields = this.userFieldList;

            this.tinymceOptions = {
                plugins: [
                    "advlist autolink lists moxiemanager link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table contextmenu paste",
                    "template textcolor colorpicker textpattern "
                ],
                toolbar: "undo redo | styleselect | bold italic | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify |" +
                " bullist numlist outdent indent | link image | preview | forecolor backcolor appfieldselector",
                menubar: "edit insert view format table tools ",
                height: 300,
                external_plugins: {
                    "moxiemanager": "/scripts/moxiemanager/plugin.js",
                    "appfieldselector": "/scripts/appfieldselector/plugin.js"
                },
                appfieldselector_menu: {
                    menu: self.availableFields,
                    getMenu: () => {
                        return self.availableFields;
                    }
                },
                moxiemanager_title: "CrossRoads File Manager",
                moxiemanager_fullscreen: true,
                relative_urls: false
            };

            this.notifcationSortOptions = {
                stop: this.notificationSorted
            };

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });
            this.activate();
        }

        private activate = () => {
            this.common.activateController([this.getEvents().then(this.getAppEventSubscriptions), this.getFields(), this.getRules(), this.createAppFieldList()], Notifications.controllerId)
                .then(result => {
                    this.log("Activated Notifications View");
                });
        };

        cancel = (): void => {
            var originalIndex = _.findIndex(this.originialSubscriptions[this.currentEvent.key], { "id": this.currentSubscription.id });
            var original = this.originialSubscriptions[this.currentEvent.key][originalIndex];
            if (originalIndex !== -1) {
                angular.copy(original, this.currentSubscription);
            } else {
                var index = _.findIndex(this.subscriptions[this.currentEvent.key], { "$$hashKey": this.currentSubscription["$$hashKey"] });
                this.subscriptions[this.currentEvent.key].splice(index, 1);
                this.currentSubscription = null;
            }
            this.$scope.notificationForm.$setPristine();
        };

        createNewSubscription = (): void => {
            var newSubscription: any = {
                applicationDefinitionId: this.common.getUser().activeApplication,
                name: "",
                event: this.currentEvent.key,
                sequence: this.getEventSubscriptions() ? this.getEventSubscriptions().length + 1 : 1,
                enabled: true
            };
            this.getEventSubscriptions().push(newSubscription);
            this.currentSubscription = newSubscription;
        };

        deleteNotification = (): void => {
            var confirm = this.dialogs.confirm("Delete Notification", "Are you sure you want to delete this notification? This action cannot be reversed.");

            confirm.result.then((button) => {
                var currentRuleRestangular: Restangular.IElement = <any>this.currentSubscription;
                currentRuleRestangular.remove().then(result => {
                    this.logSuccess("Your notification has been deleted", null, true);
                    this.currentSubscription = null;
                    this.getAppEventSubscriptions();
                }, error => {
                    this.logError("An error occurred while trying to delete the notification", error, true);
                });
            });
        };

        createAppFieldList = () => {
            this.Restangular
                .one(this.common.getUser().activeApplication).get()
                .then((data: IApplicationDefinition) => {
                    this.applicationFieldList = [];

                    this.applicationFieldList.push({
                        text: "Common Fields",
                        menu: [
                            {
                                text: "ApplicationId",
                                value: "[[Id]]"
                            },
                            {
                                text: "Date Started",
                                value: "[[Started]]"
                            },
                            {
                                text: "Date Completed",
                                value: "[[Completed]]"
                            },
                            {
                                text: "Term Name",
                                value: "[[Term.Name]]"
                            },
                            {
                                text: "Term Code",
                                value: "[[Term.Code]]"
                            },
                            {
                                text: "Payment Method",
                                value: "[[Payment.Method]]"
                            },
                            {
                                text: "Payment Amount",
                                value: "[[Payment.Amount]]"
                            }
                        ]
                    });

                    angular.forEach(data.form.sections, (section: ISection) => {
                        var groups = [];
                        angular.forEach(section.groups, (group: IGroup) => {
                            var fields = [];
                            angular.forEach(group.fields, (field: IField) => {
                                fields.push({
                                    text: field.title,
                                    value: `[[${field.key}]]`
                                });
                            });
                            groups.push({
                                text: group.name,
                                menu: fields
                            });
                        });
                        this.applicationFieldList.push({
                            text: section.name,
                            menu: groups
                        });
                    });
                }, (error) => {
                    this.logError("An error occurred while attempting to retrieve the application information.", error);
                });
        };

        createUserFieldList = () => {
            this.userFieldList = [
                {
                    text: "First Name",
                    value: "[[Name.First]]"
                }, {
                    text: "Last Name",
                    value: "[[Name.Last]]"
                }
            ];
        };
        getAppEventSubscriptions = (): angular.IPromise<void | Array<IApplicationEventSubscription>> => {
            this.loadingNotifications = true;
            this.subscriptions = {};
            this.currentSubscription = null;
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                filter: this.searchFilter
            };


            return this.Restangular.one(this.common.getUser().activeApplication).getList("applicationEventSubscriptions", queryParams).then((result: IRestangularResult<IApplicationEventSubscription>) => {
                var allSubscriptions: Array<IApplicationEventSubscription> = result;

                angular.forEach(this.events, (event, key) => {
                    this.subscriptions[event.key] = _.filter(allSubscriptions, { event: event.key });
                });

                this.currentSubscription = null;
                this.originialSubscriptions = angular.copy(this.subscriptions);
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingNotifications = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the notifications", reason);
            });
        };

        changeEvent = () => {
            if (this.currentEvent) {
                switch (this.currentEvent.key) {
                    case "ApplicationSubmitted":
                        this.availableFields = this.applicationFieldList;
                        break;
                    default:
                        this.availableFields = this.userFieldList;
                }
            } else {
                this.availableFields = this.userFieldList;
            }
            this.currentSubscription = null;
            if (tinyMCE.activeEditor) {
                tinyMCE.activeEditor.plugins.appfieldselector.refresh();
            }
        };

        getEventSubscriptions = (): Array<IApplicationEventSubscription> => {
            if (this.currentEvent) {
                return this.subscriptions[this.currentEvent.key];
            }
            return null;
        };

        getEvents = (): angular.IPromise<void> => {
            return this.Restangular.all("events").getList().then((result) => {
                this.events = result;
                if (!this.currentEvent) {
                    this.currentEvent = this.events[0];
                }
            }, (reason) => {
                this.logError("An error occurred trying to retrieve the events.", reason);
            });
        };

        getFields = (): angular.IPromise<Array<IInputField>> => {
            this.inputFields = [];
            return this.Restangular.all(this.common.getUser().activeApplication).all("fields").getList().then((result) => {
                this.inputFields = result;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the application fields.", reason);
                return this.inputFields;
            });
        };

        getRules = (): angular.IPromise<Array<IRuleSet>> => {
            this.rules = [];
            return this.Restangular.all(this.common.getUser().activeApplication).all("rules").getList().then((result) => {
                this.rules = result;
                this.rules.unshift({
                    id: null,
                    name: " ",
                    description: "",
                    applicationDefinitionId: null,
                    groups: null,
                    isSelected: false,
                    selectedItem: null
                });
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the rules", reason);
                return this.rules;
            });
        };

        notificationSorted = (e, ui) => {
            angular.forEach(this.getEventSubscriptions(), (value, index) => {
                value.sequence = index + 1;

                if (value.id) {
                    var currentSubscriptionRestangular: Restangular.IElement = <any>value;
                    currentSubscriptionRestangular.put().then((result) => {
                        this.logSuccess("notification sequence has been updated", null, false);
                    }, (reason) => {
                        this.logError("An error occurred while trying to save the notification", reason, true);
                    });
                }
            });
        };

        save = () => {
            this.isSaving = true;
            if (!this.currentSubscription.id) {
                this.Restangular.all(this.common.getUser().activeApplication).all("applicationEventSubscriptions").post(this.currentSubscription).then(result => {
                    this.logSuccess("Your new notification has been created", null, true);
                    var index = this.getEventSubscriptions().indexOf(this.currentSubscription);
                    this.getEventSubscriptions()[index] = result;
                    this.currentSubscription = this.subscriptions[index];
                    angular.copy(this.subscriptions, this.originialSubscriptions);
                    this.isSaving = false;
                    this.$scope.notificationForm.$setPristine();
                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to save the notification", error, true);
                });
            } else {
                var currentSubscriptionRestangular: Restangular.IElement = <any>this.currentSubscription;
                currentSubscriptionRestangular.put().then(result => {
                    this.logSuccess("Your notification has been updated", null, true);
                    this.isSaving = false;
                    var originalIndex = _.findIndex(this.originialSubscriptions[this.currentEvent.key], { "id": this.currentSubscription.id });
                    var original = this.originialSubscriptions[this.currentEvent.key][originalIndex];
                    angular.copy(this.currentSubscription, original);
                    this.$scope.notificationForm.$setPristine();
                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to save the notification", error, true);
                });
            }
        };
        selectSubscription = (subscription: IApplicationEventSubscription) => {
            this.currentSubscription = subscription;
        };
    }

    angular.module("app").controller(Notifications.controllerId, Notifications);
}