/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    interface ICfncUniqueTermName extends ng.IDirective {

    }

    interface ICfncUniqueTermNameScope extends ng.IScope {
        cfncUniqueTermName: any;
        ngModel: any;
    }

    class CfncUniqueTermName implements ICfncUniqueTermName {
        static directiveId: string = "cfncUniqueTermName";
        restrict: string = "A";
        require: string = "?ngModel";
        scope: any = {
            cfncUniqueTermName: "=",
            ngModel: "="
        };

        constructor(private $parse: ng.IParseService, private Restangular: Restangular.IService, private common: ICommonService) {
        }

        link = (scope: ICfncUniqueTermNameScope, element, attrs, controller) => {

            scope.$watch("ngModel",(newModelValue, oldModleValue, scope: ICfncUniqueTermNameScope) => {
                this.common.debouncedThrottle("UniqueTermName",() => {
                    if (newModelValue !== oldModleValue) {
                        var tempKeyArray = [];
                        angular.forEach(scope.cfncUniqueTermName, (term, key) => {
                            if (term.name.toLowerCase() == scope.ngModel.toLowerCase()) {
                                tempKeyArray.push(term);
                            }
                        });
                        var compare = newModelValue.toString();
                        var duplicateName = _.filter(tempKeyArray,(value) => {
                            return value.name.toLowerCase() == compare.toLowerCase();
                        });

                        if (duplicateName.length > 1) {
                            controller.$setValidity("unique", false);
                        } else {
                            controller.$setValidity("unique", true);
                        }

                    }
                }, 500);
            });

        };
    }

    angular.module("app").directive(CfncUniqueTermName.directiveId, ["$parse", "Restangular", "common",
        ($parse: ng.IParseService, Restangular: Restangular.IService, common: ICommonService) =>
            new CfncUniqueTermName($parse, Restangular, common)
    ]);
}