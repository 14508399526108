/// <reference path="../app.ts" />

namespace Advant.Crossroads {
    "use strict";

    interface ILoginWithTfaCodeScope extends angular.IScope {
        vm: ILoginWithTfaCode;
        loginForm: angular.IFormController;
    }

    interface ILoginWithTfaCode {
        tfaCodeInfo: ILoginModel;
    }


    class LoginWithTfaCode implements ILoginWithTfaCode {
        tfaCodeInfo: ILoginModel;
        errors: Array<any> = [];
        busy: boolean;
        loginInfo: ILoginModel;

        static $inject: any = ["$scope", "$location", "authentication", "resetPassword"];

        constructor(private $scope: ILoginWithTfaCodeScope,
            private $location: angular.ILocationService,
            private authentication: IAuthenticationProviderService,
            private resetPassword: IResetPassword) {
            
            this.loginInfo = {
                client_id: this.resetPassword.resetPasswordInfo.client_id,
                userName: this.resetPassword.resetPasswordInfo.userName,
                password: this.resetPassword.resetPasswordInfo.password,
                rememberMe: this.resetPassword.resetPasswordInfo.rememberMe,
                tenant: this.resetPassword.resetPasswordInfo.tenant,
                grant_type: this.resetPassword.resetPasswordInfo.grant_type
            };
            this.busy = false;
        }

        login() {
            var self = this;

            self.errors = [];
            if (self.$scope.loginForm.$valid) {
                self.busy = true;
                
                self.authentication.login(self.loginInfo).then(result => {
                    self.busy = false;
                }, reason => {
                    if (reason.data) {
                        if (reason.data.error === "expired_password_missing_secret") {
                            self.resetPassword.resetPasswordInfo = {
                                client_id: self.loginInfo.client_id,
                                userName: self.loginInfo.userName,
                                password: self.loginInfo.password,
                                rememberMe: self.loginInfo.rememberMe,
                                grant_type: self.loginInfo.grant_type,
                                tenant: self.loginInfo.tenant,
                                tfaCode:self.loginInfo.tfaCode,
                                needSecurityQuestions: true
                            };
                            self.$location.path("/login/resetPassword");
                        }

                        if (reason.data.error === "expired_password") {
                            self.resetPassword.resetPasswordInfo = {
                                client_id: self.loginInfo.client_id,
                                userName: self.loginInfo.userName,
                                password: self.loginInfo.password,
                                rememberMe: self.loginInfo.rememberMe,
                                grant_type: self.loginInfo.grant_type,
                                tenant: self.loginInfo.tenant,
                                tfaCode: self.loginInfo.tfaCode,
                                needSecurityQuestions: false
                            };
                            self.$location.path("/login/resetPassword");
                        }

                        if (reason.data.error === "missing_secret") {
                            self.resetPassword.resetPasswordInfo = {
                                client_id: self.loginInfo.client_id,
                                userName: self.loginInfo.userName,
                                password: self.loginInfo.password,
                                rememberMe: self.loginInfo.rememberMe,
                                grant_type: self.loginInfo.grant_type,
                                tenant: self.loginInfo.tenant,
                                tfaCode: self.loginInfo.tfaCode,
                                needSecurityQuestions: false
                            };
                            self.$location.path("/login/changeQuestion");
                        }

                        if (reason.data.error_description) {
                            self.errors.push(reason.data.error_description);
                        }
                    }
                    else {
                        self.errors.push("An error occurred while logging in. Please try again later.");
                        self.busy = false;
                    }

                    self.busy = false;
                });
            }
        }
    }

    angular.module("app").controller("loginWithTfaCode", LoginWithTfaCode);
}