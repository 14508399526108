/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    interface IAdvAppSelector extends angular.IDirective {
    }

    interface IAdvAppSelectorScope extends angular.IScope {
        availableApplications: Array<any>;
        userInfo: IUserInfoModel;
    }

    class AdvAppSelector implements IAdvAppSelector {
        static directiveId: string = "advAppSelector";
        restrict: string = "E";
        scope: any = true;
        templateUrl: string = "/app/directives/advAppSelector.html";

        constructor(private common: ICommonService, private config: Advant.Crossroads.ICrossroadsConfig) {
        }

        link = (scope: IAdvAppSelectorScope, element, attrs) => {

            scope.userInfo = this.common.getUser();

            scope.$on(this.config.events.applicationChanged, (event) => {
                scope.userInfo = this.common.getUser();
            });

            if (scope.userInfo) {
                scope.$watch(() => { return scope.userInfo.activeApplication; }, (newValue, oldValue) => {
                    if (newValue !== undefined && sessionStorage[CrossroadsStorage.activeApplication] !== newValue) {
                        sessionStorage[CrossroadsStorage.activeApplication] = newValue;
                        this.common.$broadcast(this.config.events.applicationChanged, newValue);
                    } else {
                        scope.userInfo = this.common.getUser();
                    }
                });
            }
            scope.$watch(attrs.applications, (newValue: any[], oldValue) => {
                scope.availableApplications = newValue;
            });
        };
    }

    angular.module("app").directive(AdvAppSelector.directiveId, ["common", "config", (common: ICommonService, config: Advant.Crossroads.ICrossroadsConfig) =>
        new AdvAppSelector(common, config)
    ]);
}