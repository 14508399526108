namespace Advant.Crossroads {
    "use strict";

    class AddProspectExportSettings implements IAddExportSettings {
        static controllerId: string = "addProspectExportSettings";
        static $inject: any = ["$scope", "$timeout", "$uibModalInstance", "common", "config", "Restangular", "data"];
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;

        constructor(private $scope: IAddExportSettingsScope,
            private $timeout: angular.ITimeoutService,
            private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private Restangular: Restangular.IService,
            private existingSettings: IExportSettings[]) {
            this.log = common.logger.getLogFn(AddProspectExportSettings.controllerId);
            this.logError = this.common.logger.getLogFn(AddProspectExportSettings.controllerId, "error");

            $scope.page = 1;
            $scope.pageSize = 10;
            $scope.sortBy = "Name";
            $scope.sortDirection = "ASC";

            this.getSettings();

            $scope.save = this.save;
            $scope.cancel = this.cancel;
            $scope.getSettings = this.getSettings;
            $scope.sort = this.sort;
            $scope.searchExportSettings = this.searchExportSettings;
            $scope.addSetting = this.addSetting;
            $scope.alreadyInList = this.alreadyInList;
        }


        save = () => {
            this.$uibModalInstance.close(this.existingSettings);
        };

        cancel = () => {
            this.$uibModalInstance.dismiss("cancel");
        };

        alreadyInList = (setting: IExportSettings): boolean => {
            if (this.existingSettings) {
                return _.find(this.existingSettings, { "id": setting.id }) ? true : false;
            }
            return false;
        };

        addSetting = (setting: IExportSettings): void => {
            this.$timeout(() => {
                this.existingSettings.push(setting);
            });
        };


        getSettings = (): angular.IPromise<void | Array<IExportSettings>> => {
            this.$scope.loadingSettings = true;
            this.$scope.settings = [];
            this.$scope.currentSetting = null;

            var queryParams = {
                page: this.$scope.page,
                pageSize: this.$scope.pageSize,
                sortBy: this.$scope.sortBy,
                sortDirection: this.$scope.sortDirection,
                filter: this.$scope.searchFilter
            };

            return this.Restangular.one(this.common.getUser().activeApplication).getList("prospectExportSettings", queryParams).then((result: IRestangularResult<IExportSettings>) => {
                this.$scope.settings = result;
                this.$scope.totalCount = result.totalCount;
                this.$scope.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.$scope.startRow = this.$scope.page === 1 ? 1 : (this.$scope.pageSize * (this.$scope.page - 1)) + 1;
                    this.$scope.endRow = (this.$scope.startRow - 1) + result.length;
                } else {
                    this.$scope.startRow = 0;
                    this.$scope.endRow = 0;
                }
                this.$scope.loadingSettings = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the export settings", reason);
                return;
            });
        };

        searchExportSettings = (): void => {
            this.common.debouncedThrottle("ProspectExportSettingsSearch", () => { this.$scope.page = 1; this.getSettings(); }, 400);
        };

        sort = (sortValue: string): void => {
            if (this.$scope.sortBy === sortValue) {
                this.$scope.sortDirection = this.$scope.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.$scope.sortBy = sortValue;
            }
            this.$scope.page = 1;
            this.getSettings();
        };
    }

    angular.module("app").controller(AddProspectExportSettings.controllerId, AddProspectExportSettings);
}