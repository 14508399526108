/// <reference path="../../app.ts" />

namespace Advant.Crossroads {
    "use strict";

    interface ISubmittedApplicationsReport {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingUserApplications: boolean;
        userApplications: Array<IUserApplicationViewModel>;
        applicationDefinition: IApplicationDefinition;
        rules: Array<IRuleSet>;
        page: number;
        pageSize: number;
        sortBy: string;
        searchFilter: string;
        sortDirection: string;
        totalCount: number;
        totalPages: number;
        startRow: number;
        endRow: number;
        dataPath: string;
        dateLabel: string;
        showNewOnlyFilter: boolean;
        dateStart: string;
        dateEnd: string;
        newOnly: boolean;
        ruleIdFilter: string;
        showFilter: boolean;
        filter: any;

        activate: () => void;
        getApplications: () => angular.IPromise<void | Array<IUserApplicationViewModel>>;
        searchApplications: () => void;
        sort: (sortValue: string) => void;
    }

    class SubmittedApplicationsReport implements ISubmittedApplicationsReport {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingUserApplications: boolean;
        userApplications: Array<IUserApplicationViewModel>;
        applicationDefinition: IApplicationDefinition;
        rules: Array<IRuleSet>;
        page: number;
        pageSize: number;
        sortBy: string;
        searchFilter: string;
        sortDirection: string;
        totalCount: number;
        totalPages: number;
        startRow: number;
        termCode: string;
        endRow: number;
        dataPath: string = "userApplications";
        dateLabel: string;
        showNewOnlyFilter: boolean;
        dateStart: string;
        dateEnd: string;
        newOnly: boolean;
        ruleIdFilter: string;
        showFilter: boolean;
        exporting: boolean = false;
        filter: any;

        static $inject: any = ["$scope", "$location", "$http", "Restangular", "dialogs", "common", "config", "helper"];

        constructor(public $scope: IUserApplicationsScope,
            public $location: angular.ILocationService,
            public $http: angular.IHttpService,
            public Restangular: Restangular.IService,
            public dialogs: angular.dialogs.IDialogService,
            public common: ICommonService,
            public config: ICrossroadsConfig,
            private helper: IHelper) {

            this.log = common.logger.getLogFn("submittedApplicationsReport");
            this.logError = common.logger.getLogFn("submittedApplicationsReport", "error");
            this.logSuccess = common.logger.getLogFn("submittedApplicationsReport", "success");

            this.dataPath = "userApplications/submitted";
            this.dateLabel = "Submitted Between";
            this.showNewOnlyFilter = true;

            this.page = 1;
            this.pageSize = 25;
            this.sortBy = "Completed";
            this.sortDirection = "DESC";
            this.searchFilter = "";
            this.showFilter = false;

            this.loadingUserApplications = false;

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.activate();
        }

        activate = () => {
            this.common.activateController([this.getApplications(), this.getApplicationDefinition(), this.getRules()], "submittedApplicationsReport")
                .then(result => {
                    this.log("Activated UserApplications View");
                });
        };

        applyFilter = () => {
            if (this.filter) {
                this.termCode = this.filter.termCode ? this.filter.termCode : null;
                this.ruleIdFilter = this.filter.ruleId ? this.filter.ruleId : null;
                this.dateEnd = this.filter.dateEnd ? this.filter.dateEnd : "";
                this.dateStart = this.filter.dateStart ? this.filter.dateStart : "";
                this.newOnly = this.filter.newOnly ? this.filter.newOnly : false;
            }

            this.page = 1;
            this.getApplications();
        };

        clearFilter = () => {
            this.termCode = null;
            this.newOnly = null;
            this.dateStart = null;
            this.dateEnd = null;
            this.ruleIdFilter = null;
            this.filter = null;
            this.getApplications();
        };

        exportToExcel = () => {
            this.exporting = true;
            var queryParams = this.getQueryParams();
            var httpPath = this.config.apiHost + "/" + this.common.getUser().activeApplication +
                "/userApplications/submitted/export?sortBy=" + encodeURI(queryParams.sortBy) + "&sortDirection=" + queryParams.sortDirection + "&pageSize=0&page=1" +
                "&startDate=" + queryParams.startDate +
                "&endDate=" + queryParams.endDate;
            if (queryParams.termCode) {
                httpPath += "&termCode=" + queryParams.termCode;
            }

            if (queryParams.ruleId) {
                httpPath += "&ruleId=" + queryParams.ruleId;
            }

            if (queryParams.filter) {
                httpPath += "&filter=" + queryParams.filter;
            }




            this.helper.openFile(httpPath).then(() => {
                this.exporting = false;
            }, (reason) => {
                this.logError("An error occurred while saving the report to excel", reason);
            });
        }

        getApplications = (): angular.IPromise<void | Array<IUserApplicationViewModel>> => {
            this.loadingUserApplications = true;
            this.userApplications = [];

            return this.Restangular.one(this.common.getUser().activeApplication).getList(this.dataPath, this.getQueryParams()).then((result: IRestangularResult<IUserApplicationViewModel>) => {
                this.userApplications = result;
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingUserApplications = false;
                return result;
            }, (reason) => {
                this.loadingUserApplications = false;
                this.logError("An error occurred while getting the user applications", reason);
            });
        };

        getApplicationDefinition = (): angular.IPromise<IApplicationDefinition> => {
            return this.Restangular
                .one(this.common.getUser().activeApplication).get()
                .then((result) => {
                    this.applicationDefinition = result;

                    return result;
                }, (error) => {
                    this.logError("An error occurred while attempting to retrieve the application information.", error);
                });
        };

        getRules = (): angular.IPromise<Array<IRuleSet>> => {
            this.rules = [];
            return this.Restangular.one(this.common.getUser().activeApplication).all("rules").getList().then((result) => {
                this.rules = result;
                this.rules.unshift({ id: null, name: "" });
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the rules", reason);
                return this.rules;
            });
        };

        getQueryParams = (): any => {
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                termCode: this.termCode ? this.termCode : null,
                startDate: this.dateStart ? moment(this.dateStart).toISOString() : "",
                endDate: this.dateEnd ? moment(this.dateEnd).add(1, "day").subtract(1, "second").toISOString() : "",
                newOnly: this.newOnly,
                ruleId: this.ruleIdFilter ? this.ruleIdFilter : null,
                filter: this.searchFilter
            };

            return queryParams;
        };

        searchApplications = (): void => {
            this.common.debouncedThrottle("UserApplicationSearch", () => { this.page = 1; this.getApplications(); }, 400);
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.getApplications();
        };
    }

    angular.module("app").controller("submittedApplicationsReport", SubmittedApplicationsReport);
}