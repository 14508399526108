/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    export interface IOrganizationDetailDialogScope extends angular.IScope {
        organization: IOrganization;
        save: () => void;
        cancel: () => void;
    }

    export interface IOrganizationDetailDialog {
        save: () => void;
        cancel: () => void;
    }

    export class OrganizationDetailDialog implements IOrganizationDetailDialog {
        static controllerId: string = "organizationDetailDialog";
        static $inject: any = ["$scope", "$uibModalInstance", "data"];

        constructor(private $scope: IOrganizationDetailDialogScope, private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance, private data: IOrganization) {
            if (!data) {
                var empty: any = {};
                this.data = empty;
            }

            $scope.save = () => this.save();
            $scope.cancel = () => this.cancel();
            $scope.organization = data;
        }

        save() {
            this.$uibModalInstance.close(this.$scope.organization);
        }

        cancel() {
            this.$uibModalInstance.dismiss("cancel");
        }
    }

    angular.module("app").controller(OrganizationDetailDialog.controllerId, OrganizationDetailDialog);
}
