namespace Advant.Crossroads {
    "use strict";

    interface ILogoutDialogScope extends angular.IScope {
        countdown: number;
    }

    class LogoutDialog {
        static $inject = ["$scope"];

        constructor(private $scope: ILogoutDialogScope) {

        }
    }

    angular.module("app").controller("logoutDialog", LogoutDialog);
}