/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IAdminImportProspects {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        uploadingFile: boolean;
        source: string;
        file: any;
        organizations: IOrganization[];
        selectedApplications: string[];
        selectAll: boolean;
        filter: string;
        activate: () => void;
    }

    class AdminImportProspects implements IAdminImportProspects {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        uploadingFile: boolean;
        source: string;
        organizations: IOrganization[];
        file: any;
        selectedApplications: string[];
        selectAll: boolean;
        filter: string;

        static $inject: string[] = ["$scope", "$location", "Restangular", "common", "config", "Upload"];

        constructor(private $scope: angular.IScope,
            private $location: angular.ILocationService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private upload: angular.angularFileUpload.IUploadService) {
            this.log = common.logger.getLogFn("importProspects");
            this.logError = common.logger.getLogFn("importProspects", "error");
            this.logSuccess = common.logger.getLogFn("importProspects", "success");


            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.selectedApplications = [];
            this.filter = "";
            this.activate();
        }


        importProspects = () => {
            if (this.file) {
                this.uploadingFile = true;
                this.upload.upload(<any>{
                    url: this.config.apiHost + "/" + this.common.getUser().activeApplication + "/adminprospectsimport",
                    method: "POST",
                    data: { file: this.file, source: this.source, applicationDefinitionIds: JSON.stringify(this.selectedApplications) },

                }).then((response) => {
                    this.file = null;
                    this.logSuccess("Prospects imported successfully.", null, true)
                }, (reason) => {
                    this.logError(reason, reason, true);
                }).finally(() => {
                    this.uploadingFile = false;
                });
            }
        }

        selectFile = ($file) => {
            if ($file) {
                this.file = $file;
            }

        }

        clearFile = () => {
            this.file = null;
        }

        getOrganizations = () => {
            return this.Restangular.all("organizations").getList<IOrganization>().then((result) => {
                this.organizations = result;
            });
        }

        toggleAppSelection = (appId) => {
            this.selectAll = false;
            var idx = this.selectedApplications.indexOf(appId);

            if (idx > -1) {
                this.selectedApplications.splice(idx, 1);
            }
            else {
                this.selectedApplications.push(appId);
            }
        }
         
        toggleAllApps = () => {
            if (this.selectAll) {
                this.selectedApplications = [];
                this.organizations.forEach(org => {
                    org.programs.forEach(prog => {
                        prog.applications.forEach(app => {
                            this.selectedApplications.push(app.applicationDefinitionId);
                        });
                    });
                });
            }
            else {
                this.selectedApplications = [];
            }
        }

        selectVisibleApps = () => {
            this.organizations.forEach(org => {
                org.programs.forEach(prog => {
                    prog.applications
                        .filter(app => { return app.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 })
                        .forEach(app => {
                            var idx = this.selectedApplications.indexOf(app.applicationDefinitionId);

                            if (idx == -1) {
                                this.selectedApplications.push(app.applicationDefinitionId);
                            }
                    });
                });
            });

        }

        deselectVisibleApps = () => {
            this.organizations.forEach(org => {
                org.programs.forEach(prog => {
                    prog.applications
                        .filter(app => { return app.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 })
                        .forEach(app => {
                            var idx = this.selectedApplications.indexOf(app.applicationDefinitionId);

                            if (idx > -1) {
                                this.selectedApplications.splice(idx, 1);
                            }
                        });
                });
            });

        }

        activate = () => {
            this.common.activateController([this.getOrganizations()], "adminImportProspects")
                .then(result => {
                    this.log("Activated Admin Import Prospects View");
                });
        };

       
    }

    angular.module("app").controller("adminImportProspects", AdminImportProspects);
}