/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    export interface IMajorListsScope extends angular.IScope {
        vm: MajorLists;
        majorListForm: angular.IFormController;
    }

    export interface IMajorListsController {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        isSaving: boolean;
        loadingLists: boolean;
        rules: Array<IRuleSet>;
        currentList: IMajorList;
        majorLists: Array<IMajorList>;
        originalMajorLists: Array<IMajorList>;
        majorListMajorSortBy: string;
        majorListMajorSortReverse: boolean;
        majorListMajorSearchFilter: string;
        majors: Array<IMajor>;

        page: number;
        pageSize: number;
        totalCount: number;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;

        addMajor: (major: IMajor) => void;
        alreadyInList: (major: IMajor) => boolean;
        createNewMajorList: () => void;
        delete: () => void;
        getMajorLists: () => angular.IPromise<void | Array<IMajorList>>;
        getRules: () => angular.IPromise<Array<IRuleSet>>;
        save: () => void;
    }

    export class MajorLists implements IMajorListsController {
        static controllerId: string = "majorLists";
        static $inject: any = ["$scope", "dialogs", "Restangular", "common", "config"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        public isSaving: boolean;
        public loadingLists: boolean;
        public rules: Array<IRuleSet>;
        public currentList: IMajorList;
        public majorLists: Array<IMajorList>;
        public originalMajorLists: Array<IMajorList>;
        public majorListMajorSortBy: string;
        public majorListMajorSortReverse: boolean;
        public majorListMajorSearchFilter: string;
        public majors: Array<IMajor>;
        public page: number;
        public pageSize: number;
        public totalCount: number;
        public sortBy: string;
        public sortDirection: string;
        public totalPages: number;
        public startRow: number;
        public endRow: number;
        public searchFilter: string;


        constructor(private $scope: IMajorListsScope,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig) {

            this.log = common.logger.getLogFn(MajorLists.controllerId);
            this.logError = common.logger.getLogFn(MajorLists.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(MajorLists.controllerId, "success");

            this.loadingLists = false;
            this.isSaving = false;

            this.page = 1;
            this.pageSize = 25;
            this.sortBy = "Name";
            this.sortDirection = "ASC";
            this.searchFilter = "";

            this.majorListMajorSortBy = "name";
            this.majorListMajorSortReverse = false;
            this.majorListMajorSearchFilter = "";

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getMajorLists(), this.getRules(), this.getMajors()], MajorLists.controllerId)
                .then(result => {
                    self.log("Activated MajorList View");
                });
        }

        addMajor = (major: IMajor) => {
            this.currentList.majors.push(major);
            this.$scope.majorListForm.$setDirty();
        };

        alreadyInList = (major: IMajor): boolean => {
            if (this.currentList) {
                return _.find(this.currentList.majors, { "id": major.id }) ? true : false;
            }
            return false;
        };

        cancel = (): void => {
            var originalIndex = _.findIndex(this.originalMajorLists, { "id": this.currentList.id });
            var original = this.originalMajorLists[originalIndex];
            angular.copy(original, this.currentList);
            this.$scope.majorListForm.$setPristine();
        };

        createNewMajorList = (): void => {
            var newMajorList: any = {
                majors: []
            };
            this.majorLists.push(newMajorList);
            this.currentList = newMajorList;
        };

        delete = (): void => {
            var confirm = this.dialogs.confirm("Delete Major List", "Are you sure you want to delete this Major List? This action cannot be reversed.");

            confirm.result.then(() => {
                var currentRuleRestangular = this.currentList as any;
                currentRuleRestangular.remove().then(() => {
                    this.logSuccess("Your list has been deleted", null, true);
                    this.currentList = null;
                    this.getMajorLists();
                }, (error) => {
                    if (error.data && error.data.$type === "Advant.Crossroads.Api.Models.EntityReferencedByAnotherEntityExceptionResult, Advant.Crossroads.Api") {
                        var errorMessage = "This major list is being used by the following items:<br/>";
                        _.forEach<{type:string, key:string}>(error.data.referencedBy, (errorResult) => {
                            errorMessage += `<strong>${errorResult.type}: ${errorResult.key}</strong><br/>`;
                        });

                        errorMessage += "Please remove this major list from those items and try deleting again.";

                        this.logError(errorMessage, error, true);
                    } else {
                        this.logError("An error occurred while trying to delete the list", error, true);
                    }
                });
            });
        };


        getMajors = (): angular.IPromise<void | Array<IMajor>> => {
            this.majors = [];
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                filter: this.searchFilter
            };


            return this.Restangular.one(this.common.getUser().activeApplication).getList("majors", queryParams).then((result: IRestangularResult<IMajor>) => {
                this.majors = result;
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the majors", reason);
            });
        };

        getMajorLists = (): angular.IPromise<void | Array<IMajorList>> => {
            this.loadingLists = true;
            this.majorLists = [];
            this.currentList = null;
            return this.Restangular.all(this.common.getUser().activeApplication).all("majorlists").getList().then((result: IRestangularResult<IMajorList>) => {
                this.majorLists = result;
                this.originalMajorLists = angular.copy(this.majorLists);
                this.loadingLists = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the major lists", reason);
            });
        };


        getRules = (): angular.IPromise<Array<IRuleSet>> => {
            this.rules = [];
            return this.Restangular.all(this.common.getUser().activeApplication).all("rules").getList().then((result) => {
                this.rules = result;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the rules", reason);
                return this.rules;
            });
        };

        removeMajor = (major: IMajor): void => {
            _.remove(this.currentList.majors, (aMajor) => {
                return aMajor === major;
            });
            this.$scope.majorListForm.$setDirty();
        };

        copySetting = () => {
            var dlg = this.dialogs.create("/app/exportSettings/exportSettingsCopyDialog.html",
                "exportSettingsCopyDialog",
                { name: this.currentList.name },
                { keyboard: true, backdrop: true });
            dlg.result.then((settingName) => {
                var newList: IMajorList = angular.copy(this.currentList);

                newList.name = settingName;
                newList.id = null;

                this.Restangular.all(this.common.getUser().activeApplication).all("majorLists").post(newList).then((result) => {
                    this.logSuccess("Your new list has been created", result, true);
                    this.getMajorLists();
                    this.getRules();
                    this.getMajors();
                }, (reason) => {
                    var message = "An error occurred while trying to save the list";
                    if (reason.data.modelState) {
                        angular.forEach(reason.data.modelState, (value: any, key: any) => {
                            message += "<br/>" + value;
                        });
                    }
                    this.logError(message, reason, true);
                    this.getMajorLists();
                });
            });
        };

        save = (): void => {
            this.isSaving = true;
            if (!this.currentList.id) {
                this.Restangular.all(this.common.getUser().activeApplication).all("majorlists").post(this.currentList).then(result => {
                    this.logSuccess("Your new list has been created", null, true);
                    var index = this.majorLists.indexOf(this.currentList);
                    this.majorLists[index] = result;
                    this.currentList = this.majorLists[index];
                    this.isSaving = false;
                    this.$scope.majorListForm.$setPristine();
                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to save the list", error, true);
                });
            } else {
                var currentRuleRestangular: Restangular.IElement = <any>this.currentList;
                currentRuleRestangular.put().then(result => {
                    this.logSuccess("Your list has been updated", null, true);
                    this.isSaving = false;
                    this.$scope.majorListForm.$setPristine();
                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to save the list", error, true);
                });
            }
        };

        selectList = (list: IMajorList) => {
            this.currentList = list;
        };

        searchMajors = (): void => {
            this.common.debouncedThrottle("MajorSearch", () => { this.page = 1; this.getMajors(); }, 400);
        };


        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.getMajors();
        };
    }

    angular.module("app").controller(MajorLists.controllerId, MajorLists);
}