/// <reference path="../../app.ts" />
namespace Advant.Crossroads {


    interface ICollegesScope extends angular.IScope {
        vm: Colleges;
    }

    interface IColleges {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingColleges: boolean;
        isSaving: boolean;
        uploadingFile: boolean;
        colleges: ICollege[];
        currentCollege: ICollege;

        page: number;
        pageSize: number;
        totalCount: number;
        sortByRelevance: boolean;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;
    }

    class Colleges implements IColleges {
        static controllerId: string = "colleges";
        static $inject: string[] = ["$scope", "Upload", "Restangular", "common", "config", "$location", "dialogs"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingColleges: boolean;
        isSaving: boolean;
        uploadingFile: boolean;
        colleges: ICollege[];
        currentCollege: ICollege;

        page: number;
        pageSize: number;
        totalCount: number;
        sortByRelevance: boolean;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;

        constructor(private $scope: ICollegesScope,
            private Upload: angular.angularFileUpload.IUploadService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private $location: ng.ILocationService,
            private dialogs: angular.dialogs.IDialogService) {

            this.log = common.logger.getLogFn(Colleges.controllerId);
            this.logError = common.logger.getLogFn(Colleges.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(Colleges.controllerId, "success");

            this.page = 1;
            this.pageSize = 25;
            this.sortByRelevance = false;
            this.sortBy = "Name";
            this.sortDirection = "ASC";
            this.searchFilter = "";

            this.activate();

        }

        private activate = () => {
            this.common.activateController([this.getColleges()], Colleges.controllerId)
                .then(result => {
                    this.log("Activated Colleges View");
                });
        };

        getColleges = (): angular.IPromise<void | ICollege[]> => {
            this.loadingColleges = true;
            this.colleges = [];
            this.currentCollege = null;
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortByRelevance: this.sortByRelevance,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                filter: this.searchFilter
            };


            return this.Restangular.one("lookup").one("school").getList("colleges", queryParams).then((result: IRestangularResult<ICollege>) => {
                this.colleges = result;
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingColleges = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the colleges", reason);
            });
        };

        importColleges(file) {
            this.Upload.upload(<any>{
                url: this.config.apiHost + "/lookup/school/colleges/import",
                method: "POST",
                file: file
            }).then((response) => {
                this.uploadingFile = false;
                this.getColleges();
            });
        }

        searchColleges = (): void => {
            this.common.debouncedThrottle("CollegesSearch", () => {
                this.page = 1;
                this.sortByRelevance = this.searchFilter ? true : false;;
                this.getColleges();
            }, 500);
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.searchFilter = "";
            this.sortByRelevance = false;
            this.getColleges();
        };

        selectCollege = (college: ICollege) => {
            this.currentCollege = college;
        };

        createNewCollege = () => {
            this.$location.path("/admin/colleges/new");
        };

        editCollege = () => {
            this.$location.path("/admin/" + this.currentCollege.id);
        };

        deleteCollege = () => {
            var confirm = this.dialogs.confirm("Delete College", "Are you sure you want to delete this college?");

            confirm.result.then((button) => {
                this.Restangular.one("lookup").one("school").one(this.currentCollege.id).remove().then((result) => {
                    this.getColleges();
                    this.currentCollege = null;
                    this.logSuccess("College Deleted Successfully", null, true);
                }, (reason) => {
                    this.logError(reason.message, reason, true);
                });
            });


        };

    }
    angular.module("app").controller(Colleges.controllerId, Colleges);
}