namespace Advant.Crossroads {

    interface IMajorsUploadScope extends ng.IScope {
        vm: MajorsUpload;
    }

    interface IMajorsUpload {
    }

    class MajorsUpload implements IMajorsUpload {
        static controllerId: string = "majorsUpload";
        static $inject: Array<string> = ["config", "Upload", "$scope", "$routeParams", "common", "Restangular", "spinner", "dialogs"];
        private log;
        logSuccess: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;

        constructor(private config: Advant.Crossroads.ICrossroadsConfig,
            private Upload: angular.angularFileUpload.IUploadService,
            private $scope: IMajorsUploadScope, private $routeParams,
            private common: ICommonService,
            private Restangular: Restangular.IService,
            private spinner: ISpinnerService,
            private dialogs: angular.dialogs.IDialogService) {
            this.log = common.logger.getLogFn(MajorsUpload.controllerId);

            this.activate();
        }

        activate() {
            this.common.activateController([], MajorsUpload.controllerId)
                .then(() => {
                    this.log("Majors upload");
                    this.logSuccess = this.common.logger.getLogFn(MajorsUpload.controllerId, "success");
                    this.logError = this.common.logger.getLogFn(MajorsUpload.controllerId, "error");
                });
        }

        uploadMajors = (file: any) => {
            if (file.length > 0) {
                this.spinner.spinnerShow();
                this.Upload.upload(<any>{
                    url: this.config.apiHost + "/ProfileSettings/UploadMajorsFile/Majors/attachment",
                    method: "POST",
                    file: file,
                    fields: { 'name': file[0].name }
                }).then((response) => {
                    this.spinner.spinnerHide();
                    this.logSuccess("File was uploaded successfully", null, true);
                }, (reason) => {
                    this.spinner.spinnerHide();
                    console.error("Failed to upload attachment", reason);
                    this.logError("Failed to upload attachment", reason, true);
                });
            }
        }

        uploadSchoolPrograms = (file: any) => {
            if (file.length > 0) {
                this.spinner.spinnerShow();
                this.Upload.upload(<any>{
                    url: this.config.apiHost + "/ProfileSettings/UploadSchoolProgramsFile/Schools/update",
                    method: "POST",
                    file: file,
                    //fields: { 'name': file[0].name }
                }).then((response) => {
                    this.spinner.spinnerHide();
                    this.logSuccess("File was uploaded successfully", null, true);
                }, (reason) => {
                    this.spinner.spinnerHide();
                    console.error("Failed to upload attachment", reason.message);
                    this.logError("Failed to upload attachment", reason.message, true);
                });
            }
        }
    }

    angular.module("app").controller(MajorsUpload.controllerId, MajorsUpload);
}