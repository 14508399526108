/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    export class RuleSetFile {

        constructor(public rule?: IRuleSet) {

        }

        toJson = () => {
            return angular.toJson(this);
        };

        public static fromJson = (json: string): RuleSetFile => {
            var importValue = angular.fromJson(json);
            var returnValue: RuleSetFile = new RuleSetFile();
            if (importValue) {
                if (importValue.hasOwnProperty("rule")) {
                    returnValue.rule = importValue.rule;
                }
            }

            return returnValue;
        }
    }
}