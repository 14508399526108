/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    export interface IHttpBuffer {
        append: (config, deferred: ng.IDeferred<any>) => void;
        rejectAll: (reason?: {}) => void;
        retryAll: (updater?: (buffer?) => any) => void;
    }

    httpBuffer.$inject = ["$injector"];

    function httpBuffer($injector: ng.auto.IInjectorService): IHttpBuffer {
        /** Holds all the requests, so they can be re-requested in future. */
        var buffer = [];

        /** Service initialized later because of circular dependency problem. */
        var $http;

        function retryHttpRequest(config, deferred) {
            $http = $http || $injector.get('$http');

            $http(config).then((response) => {
                deferred.resolve(response);
            }, (reason) => {
                deferred.reject(reason);
            });
        }

        function append(config, deferred) {
            buffer.push({
                config: config,
                deferred: deferred
            });
        }

        /**
         * Abandon or reject (if reason provided) all the buffered requests.
         */
        function rejectAll(reason?: {}) {
            if (reason) {
                for (var i = 0; i < buffer.length; ++i) {
                    buffer[i].deferred.reject(reason);
                }
            }
            buffer = [];
        }

        /**
       * Retries all the buffered requests clears the buffer.
       */
        function retryAll(updater?: (buffer?) => any) {
            for (var i = 0; i < buffer.length; ++i) {
                var _cfg = updater(buffer[i].config);
                if (_cfg !== false)
                    retryHttpRequest(_cfg, buffer[i].deferred);
            }
            buffer = [];
        }


        var service: IHttpBuffer = {
            append: append,
            rejectAll: rejectAll,
            retryAll: retryAll
        };

        return service;
    }


    angular.module("app").factory("httpBuffer", httpBuffer);
}