/// <reference path="../app.ts" />
/// <reference path="../services/advant-auth.ts" />
namespace Advant.Crossroads {

    export interface IAdvPermission extends angular.IDirective {
        transclude: any;
    }

    export interface IAdvPermissionScope extends angular.IScope {

    }

    export class AdvPermission implements IAdvPermission {
        static directiveId: string = "advPermission";
        transclude = "element";
        restrict: string = "A";
        priority: number = 600;
        terminal: boolean = true;
        $$tbl: boolean = true;

        constructor(private $animate: angular.animate.IAnimateService, private authentication: AuthenticationProviderService) {
        }

        link = (scope: IAdvPermissionScope, element, attr, ctrl, transclude) => {
            var block, childScope, previousElements;

            var showHide = () => {
                var userInfo = this.authentication.getUser();

                if (userInfo[attr.advPermission] && userInfo[attr.advPermission] === true) {
                    if (!childScope) {
                        childScope = scope.$new();
                        transclude(childScope, (clone) => {
                            clone[clone.length++] = document.createComment(" end advPermission: " + attr.advPermission + " ");
                            // Note: We only need the first/last node of the cloned nodes.
                            // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                            // by a directive with templateUrl when its template arrives.
                            block = {
                                clone: clone
                            };

                            this.$animate.enter(clone, element.parent(), element);
                        });
                    }
                } else {
                    if (previousElements) {
                        previousElements.remove();
                        previousElements = null;
                    }
                    if (childScope) {
                        childScope.$destroy();
                        childScope = null;
                    }
                    if (block) {
                        previousElements = this.getBlockElements(block.clone);
                        this.$animate.leave(previousElements);                        
                        block = null;
                    }
                }
            };

            if (attr.checkIf) {
                scope.$watch(attr.checkIf, (newValue) => {
                    if (newValue) {
                        showHide();
                    } else {
                        if (!childScope) {
                            childScope = scope.$new();
                            transclude(childScope, (clone) => {
                                clone[clone.length++] = document.createComment(" end advPermission: " + attr.advPermission + " ");
                                // Note: We only need the first/last node of the cloned nodes.
                                // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                                // by a directive with templateUrl when its template arrives.
                                block = {
                                    clone: clone
                                };

                                this.$animate.enter(clone, element.parent(), element);
                            });
                        }
                    }
                });
            } else {
                showHide();
            }


        };

        getBlockElements = (nodes) => {
            var startNode = nodes[0],
                endNode = nodes[nodes.length - 1];
            if (startNode === endNode) {
                return angular.element(startNode);
            }

            var element = startNode;
            var elements = [element];

            do {
                element = element.nextSibling;
                if (!element) {
                    break;
                }
                elements.push(element);
            } while (element !== endNode);

            return angular.element(elements);
        };
    }


    angular.module("app").directive(AdvPermission.directiveId, ["$animate", "authentication", ($animate: angular.animate.IAnimateService, authentication: AuthenticationProviderService) =>
        (new AdvPermission($animate, authentication) as any)
    ]);
}