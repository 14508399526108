namespace Advant.Crossroads {
    "use strict";

    interface IExportFieldDialogScope {
        addOption: () => void;
        removeOption: (optionIndex: number) => void;
        exportReplacementLookup: () => void;
        importReplacementLookup: () => void;
        save: () => void;
        cancel: () => void;
        exportField: IExportField;
    }

    interface IExportFieldDialog {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
    }

    class ExportFieldDialog implements IExportFieldDialog {
        static controllerId: string = "exportFieldDialog";
        static $inject: any = ["$scope", "$uibModalInstance", "common", "config", "Restangular", "dialogs", "data"];
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;

        constructor(private $scope: IExportFieldDialogScope,
            private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private Restangular: Restangular.IService,
            private dialogs: angular.dialogs.IDialogService,
            private exportField?: IExportField) {
            this.log = common.logger.getLogFn(ExportFieldDialog.controllerId);
            this.logError = this.common.logger.getLogFn(ExportFieldDialog.controllerId, "error");

            $scope.addOption = () => this.addOption();
            $scope.removeOption = (optionIndex: number) => this.removeOption(optionIndex);
            $scope.exportReplacementLookup = () => this.exportReplacementLookup();
            $scope.importReplacementLookup = () => this.importReplacementLookup();
            $scope.save = () => this.save();
            $scope.cancel = () => this.cancel();
            $scope.exportField = exportField;
        }

        private addOption() {
            if (!(<any>this.$scope.exportField.exportFormatter).replacementLookup) {
                (<any>this.$scope.exportField.exportFormatter).replacementLookup = [];
            }
            (<any>this.$scope.exportField.exportFormatter).replacementLookup.push({});
        }
        private removeOption(optionIndex: number) {
            (<any>this.$scope.exportField.exportFormatter).replacementLookup.splice(optionIndex, 1);
        }

        aceGroupEditorLoaded = (aceEditor: AceAjax.Editor) => {
            aceEditor.setShowPrintMargin(false);
            aceEditor.setTheme("ace/theme/chrome");
            aceEditor.getSession().setMode("ace/mode/javascript");
            (<any>aceEditor).setOptions({
                enableSnippets: true,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true
            });

            (<any>aceEditor).setAutoScrollEditorIntoView(true);
            (<any>aceEditor).setOption("maxLines", 50);
            (<any>aceEditor).setOption("minLines", 5);
        };

        save = () => {
            if (this.$scope.exportField.exportFormatter && !this.$scope.exportField.exportFormatter.$type) {
                this.$scope.exportField.exportFormatter = null;
            }

            this.$uibModalInstance.close(this.$scope.exportField);
        };

        cancel = () => {
            this.$uibModalInstance.dismiss("cancel");
        };

        private exportReplacementLookup = () => {
            var replacementLookupFile: ReplacementLookupFile = new ReplacementLookupFile((<any>this.$scope.exportField.exportFormatter).replacementLookup);

            var blob: Blob = new Blob([replacementLookupFile.toJson()], { type: "application/octet-stream" });
            saveAs(blob, this.exportField.title + "_Replace_Value_Filter.json");
        };

        private importReplacementLookup = () => {
            var importFile = this.dialogs.create("/app/exportSettings/importReplacementLookup.html", "importReplacementLookup", null, { size: "lg" });

            importFile.result.then((result: ReplacementLookupFile) => {
                if ((<any>this.$scope.exportField.exportFormatter).replacementLookup && (<any>this.$scope.exportField.exportFormatter).replacementLookup.length > 0) {
                    var confirm = this.dialogs.confirm("Import Replacement Lookup", "Continuing will overwrite the existing replacement lookup list. Are you sure you want to continue?", null);
                    confirm.result.then((overwrite) => {
                        if (overwrite == "yes") {
                            (<any>this.$scope.exportField.exportFormatter).replacementLookup = result.replacementLookup;
                        }
                    });
                } else {
                    (<any>this.$scope.exportField.exportFormatter).replacementLookup = result.replacementLookup;
                }
                
                
            });
        };
    }

    angular.module("app").controller(ExportFieldDialog.controllerId, ExportFieldDialog);
}