/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IImportProspects {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        uploadingFile: boolean;
        source: string;
        file: any;
        activate: () => void;
    }

    class ImportProspects implements IImportProspects {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        uploadingFile: boolean;
        source: string;
        file: any;

        static $inject: string[] = ["$scope", "$location", "Restangular", "common", "config", "Upload"];

        constructor(private $scope: angular.IScope,
            private $location: angular.ILocationService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private upload: angular.angularFileUpload.IUploadService) {
            this.log = common.logger.getLogFn("importProspects");
            this.logError = common.logger.getLogFn("importProspects", "error");
            this.logSuccess = common.logger.getLogFn("importProspects", "success");


            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.activate();
        }


        importProspects = () => {
            if (this.file) {
                this.uploadingFile = true;
                this.upload.upload(<any>{
                    url: this.config.apiHost + "/" + this.common.getUser().activeApplication + "/prospectsimport?source=" + this.source,
                    method: "POST",
                    data: { file: this.file },
                    
                }).then((response) => {
                    this.uploadingFile = false;
                    this.file = null;
                    this.logSuccess("Prospects imported successfully.", null, true)
                }, (reason) => {
                    this.logError(reason, reason, true);
                });
            }
        }

        selectFile = ($file) => {
            if ($file) {
                this.file = $file;
            }

        }

        clearFile = () => {
            this.file = null;
        }

        activate = () => {
            this.common.activateController([], "importProspects")
                .then(result => {
                    this.log("Activated Import Prospects View");
                });
        };

       
    }

    angular.module("app").controller("importProspects", ImportProspects);
}