/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    export interface ICrossroadsService {
        organiation: angular.resource.IResourceClass<any>;
        program: angular.resource.IResourceClass<any>;
    }

    export class CrossroadsService implements ICrossroadsService {
        static serviceId: string = "crossroads";
        public organiation: angular.resource.IResourceClass<any>;
        public program: angular.resource.IResourceClass<any>;

        constructor(private $resource: angular.resource.IResourceService, authentication: Advant.Crossroads.AuthenticationProviderService) {
            this.organiation = $resource("/organizations", {}, {
                query: {
                    method: "GET",
                    isArray: true,
                    headers: { "Authorization": "Bearer " + authentication.accessToken() }
                }
            });

            this.program = $resource("/programs", {}, {
                query: {
                    method: "GET",
                    isArray: true,
                    headers: { "Authorization": "Bearer " + authentication.accessToken() }
                }
            });
        }


    }


    angular.module("app").factory(CrossroadsService.serviceId, ["$resource", "authentication", ($resource, authentication) =>
        new CrossroadsService($resource, authentication)
    ]);
}
