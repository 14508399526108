namespace Advant.Crossroads {
    "use strict";

    interface IProspectExportSettingsScope extends angular.IScope {
        vm: ProspectExportSettings;
    }

    interface IProspectExportSettings {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingSettings: boolean;
        settings: IExportSettings[];
        currentSetting: IExportSettings;
        page: number;
        pageSize: number;
        totalCount: number;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;
    }

    class ProspectExportSettings implements IProspectExportSettings {
        static controllerId: string = "prospectExportSettings";
        static $inject = ["$scope", "$location", "$timeout", "dialogs", "Restangular", "common", "config", "helper"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingSettings: boolean;
        settings: IExportSettings[];
        currentSetting: IExportSettings;
        page: number;
        pageSize: number;
        totalCount: number;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;

        constructor(private $scope: IProspectExportSettingsScope,
            private $location: angular.ILocationService,
            private $timeout: angular.ITimeoutService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private helper: IHelper) {
            this.log = common.logger.getLogFn(ProspectExportSettings.controllerId);
            this.logError = common.logger.getLogFn(ProspectExportSettings.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(ProspectExportSettings.controllerId, "success");

            this.loadingSettings = false;

            this.page = 1;
            this.pageSize = 25;
            this.sortBy = "Name";
            this.sortDirection = "ASC";
            this.searchFilter = "";

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getSettings()], ProspectExportSettings.controllerId)
                .then(result => {
                    self.log("Activated Prospect Export Settings View");
                    return result;
                });
        }

        createNewSetting() {
            this.$location.path("/prospect/export/settings/prospectExportSettings/new");
        }

        copySetting = () => {
            var dlg = this.dialogs.create("/app/exportSettings/exportSettingsCopyDialog.html",
                "exportSettingsCopyDialog",
                { name: this.currentSetting.name },
                { keyboard: true, backdrop: true });
            dlg.result.then((settingName) => {
                var newSetting = angular.copy(this.currentSetting);

                newSetting.name = settingName;
                newSetting.id = null;

                this.Restangular.all(this.common.getUser().activeApplication).all("prospectExportSettings").post(newSetting).then((result) => {
                    this.logSuccess("Your prospect export settings have been saved", result, true);
                    this.$timeout(this.getSettings, 500);
                }, (reason) => {
                    var message = "Error saving new setting!";
                    if (reason.data.modelState) {
                        angular.forEach(reason.data.modelState, (value: any, key: any) => {
                            message += "<br/>" + value;
                        });
                    }
                    this.logError(message, reason, true);
                    this.getSettings();
                });
            });
        };


        deleteSetting = () => {
            var dlg = this.dialogs.confirm("Please Confirm",
                "Are you sure you want to delete this export setting?");
            dlg.result.then(() => {
                this.currentSetting.remove().then((result) => {
                    this.getSettings();
                    return result;
                }, (reason) => {
                    this.logError("An error occurred while deleting the export settings", reason, true);
                    return;
                });
            });
        };

        editSetting = () => {
            this.$location.path("/prospect/export/settings/" + this.currentSetting.id);
        };

        getSettings = (): angular.IPromise<void | Array<IExportSettings>> => {
            this.loadingSettings = true;
            this.settings = [];
            this.currentSetting = null;

            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                filter: this.searchFilter
            };

            return this.Restangular.one(this.common.getUser().activeApplication).getList("prospectExportSettings", queryParams).then((result: IRestangularResult<IExportSettings>) => {
                this.settings = result;
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingSettings = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the export settings", reason);
            });
        };

        searchExportSettings = (): void => {
            this.common.debouncedThrottle("ProspectExportSettingsSearch", () => { this.page = 1; this.getSettings(); }, 400);
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.getSettings();
        };

    }


    angular.module("app").controller(ProspectExportSettings.controllerId, ProspectExportSettings);
} 