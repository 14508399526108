/// <reference path="../app.ts" />
/// <reference path="../services/fieldautocompleteparser.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IAutoCompleteSearchResult {
        value: string;
        start: number;
        end: number;
    }

    interface IAdvFieldAutoComplete extends angular.IDirective {
    }

    interface IAdvFieldAutoCompleteScope extends angular.IScope {
        matches: any;
        activeIdx: any;
        query: any;
        select: any;
        moveInProgress: any;
        position: any;
    }

    interface IAdvFieldAutoCompleteAttributes extends angular.IAttributes {
        advFieldAutoCompleteLoading: string;
        advFieldAutoCompleteMinLength: string;
        advFieldAutoCompleteWaitMs: string;
        advFieldAutoCompleteEditable: string;
        advFieldAutoCompleteOnSelect: string;
        advFieldAutoCompleteSelectOnBlur: string;
        advFieldAutoCompleteNoResults: string;
        typeaheadInputFormatter: string;
        advFieldAutoCompleteAppendToBody: string;
        advFieldAutoCompleteFocusFirst: string;
        advFieldAutoCompleteSelectOnExact: string;
        advFieldAutoComplete: string;
        ngModel: string;
        advFieldAutoCompleteTemplateUrl: string;
        advFieldAutoCompleteFocusOnSelect: string;
    }

    advFieldAutoComplete.$inject = ["$compile", "$parse", "$q", "$timeout", "$document", "$window", "$rootScope", "$uibPosition", "fieldAutoCompleteParser"];

    function advFieldAutoComplete($compile: angular.ICompileService, $parse: angular.IParseService, $q: angular.IQService, $timeout: angular.ITimeoutService, $document: angular.IDocumentService, $window: angular.IWindowService,
        $rootScope: angular.IRootScopeService, $uibPosition: angular.ui.bootstrap.IPositionService, fieldAutoCompleteParser: IFieldAutoCompleteParser): IAdvFieldAutoComplete {

        var HOT_KEYS = [9, 13, 27, 38, 40];
        var eventDebounceTime = 200;

        return {
            restrict: "A",
            require: "ngModel",
            link: link
        };

        function link(originalScope: IAdvFieldAutoCompleteScope, element: angular.IAugmentedJQuery, attrs: IAdvFieldAutoCompleteAttributes, modelCtrl: angular.INgModelController) {
            //SUPPORTED ATTRIBUTES (OPTIONS)

            //minimal no of characters that needs to be entered before typeahead kicks-in
            var minLength = originalScope.$eval(attrs.advFieldAutoCompleteMinLength);
            if (!minLength && minLength !== 0) {
                minLength = 1;
            }

            //minimal wait time after last character typed before typeahead kicks-in
            var waitTime = originalScope.$eval(attrs.advFieldAutoCompleteWaitMs) || 0;

            //should it restrict model values to the ones selected from the popup only?
            var isEditable = true; //originalScope.$eval(attrs.advFieldAutoCompleteEditable) !== false;

            //binding to a variable that indicates if matches are being retrieved asynchronously
            var isLoadingSetter = $parse(attrs.advFieldAutoCompleteLoading).assign || angular.noop;

            //a callback executed when a match is selected
            var onSelectCallback = $parse(attrs.advFieldAutoCompleteOnSelect);

            //should it select highlighted popup value when losing focus?
            var isSelectOnBlur = angular.isDefined(attrs.advFieldAutoCompleteSelectOnBlur) ? originalScope.$eval(attrs.advFieldAutoCompleteSelectOnBlur) : false;

            //binding to a variable that indicates if there were no results after the query is completed
            var isNoResultsSetter = $parse(attrs.advFieldAutoCompleteNoResults).assign || angular.noop;

            var inputFormatter = attrs.typeaheadInputFormatter ? $parse(attrs.typeaheadInputFormatter) : undefined;

            var appendToBody = attrs.advFieldAutoCompleteAppendToBody ? originalScope.$eval(attrs.advFieldAutoCompleteAppendToBody) : false;

            var focusFirst = originalScope.$eval(attrs.advFieldAutoCompleteFocusFirst) !== false;

            //If input matches an item of the list exactly, select it automatically
            var selectOnExact = attrs.advFieldAutoCompleteSelectOnExact ? originalScope.$eval(attrs.advFieldAutoCompleteSelectOnExact) : false;

            //INTERNAL VARIABLES

            //model setter executed upon match selection
            var $setModelValue = $parse(attrs.ngModel).assign;

            //expressions used by typeahead
            var parserResult = fieldAutoCompleteParser.parse(attrs.advFieldAutoComplete);

            var hasFocus: boolean;

            //Used to avoid bug in iOS webview where iOS keyboard does not fire
            //mousedown & mouseup events
            //Issue #3699
            var selected: boolean;

            //create a child scope for the typeahead directive so we are not polluting original scope
            //with typeahead-specific data (matches, query etc.)
            var scope = <IAdvFieldAutoCompleteScope>originalScope.$new();
            originalScope.$on("$destroy", () => {
                scope.$destroy();
            });

            // WAI-ARIA
            var popupId = `typeahead-${scope.$id}-${Math.floor(Math.random() * 10000)}`;
            element.attr({
                'aria-autocomplete': "list",
                'aria-expanded': false,
                'aria-owns': popupId
            });

            //pop-up element used to display matches
            var popUpEl = angular.element("<div typeahead-popup></div>");
            popUpEl.attr({
                id: popupId,
                matches: "matches",
                active: "activeIdx",
                select: "select(activeIdx)",
                'move-in-progress': "moveInProgress",
                query: "query",
                position: "position"
            });
            //custom item template
            if (angular.isDefined(attrs.advFieldAutoCompleteTemplateUrl)) {
                popUpEl.attr("template-url", attrs.advFieldAutoCompleteTemplateUrl);
            }

            var resetMatches = () => {
                scope.matches = [];
                scope.activeIdx = -1;
                element.attr("aria-expanded", "false");
            };

            var getMatchId = index => (popupId + "-option-" + index);

            // Indicate that the specified match is the active (pre-selected) item in the list owned by this typeahead.
            // This attribute is added or removed automatically when the `activeIdx` changes.
            scope.$watch("activeIdx", index => {
                if (index < 0) {
                    element.removeAttr("aria-activedescendant");
                } else {
                    element.attr("aria-activedescendant", getMatchId(index));
                }
            });

            var inputIsExactMatch = (inputValue, index) => {
                if (scope.matches.length > index && inputValue) {
                    return inputValue.toUpperCase() === scope.matches[index].label.toUpperCase();
                }

                return false;
            };

            function getSearchValue(inputValue: string): IAutoCompleteSearchResult {
                if (inputValue.length < 2) {
                    return null;
                }

                var currentPosition = getCaretPosition(element[0]);

                if (currentPosition < 2) {
                    return null;
                }

                var start: number;
                var end: number;

                for (var s = currentPosition; s > 0; s--) {
                    if (inputValue[s] === "[" && inputValue[s - 1] === "[") {
                        start = s - 1;
                        end = inputValue.length;
                        for (var e = s; e < (inputValue.length - 1); e++) {
                            if (inputValue[e] === "]" && inputValue[e + 1] === "]") {
                                end = e + 1;
                                break;
                            }
                        }
                        break;
                    }
                    if (inputValue[s] === "]" && inputValue[s - 1] === "]") {
                        return null;
                    }
                }

                if (start != null && end != null) {
                    var searchStringLength: number;
                    if (end !== inputValue.length) {
                        searchStringLength = end - (start + 1);
                    } else {
                        searchStringLength = end - start;
                    }
                    return {
                        value: inputValue.substr(start, searchStringLength),
                        start: start,
                        end: end
                    };
                }

                return null;
            }

            var getMatchesAsync = inputValue => {
                var locals = { $viewValue: inputValue };
                isLoadingSetter(originalScope, true);
                isNoResultsSetter(originalScope, false);
                $q.when(parserResult.source(originalScope, locals)).then(matches => {
                    //it might happen that several async queries were in progress if a user were typing fast
                    //but we are interested only in responses that correspond to the current view value
                    var searchValue = getSearchValue(modelCtrl.$viewValue);
                    var onCurrentRequest = (inputValue === (searchValue == null ? modelCtrl.$viewValue : searchValue.value));
                    if (onCurrentRequest && hasFocus) {
                        if (matches && matches.length > 0) {

                            scope.activeIdx = focusFirst ? 0 : -1;
                            isNoResultsSetter(originalScope, false);
                            scope.matches.length = 0;

                            //transform labels
                            for (var i = 0; i < matches.length; i++) {
                                locals[parserResult.itemName] = matches[i];
                                scope.matches.push({
                                    id: getMatchId(i),
                                    label: parserResult.viewMapper(scope, locals),
                                    model: matches[i]
                                });
                            }

                            scope.query = inputValue;
                            //position pop-up with matches - we need to re-calculate its position each time we are opening a window
                            //with matches as a pop-up might be absolute-positioned and position of an input might have changed on a page
                            //due to other elements being rendered
                            recalculatePosition();

                            element.attr("aria-expanded", "true");

                            //Select the single remaining option if user input matches
                            if (selectOnExact && scope.matches.length === 1 && inputIsExactMatch(inputValue, 0)) {
                                scope.select(0);
                            }
                        } else {
                            resetMatches();
                            isNoResultsSetter(originalScope, true);
                        }
                    }
                    if (onCurrentRequest) {
                        isLoadingSetter(originalScope, false);
                    }
                }, () => {
                    resetMatches();
                    isLoadingSetter(originalScope, false);
                    isNoResultsSetter(originalScope, true);
                });
            };

            // bind events only if appendToBody params exist - performance feature
            if (appendToBody) {
                angular.element($window).bind("resize", fireRecalculating);
                $document.find("body").bind("scroll", fireRecalculating);
            }

            // Declare the timeout promise var outside the function scope so that stacked calls can be cancelled later
            var timeoutEventPromise: angular.IPromise<any>;

            // Default progress type
            scope.moveInProgress = false;


            function getCaretPosition(element: any): number {
                if ("selectionStart" in element) {
                    return element.selectionStart;
                } else if ((<any>document).selection) {
                    element.focus();
                    var sel = (<any>document).selection.createRange();
                    var selLen = (<any>document).selection.createRange().text.length;
                    sel.moveStart("character", -element.value.length);
                    return sel.text.length - selLen;
                }
                return 0;
            }

            // recalculate actual position and set new values to scope
            // after digest loop is popup in right position
            function recalculatePosition() {
                var caretOffset = (getCaretPosition(element[0]) * 7);
                scope.position = appendToBody ? $uibPosition.offset(element) : $uibPosition.position(element);
                scope.position.top += element.prop("offsetHeight");
                scope.position.left += caretOffset < (($uibPosition.position(element).width - 140) + $uibPosition.position(element).left) ? caretOffset : ($uibPosition.position(element).width - 140) + $uibPosition.position(element).left;
            }

            function fireRecalculating() {
                if (!scope.moveInProgress) {
                    scope.moveInProgress = true;
                    scope.$digest();
                }

                // Cancel previous timeout
                if (timeoutEventPromise) {
                    $timeout.cancel(timeoutEventPromise);
                }

                // Debounced executing recalculate after events fired
                timeoutEventPromise = $timeout(() => {
                    // if popup is visible
                    if (scope.matches.length) {
                        recalculatePosition();
                    }

                    scope.moveInProgress = false;
                    scope.$digest();
                }, eventDebounceTime);
            }

            resetMatches();

            //we need to propagate user's query so we can higlight matches
            scope.query = undefined;

            //Declare the timeout promise var outside the function scope so that stacked calls can be cancelled later
            var timeoutPromise: angular.IPromise<any>;

            var scheduleSearchWithTimeout = inputValue => {
                timeoutPromise = $timeout(() => {
                    getMatchesAsync(inputValue);
                }, waitTime);
            };

            var cancelPreviousTimeout = () => {
                if (timeoutPromise) {
                    $timeout.cancel(timeoutPromise);
                }
            };

            function replaceSearchValue(inputValue: string, newValue: string) {
                var searchValue = getSearchValue(inputValue);

                var returnValue = inputValue.slice(0, searchValue.start) + newValue + inputValue.slice(searchValue.end < inputValue.length ? searchValue.end + 1 : searchValue.end);

                return returnValue;
            }

            //plug into $parsers pipeline to open a typeahead on view changes initiated from DOM
            //$parsers kick-in on all the changes coming from the view as well as manually triggered by $setViewValue
            modelCtrl.$parsers.unshift(inputValue => {
                hasFocus = true;

                if (minLength === 0 || inputValue && inputValue.length >= minLength) {
                    if (waitTime > 0) {
                        cancelPreviousTimeout();
                        scheduleSearchWithTimeout(inputValue);
                    } else {
                        var valueToMathch = getSearchValue(inputValue);
                        getMatchesAsync(valueToMathch == null ? null : valueToMathch.value);
                    }
                } else {
                    isLoadingSetter(originalScope, false);
                    cancelPreviousTimeout();
                    resetMatches();
                }

                if (isEditable) {
                    return inputValue;
                } else {
                    if (!inputValue) {
                        // Reset in case user had typed something previously.
                        modelCtrl.$setValidity("editable", true);
                        return null;
                    } else {
                        modelCtrl.$setValidity("editable", false);
                        return undefined;
                    }
                }
            });

            modelCtrl.$formatters.push(modelValue => {
                var candidateViewValue, emptyViewValue;
                var locals: any = {};

                // The validity may be set to false via $parsers (see above) if
                // the model is restricted to selected values. If the model
                // is set manually it is considered to be valid.
                if (!isEditable) {
                    modelCtrl.$setValidity("editable", true);
                }

                if (inputFormatter) {
                    locals.$model = modelValue;
                    return inputFormatter(originalScope, locals);
                } else {
                    //it might happen that we don't have enough info to properly render input value
                    //we need to check for this situation and simply return model value if we can't apply custom formatting
                    locals[parserResult.itemName] = modelValue;
                    candidateViewValue = parserResult.viewMapper(originalScope, locals);
                    locals[parserResult.itemName] = undefined;
                    emptyViewValue = parserResult.viewMapper(originalScope, locals);

                    return candidateViewValue !== emptyViewValue ? candidateViewValue : modelValue;
                }
            });

            scope.select = activeIdx => {
                //called from within the $digest() cycle
                var locals = {};
                var model, item;
                selected = true;
                locals[parserResult.itemName] = item = scope.matches[activeIdx].model;
                model = parserResult.modelMapper(originalScope, locals);
                var fullModel = replaceSearchValue(modelCtrl.$viewValue, model);
                $setModelValue(originalScope, fullModel);
                modelCtrl.$setValidity("editable", true);
                modelCtrl.$setValidity("parse", true);

                onSelectCallback(originalScope, {
                    $item: item,
                    $model: model,
                    $label: parserResult.viewMapper(originalScope, locals)
                });

                resetMatches();

                //return focus to the input element if a match was selected via a mouse click event
                // use timeout to avoid $rootScope:inprog error
                if (scope.$eval(attrs.advFieldAutoCompleteFocusOnSelect) !== false) {
                    $timeout(() => { element[0].focus(); }, 0, false);
                }
            };

            //bind keyboard events: arrows up(38) / down(40), enter(13) and tab(9), esc(27)
            element.bind("keydown", evt => {
                //typeahead is open and an "interesting" key was pressed
                if (scope.matches.length === 0 || HOT_KEYS.indexOf(evt.which) === -1) {
                    return;
                }

                // if there's nothing selected (i.e. focusFirst) and enter or tab is hit, clear the results
                if (scope.activeIdx === -1 && (evt.which === 9 || evt.which === 13)) {
                    resetMatches();
                    scope.$digest();
                    return;
                }

                evt.preventDefault();

                if (evt.which === 40) {
                    scope.activeIdx = (scope.activeIdx + 1) % scope.matches.length;
                    scope.$digest();

                } else if (evt.which === 38) {
                    scope.activeIdx = (scope.activeIdx > 0 ? scope.activeIdx : scope.matches.length) - 1;
                    scope.$digest();

                } else if (evt.which === 13 || evt.which === 9) {
                    scope.$apply(() => {
                        scope.select(scope.activeIdx);
                    });

                } else if (evt.which === 27) {
                    evt.stopPropagation();

                    resetMatches();
                    scope.$digest();
                }
            });

            element.bind("blur", () => {
                if (isSelectOnBlur && scope.matches.length && scope.activeIdx !== -1 && !selected) {
                    selected = true;
                    scope.$apply(() => {
                        scope.select(scope.activeIdx);
                    });
                }
                hasFocus = false;
                selected = false;
            });

            // Keep reference to click handler to unbind it.
            var dismissClickHandler = evt => {
                // Issue #3973
                // Firefox treats right click as a click on document
                if (element[0] !== evt.target && evt.which !== 3 && scope.matches.length !== 0) {
                    resetMatches();
                    if (!$rootScope.$$phase) {
                        scope.$digest();
                    }
                }
            };

            $document.bind("click", dismissClickHandler);

            originalScope.$on("$destroy", () => {
                $document.unbind("click", dismissClickHandler);
                if (appendToBody) {
                    $popup.remove();
                }
                // Prevent jQuery cache memory leak
                popUpEl.remove();
            });

            var $popup = $compile(popUpEl)(scope);

            if (appendToBody) {
                $document.find("body").append($popup);
            } else {
                element.after($popup);
            }
        }
    }

    angular.module("app").directive("advFieldAutoComplete", advFieldAutoComplete);
}