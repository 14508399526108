/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IProspects {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProspects: boolean;
        isSaving: boolean;
        uploadingFile: boolean;
        prospects: IProspect[];
        currentProspect: IProspect;

        page: number;
        pageSize: number;
        totalCount: number;
        sortByRelevance: boolean;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;

        activate: () => void;
        getProspects: () => angular.IPromise<IProspect[]>;

        filter: any;
        rules: Array<IRuleSet>;
        sources: string[];
        ruleId: string;
        source: string;
        dateStart: string;
        dateEnd: string;
    }

    class Prospects implements IProspects {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProspects: boolean;
        isSaving: boolean;
        uploadingFile: boolean;
        prospects: IProspect[];
        currentProspect: IProspect;

        page: number;
        pageSize: number;
        totalCount: number;
        sortByRelevance: boolean;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;
        prospectStatus: string = "open";

        filter: any;
        rules: Array<IRuleSet>;
        sources: string[];
        ruleId: string;
        source: string;
        dateStart: string;
        dateEnd: string;

        static $inject: string[] = ["$scope", "$location", "Restangular", "common", "config"];

        constructor(private $scope: angular.IScope,
            private $location: angular.ILocationService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig) {
            this.log = common.logger.getLogFn("prospects");
            this.logError = common.logger.getLogFn("prospects", "error");
            this.logSuccess = common.logger.getLogFn("prospects", "success");

            this.page = 1;
            this.pageSize = 25;
            this.sortByRelevance = false;
            this.sortBy = "Email";
            this.sortDirection = "ASC";
            this.searchFilter = "";

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.filter = {};
            this.filter.status = this.prospectStatus;
            this.activate();
        }


        activate = () => {
            this.common.activateController([this.getProspects(), this.getSources(), this.getRules()], "prospects")
                .then(result => {
                    this.log("Activated Prospects View");
                });
        };

        editProspect = () => {
            this.$location.path(this.currentProspect.id);
        };

        getProspects = (): angular.IPromise<IProspect[]> => {
            this.loadingProspects = true;
            this.prospects = [];
            this.currentProspect = null;
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortByRelevance: this.sortByRelevance,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                filter: this.searchFilter,
                open: this.prospectStatus == "open" ? true : this.prospectStatus == "closed" ? false : null,
                sourceId: this.source,
                createdDateStart: this.dateStart,
                createdDateEnd: this.dateEnd,
                ruleId: this.ruleId
            };


            return this.Restangular.one(this.common.getUser().activeApplication).getList("prospects", queryParams).then((result: IRestangularResult<IProspect>) => {
                this.prospects = result;
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingProspects = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the prospects", reason);
                return null;
            });
        };

        searchProspects = (): void => {
            this.common.debouncedThrottle("ProspectsSearch", () => {
                this.page = 1;
                this.sortByRelevance = true;
                this.sortBy = null;
                this.getProspects();
            }, 500);
        };

        selectProspect = (prospect: IProspect): void => {
            this.currentProspect = prospect;
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.searchFilter = "";
            this.sortByRelevance = false;
            this.getProspects();
        };

        close = () => {
            this.isSaving = true;
            this.currentProspect.closedDate = moment().toDate();
            var prospectRestangular: Restangular.IElement = <any>this.currentProspect;
            prospectRestangular.put().then(result => {
                this.logSuccess("Your prospect has been closed", null, true);
                this.isSaving = false;
                this.getProspects();
            }, error => {
                this.isSaving = false;
                this.logError("An error occurred while trying to save the prospect", error, true);
            });
        };

        getSources = (): angular.IPromise<string[]> => {
            return this.Restangular.one(this.common.getUser().activeApplication).all("prospects").all("sources").getList<string>().then((result) => {
                this.sources = result;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting sources", reason);
                return null;
            });
        };

        getRules = (): angular.IPromise<Array<IRuleSet>> => {

            this.rules = [];
            return this.Restangular.one(this.common.getUser().activeApplication).all("rules").getList<IRuleSet>({ type: "Prospect" }).then((result) => {
                this.rules = result;
                this.rules.unshift({ id: null, name: "" });
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the rules", reason);
                return null;
            });
        };

        applyFilters = () => {
            if (this.filter) {
                this.prospectStatus = this.filter.status ? this.filter.status : null
                this.ruleId = this.filter.ruleId ? this.filter.ruleId : null;
                this.dateEnd = this.filter.dateEnd ? this.filter.dateEnd : "";
                this.dateStart = this.filter.dateStart ? this.filter.dateStart : "";
                this.source = this.filter.source ? this.filter.source : null;
            }

            this.page = 1;
            this.getProspects();
        };

        clearFilter = () => {
            this.prospectStatus = "open";
            this.dateStart = null;
            this.dateEnd = null;
            this.source = null;
            this.ruleId = null;
            this.filter = {};
            this.filter.status = this.prospectStatus;
            this.getProspects();
        };
    }

    angular.module("app").controller("prospects", Prospects);
}