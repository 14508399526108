/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    export interface IProspectDetailRouteParams extends angular.route.IRouteParamsService {
        id: string;
    }

    interface IProspectDetail {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;

        prospect: IProspect;
        originalProspect: IProspect;
        isSaving: boolean;
        activate: () => void;
    }

    class ProspectDetail implements IProspectDetail {
        static controllerId: string = "prospectDetail";
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;

        prospect: IProspect;
        originalProspect: IProspect;
        isSaving: boolean;
        prospectName: string;

        static $inject = ["$scope", "$location", "$routeParams", "dialogs", "Restangular", "common", "config"];
        constructor(private $scope: angular.IScope,
            private $location: angular.ILocationService,
            private $routeParams: IProspectDetailRouteParams,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig) {

            this.log = common.logger.getLogFn(ProspectDetail.controllerId);
            this.logError = common.logger.getLogFn(ProspectDetail.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(ProspectDetail.controllerId, "success");

            $scope.$on(config.events.applicationChanged, (event) => {
                this.$location.path("/prospects");
            });
            this.activate();
        }

        activate() {
            var self = this;
            this.common.activateController([this.getProspect()], ProspectDetail.controllerId)
                .then(result => {
                    self.log("Activated Prospect Detail View");
                });
        }

        cancel = (): void => {
            angular.copy(this.originalProspect, this.prospect);
        };

        close = (): void => {
            this.prospect.closedDate = moment().toDate();
            this.save("close");
        };


        getProspect = (): angular.IPromise<IProspect> => {
            if (!_.isNaN(parseInt(this.$routeParams.id, 10))) {
                return this.Restangular.all(this.common.getUser().activeApplication).one("prospects", this.$routeParams.id).get().then((result) => {
                    this.prospect = result;
                    this.originalProspect = _.cloneDeep(this.prospect);
                    this.prospectName = this.prospect.name.first + " " + this.prospect.name.last;
                    return this.prospect;
                }, (reason) => {
                    this.logError("An error occurred while retrieving the prospect. Please try again later.", reason, true);
                    return this.prospect;
                });
            } else {
                this.prospect = {
                    applicationDefinitionId: this.common.getUser().activeApplication,
                    email: "",
                    exportedHistory: [],
                    physicalAddress: {
                        addressId: null,
                        street1: "",
                        street2: "",
                        state: "",
                        city: "",
                        zip: "",
                        country: "US"
                    },
                    mailingAddress: {
                        addressId: null,
                        street1: "",
                        street2: "",
                        state: "",
                        city: "",
                        zip: "",
                        country: "US"
                    }
                };

                this.originalProspect = _.cloneDeep(this.prospect);
                return this.common.$q.when(this.prospect);
            }

        };

        reopen = () => {
            this.prospect.closedDate = null;
            this.prospect.reOpenDate = moment().toDate();
            this.save("reopen");
        };

        save = (event?) => {
            this.isSaving = true;

            this.prospect.physicalAddress = this.addressValid(this.prospect.physicalAddress) ? this.prospect.physicalAddress : null;
            this.prospect.mailingAddress = this.addressValid(this.prospect.mailingAddress) ? this.prospect.mailingAddress : null;

            if (!this.prospect.id) {
                this.Restangular.all(this.common.getUser().activeApplication).all("prospects").post(this.prospect).then(result => {
                    this.logSuccess("Your new prospect has been created", null, true);
                    this.prospect = result;
                    this.originalProspect = _.cloneDeep(this.prospect);
                    this.isSaving = false;
                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to save the prospect", error, true);
                });
            } else {
                var prospectRestangular: Restangular.IElement = <any>this.prospect;
                prospectRestangular.put().then(result => {
                    switch (event) {
                        case "close":
                            this.logSuccess("Your prospect has been closed", null, true);
                            break;
                        case "reopen":
                            this.logSuccess("Your prospect has been reopened", null, true);
                            break;
                        default:
                            this.logSuccess("Your prospect has been updated", null, true);
                            break;
                    }

                    this.isSaving = false;
                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to save the prospect", error, true);
                });
            }
        };

        addressValid(address: IAddress) {
            if (address) {
                if (address.addressId == null && !address.city && !address.state && !address.street1 && !address.zip) {
                    return false;
                }
            }
            else {
                return false;
            }

            return true;

        };

        copyAddress = () => {
            this.prospect.physicalAddress = _.clone(this.prospect.mailingAddress);
        };
    }

    angular.module("app").controller("prospectDetail", ProspectDetail);
}