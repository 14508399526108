namespace Advant.Crossroads {


    interface IDisableDirtyFlag extends angular.IDirective {

    }

    interface IDisableDirtyFlagScope extends angular.IScope {

    }

    class DisableDirtyFlag implements IDisableDirtyFlag {
        static directiveId: string = "disableDirtyFlag";
        require: string = "ngModel";
        restrict: string = "A";

        link = (scope: IDisableDirtyFlagScope, element, attrs, ctrl: angular.INgModelController) => {
            /* tslint:disable: no-empty */
            var alwaysFalse = {
                get: () => { return false; },
                set: () => { }
            };
            Object.defineProperty(ctrl, "$pristine", alwaysFalse);
            Object.defineProperty(ctrl, "$dirty", alwaysFalse);

        }
    }

    angular.module("app").directive(DisableDirtyFlag.directiveId,() =>
        new DisableDirtyFlag()
        );
}