/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    interface IProfileSettingsScope extends ng.IScope {
        vm: ProfileSettings;
        highlights: ng.IFormController;
        quickFacts: ng.IFormController;
        contactInformation: ng.IFormController;
        campusLinks: ng.IFormController;
        quickCodes: ng.IFormController;
        campusSummary: ng.IFormController;
        aboutSpecialPrograms: ng.IFormController;

        adminOffice: ng.IFormController;
        applicationProcess: ng.IFormController;
        selectionProcess: ng.IFormController;

        academicCalendar: ng.IFormController;
        academicExperience: ng.IFormController;
        requiredCurriculum: ng.IFormController;
        programsOffered: ng.IFormController;
        creditPlacement: ng.IFormController;

        aidOffice: ng.IFormController;
        expenses: ng.IFormController;
        applyingForAid: ng.IFormController;
        typesOfAid: ng.IFormController;

        studentProfile: ng.IFormController;
        campusEnvironment: ng.IFormController;
        livingOnCampus: ng.IFormController;
        studyFacilities: ng.IFormController;
        assistanceServices: ng.IFormController;
        socialActivities: ng.IFormController;
        athletics: ng.IFormController;
        athleticParticipation: ng.IFormController;
        gettingAway: ng.IFormController;
        studentWorkOptions: ng.IFormController;
        afterGraduation: ng.IFormController;

        transferAcceptance: ng.IFormController;
        applicationCreditTransfer: ng.IFormController;

        internationalOffice: ng.IFormController;
        countriesRepresented: ng.IFormController;
        intlApplicationProcess: ng.IFormController;
        financialAidAvailability: ng.IFormController;

        disabledContact: ng.IFormController;
        aboutTheProgram: ng.IFormController;
        applyingToLD: ng.IFormController;
    }

    interface IProfileSettings {
    }

    class ProfileSettings implements IProfileSettings {
        static controllerId: string = "profileSettings";
        static $inject: Array<string> = ["config", "Upload", "$scope", "$routeParams", "common", "Restangular", "spinner", "dialogs"];
        private log;
        templateUrl: string = "about";
        institution: any;
        imageList: any;
        apiHost: string;
        institutionId: any;
        sat2Tests: any;
        fillerVariable: any;
        earlyDecisionMonth: any;
        earlyDecisionDay: any;
        earlyActionMonth: any;
        earlyActionDay: any;
        applicationDeadlineMonth: any;
        applicationDeadlineDay: any;
        interviewDeadlineMonth: any;
        interviewDeadlineDay: any;
        scoreDeadlineMonth: any;
        scoreDeadlineDay: any;
        subjectScoreMonth: any;
        subjectScoreDay: any;
        undergradNotificationMonth: any;
        undergradNotificationDay: any;
        admissionAcceptanceMonth: any;
        admissionAcceptanceDay: any;
        aidAwardsBeginMonth: any;
        aidAwardsBeginDay: any;
        tuitionRefund: boolean;
        roomRefund: boolean;
        artPortfolioReq: boolean;
        musicAuditionReq: boolean;
        danceAuditionReq: boolean;
        theatreAuditionReq: boolean;
        rnNusingReq: boolean;
        englishRequiredCredits: number;
        englishRecommendedCredits: number;
        foreignLanguageRequiredCredits: number;
        foreignLanguageRecommendedCredits: number;
        historyRequiredCredits: number;
        historyRecommendedCredits: number;
        scienceLabRequiredCredits: number;
        scienceLabRecommendedCredits: number;
        scienceRequiredCredits: number;
        scienceRecommendedCredits: number;
        mathRequiredCredits: number;
        mathRecommendedCredits: number;
        socialStudiesRequiredCredits: number;
        socialStudiesRecommendedCredits: number;
        electiveRequiredCredits: number;
        electiveRecommendedCredits: number;
        artRequiredCredits: number;
        artRecommendedCredits: number;
        agricultureRequiredCredits: number;
        agricultureRecommendedCredits: number;
        otherRequiredCredits: number;
        otherRecommendedCredits: number;
        totalRequiredCredits: number;
        totalRecommendedCredits: number;
        sportsAvailable: any;
        selectedSport: any;
        selectedGender: any;
        selectedDivision: any;
        maleSelectedSport: any;
        femaleSelectedSport: any;
        scholarshipOffered: boolean;
        fallFilingDate: boolean;
        winterFilingDate: boolean;
        springFilingDate: boolean;
        summerFilingDate: boolean;
        fallClosingDate: boolean;
        winterClosingDate: boolean;
        springClosingDate: boolean;
        summerClosingDate: boolean;
        fallNotificationDate: boolean;
        winterNotificationDate: boolean;
        springNotificationDate: boolean;
        summerNotificationDate: boolean;
        fallAcceptanceDate: boolean;
        winterAcceptanceDate: boolean;
        springAcceptanceDate: boolean;
        summerAcceptanceDate: boolean;
        enteredAlum: any;
        enteredFirm: any;
        enteredGrad: any;
        enteredCollege: any;        
        cfncURL: string;
        enteredFinancialAidForm: string;
        enteredPhotoSection: string;
        enteredPhotoTitle: string;
        enteredPhotoDesc: string;
        internationalTest: string;
        enteredCountry: string;
        fallIntlClosingDate: boolean;
        winterIntlClosingDate: boolean;
        springIntlClosingDate: boolean;
        summerIntlClosingDate: boolean;
        enteredRequirement: string;
        satSubjectTestCount: number;
        specialProgramRequirementCount: number;
        preprofessionalCount: number;
        educationCertCount: number;
        coopEdCount: number;
        semesterAwayCount: number;
        availableLoanCount: number;
        paymentPlanCount: number;
        conferenceCount: number;
        mensClubCount: number;
        womensClubCount: number;
        fourYearSchoolsTransferCount: number;
        gradSchoolsTransferCount: number;
        firmsHiringCount: number;
        alumniCount: number;
        countryCount: number;
        testsRequiredCount: number;
        aidFormsCount: number;
        servicesOfferedCount: number;
        waivedReqCount: number;
        enteredVideoTitle: string;
        enteredVideoDesc: string;
        videoURL: string;
        thumbnailURL: string;
        isVR: boolean;
        enteredVideoSection: string;
        logSuccess: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        tuitionDepositMonth: any;
        tuitionDepositDay: any;
        roomDepositMonth: any;
        roomDepositDay: any;
        academicCalendarMonth: string;
        academicCalendarDay: string;
        enteredMinor: string;
        minorCount: number;
        enteredDomesticExchange: string;
        domesticExchangeCount: number;
        fafsaPriorityMonth: string;
        fafsaPriorityDay: string;
        fafsaClosingMonth: string;
        fafsaClosingDay: string;
        customFAFSAPriorityMonth: string;
        customFAFSAPriorityDay: string;
        customFAFSAClosingMonth: string;
        customFAFSAClosingDay: string;
        cssProfilePriorityMonth: string;
        cssProfilePriorityDay: string;
        cssProfileClosingMonth: string;
        cssProfileClosingDay: string;
        stateAidPriorityMonth: string;
        stateAidPriorityDay: string;
        stateAidClosingMonth: string;
        stateAidClosingDay: string;
        noncustodialPriorityMonth: string;
        noncustodialPriorityDay: string;
        noncustodialClosingMonth: string;
        noncustodialClosingDay: string;
        businessFarmPriorityMonth: string;
        businessFarmPriorityDay: string;
        businessFarmClosingMonth: string;
        businessFarmClosingDay: string;

        fallPriorityMonth: string;
        fallPriorityDay: string;
        winterPriorityMonth: string;
        winterPriorityDay: string;
        springPriorityMonth: string;
        springPriorityDay: string;
        summerPriorityMonth: string;
        summerPriorityDay: string;

        fallClosingMonth: string;
        fallClosingDay: string;
        winterClosingMonth: string;
        winterClosingDay: string;
        springClosingMonth: string;
        springClosingDay: string;
        summerClosingMonth: string;
        summerClosingDay: string;

        fallNotificationMonth: string;
        fallNotificationDay: string;
        winterNotificationMonth: string;
        winterNotificationDay: string;
        springNotificationMonth: string;
        springNotificationDay: string;
        summerNotificationMonth: string;
        summerNotificationDay: string;

        fallAcceptanceMonth: string;
        fallAcceptanceDay: string;
        winterAcceptanceMonth: string;
        winterAcceptanceDay: string;
        springAcceptanceMonth: string;
        springAcceptanceDay: string;
        summerAcceptanceMonth: string;
        summerAcceptanceDay: string;

        fallIntlClosingMonth: string;
        fallIntlClosingDay: string;
        winterIntlClosingMonth: string;
        winterIntlClosingDay: string;
        springIntlClosingMonth: string;
        springIntlClosingDay: string;
        summerIntlClosingMonth: string;
        summerIntlClosingDay: string;
        intramuralSelectedSport: string;
        intramuralsportsAvailable: any;
        intramuralClubCount: number;

        acceptanceMonth: string;
        acceptanceDay: string;

        antiCache: any;
        isDeleting: boolean;

        editForeignExchange: any;
        editMinorsOffered: any;
        editIntramuralSportsOffered: any;
        mensClubSportsAlt: any;
        mensClubSportsAltSelect: any;
        womensClubSportsAlt: any;
        womensClubSportsAltSelect: any;

        firstTimeFinancialAidApplicants: boolean;
        fullTimeFinancialAidApplicants: boolean;
        partTimeFinancialAidApplicants: boolean;

        constructor(private config: Advant.Crossroads.ICrossroadsConfig,
            private Upload: angular.angularFileUpload.IUploadService,
            private $scope: IProfileSettingsScope, private $routeParams,
            private common: ICommonService,
            private Restangular: Restangular.IService,
            private spinner: ISpinnerService,
            private dialogs: angular.dialogs.IDialogService) {
            this.log = common.logger.getLogFn(ProfileSettings.controllerId);
            this.$scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });
            this.activate();
        }

        activate() {
            this.common.activateController([], ProfileSettings.controllerId)
                .then(() => {
                    this.cfncURL = this.config.cfncUrl;
                    this.log("Profile Settings");
                    this.apiHost = this.config.apiHost;
                    this.tuitionRefund = false;
                    this.roomRefund = false;
                    this.scholarshipOffered = false;
                    this.logSuccess = this.common.logger.getLogFn(ProfileSettings.controllerId, "success");
                    this.logError = this.common.logger.getLogFn(ProfileSettings.controllerId, "error");

                    var app = sessionStorage.getItem("activeApplication");
                    var test = app.split("/");
                    this.institutionId = test[1];
                    this.getInstitution();
                    this.englishRequiredCredits = 0;
                    this.englishRecommendedCredits = 0;
                    this.foreignLanguageRequiredCredits = 0;
                    this.foreignLanguageRecommendedCredits = 0;
                    this.historyRequiredCredits = 0;
                    this.historyRecommendedCredits = 0;
                    this.scienceLabRequiredCredits = 0;
                    this.scienceLabRecommendedCredits = 0;
                    this.scienceRequiredCredits = 0;
                    this.scienceRecommendedCredits = 0;
                    this.mathRequiredCredits = 0;
                    this.mathRecommendedCredits = 0;
                    this.socialStudiesRequiredCredits = 0;
                    this.socialStudiesRecommendedCredits = 0;
                    this.electiveRequiredCredits = 0;
                    this.electiveRecommendedCredits = 0;
                    this.artRequiredCredits = 0;
                    this.artRecommendedCredits = 0;
                    this.agricultureRequiredCredits = 0;
                    this.agricultureRecommendedCredits = 0;
                    this.otherRequiredCredits = 0;
                    this.otherRecommendedCredits = 0;
                    this.totalRequiredCredits = 0;
                    this.totalRecommendedCredits = 0;
                    this.isDeleting = false;
                    this.mensClubSportsAlt = false;
                    this.womensClubSportsAlt = false;
                    this.mensClubSportsAltSelect = "";
                    this.womensClubSportsAltSelect = "";
                    this.antiCache = this.getAntiCache();

                    this.sat2Tests = [{ id: "LIT", name: "Literature" },
                    { id: "USHIST", name: "U.S. History" },
                    { id: "WHIST", name: "World History" },
                    { id: "MATH1", name: "Mathematics Level 1" },
                    { id: "MATH2", name: "Mathematics Level 2" },
                    { id: "BIO", name: "Biology E/M" },
                    { id: "CHEM", name: "Chemistry" },
                    { id: "PHYS", name: "Physics" },
                    { id: "FRNCH", name: "French" },
                    { id: "FRNCHL", name: "French with Listening" },
                    { id: "GRMN", name: "German" },
                    { id: "GRMNL", name: "German with Listening" },
                    { id: "SPNSH", name: "Spanish" },
                    { id: "SPNSHL", name: "Spanish with Listening" },
                    { id: "HBRW", name: "Modern Hebrew" },
                    { id: "ITLN", name: "Italian" },
                    { id: "LTN", name: "Latin" },
                    { id: "CHNSL", name: "Chinese with Listening" },
                    { id: "JPNSL", name: "Japanese with Listening" },
                    { id: "KRNL", name: "Korean with Listening" }
                    ]

                    this.sportsAvailable = [{ code: "ARCH", name: "Archery" },
                    { code: "BADMI", name: "Badminton" },
                    { code: "BASEB", name: "Baseball" },
                    { code: "BOWLG", name: "Bowling" },
                    { code: "BSKTB", name: "Basketball" },
                    { code: "CHEER", name: "Cheerleading" },
                    { code: "CREW", name: "Crew" },
                    { code: "XRUN", name: "Cross-Country" },
                    { code: "EQUES", name: "Equestrian Sports" },
                    { code: "FENCE", name: "Fencing" },
                    { code: "FHOCK", name: "Field Hockey" },
                    { code: "FOOTB", name: "Football" },
                    { code: "GOLF", name: "Golf" },
                    { code: "GYMNA", name: "Gymnastics" },
                    { code: "ICEH", name: "Ice Hockey" },
                    { code: "LACRO", name: "Lacrosse" },
                    { code: "RACQU", name: "Raquetball" },
                    { code: "RIFLE", name: "Riflery" },
                    { code: "RUGBY", name: "Rugby" },
                    { code: "SAILG", name: "Sailing" },
                    { code: "SKIIN", name: "Skiing (downhill)" },
                    { code: "SOCCR", name: "Soccer" },
                    { code: "SOFTB", name: "Softball" },
                    { code: "STRE3", name: "Strength & Conditioning" },
                    { code: "SWIM", name: "Swimming" },
                    { code: "TENNI", name: "Tennis" },
                    { code: "WINTE", name: "Track (Indoor)" },
                    { code: "TRACK", name: "Track and Field (Outdoor)" },
                    { code: "ULTIM", name: "Ultimate Frisbee" },
                    { code: "VOLLB", name: "Volleyball" },
                    { code: "WLIFT", name: "Weight Lifting" },
                    { code: "WPOLO", name: "Water Polo" },
                    { code: "WREST", name: "Wrestling" },
                    ]

                    this.intramuralsportsAvailable = [
                        {
                            code: "2",
                            name: "Dance Team",
                            user_defined: false
                        },
                        {
                            code: "00003",
                            name: "Triathlon",
                            user_defined: false
                        },
                        {
                            code: "00004",
                            name: "Indoor Soccer",
                            user_defined: false
                        },
                        {
                            code: "00005",
                            name: "Cheering",
                            user_defined: false
                        },
                        {
                            code: "00006",
                            name: "Roller Hockey",
                            user_defined: false
                        },
                        {
                            code: "00008",
                            name: "Indoor Track",
                            user_defined: false
                        },
                        {
                            code: "00009",
                            name: "Outdoor Skills",
                            user_defined: false
                        },
                        {
                            code: "00010",
                            name: "Winter Track",
                            user_defined: false
                        },
                        {
                            code: "00011",
                            name: "Mountaineering",
                            user_defined: false
                        },
                        {
                            code: "00012",
                            name: "Winter Soccer",
                            user_defined: false
                        },
                        {
                            code: "00013",
                            name: "Wilderness Survival",
                            user_defined: false
                        },
                        {
                            code: "00016",
                            name: "Rifle Team",
                            user_defined: false
                        },
                        {
                            code: "00017",
                            name: "Fitness",
                            user_defined: false
                        },
                        {
                            code: "00018",
                            name: "Outdoors",
                            user_defined: false
                        },
                        {
                            code: "00020",
                            name: "Hiking",
                            user_defined: false
                        },
                        {
                            code: "00021",
                            name: "Water Badminton",
                            user_defined: false
                        },
                        {
                            code: "00022",
                            name: "Wildernessways",
                            user_defined: false
                        },
                        {
                            code: "00023",
                            name: "Aerobics/Dance",
                            user_defined: false
                        },
                        {
                            code: "00024",
                            name: "Water Skiing",
                            user_defined: false
                        },
                        {
                            code: "00025",
                            name: "Canoeing/Kayaking",
                            user_defined: false
                        },
                        {
                            code: "00029",
                            name: "Aerobics/Nautilus",
                            user_defined: false
                        },
                        {
                            code: "00030",
                            name: "Outdoor Recreation",
                            user_defined: false
                        },
                        {
                            code: "00031",
                            name: "Indoor Track & Field",
                            user_defined: false
                        },
                        {
                            code: "AEROB",
                            name: "Aerobics",
                            user_defined: false
                        },
                        {
                            code: "ALPIN",
                            name: "Alpine Skiing",
                            user_defined: false
                        },
                        {
                            code: "AQUAT",
                            name: "Aquatics",
                            user_defined: false
                        },
                        {
                            code: "ARCH",
                            name: "Archery",
                            user_defined: false
                        },
                        {
                            code: "ART",
                            name: "Art",
                            user_defined: false
                        },
                        {
                            code: "ARTIS",
                            name: "Artistic Gym",
                            user_defined: false
                        },
                        {
                            code: "ATHLE",
                            name: "Athletic Trainer",
                            user_defined: false
                        },
                        {
                            code: "BACK",
                            name: "Backpacking",
                            user_defined: false
                        },
                        {
                            code: "BADMI",
                            name: "Badminton",
                            user_defined: false
                        },
                        {
                            code: "BALL",
                            name: "Ball Hockey",
                            user_defined: false
                        },
                        {
                            code: "BALLE",
                            name: "Ballet",
                            user_defined: false
                        },
                        {
                            code: "BASEB",
                            name: "Baseball",
                            user_defined: false
                        },
                        {
                            code: "BIATH",
                            name: "Biathlon",
                            user_defined: false
                        },
                        {
                            code: "BICYC",
                            name: "Bicycling",
                            user_defined: false
                        },
                        {
                            code: "BILLI",
                            name: "Billiards",
                            user_defined: false
                        },
                        {
                            code: "BLADI",
                            name: "Blading",
                            user_defined: false
                        },
                        {
                            code: "BOCCE",
                            name: "Bocce",
                            user_defined: false
                        },
                        {
                            code: "BORDE",
                            name: "Bordenball",
                            user_defined: false
                        },
                        {
                            code: "BOWLG",
                            name: "Bowling",
                            user_defined: false
                        },
                        {
                            code: "BOXIN",
                            name: "Boxing",
                            user_defined: false
                        },
                        {
                            code: "BROOM",
                            name: "Broomball",
                            user_defined: false
                        },
                        {
                            code: "BSKTB",
                            name: "Basketball",
                            user_defined: false
                        },
                        {
                            code: "CANOE",
                            name: "Canoeing",
                            user_defined: false
                        },
                        {
                            code: "CHEER",
                            name: "Cheerleading",
                            user_defined: false
                        },
                        {
                            code: "CHESS",
                            name: "Chess",
                            user_defined: false
                        },
                        {
                            code: "CIRCU",
                            name: "Circus",
                            user_defined: false
                        },
                        {
                            code: "CLIMB",
                            name: "Climbing",
                            user_defined: false
                        },
                        {
                            code: "COMBI",
                            name: "Combined Training",
                            user_defined: false
                        },
                        {
                            code: "COOPE",
                            name: "Cooperative Games",
                            user_defined: false
                        },
                        {
                            code: "COSOM",
                            name: "Cosom Hockey",
                            user_defined: false
                        },
                        {
                            code: "CREW",
                            name: "Crew",
                            user_defined: false
                        },
                        {
                            code: "CRICK",
                            name: "Cricket",
                            user_defined: false
                        },
                        {
                            code: "CROQU",
                            name: "Croquet",
                            user_defined: false
                        },
                        {
                            code: "CURLI",
                            name: "Curling",
                            user_defined: false
                        },
                        {
                            code: "DANC2",
                            name: "Dance Squad",
                            user_defined: false
                        },
                        {
                            code: "DANCE",
                            name: "Dance",
                            user_defined: false
                        },
                        {
                            code: "DANLN",
                            name: "Danceline",
                            user_defined: false
                        },
                        {
                            code: "DECK",
                            name: "Deck Hockey",
                            user_defined: false
                        },
                        {
                            code: "DIVE",
                            name: "Diving",
                            user_defined: false
                        },
                        {
                            code: "DRESS",
                            name: "Dressage",
                            user_defined: false
                        },
                        {
                            code: "DRILL",
                            name: "Drill Team",
                            user_defined: false
                        },
                        {
                            code: "EQUES",
                            name: "Equestrian Sports",
                            user_defined: false
                        },
                        {
                            code: "FENCE",
                            name: "Fencing",
                            user_defined: false
                        },
                        {
                            code: "FHOCK",
                            name: "Field Hockey",
                            user_defined: false
                        },
                        {
                            code: "FIGUR",
                            name: "Figure Skating",
                            user_defined: false
                        },
                        {
                            code: "FISHI",
                            name: "Fishing",
                            user_defined: false
                        },
                        {
                            code: "FITNE",
                            name: "Fitness Walking",
                            user_defined: false
                        },
                        {
                            code: "FIVES",
                            name: "Fives",
                            user_defined: false
                        },
                        {
                            code: "FLAG",
                            name: "Flag Football",
                            user_defined: false
                        },
                        {
                            code: "FLAGB",
                            name: "Flagball",
                            user_defined: false
                        },
                        {
                            code: "FLOOR",
                            name: "Floor Hockey",
                            user_defined: false
                        },
                        {
                            code: "FLY F",
                            name: "Fly Fishing",
                            user_defined: false
                        },
                        {
                            code: "FOOTB",
                            name: "Football",
                            user_defined: false
                        },
                        {
                            code: "FREES",
                            name: "Freestyle Skiing",
                            user_defined: false
                        },
                        {
                            code: "FRISB",
                            name: "Frisbee",
                            user_defined: false
                        },
                        {
                            code: "GATOR",
                            name: "Gatorball",
                            user_defined: false
                        },
                        {
                            code: "GOLF",
                            name: "Golf",
                            user_defined: false
                        },
                        {
                            code: "GYMNA",
                            name: "Gymnastics",
                            user_defined: false
                        },
                        {
                            code: "HANDB",
                            name: "Handball",
                            user_defined: false
                        },
                        {
                            code: "HIKIN",
                            name: "Hiking/Backpacking",
                            user_defined: false
                        },
                        {
                            code: "HOCKE",
                            name: "Hockey",
                            user_defined: false
                        },
                        {
                            code: "HORS2",
                            name: "Horseback Riding",
                            user_defined: false
                        },
                        {
                            code: "HORSE",
                            name: "Horseshoes",
                            user_defined: false
                        },
                        {
                            code: "ICE S",
                            name: "Ice Skating",
                            user_defined: false
                        },
                        {
                            code: "ICEH",
                            name: "Ice Hockey",
                            user_defined: false
                        },
                        {
                            code: "INDEP",
                            name: "Independent Competitive Sports",
                            user_defined: false
                        },
                        {
                            code: "INDOO",
                            name: "Indoor Hockey",
                            user_defined: false
                        },
                        {
                            code: "IN-L2",
                            name: "In-line Hockey",
                            user_defined: false
                        },
                        {
                            code: "IN-LI",
                            name: "In-line Skating",
                            user_defined: false
                        },
                        {
                            code: "JOGGI",
                            name: "Jogging",
                            user_defined: false
                        },
                        {
                            code: "JROTC",
                            name: "JROTC Drill",
                            user_defined: false
                        },
                        {
                            code: "JUDO",
                            name: "Judo",
                            user_defined: false
                        },
                        {
                            code: "JUGGL",
                            name: "Juggling",
                            user_defined: false
                        },
                        {
                            code: "JUMP",
                            name: "Jump Rope",
                            user_defined: false
                        },
                        {
                            code: "KASTI",
                            name: "Kasti",
                            user_defined: false
                        },
                        {
                            code: "KAYAK",
                            name: "Kayaking",
                            user_defined: false
                        },
                        {
                            code: "KICKB",
                            name: "Kickball",
                            user_defined: false
                        },
                        {
                            code: "KICKI",
                            name: "Kicking Ball",
                            user_defined: false
                        },
                        {
                            code: "LACRO",
                            name: "Lacrosse",
                            user_defined: false
                        },
                        {
                            code: "LIFE",
                            name: "Life saving",
                            user_defined: false
                        },
                        {
                            code: "LUGE",
                            name: "Luge",
                            user_defined: false
                        },
                        {
                            code: "MARKS",
                            name: "Marksmanship",
                            user_defined: false
                        },
                        {
                            code: "MARTI",
                            name: "Martial Arts",
                            user_defined: false
                        },
                        {
                            code: "MODER",
                            name: "Modern Dance",
                            user_defined: false
                        },
                        {
                            code: "MOUNT",
                            name: "Mountain Biking",
                            user_defined: false
                        },
                        {
                            code: "NAUTI",
                            name: "Nautilus",
                            user_defined: false
                        },
                        {
                            code: "NET B",
                            name: "Net Ball",
                            user_defined: false
                        },
                        {
                            code: "NETBA",
                            name: "Netball",
                            user_defined: false
                        },
                        {
                            code: "NEWCO",
                            name: "Newcombe Ball",
                            user_defined: false
                        },
                        {
                            code: "NORDI",
                            name: "Nordic Skiing",
                            user_defined: false
                        },
                        {
                            code: "OCEAN",
                            name: "Ocean Paddling",
                            user_defined: false
                        },
                        {
                            code: "ORIEN",
                            name: "Orienteering",
                            user_defined: false
                        },
                        {
                            code: "OUTD2",
                            name: "Outdoor Adventure",
                            user_defined: false
                        },
                        {
                            code: "OUTD3",
                            name: "Outdoor Education",
                            user_defined: false
                        },
                        {
                            code: "OUTD4",
                            name: "Outdoor Activities",
                            user_defined: false
                        },
                        {
                            code: "PADD2",
                            name: "Paddling",
                            user_defined: false
                        },
                        {
                            code: "PADTE",
                            name: "Paddle Tennis",
                            user_defined: false
                        },
                        {
                            code: "PAINT",
                            name: "Paint Pall",
                            user_defined: false
                        },
                        {
                            code: "PHYS2",
                            name: "Physical Fitness",
                            user_defined: false
                        },
                        {
                            code: "PHYSI",
                            name: "Physical Training",
                            user_defined: false
                        },
                        {
                            code: "PILLO",
                            name: "Pillo Polo",
                            user_defined: false
                        },
                        {
                            code: "PISTL",
                            name: "Pistol",
                            user_defined: false
                        },
                        {
                            code: "POLO",
                            name: "Polo",
                            user_defined: false
                        },
                        {
                            code: "POLOC",
                            name: "Polocrosse",
                            user_defined: false
                        },
                        {
                            code: "POM S",
                            name: "Pom Squad",
                            user_defined: false
                        },
                        {
                            code: "POTTE",
                            name: "Pottery",
                            user_defined: false
                        },
                        {
                            code: "POWER",
                            name: "Power Lifting",
                            user_defined: false
                        },
                        {
                            code: "PROJE",
                            name: "Project Adventure",
                            user_defined: false
                        },
                        {
                            code: "RACQU",
                            name: "Racquetball",
                            user_defined: false
                        },
                        {
                            code: "RAFTI",
                            name: "Rafting",
                            user_defined: false
                        },
                        {
                            code: "RAPPE",
                            name: "Rappelling",
                            user_defined: false
                        },
                        {
                            code: "RHYTH",
                            name: "Rhythmic Gymnastics",
                            user_defined: false
                        },
                        {
                            code: "RIDIN",
                            name: "Riding",
                            user_defined: false
                        },
                        {
                            code: "RIFLE",
                            name: "Riflery",
                            user_defined: false
                        },
                        {
                            code: "ROCK",
                            name: "Rock Climbing",
                            user_defined: false
                        },
                        {
                            code: "RODEO",
                            name: "Rodeo",
                            user_defined: false
                        },
                        {
                            code: "ROLL2",
                            name: "Roller Blading",
                            user_defined: false
                        },
                        {
                            code: "ROLLE",
                            name: "Roller Skating",
                            user_defined: false
                        },
                        {
                            code: "ROPES",
                            name: "Ropes Courses",
                            user_defined: false
                        },
                        {
                            code: "ROUGH",
                            name: "Rough Riding",
                            user_defined: false
                        },
                        {
                            code: "ROUND",
                            name: "Rounders",
                            user_defined: false
                        },
                        {
                            code: "ROWIN",
                            name: "Rowing",
                            user_defined: false
                        },
                        {
                            code: "RUGBY",
                            name: "Rugby",
                            user_defined: false
                        },
                        {
                            code: "RUNNI",
                            name: "Running",
                            user_defined: false
                        },
                        {
                            code: "SAILB",
                            name: "Sailboarding",
                            user_defined: false
                        },
                        {
                            code: "SAILG",
                            name: "Sailing",
                            user_defined: false
                        },
                        {
                            code: "SCOOT",
                            name: "Scooter Football",
                            user_defined: false
                        },
                        {
                            code: "SCUB2",
                            name: "Scuba Diving",
                            user_defined: false
                        },
                        {
                            code: "SCUBA",
                            name: "Scuba",
                            user_defined: false
                        },
                        {
                            code: "SEARE",
                            name: "Sea Rescue",
                            user_defined: false
                        },
                        {
                            code: "SELFD",
                            name: "Self Defense",
                            user_defined: false
                        },
                        {
                            code: "SKATE",
                            name: "Skateboarding",
                            user_defined: false
                        },
                        {
                            code: "SKEET",
                            name: "Skeet Shooting",
                            user_defined: false
                        },
                        {
                            code: "SKI J",
                            name: "Ski Jumping",
                            user_defined: false
                        },
                        {
                            code: "SKIIN",
                            name: "Skiing (downhill)",
                            user_defined: false
                        },
                        {
                            code: "SKYDI",
                            name: "Skydiving",
                            user_defined: false
                        },
                        {
                            code: "SNOWB",
                            name: "Snowboarding",
                            user_defined: false
                        },
                        {
                            code: "SNOWS",
                            name: "Snowshoeing",
                            user_defined: false
                        },
                        {
                            code: "SOCCR",
                            name: "Soccer",
                            user_defined: false
                        },
                        {
                            code: "SOFTB",
                            name: "Softball",
                            user_defined: false
                        },
                        {
                            code: "SPECI",
                            name: "Special Olympics",
                            user_defined: false
                        },
                        {
                            code: "SPEE2",
                            name: "Speedskating",
                            user_defined: false
                        },
                        {
                            code: "SPEED",
                            name: "Speedball",
                            user_defined: false
                        },
                        {
                            code: "SPELE",
                            name: "Speleology",
                            user_defined: false
                        },
                        {
                            code: "SQUAS",
                            name: "Squash",
                            user_defined: false
                        },
                        {
                            code: "STRE3",
                            name: "Strength & Conditioning",
                            user_defined: false
                        },
                        {
                            code: "STREE",
                            name: "Street Hockey",
                            user_defined: false
                        },
                        {
                            code: "STUDI",
                            name: "Studio Art",
                            user_defined: false
                        },
                        {
                            code: "SURFI",
                            name: "Surfing",
                            user_defined: false
                        },
                        {
                            code: "SWIM",
                            name: "Swimming and Diving",
                            user_defined: false
                        },
                        {
                            code: "SYNCH",
                            name: "Synchronized Swimming",
                            user_defined: false
                        },
                        {
                            code: "TAG F",
                            name: "Tag Football",
                            user_defined: false
                        },
                        {
                            code: "TAI C",
                            name: "Tai Chi",
                            user_defined: false
                        },
                        {
                            code: "TEAM",
                            name: "Team Handball",
                            user_defined: false
                        },
                        {
                            code: "TELEM",
                            name: "Telemark Skiing",
                            user_defined: false
                        },
                        {
                            code: "TENNI",
                            name: "Tennis",
                            user_defined: false
                        },
                        {
                            code: "TOUCH",
                            name: "Touch Football",
                            user_defined: false
                        },
                        {
                            code: "TRACK",
                            name: "Track and Field",
                            user_defined: false
                        },
                        {
                            code: "TRAPS",
                            name: "Trap and Skeet",
                            user_defined: false
                        },
                        {
                            code: "TTEN",
                            name: "Table Tennis",
                            user_defined: false
                        },
                        {
                            code: "ULTIM",
                            name: "Ultimate Frisbee",
                            user_defined: false
                        },
                        {
                            code: "UNICY",
                            name: "Unicycling",
                            user_defined: false
                        },
                        {
                            code: "VARSI",
                            name: "Varsity Dance",
                            user_defined: false
                        },
                        {
                            code: "VOLLB",
                            name: "Volleyball",
                            user_defined: false
                        },
                        {
                            code: "WALKI",
                            name: "Walking",
                            user_defined: false
                        },
                        {
                            code: "WALL",
                            name: "Wall Climbing",
                            user_defined: false
                        },
                        {
                            code: "WALLY",
                            name: "Wallyball",
                            user_defined: false
                        },
                        {
                            code: "WATER",
                            name: "Water Volleyball",
                            user_defined: false
                        },
                        {
                            code: "WEIGH",
                            name: "Weight Training",
                            user_defined: false
                        },
                        {
                            code: "WHIFF",
                            name: "Whiffle Ball",
                            user_defined: false
                        },
                        {
                            code: "WILDE",
                            name: "Wilderness",
                            user_defined: false
                        },
                        {
                            code: "WINDS",
                            name: "Windsurfing",
                            user_defined: false
                        },
                        {
                            code: "WINT2",
                            name: "Winter Walking",
                            user_defined: false
                        },
                        {
                            code: "WINTE",
                            name: "Winter (indoor) Track",
                            user_defined: false
                        },
                        {
                            code: "WLIFT",
                            name: "Weight Lifting",
                            user_defined: false
                        },
                        {
                            code: "WPOLO",
                            name: "Water polo",
                            user_defined: false
                        },
                        {
                            code: "WREST",
                            name: "Wrestling",
                            user_defined: false
                        },
                        {
                            code: "XRUN",
                            name: "Cross-country Running",
                            user_defined: false
                        },
                        {
                            code: "XSKI",
                            name: "Skiing (cross-country)",
                            user_defined: false
                        },
                        {
                            code: "YOGA",
                            name: "Yoga",
                            user_defined: false
                        }
                    ]
                });
        }

        getAntiCache() {
            return new Date().getTime();
        }

        verifyFinancialAid() {
            this.checkFirstTimeAidApps();
            this.checkFullTimeAidApps();
            this.checkPartTimeAidApps();
            this.checkFirstTimeAidNeed();
            this.checkFullTimeAidNeed();
            this.checkPartTimeAidNeed();
            this.checkFirstTimeAidRecieve();
            this.checkFullTimeRecieve();
            this.checkPartTimeRecieve();
            this.checkFirstTimeAidGift();
            this.checkFullTimeAidGift();
            this.checkPartTimeAidGift();
            this.checkFirstTimeAidSelfHelp();
            this.checkFullTimeAidSelfHelp();
            this.checkPartTimeAidSelfHelp();
            this.checkFirstTimeNonNeedGift();
            this.checkFullTimeNonNeedGift();
            this.checkPartTimeNonNeedGift();
            this.checkFirstTimeNeedsMet();
            this.checkFullTimeNeedsMet();
            this.checkPartTimeNeedsMet();
            this.checkFirstTimeNoNeedNonAthlete();
            this.checkFullTimeNoNeedNonAthlete();
            this.checkPartTimeNoNeedNonAthlete();
            this.checkFirstTimeNoNeedAthlete();
            this.checkFullTimeNoNeedAthlete();
            this.checkPartTimeNoNeedAthlete();
        }

        checkFirstTimeAidApps() {
            var apps = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.financialAidApplicants);
            var udg = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps > udg) {
                this.$scope.applyingForAid.firstTimeFinancialAidApplicants.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
                this.firstTimeFinancialAidApplicants = true;
            }
            else{
                this.$scope.applyingForAid.firstTimeFinancialAidApplicants.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
                this.firstTimeFinancialAidApplicants = false;
            }
        }

        checkFullTimeAidApps() {
            var apps = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.financialAidApplicants);
            var udg = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.degreeSeekingUndergraduates);
            if (apps > udg) {
                this.$scope.applyingForAid.fullTimeFinancialAidApplicants.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
                this.fullTimeFinancialAidApplicants = true;
            }
            else {
                this.$scope.applyingForAid.fullTimeFinancialAidApplicants.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
                this.fullTimeFinancialAidApplicants = false;
            }
                
        }

        checkPartTimeAidApps() {
            var apps = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.financialAidApplicants);
            var udg = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps > udg) {
                this.$scope.applyingForAid.partTimeFinancialAidApplicants.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
                this.partTimeFinancialAidApplicants = true;
            }
            else {
                this.$scope.applyingForAid.partTimeFinancialAidApplicants.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
                this.partTimeFinancialAidApplicants = false;
            }
        }

        checkFirstTimeAidNeed() {
            var apps = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.financialAidApplicants);
            var elg = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.financialAidEligibleStudents);
            var udg = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < elg || udg < elg) {
                this.$scope.applyingForAid.firstTimeFinancialAidEligible.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
                this.firstTimeFinancialAidApplicants = true;
            }
            else {
                this.$scope.applyingForAid.firstTimeFinancialAidEligible.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
                this.firstTimeFinancialAidApplicants = false;
            }
        }

        checkFullTimeAidNeed() {
            var apps = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.financialAidApplicants);
            var elg = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.financialAidEligibleStudents);
            var udg = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.degreeSeekingUndergraduates);
            if (apps < elg || udg < elg) {
                this.$scope.applyingForAid.fullTimeFinancialAidEligible.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
                this.fullTimeFinancialAidApplicants = true;
            }
            else {
                this.$scope.applyingForAid.fullTimeFinancialAidEligible.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
                this.fullTimeFinancialAidApplicants = false;
            }

        }

        checkPartTimeAidNeed() {
            var apps = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.financialAidApplicants);
            var elg = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.financialAidEligibleStudents);
            var udg = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < elg || udg < elg) {
                this.$scope.applyingForAid.partTimeFinancialAidEligible.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.partTimeFinancialAidEligible.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFirstTimeAidRecieve() {
            var apps = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.financialAidEligibleStudents);
            var rec = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.studentsReceivingFinancialAid);
            var udg = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < rec || udg < rec) {
                this.$scope.applyingForAid.firstTimeStudentsReceivingFinancialAid.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.firstTimeStudentsReceivingFinancialAid.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFullTimeRecieve() {
            var apps = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.financialAidEligibleStudents);
            var rec = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.studentsReceivingFinancialAid);
            var udg = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.degreeSeekingUndergraduates);
            if (apps < rec || udg < rec) {
                this.$scope.applyingForAid.fullTimeStudentsReceivingFinancialAid.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.fullTimeStudentsReceivingFinancialAid.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }

        }

        checkPartTimeRecieve() {
            var rec = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.studentsReceivingFinancialAid);
            var apps = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.financialAidEligibleStudents);
            var udg = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < rec || udg < rec) {
                this.$scope.applyingForAid.partTimeStudentsReceivingFinancialAid.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.partTimeStudentsReceivingFinancialAid.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFirstTimeAidGift() {
            var apps = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.studentsReceivingFinancialAid);
            var gft = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.studentsReceivingNeedBasedGifts);
            var udg = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < gft || udg < gft) {
                this.$scope.applyingForAid.firstTimeStudentsReceivingNeedBasedGifts.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.firstTimeStudentsReceivingNeedBasedGifts.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFullTimeAidGift() {
            var apps = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.studentsReceivingFinancialAid);
            var gft = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.studentsReceivingNeedBasedGifts);
            var udg = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.degreeSeekingUndergraduates);
            if (apps < gft || udg < gft) {
                this.$scope.applyingForAid.fullTimeStudentsReceivingNeedBasedGifts.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.fullTimeStudentsReceivingNeedBasedGifts.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }

        }

        checkPartTimeAidGift() {
            var apps = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.studentsReceivingFinancialAid);
            var gft = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.studentsReceivingNeedBasedGifts);
            var udg = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < gft || udg < gft) {
                this.$scope.applyingForAid.partTimeStudentsReceivingNeedBasedGifts.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.partTimeStudentsReceivingNeedBasedGifts.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFirstTimeAidSelfHelp() {
            var apps = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.studentsReceivingFinancialAid);
            var aid = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.studentsReceivingNeedBasedSelfHelp);
            var udg = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < aid || udg < aid) {
                this.$scope.applyingForAid.firstTimeNeedBasedSelfHelp.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.firstTimeNeedBasedSelfHelp.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFullTimeAidSelfHelp() {
            var apps = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.studentsReceivingFinancialAid);
            var aid = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.studentsReceivingNeedBasedSelfHelp);
            var udg = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.degreeSeekingUndergraduates);
            if (apps < aid || udg < aid) {
                this.$scope.applyingForAid.fullTimeNeedBasedSelfHelp.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.fullTimeNeedBasedSelfHelp.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }

        }

        checkPartTimeAidSelfHelp() {
            var apps = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.studentsReceivingFinancialAid);
            var aid = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.studentsReceivingNeedBasedSelfHelp);
            var udg = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < aid || udg < aid) {
                this.$scope.applyingForAid.partTimeNeedBasedSelfHelp.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.partTimeNeedBasedSelfHelp.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFirstTimeNonNeedGift() {
            var apps = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.studentsReceivingFinancialAid);
            var gft = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.studentsReceivingNonNeedBasedGift);
            var udg = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < gft || udg < gft) {
                this.$scope.applyingForAid.firstTimeNonNeedBasedGift.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.firstTimeNonNeedBasedGift.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFullTimeNonNeedGift() {
            var apps = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.studentsReceivingFinancialAid);
            var gft = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.studentsReceivingNonNeedBasedGift);
            var udg = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.degreeSeekingUndergraduates);
            if (apps < gft || udg < gft) {
                this.$scope.applyingForAid.fullTimeNonNeedBasedGift.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.fullTimeNonNeedBasedGift.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }

        }

        checkPartTimeNonNeedGift() {
            var apps = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.studentsReceivingFinancialAid);
            var gft = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.studentsReceivingNonNeedBasedGift);
            var udg = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < gft || udg < gft) {
                this.$scope.applyingForAid.partTimeNonNeedBasedGift.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.partTimeNonNeedBasedGift.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFirstTimeNeedsMet() {
            var apps = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.studentsReceivingFinancialAid);
            var gft = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.studentsReceivingFullAid);
            var udg = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < gft || udg < gft) {
                this.$scope.applyingForAid.firstTimeReceivingFullAid.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.firstTimeReceivingFullAid.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFullTimeNeedsMet() {
            var apps = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.studentsReceivingFinancialAid);
            var gft = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.studentsReceivingFullAid);
            var udg = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.degreeSeekingUndergraduates);
            if (apps < gft || udg < gft) {
                this.$scope.applyingForAid.fullTimeReceivingFullAid.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.fullTimeReceivingFullAid.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }

        }

        checkPartTimeNeedsMet() {
            var apps = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.studentsReceivingFinancialAid);
            var gft = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.studentsReceivingFullAid);
            var udg = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < gft || udg < gft) {
                this.$scope.applyingForAid.partTimeReceivingFullAid.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.partTimeReceivingFullAid.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFirstTimeNoNeedNonAthlete() {
            var apps = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.degreeSeekingUndergraduates);
            var gft = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.studentsReceivingNonNeedAid);
            var udg = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < gft) {
                this.$scope.applyingForAid.firstTimeReceivingNonNeedAid.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.firstTimeReceivingNonNeedAid.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFullTimeNoNeedNonAthlete() {
            var apps = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.degreeSeekingUndergraduates);
            var gft = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.studentsReceivingNonNeedAid);
            var udg = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.degreeSeekingUndergraduates);
            if (apps < gft) {
                this.$scope.applyingForAid.fullTimeReceivingNonNeedAid.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.fullTimeReceivingNonNeedAid.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }

        }

        checkPartTimeNoNeedNonAthlete() {
            var apps = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.degreeSeekingUndergraduates);
            var gft = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.studentsReceivingNonNeedAid);
            var udg = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < gft) {
                this.$scope.applyingForAid.partTimeReceivingNonNeedAid.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.partTimeReceivingNonNeedAid.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFirstTimeNoNeedAthlete() {
            var apps = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.degreeSeekingUndergraduates);
            var gft = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.studentsReceivingAthleticAward);
            var udg = parseInt(this.institution.profile.costFinancialAid.firstTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < gft) {
                this.$scope.applyingForAid.firstTimeReceivingAthleticAward.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.firstTimeReceivingAthleticAward.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkFullTimeNoNeedAthlete() {
            var apps = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.degreeSeekingUndergraduates);
            var gft = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.studentsReceivingAthleticAward);
            var udg = parseInt(this.institution.profile.costFinancialAid.fullTimeFirstYearStats.degreeSeekingUndergraduates);
            if (apps < gft) {
                this.$scope.applyingForAid.fullTimeReceivingAthleticAward.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.fullTimeReceivingAthleticAward.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }

        }

        checkPartTimeNoNeedAthlete() {
            var apps = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.degreeSeekingUndergraduates);
            var gft = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.studentsReceivingAthleticAward);
            var udg = parseInt(this.institution.profile.costFinancialAid.partTimeUnderGrad.degreeSeekingUndergraduates);
            if (apps < gft) {
                this.$scope.applyingForAid.partTimeReceivingAthleticAward.$error.validationError = true;
                this.$scope.applyingForAid.$invalid = true;
            }
            else {
                this.$scope.applyingForAid.partTimeReceivingAthleticAward.$error.validationError = false;
                this.$scope.applyingForAid.$invalid = false;
            }
        }

        checkSATSubjectTests(testName: string) {
            var found = false;
            for (var i in this.institution.profile.freshmanApplicationProcess.SATSubjectTests) {
                if (this.institution.profile.freshmanApplicationProcess.SATSubjectTests[i] == testName)
                    found = true;
            }
            return found;
        }

        modifySATSubjectTests(testName: string) {
            var found = false;
            for (var i in this.institution.profile.freshmanApplicationProcess.SATSubjectTests) {
                if (this.institution.profile.freshmanApplicationProcess.SATSubjectTests[i] == testName) {
                    found = true;
                    this.institution.profile.freshmanApplicationProcess.SATSubjectTests.splice(i, 1);
                }
            }
            if (!found) {
                this.institution.profile.freshmanApplicationProcess.SATSubjectTests.push(testName);
            }
        }

        addSpecialProgram(name: string) {
            var index = _.indexOf(this.institution.profile.freshmanApplicationProcess.specialProgramRequirements, name);
            if (index == -1) {
                console.log("added" + name);
                this.institution.profile.freshmanApplicationProcess.specialProgramRequirements.push(name);
            }
        }

        removeSpecialProgram(name: string) {
            var index = _.indexOf(this.institution.profile.freshmanApplicationProcess.specialProgramRequirements, name);
            if (index != -1) {
                console.log("removed" + name);
                this.institution.profile.freshmanApplicationProcess.specialProgramRequirements(index, 1);
            }
        }

        checkList(listTocheck: any, name: string) {
            var found = false;
            for (var i in listTocheck) {
                if (listTocheck[i] == name)
                    found = true;
            }
            return found;
        }

        modifyList(listTocheck: any, name: string) {
            var found = false;
            for (var i in listTocheck) {
                if (listTocheck[i] == name) {
                    found = true;
                    listTocheck.splice(i, 1);
                    console.log("removed " + name);
                }
            }
            if (!found) {
                listTocheck.push(name);
                console.log("added" + name);
            }
        }

        addIntercollegiateSport() {
            var found = _.filter(this.institution.profile.studentLife.athletics.intercollegiateSports, { 'sportCode': this.selectedSport, 'ncaaDivision': this.selectedDivision, 'gender': this.selectedGender });
            if (found.length == 0) {
                var sport = _.findIndex(this.sportsAvailable, { 'code': this.selectedSport });
                var sportName = this.sportsAvailable[sport].name;
                var data = { name: sportName, sportCode: this.selectedSport, ncaaDivision: this.selectedDivision, gender: this.selectedGender, scholarshipOffered: this.scholarshipOffered };
                this.institution.profile.studentLife.athletics.intercollegiateSports.push(data);
            }
        }

        confirmIntramuralSport(sport: any) {
            var found = _.indexOf(this.institution.profile.studentLife.athletics.intramuralClubsAvailable, sport.name);
            if (found != -1)
                this.logError("Sport is already in the list", "", true);
            else {
                this.saveStudentLifeSection();
                sport.editMode = false;
            }
        }

        deleteIntercollegiateSport(index: any) {
            this.institution.profile.studentLife.athletics.intercollegiateSports.splice(index, 1);
        }

        addClubSport(originalList: any, newItem: any) {
            var sport = _.findIndex(this.intramuralsportsAvailable, { 'code': newItem });
            var found = _.indexOf(originalList, this.intramuralsportsAvailable[sport].name);
            if (found == -1) {
                originalList.push(this.intramuralsportsAvailable[sport].name);
                originalList = originalList.sort();
            }
            else {
                this.logError("Sport is already in the list", "", true);
            }
        }

        addStringToList(originalList: any, newItem: string) {
            var found = _.indexOf(originalList, newItem);
            if (found == -1) {
                originalList.push(newItem);
                originalList = originalList.sort();
            }
        }

        removeFromList(listObject: any, index: any) {
            listObject.splice(index, 1);
        }

        removeFromListAlt(listObject: any, itemToRemove: any) {
            var index = _.indexOf(listObject, itemToRemove);
            listObject.splice(index, 1);
        }

        checkProgramRequirements() {
            for (var i in this.institution.profile.freshmanApplicationProcess.creditRequirements) {
                switch (this.institution.profile.freshmanApplicationProcess.creditRequirements[i].subject) {
                    case "English":
                        this.englishRequiredCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].requiredUnits;
                        this.englishRecommendedCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].recommendedUnits;
                        break;
                    case "Foreign language":
                        this.foreignLanguageRequiredCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].requiredUnits;
                        this.foreignLanguageRecommendedCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].recommendedUnits;
                        break;
                    case "History":
                        this.historyRequiredCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].requiredUnits;
                        this.historyRecommendedCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].recommendedUnits;
                        break;
                    case "Science units that must be lab units":
                        this.scienceLabRequiredCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].requiredUnits;
                        this.scienceLabRecommendedCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].recommendedUnits;
                        break;
                    case "Science":
                        this.scienceRequiredCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].requiredUnits;
                        this.scienceRecommendedCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].recommendedUnits;
                        break;
                    case "Mathematics":
                        this.mathRequiredCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].requiredUnits;
                        this.mathRecommendedCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].recommendedUnits;
                        break;
                    case "Social studies":
                        this.socialStudiesRequiredCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].requiredUnits;
                        this.socialStudiesRecommendedCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].recommendedUnits;
                        break;
                    case "Academic elective":
                        this.electiveRequiredCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].requiredUnits;
                        this.electiveRecommendedCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].recommendedUnits;
                        break;
                    case "Art":
                        this.artRequiredCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].requiredUnits;
                        this.artRecommendedCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].recommendedUnits;
                        break;
                    case "Agriculture":
                        this.agricultureRequiredCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].requiredUnits;
                        this.agricultureRecommendedCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].recommendedUnits;
                        break;
                    case "Other":
                        this.otherRequiredCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].requiredUnits;
                        this.otherRecommendedCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].recommendedUnits;
                        break;
                    case "Total":
                        this.totalRequiredCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].requiredUnits;
                        this.totalRecommendedCredits = this.institution.profile.freshmanApplicationProcess.creditRequirements[i].recommendedUnits;
                        break;
                }
            }
        }

        updateProgramRequirements() {
            var location;
            var data;
            var subjects = _.map(this.institution.profile.freshmanApplicationProcess.creditRequirements, "subject");
            location = _.indexOf(subjects, "English");
            if (location != -1) {
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].requiredUnits = this.englishRequiredCredits;
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].recommendedUnits = this.englishRecommendedCredits;
            }
            else {
                data = { subject: "English", requiredUnits: this.englishRequiredCredits, recommendedUnits: this.englishRecommendedCredits };
                this.institution.profile.freshmanApplicationProcess.creditRequirements.push(data);
            }
            location = _.indexOf(subjects, "Foreign language");
            if (location != -1) {
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].requiredUnits = this.foreignLanguageRequiredCredits;
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].recommendedUnits = this.foreignLanguageRecommendedCredits;
            }
            else {
                data = { subject: "Foreign language", requiredUnits: this.foreignLanguageRequiredCredits, recommendedUnits: this.foreignLanguageRecommendedCredits };
                this.institution.profile.freshmanApplicationProcess.creditRequirements.push(data);
            }
            location = _.indexOf(subjects, "History");
            if (location != -1) {
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].requiredUnits = this.historyRequiredCredits;
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].recommendedUnits = this.historyRecommendedCredits;
            }
            else {
                data = { subject: "History", requiredUnits: this.historyRequiredCredits, recommendedUnits: this.historyRecommendedCredits };
                this.institution.profile.freshmanApplicationProcess.creditRequirements.push(data);
            }
            location = _.indexOf(subjects, "Science units that must be lab units");
            if (location != -1) {
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].requiredUnits = this.scienceLabRequiredCredits;
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].recommendedUnits = this.scienceLabRecommendedCredits;
            }
            else {
                data = { subject: "Science units that must be lab units", requiredUnits: this.scienceLabRequiredCredits, recommendedUnits: this.scienceLabRecommendedCredits };
                this.institution.profile.freshmanApplicationProcess.creditRequirements.push(data);
            }
            location = _.indexOf(subjects, "Science");
            if (location != -1) {
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].requiredUnits = this.scienceRequiredCredits;
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].recommendedUnits = this.scienceRecommendedCredits;
            }
            else {
                data = { subject: "Science", requiredUnits: this.scienceRequiredCredits, recommendedUnits: this.scienceRecommendedCredits };
                this.institution.profile.freshmanApplicationProcess.creditRequirements.push(data);
            }
            location = _.indexOf(subjects, "Mathematics");
            if (location != -1) {
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].requiredUnits = this.mathRequiredCredits;
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].recommendedUnits = this.mathRecommendedCredits;
            }
            else {
                data = { subject: "Mathematics", requiredUnits: this.mathRequiredCredits, recommendedUnits: this.mathRecommendedCredits };
                this.institution.profile.freshmanApplicationProcess.creditRequirements.push(data);
            }
            location = _.indexOf(subjects, "Social studies");
            if (location != -1) {
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].requiredUnits = this.socialStudiesRequiredCredits;
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].recommendedUnits = this.socialStudiesRecommendedCredits;
            }
            else {
                data = { subject: "Social studies", requiredUnits: this.socialStudiesRequiredCredits, recommendedUnits: this.socialStudiesRecommendedCredits };
                this.institution.profile.freshmanApplicationProcess.creditRequirements.push(data);
            }
            location = _.indexOf(subjects, "Academic elective");
            if (location != -1) {
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].requiredUnits = this.electiveRequiredCredits;
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].recommendedUnits = this.electiveRecommendedCredits;
            }
            else {
                data = { subject: "Academic elective", requiredUnits: this.electiveRequiredCredits, recommendedUnits: this.electiveRecommendedCredits };
                this.institution.profile.freshmanApplicationProcess.creditRequirements.push(data);
            }
            location = _.indexOf(subjects, "Art");
            if (location != -1) {
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].requiredUnits = this.artRequiredCredits;
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].recommendedUnits = this.artRecommendedCredits;
            }
            else {
                data = { subject: "Art", requiredUnits: this.artRequiredCredits, recommendedUnits: this.artRecommendedCredits };
                this.institution.profile.freshmanApplicationProcess.creditRequirements.push(data);
            }
            location = _.indexOf(subjects, "Agriculture");
            if (location != -1) {
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].requiredUnits = this.agricultureRequiredCredits;
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].recommendedUnits = this.agricultureRecommendedCredits;
            }
            else {
                data = { subject: "Agriculture", requiredUnits: this.agricultureRequiredCredits, recommendedUnits: this.agricultureRecommendedCredits };
                this.institution.profile.freshmanApplicationProcess.creditRequirements.push(data);
            }
            location = _.indexOf(subjects, "Other");
            if (location != -1) {
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].requiredUnits = this.otherRequiredCredits;
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].recommendedUnits = this.otherRecommendedCredits;
            }
            else {
                data = { subject: "Other", requiredUnits: this.otherRequiredCredits, recommendedUnits: this.otherRecommendedCredits };
                this.institution.profile.freshmanApplicationProcess.creditRequirements.push(data);
            }
            location = _.indexOf(subjects, "Total");
            if (location != -1) {
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].requiredUnits = this.totalRequiredCredits;
                this.institution.profile.freshmanApplicationProcess.creditRequirements[location].recommendedUnits = this.totalRecommendedCredits;
            }
            else {
                data = { subject: "Total", requiredUnits: this.totalRequiredCredits, recommendedUnits: this.totalRecommendedCredits };
                this.institution.profile.freshmanApplicationProcess.creditRequirements.push(data);
            }
        }

        getDates() {
            this.institution.profile.freshmanApplicationProcess.earlyDecisionInfo.fallEarlyDecisionAppDeadline =
                this.earlyDecisionMonth != "" && this.earlyDecisionDay != "" ? this.earlyDecisionMonth + "/" + this.earlyDecisionDay : "";
            this.institution.profile.freshmanApplicationProcess.earlyDecisionInfo.earlyActionFilingDate =
                this.earlyActionMonth != "" && this.earlyActionDay != "" ? this.earlyActionMonth + "/" + this.earlyActionDay : "";
            this.institution.profile.freshmanApplicationProcess.finalFilingDeadline =
                this.applicationDeadlineMonth != "" && this.applicationDeadlineDay != "" ? this.applicationDeadlineMonth + "/" + this.applicationDeadlineDay : "";
            this.institution.profile.freshmanApplicationProcess.interviewVisitDeadline =
                this.interviewDeadlineMonth != "" && this.interviewDeadlineDay != "" ? this.interviewDeadlineMonth + "/" + this.interviewDeadlineDay : "";
            this.institution.profile.freshmanApplicationProcess.satACTScoresDeadline =
                this.scoreDeadlineMonth != "" && this.scoreDeadlineDay != "" ? this.scoreDeadlineMonth + "/" + this.scoreDeadlineDay : "";
            this.institution.profile.freshmanApplicationProcess.saT2ScoresDeadline =
                this.subjectScoreMonth != "" && this.subjectScoreDay != "" ? this.subjectScoreMonth + "/" + this.subjectScoreDay : "";
            this.institution.profile.fallUnderGraduateNotification.notificationDate =
                this.undergradNotificationMonth != "" && this.undergradNotificationDay != "" ? this.undergradNotificationMonth + "/" + this.undergradNotificationDay : "";
            this.institution.profile.freshmanSelectionProcess.acceptanceReplyDate =
                this.admissionAcceptanceMonth != "" && this.admissionAcceptanceDay != "" ? this.admissionAcceptanceMonth + "/" + this.admissionAcceptanceDay : "";
            this.institution.profile.costFinancialAid.expenses.notificationDateStart =
                this.aidAwardsBeginMonth != "" && this.aidAwardsBeginDay != "" ? this.aidAwardsBeginMonth + "/" + this.aidAwardsBeginDay : "";
            this.institution.profile.freshmanEnrollment.tuitionDeposit.refundableDeadline =
                this.tuitionDepositMonth != "" && this.tuitionDepositDay != "" ? this.tuitionDepositMonth + "/" + this.tuitionDepositDay : "";
            this.institution.profile.freshmanEnrollment.roomDeposit.refundableDeadline =
                this.roomDepositMonth != "" && this.roomDepositDay != "" ? this.roomDepositMonth + "/" + this.roomDepositDay : "";
            this.institution.profile.academics.academicCalendar.semesterTermBeggining =
                this.academicCalendarMonth != "" && this.academicCalendarDay != "" ? this.academicCalendarMonth + "/" + this.academicCalendarDay : "";
            this.institution.profile.costFinancialAid.expenses.fafsaPriorityDate =
                this.fafsaPriorityMonth != "" && this.fafsaPriorityDay != "" ? this.fafsaPriorityMonth + "/" + this.fafsaPriorityDay : "";
            this.institution.profile.costFinancialAid.expenses.fafsaClosingDate =
                this.fafsaClosingMonth != "" && this.fafsaClosingDay != "" ? this.fafsaClosingMonth + "/" + this.fafsaClosingDay : "";
            this.institution.profile.costFinancialAid.expenses.customFAFSAFormPriorityDate =
                this.customFAFSAPriorityMonth != "" && this.customFAFSAPriorityDay != "" ? this.customFAFSAPriorityMonth + "/" + this.customFAFSAPriorityDay : "";
            this.institution.profile.costFinancialAid.expenses.customFAFSAFormClosingDate =
                this.customFAFSAClosingMonth != "" && this.customFAFSAClosingDay != "" ? this.customFAFSAClosingMonth + "/" + this.customFAFSAClosingDay : "";
            this.institution.profile.costFinancialAid.expenses.cssFinancialAidProfilePriorityDate =
                this.cssProfilePriorityMonth != "" && this.cssProfilePriorityDay != "" ? this.cssProfilePriorityMonth + "/" + this.cssProfilePriorityDay : "";
            this.institution.profile.costFinancialAid.expenses.cssFinancialAidProfileClosingDate =
                this.cssProfileClosingMonth != "" && this.cssProfileClosingDay != "" ? this.cssProfileClosingMonth + "/" + this.cssProfileClosingDay : "";
            this.institution.profile.costFinancialAid.expenses.stateAidFormPriorityDate =
                this.stateAidPriorityMonth != "" && this.stateAidPriorityDay != "" ? this.stateAidPriorityMonth + "/" + this.stateAidPriorityDay : "";
            this.institution.profile.costFinancialAid.expenses.stateAidFormClosingDate =
                this.stateAidClosingMonth != "" && this.stateAidClosingDay != "" ? this.stateAidClosingMonth + "/" + this.stateAidClosingDay : "";
            this.institution.profile.costFinancialAid.expenses.noncustodialStatmentRequiredPriorityDate =
                this.noncustodialPriorityMonth != "" && this.noncustodialPriorityDay != "" ? this.noncustodialPriorityMonth + "/" + this.noncustodialPriorityDay : "";
            this.institution.profile.costFinancialAid.expenses.noncustodialStatmentRequiredClosingDate =
                this.noncustodialClosingMonth != "" && this.noncustodialClosingDay != "" ? this.noncustodialClosingMonth + "/" + this.noncustodialClosingDay : "";
            this.institution.profile.costFinancialAid.expenses.businessFarmSupplimentPriorityDate =
                this.businessFarmPriorityMonth != "" && this.businessFarmPriorityDay != "" ? this.businessFarmPriorityMonth + "/" + this.businessFarmPriorityDay : "";
            this.institution.profile.costFinancialAid.expenses.businessFarmSupplimentClosingDate =
                this.businessFarmClosingMonth != "" && this.businessFarmClosingDay != "" ? this.businessFarmClosingMonth + "/" + this.businessFarmClosingDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.filingDates.fall =
                this.fallPriorityMonth != "" && this.fallPriorityDay != "" ? this.fallPriorityMonth + "/" + this.fallPriorityDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.filingDates.winter =
                this.winterPriorityMonth != "" && this.winterPriorityDay != "" ? this.winterPriorityMonth + "/" + this.winterPriorityDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.filingDates.spring =
                this.springPriorityMonth != "" && this.springPriorityDay != "" ? this.springPriorityMonth + "/" + this.springPriorityDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.filingDates.summer =
                this.summerPriorityMonth != "" && this.summerPriorityDay != "" ? this.summerPriorityMonth + "/" + this.summerPriorityDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.closingDates.fall =
                this.fallClosingMonth != "" && this.fallClosingDay != "" ? this.fallClosingMonth + "/" + this.fallClosingDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.closingDates.winter =
                this.winterClosingMonth != "" && this.winterClosingDay != "" ? this.winterClosingMonth + "/" + this.winterClosingDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.closingDates.spring =
                this.springClosingMonth != "" && this.springClosingDay != "" ? this.springClosingMonth + "/" + this.springClosingDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.closingDates.summer =
                this.summerClosingMonth != "" && this.summerClosingDay != "" ? this.summerClosingMonth + "/" + this.summerClosingDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.notificationDates.fall =
                this.fallNotificationMonth != "" && this.fallNotificationDay != "" ? this.fallNotificationMonth + "/" + this.fallNotificationDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.notificationDates.winter =
                this.winterNotificationMonth != "" && this.winterNotificationDay != "" ? this.winterNotificationMonth + "/" + this.winterNotificationDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.notificationDates.spring =
                this.springNotificationMonth != "" && this.springNotificationDay != "" ? this.springNotificationMonth + "/" + this.springNotificationDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.notificationDates.summer =
                this.summerNotificationMonth != "" && this.summerNotificationDay != "" ? this.summerNotificationMonth + "/" + this.summerNotificationDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.acceptanceDates.fall =
                this.fallAcceptanceMonth != "" && this.fallAcceptanceDay != "" ? this.fallAcceptanceMonth + "/" + this.fallAcceptanceDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.acceptanceDates.winter =
                this.winterAcceptanceMonth != "" && this.winterAcceptanceDay != "" ? this.winterAcceptanceMonth + "/" + this.winterAcceptanceDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.acceptanceDates.spring =
                this.springAcceptanceMonth != "" && this.springAcceptanceDay != "" ? this.springAcceptanceMonth + "/" + this.springAcceptanceDay : "";
            this.institution.profile.transferAdmissions.applicationCreditTransfer.acceptanceDates.summer =
                this.summerAcceptanceMonth != "" && this.summerAcceptanceDay != "" ? this.summerAcceptanceMonth + "/" + this.summerAcceptanceDay : "";
            this.institution.profile.internationalStudents.internationalApplicationProcess.applicationClosingDates.fall =
                this.fallIntlClosingMonth != "" && this.fallIntlClosingDay != "" ? this.fallIntlClosingMonth + "/" + this.fallIntlClosingDay : "";
            this.institution.profile.internationalStudents.internationalApplicationProcess.applicationClosingDates.winter =
                this.winterIntlClosingMonth != "" && this.winterIntlClosingDay != "" ? this.winterIntlClosingMonth + "/" + this.winterIntlClosingDay : "";
            this.institution.profile.internationalStudents.internationalApplicationProcess.applicationClosingDates.spring =
                this.springIntlClosingMonth != "" && this.springIntlClosingDay != "" ? this.springIntlClosingMonth + "/" + this.springIntlClosingDay : "";
            this.institution.profile.internationalStudents.internationalApplicationProcess.applicationClosingDates.summer =
                this.summerIntlClosingMonth != "" && this.summerIntlClosingDay != "" ? this.summerIntlClosingMonth + "/" + this.summerIntlClosingDay : "";

            this.institution.profile.fallUnderGraduateNotification.AcceptanceDate =
                this.acceptanceMonth != "" && this.acceptanceDay != "" ? this.acceptanceMonth + "/" + this.acceptanceDay : "";
        }

        splitDates() {
            var splitDate;
            if (this.institution.profile.fallUnderGraduateNotification.AcceptanceDate != null) {
                splitDate = this.institution.profile.fallUnderGraduateNotification.AcceptanceDate.split('/');
                this.acceptanceMonth = splitDate[0];
                this.acceptanceDay = splitDate[1];
            }
            else {
                this.acceptanceMonth = "";
                this.acceptanceDay = "";
            }
            if (this.institution.profile.freshmanApplicationProcess.earlyDecisionInfo.fallEarlyDecisionAppDeadline != null) {
                splitDate = this.institution.profile.freshmanApplicationProcess.earlyDecisionInfo.fallEarlyDecisionAppDeadline.split('/');
                this.earlyDecisionMonth = splitDate[0];
                this.earlyDecisionDay = splitDate[1];
            }
            else {
                this.earlyDecisionMonth = "";
                this.earlyDecisionDay = "";
            }
            if (this.institution.profile.freshmanApplicationProcess.earlyDecisionInfo.earlyActionFilingDate != null) {
                splitDate = this.institution.profile.freshmanApplicationProcess.earlyDecisionInfo.earlyActionFilingDate.split('/');
                this.earlyActionMonth = splitDate[0];
                this.earlyActionDay = splitDate[1];
            }
            else {
                this.earlyActionMonth = "";
                this.earlyActionDay = "";
            }
            if (this.institution.profile.freshmanApplicationProcess.finalFilingDeadline != null) {
                splitDate = this.institution.profile.freshmanApplicationProcess.finalFilingDeadline.split('/');
                this.applicationDeadlineMonth = splitDate[0];
                this.applicationDeadlineDay = splitDate[1];
            }
            else {
                this.applicationDeadlineMonth = "";
                this.applicationDeadlineDay = "";
            }
            if (this.institution.profile.freshmanApplicationProcess.interviewVisitDeadline != null) {
                splitDate = this.institution.profile.freshmanApplicationProcess.interviewVisitDeadline.split('/');
                this.interviewDeadlineMonth = splitDate[0];
                this.interviewDeadlineDay = splitDate[1];
            }
            else {
                this.interviewDeadlineMonth = "";
                this.interviewDeadlineDay = "";
            }
            if (this.institution.profile.freshmanApplicationProcess.satACTScoresDeadline != null) {
                splitDate = this.institution.profile.freshmanApplicationProcess.satACTScoresDeadline.split('/');
                this.scoreDeadlineMonth = splitDate[0];
                this.scoreDeadlineDay = splitDate[1];
            }
            else {
                this.scoreDeadlineMonth = "";
                this.scoreDeadlineDay = "";
            }
            if (this.institution.profile.freshmanApplicationProcess.saT2ScoresDeadline != null) {
                splitDate = this.institution.profile.freshmanApplicationProcess.saT2ScoresDeadline.split('/');
                this.subjectScoreMonth = splitDate[0];
                this.subjectScoreDay = splitDate[1];
            }
            else {
                this.subjectScoreMonth = "";
                this.subjectScoreDay = "";
            }
            if (this.institution.profile.freshmanApplicationProcess.satactScoresDeadline != null) {
                splitDate = this.institution.profile.freshmanApplicationProcess.satactScoresDeadline.split('/');
                this.scoreDeadlineMonth = splitDate[0];
                this.scoreDeadlineDay = splitDate[1];
            }
            else {
                this.scoreDeadlineMonth = "";
                this.scoreDeadlineDay = "";
            }
            if (this.institution.profile.fallUnderGraduateNotification.notificationDate != null) {
                splitDate = this.institution.profile.fallUnderGraduateNotification.notificationDate.split('/');
                this.undergradNotificationMonth = splitDate[0];
                this.undergradNotificationDay = splitDate[1];
            }
            else {
                this.undergradNotificationMonth = "";
                this.undergradNotificationDay = "";
            }
            if (this.institution.profile.fallUnderGraduateNotification.byDate != null) {
                splitDate = this.institution.profile.fallUnderGraduateNotification.byDate.split('/');
                this.admissionAcceptanceMonth = splitDate[0];
                this.admissionAcceptanceDay = splitDate[1];
            }
            else {
                this.admissionAcceptanceMonth = "";
                this.admissionAcceptanceDay = "";
            }
            if (this.institution.profile.costFinancialAid.expenses.notificationDateStart != null) {
                splitDate = this.institution.profile.costFinancialAid.expenses.notificationDateStart.split('/');
                this.aidAwardsBeginMonth = splitDate[0];
                this.aidAwardsBeginDay = splitDate[1];
            }
            else {
                this.aidAwardsBeginMonth = "";
                this.aidAwardsBeginDay = "";
            }
            if (this.institution.profile.freshmanEnrollment.roomDeposit.refundableDeadline != null) {
                splitDate = this.institution.profile.freshmanEnrollment.roomDeposit.refundableDeadline.split('/');
                this.roomDepositMonth = splitDate[0];
                this.roomDepositDay = splitDate[1];
            }
            else {
                this.roomDepositMonth = "";
                this.roomDepositDay = "";
            }
            if (this.institution.profile.freshmanEnrollment.tuitionDeposit.refundableDeadline != null) {
                splitDate = this.institution.profile.freshmanEnrollment.tuitionDeposit.refundableDeadline.split('/');
                this.tuitionDepositMonth = splitDate[0];
                this.tuitionDepositDay = splitDate[1];
            }
            else {
                this.tuitionDepositMonth = "";
                this.tuitionDepositDay = "";
            }
            if (this.institution.profile.academics.academicCalendar.semesterTermBeggining != null) {
                splitDate = this.institution.profile.academics.academicCalendar.semesterTermBeggining.split('/');
                this.academicCalendarMonth = splitDate[0];
                this.academicCalendarDay = splitDate[1];
            }
            else {
                this.academicCalendarMonth = "";
                this.academicCalendarDay = "";
            }
            if (this.institution.profile.costFinancialAid.expenses.fafsaPriorityDate != null) {
                splitDate = this.institution.profile.costFinancialAid.expenses.fafsaPriorityDate.split('/');
                this.fafsaPriorityMonth = splitDate[0];
                this.fafsaPriorityDay = splitDate[1];
            }
            else {
                this.fafsaPriorityMonth = "";
                this.fafsaPriorityDay = "";
            }
            if (this.institution.profile.costFinancialAid.expenses.fafsaClosingDate != null) {
                splitDate = this.institution.profile.costFinancialAid.expenses.fafsaClosingDate.split('/');
                this.fafsaClosingMonth = splitDate[0];
                this.fafsaClosingDay = splitDate[1];
            }
            else {
                this.fafsaClosingMonth = "";
                this.fafsaClosingDay = "";
            }
            if (this.institution.profile.costFinancialAid.expenses.customFAFSAFormPriorityDate != null) {
                splitDate = this.institution.profile.costFinancialAid.expenses.customFAFSAFormPriorityDate.split('/');
                this.customFAFSAPriorityMonth = splitDate[0];
                this.customFAFSAPriorityDay = splitDate[1];
            }
            else {
                this.customFAFSAPriorityMonth = "";
                this.customFAFSAPriorityDay = "";
            }
            if (this.institution.profile.costFinancialAid.expenses.customFAFSAFormClosingDate != null) {
                splitDate = this.institution.profile.costFinancialAid.expenses.customFAFSAFormClosingDate.split('/');
                this.customFAFSAClosingMonth = splitDate[0];
                this.customFAFSAClosingDay = splitDate[1];
            }
            else {
                this.customFAFSAClosingMonth = "";
                this.customFAFSAClosingDay = "";
            }
            if (this.institution.profile.costFinancialAid.expenses.cssFinancialAidProfilePriorityDate != null) {
                splitDate = this.institution.profile.costFinancialAid.expenses.cssFinancialAidProfilePriorityDate.split('/');
                this.cssProfilePriorityMonth = splitDate[0];
                this.cssProfilePriorityDay = splitDate[1];
            }
            else {
                this.cssProfilePriorityMonth = "";
                this.cssProfilePriorityDay = "";
            }
            if (this.institution.profile.costFinancialAid.expenses.cssFinancialAidProfileClosingDate != null) {
                splitDate = this.institution.profile.costFinancialAid.expenses.cssFinancialAidProfileClosingDate.split('/');
                this.cssProfileClosingMonth = splitDate[0];
                this.cssProfileClosingDay = splitDate[1];
            }
            else {
                this.cssProfileClosingMonth = "";
                this.cssProfileClosingDay = "";
            }
            if (this.institution.profile.costFinancialAid.expenses.stateAidFormPriorityDate != null) {
                splitDate = this.institution.profile.costFinancialAid.expenses.stateAidFormPriorityDate.split('/');
                this.stateAidPriorityMonth = splitDate[0];
                this.stateAidPriorityDay = splitDate[1];
            }
            else {
                this.stateAidPriorityMonth = "";
                this.stateAidPriorityDay = "";
            }
            if (this.institution.profile.costFinancialAid.expenses.stateAidFormClosingDate != null) {
                splitDate = this.institution.profile.costFinancialAid.expenses.stateAidFormClosingDate.split('/');
                this.stateAidClosingMonth = splitDate[0];
                this.stateAidClosingDay = splitDate[1];
            }
            else {
                this.academicCalendarMonth = "";
                this.academicCalendarDay = "";
            }
            if (this.institution.profile.costFinancialAid.expenses.noncustodialStatmentRequiredPriorityDate != null) {
                splitDate = this.institution.profile.costFinancialAid.expenses.noncustodialStatmentRequiredPriorityDate.split('/');
                this.noncustodialPriorityMonth = splitDate[0];
                this.noncustodialPriorityDay = splitDate[1];
            }
            else {
                this.academicCalendarMonth = "";
                this.academicCalendarDay = "";
            }
            if (this.institution.profile.costFinancialAid.expenses.noncustodialStatmentRequiredClosingDate != null) {
                splitDate = this.institution.profile.costFinancialAid.expenses.noncustodialStatmentRequiredClosingDate.split('/');
                this.noncustodialClosingMonth = splitDate[0];
                this.noncustodialClosingDay = splitDate[1];
            }
            else {
                this.noncustodialClosingMonth = "";
                this.noncustodialClosingDay = "";
            }
            if (this.institution.profile.costFinancialAid.expenses.businessFarmSupplimentPriorityDate != null) {
                splitDate = this.institution.profile.costFinancialAid.expenses.businessFarmSupplimentPriorityDate.split('/');
                this.businessFarmPriorityMonth = splitDate[0];
                this.businessFarmPriorityDay = splitDate[1];
            }
            else {
                this.businessFarmPriorityMonth = "";
                this.businessFarmPriorityDay = "";
            }
            if (this.institution.profile.costFinancialAid.expenses.businessFarmSupplimentClosingDate != null) {
                splitDate = this.institution.profile.costFinancialAid.expenses.businessFarmSupplimentClosingDate.split('/');
                this.businessFarmClosingMonth = splitDate[0];
                this.businessFarmClosingDay = splitDate[1];
            }
            else {
                this.businessFarmClosingMonth = "";
                this.businessFarmClosingDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.filingDates.fall != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.filingDates.fall.split('/');
                this.fallPriorityMonth = splitDate[0];
                this.fallPriorityDay = splitDate[1];
            }
            else {
                this.fallPriorityMonth = "";
                this.fallPriorityDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.filingDates.winter != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.filingDates.winter.split('/');
                this.winterPriorityMonth = splitDate[0];
                this.winterPriorityDay = splitDate[1];
            }
            else {
                this.winterPriorityMonth = "";
                this.winterPriorityDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.filingDates.spring != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.filingDates.spring.split('/');
                this.springPriorityMonth = splitDate[0];
                this.springPriorityDay = splitDate[1];
            }
            else {
                this.springPriorityMonth = "";
                this.springPriorityDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.filingDates.summer != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.filingDates.summer.split('/');
                this.summerPriorityMonth = splitDate[0];
                this.summerPriorityDay = splitDate[1];
            }
            else {
                this.summerPriorityMonth = "";
                this.summerPriorityDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.closingDates.fall != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.closingDates.fall.split('/');
                this.fallClosingMonth = splitDate[0];
                this.fallClosingDay = splitDate[1];
            }
            else {
                this.fallClosingMonth = "";
                this.fallClosingDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.closingDates.winter != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.closingDates.winter.split('/');
                this.winterClosingMonth = splitDate[0];
                this.winterClosingDay = splitDate[1];
            }
            else {
                this.winterClosingMonth = "";
                this.winterClosingDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.closingDates.spring != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.closingDates.spring.split('/');
                this.springClosingMonth = splitDate[0];
                this.springClosingDay = splitDate[1];
            }
            else {
                this.springClosingMonth = "";
                this.springClosingDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.closingDates.summer != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.closingDates.summer.split('/');
                this.summerClosingMonth = splitDate[0];
                this.summerClosingDay = splitDate[1];
            }
            else {
                this.summerClosingMonth = "";
                this.summerClosingDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.notificationDates.fall != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.notificationDates.fall.split('/');
                this.fallNotificationMonth = splitDate[0];
                this.fallNotificationDay = splitDate[1];
            }
            else {
                this.fallNotificationMonth = "";
                this.fallNotificationDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.notificationDates.winter != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.notificationDates.winter.split('/');
                this.winterNotificationMonth = splitDate[0];
                this.winterNotificationDay = splitDate[1];
            }
            else {
                this.winterNotificationMonth = "";
                this.winterNotificationDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.notificationDates.spring != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.notificationDates.spring.split('/');
                this.springNotificationMonth = splitDate[0];
                this.springNotificationDay = splitDate[1];
            }
            else {
                this.springNotificationMonth = "";
                this.springNotificationDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.notificationDates.summer != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.notificationDates.summer.split('/');
                this.summerNotificationMonth = splitDate[0];
                this.summerNotificationDay = splitDate[1];
            }
            else {
                this.summerNotificationMonth = "";
                this.summerNotificationDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.acceptanceDates.fall != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.acceptanceDates.fall.split('/');
                this.fallAcceptanceMonth = splitDate[0];
                this.fallAcceptanceDay = splitDate[1];
            }
            else {
                this.fallAcceptanceMonth = "";
                this.fallAcceptanceDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.acceptanceDates.winter != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.acceptanceDates.winter.split('/');
                this.winterAcceptanceMonth = splitDate[0];
                this.winterAcceptanceDay = splitDate[1];
            }
            else {
                this.winterAcceptanceMonth = "";
                this.winterAcceptanceDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.acceptanceDates.spring != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.acceptanceDates.spring.split('/');
                this.springAcceptanceMonth = splitDate[0];
                this.springAcceptanceDay = splitDate[1];
            }
            else {
                this.springAcceptanceMonth = "";
                this.springAcceptanceDay = "";
            }
            if (this.institution.profile.transferAdmissions.applicationCreditTransfer.acceptanceDates.summer != null) {
                splitDate = this.institution.profile.transferAdmissions.applicationCreditTransfer.acceptanceDates.summer.split('/');
                this.summerAcceptanceMonth = splitDate[0];
                this.summerAcceptanceDay = splitDate[1];
            }
            else {
                this.summerAcceptanceMonth = "";
                this.summerAcceptanceDay = "";
            }

            if (this.institution.profile.internationalStudents.internationalApplicationProcess.applicationClosingDates.fall != null) {
                splitDate = this.institution.profile.internationalStudents.internationalApplicationProcess.applicationClosingDates.fall.split('/');
                this.fallIntlClosingMonth = splitDate[0];
                this.fallIntlClosingDay = splitDate[1];
            }
            else {
                this.fallIntlClosingMonth = "";
                this.fallIntlClosingDay = "";
            }
            if (this.institution.profile.internationalStudents.internationalApplicationProcess.applicationClosingDates.winter != null) {
                splitDate = this.institution.profile.internationalStudents.internationalApplicationProcess.applicationClosingDates.winter.split('/');
                this.winterIntlClosingMonth = splitDate[0];
                this.winterIntlClosingDay = splitDate[1];
            }
            else {
                this.winterIntlClosingMonth = "";
                this.winterIntlClosingDay = "";
            }
            if (this.institution.profile.internationalStudents.internationalApplicationProcess.applicationClosingDates.spring != null) {
                splitDate = this.institution.profile.internationalStudents.internationalApplicationProcess.applicationClosingDates.spring.split('/');
                this.springIntlClosingMonth = splitDate[0];
                this.springIntlClosingDay = splitDate[1];
            }
            else {
                this.springIntlClosingMonth = "";
                this.springIntlClosingDay = "";
            }
            if (this.institution.profile.internationalStudents.internationalApplicationProcess.applicationClosingDates.summer != null) {
                splitDate = this.institution.profile.internationalStudents.internationalApplicationProcess.applicationClosingDates.summer.split('/');
                this.summerIntlClosingMonth = splitDate[0];
                this.summerIntlClosingDay = splitDate[1];
            }
            else {
                this.summerIntlClosingMonth = "";
                this.summerIntlClosingDay = "";
            }
        }

        setDefaultDropdownValues() {
            this.institution.profile.freshmanApplicationProcess.diplomaRequired = this.institution.profile.freshmanApplicationProcess.diplomaRequired == null ? "" : this.institution.profile.freshmanApplicationProcess.diplomaRequired;
            this.institution.profile.freshmanApplicationProcess.interviewRecommended = this.institution.profile.freshmanApplicationProcess.interviewRecommended == null ? "" : this.institution.profile.freshmanApplicationProcess.interviewRecommended;
            this.institution.profile.freshmanApplicationProcess.testRequirement = this.institution.profile.freshmanApplicationProcess.testRequirement == null ? "" : this.institution.profile.freshmanApplicationProcess.testRequirement;
            this.institution.profile.freshmanApplicationProcess.testPreferred = this.institution.profile.freshmanApplicationProcess.testPreferred == null ? "" : this.institution.profile.freshmanApplicationProcess.testPreferred;
            this.institution.profile.freshmanApplicationProcess.satSubjectTestsRequired = this.institution.profile.freshmanApplicationProcess.satSubjectTestsRequired == null ? "" : this.institution.profile.freshmanApplicationProcess.satSubjectTestsRequired;
            this.institution.profile.freshmanApplicationProcess.saT2TestRequiredBy = this.institution.profile.freshmanApplicationProcess.saT2TestRequiredBy == null ? "" : this.institution.profile.freshmanApplicationProcess.saT2TestRequiredBy;
            this.institution.profile.freshmanSelectionProcess.secondarySchoolRecord = this.institution.profile.freshmanSelectionProcess.secondarySchoolRecord == null ? "" : this.institution.profile.freshmanSelectionProcess.secondarySchoolRecord;
            this.institution.profile.freshmanSelectionProcess.classRank = this.institution.profile.freshmanSelectionProcess.classRank == null ? "" : this.institution.profile.freshmanSelectionProcess.classRank;
            this.institution.profile.freshmanSelectionProcess.recommendations = this.institution.profile.freshmanSelectionProcess.recommendations == null ? "" : this.institution.profile.freshmanSelectionProcess.recommendations;
            this.institution.profile.freshmanSelectionProcess.standardizedTestScores = this.institution.profile.freshmanSelectionProcess.standardizedTestScores == null ? "" : this.institution.profile.freshmanSelectionProcess.standardizedTestScores;
            this.institution.profile.freshmanSelectionProcess.essay = this.institution.profile.freshmanSelectionProcess.essay == null ? "" : this.institution.profile.freshmanSelectionProcess.essay;
            this.institution.profile.freshmanSelectionProcess.interview = this.institution.profile.freshmanSelectionProcess.interview == null ? "" : this.institution.profile.freshmanSelectionProcess.interview;
            this.institution.profile.freshmanSelectionProcess.extracurricularActivities = this.institution.profile.freshmanSelectionProcess.extracurricularActivities == null ? "" : this.institution.profile.freshmanSelectionProcess.extracurricularActivities;
            this.institution.profile.freshmanSelectionProcess.particularTalent = this.institution.profile.freshmanSelectionProcess.particularTalent == null ? "" : this.institution.profile.freshmanSelectionProcess.particularTalent;
            this.institution.profile.freshmanSelectionProcess.characterQualities = this.institution.profile.freshmanSelectionProcess.characterQualities == null ? "" : this.institution.profile.freshmanSelectionProcess.characterQualities;
            this.institution.profile.freshmanSelectionProcess.alumniRelationship = this.institution.profile.freshmanSelectionProcess.alumniRelationship == null ? "" : this.institution.profile.freshmanSelectionProcess.alumniRelationship;
            this.institution.profile.freshmanSelectionProcess.geographicalResidence = this.institution.profile.freshmanSelectionProcess.geographicalResidence == null ? "" : this.institution.profile.freshmanSelectionProcess.geographicalResidence;
            this.institution.profile.freshmanSelectionProcess.stateResidency = this.institution.profile.freshmanSelectionProcess.stateResidency == null ? "" : this.institution.profile.freshmanSelectionProcess.stateResidency;
            this.institution.profile.freshmanSelectionProcess.religiousAffiliation = this.institution.profile.freshmanSelectionProcess.religiousAffiliation == null ? "" : this.institution.profile.freshmanSelectionProcess.religiousAffiliation;
            this.institution.profile.freshmanSelectionProcess.minorityAffiliation = this.institution.profile.freshmanSelectionProcess.minorityAffiliation == null ? "" : this.institution.profile.freshmanSelectionProcess.minorityAffiliation;
            this.institution.profile.freshmanSelectionProcess.volunteerWork = this.institution.profile.freshmanSelectionProcess.volunteerWork == null ? "" : this.institution.profile.freshmanSelectionProcess.volunteerWork;
            this.institution.profile.freshmanSelectionProcess.workExperience = this.institution.profile.freshmanSelectionProcess.workExperience == null ? "" : this.institution.profile.freshmanSelectionProcess.workExperience;
            this.institution.profile.freshmanSelectionProcess.admissionsIsNeedBlind = this.institution.profile.freshmanSelectionProcess.admissionsIsNeedBlind == null ? "" : this.institution.profile.freshmanSelectionProcess.admissionsIsNeedBlind;
            this.institution.profile.freshmanSelectionProcess.testTakenByMajority = this.institution.profile.freshmanSelectionProcess.testTakenByMajority == null ? "" : this.institution.profile.freshmanSelectionProcess.testTakenByMajority;
            this.institution.profile.fallUnderGraduateNotification.notificationPolicy = this.institution.profile.fallUnderGraduateNotification.notificationPolicy == null ? "" : this.institution.profile.fallUnderGraduateNotification.notificationPolicy;
            this.institution.profile.studentLife.campusLife.unmarriedRequiredToLiveOnCampus = this.institution.profile.studentLife.campusLife.unmarriedRequiredToLiveOnCampus == null ? "" : this.institution.profile.studentLife.campusLife.unmarriedRequiredToLiveOnCampus;
        }

        checkListCounts() {
            this.satSubjectTestCount = this.institution.profile.freshmanApplicationProcess.satSubjectTests.length;
            this.specialProgramRequirementCount = this.institution.profile.freshmanApplicationProcess.specialProgramRequirements.length;
            this.preprofessionalCount = this.institution.profile.academics.offeredPrograms.preprofessionalPrograms.length;
            this.educationCertCount = this.institution.profile.academics.offeredPrograms.educationCertificatePrograms.length;
            this.coopEdCount = this.institution.profile.academics.offeredPrograms.cooperativeEducationPrograms.length;
            this.semesterAwayCount = this.institution.profile.academics.offeredPrograms.domesticSemesterAwayPrograms.length;
            this.availableLoanCount = this.institution.profile.costFinancialAid.availableLoanTypes.length;
            this.paymentPlanCount = this.institution.profile.costFinancialAid.paymentPlans.length;
            this.conferenceCount = this.institution.profile.studentLife.athletics.conferences.length;
            this.mensClubCount = this.institution.profile.studentLife.athletics.mensClubsAvailable.length;
            this.womensClubCount = this.institution.profile.studentLife.athletics.womensClubsAvailable.length;
            this.fourYearSchoolsTransferCount = this.institution.profile.studentLife.afterGraduation.fourYearSchoolsTransferredTo.length;
            this.gradSchoolsTransferCount = this.institution.profile.studentLife.afterGraduation.graduateSchoolsTransferredTo.length;
            this.firmsHiringCount = this.institution.profile.studentLife.afterGraduation.mostFrequentHiringFirms.length;
            this.alumniCount = this.institution.profile.studentLife.afterGraduation.mostProminentAlumni.length;
            this.countryCount = this.institution.profile.internationalStudents.countries.length;
            this.testsRequiredCount = this.institution.profile.internationalStudents.internationalApplicationProcess.testsRequired.length;
            this.aidFormsCount = this.institution.profile.internationalStudents.internationalApplicationProcess.financialAidForms.length;
            this.servicesOfferedCount = this.institution.profile.learningDisabled.servicesOffered.length;
            this.minorCount = this.institution.profile.academics.offeredPrograms.minors.length;
            this.domesticExchangeCount = this.institution.profile.academics.offeredPrograms.foreignExchangePrograms.length;
            this.intramuralClubCount = this.institution.profile.studentLife.athletics.intramuralClubsAvailable.length;
            //this.waivedReqCount = this.institution.profile.learningDisabled.waivedRequirements.length;
        }

        createNewItem = (arrayToExpand: any): void => {
            var newItem: any = {
                name: "",
                editMode: true,
                newItem: true
            };

            arrayToExpand.unshift(newItem);
        };

        cancelEdit = (editArray: any, originalArray: any, itemToRemove: any, index: any): void => {
            var found = _.indexOf(originalArray, itemToRemove.name);
            if (!itemToRemove.newItem) {
                var originalName = originalArray[index];
                itemToRemove.editMode = false;
                itemToRemove.name = originalName;

            } else {
                editArray.splice(index, 1);
            };
        };

        logSavedChanges() {
            this.logSuccess("Your profile changes have been saved", null, true);
        }

        getInstitution = () => {
            this.Restangular.one("ProfileSettings", this.institutionId).get().then((results) => {
                this.institution = results;
                this.getInstitutionImages();
                this.checkListCounts();
                this.splitDates();
                this.setDefaultDropdownValues();
                this.checkProgramRequirements();
                this.verifyFinancialAid();
                this.institution.profile.academics.offeredPrograms.foreignExchangePrograms = this.institution.profile.academics.offeredPrograms.foreignExchangePrograms.sort();
                this.editForeignExchange = _.map(this.institution.profile.academics.offeredPrograms.foreignExchangePrograms, o => _.extend({ name: o, editMode: false }, ""));
                this.editMinorsOffered = _.map(this.institution.profile.academics.offeredPrograms.minors, o => _.extend({ name: o, editMode: false }, ""));
                this.editIntramuralSportsOffered = _.map(this.institution.profile.studentLife.athletics.intramuralClubsAvailable, o => _.extend({ name: o, editMode: false }, ""));
            }, (reason) => {
                console.error(reason);
                this.logError("An error occured while getting profile data", reason, true);
            });
        }

        getInstitutionImages() {
            this.Restangular.one("ProfileSettings/GetInstitutionImageList", this.institution.id).get().then((results) => {
                this.imageList = results;
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        saveAboutSection() {
            this.Restangular.all("ProfileSettings/UpdateAboutSection").post(this.institution).then((results) => {
                this.$scope.contactInformation.$setPristine();
                this.$scope.campusLinks.$setPristine();
                this.$scope.quickCodes.$setPristine();
                this.$scope.campusSummary.$setPristine();
                this.$scope.aboutSpecialPrograms.$setPristine();
                this.$scope.highlights.$setPristine();
                this.$scope.quickFacts.$setPristine();
                this.logSavedChanges();
                this.institution.aboutLastModified = new Date;
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        saveAdmissionsSection() {
            this.updateProgramRequirements();
            this.getDates();
            this.Restangular.all("ProfileSettings/UpdateAdmissionsSection").post(this.institution).then((results) => {
                this.$scope.adminOffice.$setPristine();
                this.$scope.applicationProcess.$setPristine();
                this.$scope.selectionProcess.$setPristine();
                this.satSubjectTestCount = this.institution.profile.freshmanApplicationProcess.satSubjectTests.length;
                this.specialProgramRequirementCount = this.institution.profile.freshmanApplicationProcess.specialProgramRequirements.length;
                this.logSavedChanges();
                this.institution.admissionsLastModified = new Date;
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        saveAcademicsSection() {
            this.institution.profile.academics.offeredPrograms.foreignExchangePrograms = _.map(this.editForeignExchange, 'name');
            this.institution.profile.academics.offeredPrograms.minors = _.map(this.editMinorsOffered, 'name');
            this.getDates();
            this.Restangular.all("ProfileSettings/UpdateAcademicsSection").post(this.institution).then((results) => {
                this.$scope.academicCalendar.$setPristine();
                this.$scope.academicExperience.$setPristine();
                this.$scope.requiredCurriculum.$setPristine();
                this.$scope.programsOffered.$setPristine();
                this.$scope.creditPlacement.$setPristine();
                this.preprofessionalCount = this.institution.profile.academics.offeredPrograms.preprofessionalPrograms.length;
                this.educationCertCount = this.institution.profile.academics.offeredPrograms.educationCertificatePrograms.length;
                this.coopEdCount = this.institution.profile.academics.offeredPrograms.cooperativeEducationPrograms.length;
                this.semesterAwayCount = this.institution.profile.academics.offeredPrograms.domesticSemesterAwayPrograms.length;
                this.institution.academicsLastModified = new Date;
                this.logSavedChanges();
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        saveCostFinancialAidSection() {
            this.getDates();
            this.Restangular.all("ProfileSettings/UpdateCostFinancialAidSection").post(this.institution).then((results) => {
                this.$scope.aidOffice.$setPristine();
                this.$scope.expenses.$setPristine();
                this.$scope.applyingForAid.$setPristine();
                this.$scope.typesOfAid.$setPristine();
                this.availableLoanCount = this.institution.profile.costFinancialAid.availableLoanTypes.length;
                this.paymentPlanCount = this.institution.profile.costFinancialAid.paymentPlans.length;
                this.logSavedChanges();
                this.institution.costFinancialAidLastModified = new Date;
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        saveStudentLifeSection() {
            this.updateProgramRequirements();
            this.getDates();
            this.institution.profile.studentLife.athletics.intramuralClubsAvailable = _.map(this.editIntramuralSportsOffered, 'name');
            this.institution.profile.studentLife.athletics.intramuralClubsAvailable = this.institution.profile.studentLife.athletics.intramuralClubsAvailable.sort();
            this.editIntramuralSportsOffered = _.map(this.institution.profile.studentLife.athletics.intramuralClubsAvailable, o => _.extend({ name: o, editMode: false }, ""));
            this.Restangular.all("ProfileSettings/UpdateStudentLifeSection").post(this.institution).then((results) => {
                this.$scope.studentProfile.$setPristine();
                this.$scope.campusEnvironment.$setPristine();
                this.$scope.livingOnCampus.$setPristine();
                this.$scope.studyFacilities.$setPristine();
                this.$scope.assistanceServices.$setPristine();
                this.$scope.socialActivities.$setPristine();
                this.$scope.athletics.$setPristine();
                this.$scope.athleticParticipation.$setPristine();
                this.$scope.gettingAway.$setPristine();
                this.$scope.studentWorkOptions.$setPristine();
                this.$scope.afterGraduation.$setPristine();
                this.conferenceCount = this.institution.profile.studentLife.athletics.conferences.length;
                this.mensClubCount = this.institution.profile.studentLife.athletics.mensClubsAvailable.length;
                this.womensClubCount = this.institution.profile.studentLife.athletics.womensClubsAvailable.length;
                this.fourYearSchoolsTransferCount = this.institution.profile.studentLife.afterGraduation.fourYearSchoolsTransferredTo.length;
                this.gradSchoolsTransferCount = this.institution.profile.studentLife.afterGraduation.graduateSchoolsTransferredTo.length;
                this.firmsHiringCount = this.institution.profile.studentLife.afterGraduation.mostFrequentHiringFirms.length;
                this.alumniCount = this.institution.profile.studentLife.afterGraduation.mostProminentAlumni.length;
                this.logSavedChanges();
                this.institution.studentLifeLastModified = new Date;
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        saveTransferStudentSection() {
            this.getDates();
            this.Restangular.all("ProfileSettings/UpdateTransferStudentSection").post(this.institution).then((results) => {
                this.$scope.transferAcceptance.$setPristine();
                this.$scope.applicationCreditTransfer.$setPristine();
                this.logSavedChanges();
                this.institution.transferStudentLastModified = new Date;
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        saveInternationalStudentSection() {
            this.getDates();
            this.Restangular.all("ProfileSettings/UpdateInternationalStudentSection").post(this.institution).then((results) => {
                this.$scope.internationalOffice.$setPristine();
                this.$scope.countriesRepresented.$setPristine();
                this.$scope.intlApplicationProcess.$setPristine();
                this.$scope.financialAidAvailability.$setPristine();
                this.countryCount = this.institution.profile.internationalStudents.countries.length;
                this.testsRequiredCount = this.institution.profile.internationalStudents.internationalApplicationProcess.testsRequired.length;
                this.aidFormsCount = this.institution.profile.internationalStudents.internationalApplicationProcess.financialAidForms.length;
                this.logSavedChanges();
                this.institution.internationalStudentLastModified = new Date;
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        saveLearningDisabledSection() {
            this.Restangular.all("ProfileSettings/UpdateLearningDisabledSection").post(this.institution).then((results) => {
                this.$scope.disabledContact.$setPristine();
                this.$scope.aboutTheProgram.$setPristine();
                this.$scope.applyingToLD.$setPristine();
                this.servicesOfferedCount = this.institution.profile.learningDisabled.servicesOffered.length;
                this.logSavedChanges();
                this.institution.learningDisabledLastModified = new Date;
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        uploadAttachment = (file: any) => {
            if (file.length > 0) {
                this.spinner.spinnerShow();
                this.Upload.upload(<any>{
                    url: this.config.apiHost + "/ProfileSettings/UploadCampusImage/" + this.institution.id + "/attachment",
                    method: "POST",
                    file: file,
                    fields: { 'name': file[0].name, 'title': this.enteredPhotoTitle, 'description': this.enteredPhotoDesc, 'section': this.enteredPhotoSection }
                }).then((response) => {
                    this.spinner.spinnerHide();
                    this.enteredPhotoSection = "";
                    this.enteredPhotoTitle = "";
                    this.enteredPhotoDesc = "";
                    this.getInstitutionImages();
                    this.institution.campusPhotosLastModified = new Date;
                    this.logSuccess("Photo was uploaded successfully", null, true);
                }, (reason) => {
                    this.spinner.spinnerHide();
                    console.error("Failed to upload attachment", reason);
                    this.logError("Failed to upload attachment", reason, true);
                });
            }
        };

        uploadVideo = (file: any) => {
            var data = { 'InstitutionId': this.institution.id, 'VideoURL': this.videoURL, 'Title': this.enteredVideoTitle, 'Description': this.enteredVideoDesc, 'ProfileLocation': this.enteredVideoSection, thumbNailURL: this.thumbnailURL, isVR: this.isVR };
            this.Restangular.all("ProfileSettings/UploadVideo").post(data).then((results) => {
                this.logSuccess("Video information has been saved", null, true);
                this.institution.videosLastModified = new Date;
                this.getInstitution();
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        deleteImage(photoName: string) {
            var dlg = this.dialogs.confirm("Please Confirm",
                "Are you sure you want to delete this image? This action is not reversible.");
            dlg.result.then((btn) => {
                this.spinner.spinnerShow();
                var data = { InstitutionId: this.institution.id, PhotoName: photoName }
                this.Restangular.all("ProfileSettings/DeleteImage").post(data).then((results) => {
                    this.spinner.spinnerHide();
                    this.isDeleting = false;
                    this.logSavedChanges();
                    this.institution.campusPhotosLastModified = new Date;
                    this.getInstitutionImages();
                }, (reason) => {
                    console.error(reason);
                    this.logError("An error occurred", reason, true);
                });
            });
        }

        deleteVideo(video: any) {
            var dlg = this.dialogs.confirm("Please Confirm",
                "Are you sure you want to delete this video? This action is not reversible.");
            dlg.result.then((btn) => {
                var data = { InstitutionId: this.institution.id, Video: video }
                this.Restangular.all("ProfileSettings/DeleteVideo").post(data).then((results) => {
                    this.logSavedChanges();
                    console.log(results);
                    this.institution.videosLastModified = new Date;
                    this.getInstitution();
                }, (reason) => {
                    console.error(reason);
                    this.logError("An error occurred", reason, true);
                });
            });
        }
    }

    angular.module("app").controller(ProfileSettings.controllerId, ProfileSettings);
}