/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    export interface ILoginControllerScope extends angular.IScope {
        vm: LoginController;
        loginForm: angular.IFormController;
    }

    export interface ILoginRouteParams extends angular.route.IRouteParamsService {
        tenant: string;
    }

    export interface ILogin {
        errors: Array<any>;
        login: () => void;
        busy: boolean;
        cfncURL: string;        
    }

    export class LoginController implements ILogin {
        static controllerId: string = "loginController";
        static $inject: any = ["$scope", "$routeParams", "$location", "authentication", "resetPassword", "config"];
        errors: Array<any> = [];
        busy: boolean;
        loginInfo: ILoginModel;
        cfncURL: string;        

        constructor(public $scope: ILoginControllerScope,
            private $routeParams: ILoginRouteParams,
            private $location: angular.ILocationService,
            private authentication: IAuthenticationProviderService,
            private resetPassword: IResetPassword,
            private config: ICrossroadsConfig) {

            authentication.redirectAuthenticated("/");
            this.busy = false;

            this.cfncURL = config.cfncUrl;
        }
        forgotPassword = () => {
            this.resetPassword.resetPasswordInfo = {
                client_id: null,
                userName: null,
                password: null,
                rememberMe: false,
                grant_type: null,
                tenant: this.$routeParams.tenant,
                needSecurityQuestions: false
            };
            this.$location.path("/login/forgotPassword");
        };

        public login = () => {
            var self = this;

            self.errors = [];
            if (self.$scope.loginForm.$valid) {
                self.busy = true;
                self.loginInfo.tenant = self.$routeParams.tenant;
                self.authentication.login(self.loginInfo).then(result => {
                    self.busy = false;
                }, reason => {
                    if (reason.data) {
                        if (reason.data.error === "two-factor_enabled") {
                            self.resetPassword.resetPasswordInfo = {
                                client_id: self.loginInfo.client_id,
                                userName: self.loginInfo.userName,
                                password: self.loginInfo.password,
                                rememberMe: self.loginInfo.rememberMe,
                                grant_type: self.loginInfo.grant_type,
                                tenant: self.loginInfo.tenant,
                                needSecurityQuestions: false
                            };
                            self.$location.path("/login/loginWithTfaCode");
                        }
                        if (reason.data.error === "two-factor_not_configured") {
                            self.resetPassword.resetPasswordInfo = {
                                client_id: self.loginInfo.client_id,
                                userName: self.loginInfo.userName,
                                password: self.loginInfo.password,
                                rememberMe: self.loginInfo.rememberMe,
                                grant_type: self.loginInfo.grant_type,
                                tenant: self.loginInfo.tenant,
                                needSecurityQuestions: false
                            };
                            self.$location.path("/login/tfaSetup");
                        }

                        if (reason.data.error === "expired_password_missing_secret") {
                            self.resetPassword.resetPasswordInfo = {
                                client_id: self.loginInfo.client_id,
                                userName: self.loginInfo.userName,
                                password: self.loginInfo.password,
                                rememberMe: self.loginInfo.rememberMe,
                                grant_type: self.loginInfo.grant_type,
                                tenant: self.loginInfo.tenant,
                                needSecurityQuestions: true
                            };
                            self.$location.path("/login/resetPassword");
                        }

                        if (reason.data.error === "expired_password") {
                            self.resetPassword.resetPasswordInfo = {
                                client_id: self.loginInfo.client_id,
                                userName: self.loginInfo.userName,
                                password: self.loginInfo.password,
                                rememberMe: self.loginInfo.rememberMe,
                                grant_type: self.loginInfo.grant_type,
                                tenant: self.loginInfo.tenant,
                                needSecurityQuestions: false
                            };
                            self.$location.path("/login/resetPassword");
                        }

                        if (reason.data.error === "missing_secret") {
                            self.resetPassword.resetPasswordInfo = {
                                client_id: self.loginInfo.client_id,
                                userName: self.loginInfo.userName,
                                password: self.loginInfo.password,
                                rememberMe: self.loginInfo.rememberMe,
                                grant_type: self.loginInfo.grant_type,
                                tenant: self.loginInfo.tenant,
                                needSecurityQuestions: false
                            };
                            self.$location.path("/login/changeQuestion");
                        }

                        if (reason.data.error_description) {
                            self.errors.push(reason.data.error_description);
                        }
                    }
                    else {
                        self.errors.push("An error occurred while logging in. Please try again later.");
                        self.busy = false;
                    }

                    self.busy = false;
                });
            }
        };

    }


    angular.module("app").controller(LoginController.controllerId, LoginController);
}