/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict"

    interface ICampusBasicsScope extends ng.IScope {
        vm: CampusBasics;
        campusBasics: ng.IFormController;
        campusSearchFilters: ng.IFormController;
        majorSports: ng.IFormController;
    }

    interface ICampusBasics {
    }

    class CampusBasics implements ICampusBasics {
        static controllerId: string = "campusBasics";
        static $inject: Array<string> = ["config", "$scope", "$routeParams", "common", "Restangular", "$anchorScroll", "$location", "$uibModal", "$rootScope", "$timeout"];
        private log;
        apiHost: string;
        institution: any;
        institutionId: any;

        academicScholarships: boolean;
        athleticScholarships: boolean;
        needBasedScholarships: boolean;
        stateScholarships: boolean;
        institutionalScholarhips: boolean;
        privateScholarships: boolean;

        acceleratedStudy: boolean;
        distanceLearning: boolean;
        doubleMajors: boolean;
        honorsProgram: boolean;
        independentStudy: boolean;
        internships: boolean;
        selfDesignedMajors: boolean;
        studyAbroad: boolean;

        menOnlyHousing: boolean;
        womenOnlyHousing: boolean;
        coedResidenceHalls: boolean;
        cooperativeHousing: boolean;
        greekHousing: boolean;
        specialHousing: boolean;

        sportsAvailable: any;
        divisionsAvailable: any;
        selectedSport: any;
        selectedGender: any;
        selectedDivision: any;
        scholarshipOffered: boolean;

        intercollegiateSportCount: number;
        intercollegiateSports: any;
        editIntercollegiateSports: any;
        sportsChanged: boolean;
        showSports: boolean;
        originalSports: any;
        editSportsOffered: any;
        newSport: boolean;
        originalSport: any;

        logSuccess: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;

        constructor(private config: Advant.Crossroads.ICrossroadsConfig,
            private $scope: ICampusBasicsScope,
            private $routeParams,
            private common: ICommonService,
            private Restangular: Restangular.IService,
            private $anchorScroll: ng.IAnchorScrollService,
            private $location: ng.ILocationService,
            private $uibModal: ng.ui.bootstrap.IModalService,
            private $rootScope: ICrossRoadsRootScope,
            private $timeout: ng.ITimeoutService) {
            this.log = common.logger.getLogFn(CampusBasics.controllerId);

            var test = this.common.$on("$routeChangeStart",
                (event, next, current): void => {
                    if (this.$scope.campusBasics.$dirty || this.$scope.campusSearchFilters.$dirty || this.$scope.majorSports.$dirty) {
                        event.preventDefault();
                        this.common.$broadcast(this.config.events.spinnerToggle, { show: false });
                        var modalInstance = this.$uibModal.open({
                            templateUrl: '/app/profileSettings/ConfirmModal.html',
                            size: 'sm',
                            controller: 'ConfirmModal',
                        });
                        modalInstance.result.then((val: boolean) => {
                            if (val) {
                                test();
                                $location.path(next.$$route.originalPath);
                            }
                        });
                    }
                    else {
                        test();
                        $location.path(next.$$route.originalPath);
                    }
                });

            this.activate();
            common.$on(config.events.applicationChanged,(event) => {
                this.activate();
            });
        }


        activate() {
            this.common.activateController([], CampusBasics.controllerId)
                .then(() => { this.log("Campus Basics"); });
            this.logSuccess = this.common.logger.getLogFn(CampusBasics.controllerId, "success");
            this.logError = this.common.logger.getLogFn(CampusBasics.controllerId, "error");
            var config = new Config();
            this.apiHost = config.apiHost;
            this.scholarshipOffered = false;

            var app = sessionStorage.getItem("activeApplication");
            var test = app.split("/");
            this.institutionId = test[1];
            this.sportsChanged = false;
            this.showSports = false;

            this.getInstitution();

            this.sportsAvailable = [{ code: "ARCH", name: "Archery" },
                { code: "BADMI", name: "Badminton" },
                { code: "BASEB", name: "Baseball" },
                { code: "BSKTB", name: "Basketball" },
                { code: "BICYC", name: "Bicycling" },
                { code: "BOWLG", name: "Bowling" },
                { code: "CHEER", name: "Cheerleading" },
                { code: "CREW", name: "Crew" },
                { code: "XRUN", name: "Cross-Country" },
                { code: "EQUES", name: "Equestrian Sports" },
                { code: "FENCE", name: "Fencing" },
                { code: "FHOCK", name: "Field Hockey" },
                { code: "FOOTB", name: "Football" },
                { code: "GOLF", name: "Golf" },
                { code: "GYMNA", name: "Gymnastics" },
                { code: "ICEH", name: "Ice Hockey" },
                { code: "LACRO", name: "Lacrosse" },
                { code: "RACQU", name: "Raquetball" },
                { code: "RIFLE", name: "Riflery" },
                { code: "RUGBY", name: "Rugby" },
                { code: "SAILG", name: "Sailing" },
                { code: "SKIIN", name: "Skiing (downhill)" },
                { code: "SOCCR", name: "Soccer" },
                { code: "SOFTB", name: "Softball" },
                { code: "SQUAS", name: "Squash" },
                { code: "STRE3", name: "Strength & Conditioning" },
                { code: "SWIM", name: "Swimming" },
                { code: "TENNI", name: "Tennis" },
                { code: "TRACK", name: "Track and Field (Outdoor)" },
                { code: "ULTIM", name: "Ultimate Frisbee" },
                { code: "VOLLB", name: "Volleyball" },
                { code: "WPOLO", name: "Water Polo" },
                { code: "00025", name: "Water Skiing" },
                { code: "WLIFT", name: "Weight Lifting" },
                { code: "WINTE", name: "Winter (Indoor) Track" },
                { code: "WREST", name: "Wrestling" },
            ]

            this.divisionsAvailable = [
                { value: 1, name: "Division I" },
                { value: 2, name: "Division II" },
                { value: 3, name: "Division III" },
                { value: 4, name: "Division I- A" },
                { value: 5, name: "Division I- AA" },
                { value: 6, name: "Intercollegiate club" },
                { value: 7, name: "Intercollegiate sport" },
            ]

        }

        addNonNeedScholarship(name: string) {
            var index = _.indexOf(this.institution.profile.costFinancialAid.nonNeedBasedScholarships, name);
            if (index == -1) {
                this.institution.profile.costFinancialAid.nonNeedBasedScholarships.push(name);
            }
        }

        removeNonNeedScholarship(name: string) {
            var index = _.indexOf(this.institution.profile.costFinancialAid.nonNeedBasedScholarships, name);
            if (index != -1)
                this.institution.profile.costFinancialAid.nonNeedBasedScholarships.splice(index, 1);
        }

        addNeedScholarship(name: string) {
            var index = _.indexOf(this.institution.profile.costFinancialAid.needBasedScholarships, name);
            if(index == -1)
            this.institution.profile.costFinancialAid.needBasedScholarships.push(name);
        }

        removeNeedScholarship(name: string) {
            var index = _.indexOf(this.institution.profile.costFinancialAid.needBasedScholarships, name);
            if (index != -1)
                this.institution.profile.costFinancialAid.needBasedScholarships.splice(index, 1);
        }

        addSpecialProgram(name: string) {
            var index = _.indexOf(this.institution.profile.academics.offeredPrograms.specialPrograms, name);
            if(index == -1)
            this.institution.profile.academics.offeredPrograms.specialPrograms.push(name);
        }

        removeSpecialProgram(name: string) {
            var index = _.indexOf(this.institution.profile.academics.offeredPrograms.specialPrograms, name);
            if (index != -1)
                this.institution.profile.academics.offeredPrograms.specialPrograms.splice(index, 1);
        }

        addStudentHousing(name: string) {
            var index = _.indexOf(this.institution.profile.studentLife.campusLife.availableHousingTypes, name);
            if(index == -1)
            this.institution.profile.studentLife.campusLife.availableHousingTypes.push(name);
        }

        removeStudentHousing(name: string) {
            var index = _.indexOf(this.institution.profile.studentLife.campusLife.availableHousingTypes, name);
            if (index != -1)
                this.institution.profile.studentLife.campusLife.availableHousingTypes.splice(index, 1);
        }

        checkNonNeedScholarships() {
            var index;
            index = _.indexOf(this.institution.profile.costFinancialAid.nonNeedBasedScholarships, "Academic scholarships");
            if(index != -1) this.academicScholarships = true;
            index = _.indexOf(this.institution.profile.costFinancialAid.nonNeedBasedScholarships, "Athletic scholarships");
            if(index != -1) this.athleticScholarships = true;
        }

        checkNeedScholarships() {
            var index;
            index = _.indexOf(this.institution.profile.costFinancialAid.needBasedScholarships, "Need based (general)");
            if(index != -1) this.needBasedScholarships = true;
            index = _.indexOf(this.institution.profile.costFinancialAid.needBasedScholarships, "State scholarships/grants");
            if(index != -1) this.stateScholarships = true;
            index = _.indexOf(this.institution.profile.costFinancialAid.needBasedScholarships, "Institutional grants");
            if(index != -1) this.institutionalScholarhips = true;
            index = _.indexOf(this.institution.profile.costFinancialAid.needBasedScholarships, "Private scholarships/grants");
            if(index != -1) this.privateScholarships = true;
        }

        checkSpecialPrograms() {
            var index;
            index = _.indexOf(this.institution.profile.academics.offeredPrograms.specialPrograms, "Accelerated study");
            if(index != -1) this.acceleratedStudy = true;
            index = _.indexOf(this.institution.profile.academics.offeredPrograms.specialPrograms, "Distance learning");
            if(index != -1) this.distanceLearning = true;
            index = _.indexOf(this.institution.profile.academics.offeredPrograms.specialPrograms, "Double majors");
            if(index != -1) this.doubleMajors = true;
            index = _.indexOf(this.institution.profile.academics.offeredPrograms.specialPrograms, "Honors program");
            if(index != -1) this.honorsProgram = true;
            index = _.indexOf(this.institution.profile.academics.offeredPrograms.specialPrograms, "Independent study");
            if(index != -1) this.independentStudy = true;
            index = _.indexOf(this.institution.profile.academics.offeredPrograms.specialPrograms, "Internships");
            if(index != -1) this.internships = true;
            index = _.indexOf(this.institution.profile.academics.offeredPrograms.specialPrograms, "Self-designed majors");
            if(index != -1) this.selfDesignedMajors = true;
            index = _.indexOf(this.institution.profile.academics.offeredPrograms.specialPrograms, "Study abroad");
            if(index != -1) this.studyAbroad = true;
        }

        checkHousingTypes() {
            var index;
            index = _.indexOf(this.institution.profile.studentLife.campusLife.availableHousingTypes, "Men-only");
            if(index != -1) this.menOnlyHousing = true;
            index = _.indexOf(this.institution.profile.studentLife.campusLife.availableHousingTypes, "Women-only");
            if(index != -1) this.womenOnlyHousing = true;
            index = _.indexOf(this.institution.profile.studentLife.campusLife.availableHousingTypes, "Coed residence halls");
            if(index != -1) this.coedResidenceHalls = true;
            index = _.indexOf(this.institution.profile.studentLife.campusLife.availableHousingTypes, "Cooperative housing");
            if(index != -1) this.cooperativeHousing = true;
            index = _.indexOf(this.institution.profile.studentLife.campusLife.availableHousingTypes, "Fraternity/sorority housing");
            if(index != -1) this.greekHousing = true;
            index = _.indexOf(this.institution.profile.studentLife.campusLife.availableHousingTypes, "Special housing");
            if(index != -1) this.specialHousing = true;
        }

        setDefaultDropdownValues() {
            if (this.institution.mailingAddress.state == null) {
                this.institution.mailingAddress.state = "";
            }
            if (this.institution.profile.religiousAffiliation.description == null) {
                this.institution.profile.religiousAffiliation.description = "";
            }
            if (this.institution.streetAddress.state == null) {
                this.institution.streetAddress.state = "";
            }

            if (this.institution.profile.mainFunction == null) {
                this.institution.profile.mainFunction = "";
            }
            if (this.institution.profile.schoolType == null) {
                this.institution.profile.schoolType = "";
            }
            if (this.institution.profile.locationDescription == null) {
                this.institution.profile.locationDescription = "";
            }
            if (this.institution.profile.coedType == null) {
                this.institution.profile.coedType = "";
            }
            if (this.institution.profile.freshmanEnrollment.entranceDifficulty == null) {
                this.institution.profile.freshmanEnrollment.entranceDifficulty = "";
            }
        }

        copySport(originalSport: any) {
            this.originalSport = angular.copy(originalSport);
        }

        addIntercollegiateSport(sport: any, code: any, division: any, gender: any, scholarship:any, index: any) {
            var found = _.findIndex(this.institution.profile.studentLife.athletics.intercollegiateSports, { 'sportCode': code, 'ncaaDivision': division, 'gender': gender });
            if (found == -1) {
                var sportSelected = _.findIndex(this.sportsAvailable, { 'code': code });
                this.intercollegiateSports[index].name = this.sportsAvailable[sportSelected].name;
                this.intercollegiateSports = this.intercollegiateSports.sort();
                var newSport = { name: this.sportsAvailable[sportSelected].name, sportCode: code, ncaaDivision: division, gender: gender, scholarshipOffered: scholarship };
                this.institution.profile.studentLife.athletics.intercollegiateSports.push(newSport);
                this.saveProfileChanges();
                sport.editMode = false;
            }
            else {
                this.logError("Sport is already in the list", "", true);
            }
        }

        removeFromList(listObject: any, index: any) {
            listObject.splice(index, 1);
            this.saveProfileChanges();
        }

        checkSportsList() {
            this.sportsChanged = true;
        }

        createNewItem = (): void => {
            var newItem: any = {
                name: null,
                sportCode: null,
                ncaaDivision: null,
                scholarshipOffered:false,
                editMode: true
            };

            this.intercollegiateSports.unshift(newItem);
        };

        cancelEdit = (itemToRemove: any, index: any): void => {
            if (itemToRemove.name != '' && itemToRemove.name != null) {
                var originalSport = this.institution.profile.studentLife.athletics.intercollegiateSports[index];
                itemToRemove = originalSport;
                itemToRemove.editMode = false;
                this.intercollegiateSports[index] = itemToRemove;

            } else {
                this.intercollegiateSports.splice(index, 1);
            };
        };

        getInstitution = () => {
            this.Restangular.one("ProfileSettings", this.institutionId).get().then((results) => {
                this.institution = results;
                this.setDefaultDropdownValues();
                this.checkNeedScholarships();
                this.checkNonNeedScholarships();
                this.checkSpecialPrograms();
                this.checkHousingTypes();
                this.intercollegiateSportCount = this.institution.profile.studentLife.athletics.intercollegiateSports.length;
                this.intercollegiateSports = _.cloneDeep(this.institution.profile.studentLife.athletics.intercollegiateSports);
                console.log(results);
            }, (reason) => {
                this.logError("Error getting profile data", reason, true);
                    console.error(reason);
                });
        }

        saveProfileChanges() {
            this.Restangular.all("ProfileSettings/UpdateCampusBasics").post(this.institution).then((results) => {
                this.$scope.campusBasics.$setPristine();
                this.$scope.campusSearchFilters.$setPristine();
                this.intercollegiateSportCount = this.institution.profile.studentLife.athletics.intercollegiateSports.length;
                this.intercollegiateSports = _.cloneDeep(this.institution.profile.studentLife.athletics.intercollegiateSports);
                this.sportsChanged = false;
                this.logSuccess("Your profile changes have been saved", null, true);
                console.log(results);
                this.institution.basicsLastModified = new Date;
            }, (reason) => {
                this.logError("Error saving profile changes", reason, true);
                    console.error(reason);
                });
        }

        scrollTo(id: string) {
            this.showSports = true;
                this.$location.hash(id);
            this.$anchorScroll();
        }
    }

    angular.module("app").controller(CampusBasics.controllerId, CampusBasics);
} 