/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict"

    export interface IConnectionConfigurationScope extends ng.IScope {
        vm: any;
    }

    
    export interface IConnectionConfigurationController {
        log: (msg, data?, showHowl?) => void;
        crossrouteConnection: any;
        confirmedSecret: string;
        
    }

    export class ConnectionConfigurationController implements IConnectionConfigurationController {
        static controllerId: string = "connectionConfigurationController";
        static $inject = ["$scope", "$location", "common", "config", "Restangular"];

        log: (msg, data?, showHowl?) => void;
        crossrouteConnection: any;
        confirmedSecret: string;

        constructor(
            private $scope: IConnectionConfigurationScope,
            private $location: ng.ILocationService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private Restangular: Restangular.IService) {
            this.log = common.logger.getLogFn(CrossRouteController.controllerId);
            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([], ScheduleDetailsController.controllerId)
                .then(result=> {
                self.log("Activated CrossRoute View");
                self.Restangular.one("crossroute/connection").get()
                    .then((result) => {
                    self.crossrouteConnection = result;
                    self.confirmedSecret = result.secret;
                    },(reason) => {
                        console.log(reason);
                    });

            });
        }

        save = () => {
            this.Restangular.all("crossroute/connection/save").post(this.crossrouteConnection)
                .then((result) => {
                this.$location.path("/crossroute");
            },(reason) => {

                });
        }
    
    }

    angular.module("app").controller(ConnectionConfigurationController.controllerId, ConnectionConfigurationController);
} 