/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    export interface IProgramDetailDialogScope extends angular.IScope {
        program: IProgram;
        save: () => void;
        cancel: () => void;
    }

    export interface IProgramDetailDialog {
        save: () => void;
        cancel: () => void;
    }

    export class ProgramDetailDialog implements IProgramDetailDialog {
        static controllerId: string = "programDetailDialog";
        static $inject: any = ["$scope", "$uibModalInstance", "data"];

        constructor(private $scope: IProgramDetailDialogScope, private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance, private data: IProgram) {
            if (!data) {
                var empty: any = {};
                this.data = empty;
            }

            $scope.save = () => this.save();
            $scope.cancel = () => this.cancel();
            $scope.program = data;
        }

        save() {
            this.$uibModalInstance.close(this.$scope.program);
        }

        cancel() {
            this.$uibModalInstance.dismiss("cancel");
        }
    }

    // Update the app1 variable name to be that of your module variable
    angular.module("app").controller(ProgramDetailDialog.controllerId, ProgramDetailDialog);
}
