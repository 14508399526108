/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    interface IAdvFormSection extends angular.IDirective {

    }

    interface IAdvFormSectionScope extends angular.IScope {
        section: ISection;
        response: any;
    }

    class AdvFormSection implements IAdvFormSection {
        static directiveId: string = "advFormSection";
        restrict: string = "E";
        templateUrl = "/app/directives/advFormSection.html";
        scope: any = {
            section: "=",
            response: "="
        };

    }


    angular.module("app").directive(AdvFormSection.directiveId, [() =>
        new AdvFormSection()
    ]);

}
