/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    interface IusersScope extends angular.IScope {
        vm: Users;
    }

    interface IUsers {
        userResults: angular.resource.IResource<IUserQueryResults>;
        userData: Array<any>;
        createNewUser: () => void;
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        page: number;
        pageSize: number;
        totalCount: number;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        searchFilter: string;
        activeUser: IUserViewModel;
    }

    class Users implements IUsers {
        static controllerId: string = "users";
        static $inject: any = ["$scope", "$location", "$uibModal", "dialogs", "common", "config", "userService"];
        public dataAvailable: boolean;
        public userResults: angular.resource.IResource<IUserQueryResults>;
        public userData: Array<any>;
        public page: number;
        public pageSize: number;
        public totalCount: number;
        public sortBy: string;
        public sortDirection: string;
        public totalPages: number;
        public startRow: number;
        public endRow: number;
        public searchFilter: string;
        public activeUser: IUserViewModel;

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;

        constructor(private $scope: IusersScope,
            private $location: angular.ILocationService,
            private $uibModal: angular.ui.bootstrap.IModalService,
            private dialogs,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private userService: IUserService) {
            this.log = common.logger.getLogFn(Users.controllerId);
            this.logError = common.logger.getLogFn(Users.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(Users.controllerId, "success");
            this.userData = [];

            this.page = 1;
            this.pageSize = 25;
            this.sortBy = "LastName";
            this.sortDirection = "ASC";
            this.searchFilter = "";

            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getUsers()], Users.controllerId)
                .then(result => {
                    self.log("Activated Users View", null, false);

                });
        }

        createNewUser() {
            this.$location.path("/admin/users/new");
        }

        editUser() {
            this.$location.path("/admin/users/" + encodeURI(this.activeUser.tenant) + "/" + encodeURI(this.activeUser.email));
        }

        disableAccount() {
            var confirm = this.dialogs.confirm("Close Account", "Are you sure you want to close this account?");

            confirm.result.then((button) => {
                this.userService.user.delete({ tenant: this.activeUser.tenant, email: this.activeUser.email },
                    (result) => {
                        this.logSuccess(this.activeUser.username + " has been disabled.");
                        this.activeUser.isAccountClosed = true;
                        this.activeUser.accountClosed = new Date();
                    }, (reason: any) => {
                        this.logError("An error occurred while trying to disable the user's account!", reason);
                    });
            });
        }

        getUsers() {
            var self = this;
            self.userResults = self.userService.user.restQuery({
                page: self.page,
                pageSize: self.pageSize,
                sortBy: self.sortBy,
                sortDirection: self.sortDirection,
                filter: self.searchFilter

            });

            return this.userResults.$promise.then((result) => {
                self.userData = result.results;
                self.totalCount = result.totalCount;
                self.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    self.startRow = self.page === 1 ? 1 : (self.pageSize * (self.page - 1)) + 1;
                    self.endRow = (self.startRow - 1) + result.results.length;
                } else {
                    self.startRow = 0;
                    self.endRow = 0;
                }

            }, reason => {
                self.logError("Error getting users", reason, true);
            });
        }

        searchUsers() {
            this.common.debouncedThrottle("UserSearch", () => { this.page = 1; this.getUsers(); }, 400);
        }

        selectUser(user: IUserViewModel) {
            this.activeUser = user;
        }

        sort(sortValue: string) {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
                if (sortValue === "Created" || sortValue === "LastLogin") {
                    this.sortDirection = "DESC";
                } else {
                    this.sortDirection = "ASC";
                }
            }
            this.page = 1;
            this.getUsers();
        }
    }

    angular.module("app").controller(Users.controllerId, Users);
}
