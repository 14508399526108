/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    interface IUserApplicationDetailScope extends angular.IScope {
        vm: UserApplicationDetail;
    }

    export interface IUserApplicationDetailRouteParams extends angular.route.IRouteParamsService {
        userAppId: number;
    }

    export interface IUserApplicationDetail {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadinUserApplication: boolean;
        userApplication: IUserApplicationViewModel;
        applicationDefinition: IApplicationDefinition;
        uploading: boolean;
        uploadingTaskId: string;

        activate: () => void;
    }

    export class UserApplicationDetail implements IUserApplicationDetail {
        static controllerId: string = "userApplicationDetail";
        static $inject: any = ["$scope", "$location", "$routeParams", "$http", "Upload", "Restangular", "dialogs", "common", "config", "helper"];
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadinUserApplication: boolean;
        userApplication: IUserApplicationViewModel;
        applicationDefinition: IApplicationDefinition;
        uploading: boolean;
        uploadingTaskId: string;
        isRlcpApp: boolean;
        rlcpApprovalField: string;
        rlcpApprovedOnField: string;
        rlcpCollege1Field: string;
        rlcpCollege2Field: string;
        rlcpCollege3Field: string;
        rlcpApprovedCollegeField: string;
        rlcpApprovedCollegeOnField: string;
        majorList: IMajorList;

        constructor(private $scope: IUserApplicationsScope,
            private $location: angular.ILocationService,
            private $routeParams: IUserApplicationDetailRouteParams,
            private $http: angular.IHttpService,
            private Upload: angular.angularFileUpload.IUploadService,
            private Restangular: Restangular.IService,
            private dialogs: angular.dialogs.IDialogService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private helper: IHelper) {

            this.log = common.logger.getLogFn(UserApplicationDetail.controllerId);
            this.logError = common.logger.getLogFn(UserApplicationDetail.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(UserApplicationDetail.controllerId, "success");
            this.uploading = false;
            this.uploadingTaskId = null;
            this.loadinUserApplication = false;

            $scope.$on(config.events.applicationChanged, (event) => {
                this.$location.path("/");
            });

            this.activate();
        }

        activate = () => {
            this.common.activateController([this.getApplicationDefinition()], UserApplicationDetail.controllerId)
                .then(result => {
                    this.getRLCPSchoolsList();
                    this.log("Activated UserApplicationDetail View");
                });
        };

        attachmentIcon = (attachment) => {
            if (attachment.fileExtension === ".pdf") {
                return "fa-file-pdf-o";
            } else if (attachment.fileExtension.indexOf(".doc") > -1) {
                return "fa-file-word-o";
            } else if (attachment.contentType === "application/zip") {
                return "fa-file-archive-o";
            } else {
                return "fa-file-o";
            }
        };

        getApplication = (): angular.IPromise<Advant.Crossroads.IUserApplicationViewModel> => {
            this.loadinUserApplication = true;
            this.userApplication = null;
            this.log("loading user app");

            return this.Restangular.one(this.common.getUser().activeApplication).one("userApplications", this.$routeParams.userAppId).get().then((result) => {
                this.userApplication = result;
                this.loadinUserApplication = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the user applications", reason);
            });
        };

        getApplicationDefinition = (): angular.IPromise<Advant.Crossroads.IApplicationDefinition> => {
            this.log("Loading definition");
            return this.Restangular
                .one(this.common.getUser().activeApplication).get()
                .then((result) => {
                    this.applicationDefinition = result;
                    if (this.applicationDefinition.isRlcpApplication) {
                        this.isRlcpApp = true;
                        this.rlcpApprovalField = this.toCamelCase(this.applicationDefinition.rlcpApprovalField);
                        this.rlcpApprovedOnField = this.toCamelCase(this.applicationDefinition.rlcpApprovedOnField);

                        this.rlcpCollege1Field = "adv_" + this.applicationDefinition.rlcpCollege1Field.toLowerCase() + "SelectedMajor";
                        this.rlcpCollege2Field = "adv_" + this.applicationDefinition.rlcpCollege2Field.toLowerCase() + "SelectedMajor";
                        this.rlcpCollege3Field = "adv_" + this.applicationDefinition.rlcpCollege3Field.toLowerCase() + "SelectedMajor";

                        this.rlcpApprovedCollegeField = this.toCamelCase(this.applicationDefinition.rlcpApprovedCollegeField);

                        this.rlcpApprovedCollegeOnField = this.toCamelCase(this.applicationDefinition.rlcpApprovedCollegeOnField);

                            
                    }
                    this.getApplication();
                    return result;
                }, (error) => {
                    this.logError("An error occurred while attempting to retrieve the application information.", error);
                });
        }


        isUploading = (task) => {
            if (this.isUploading && this.uploadingTaskId === task.applicationTaskId) {

                return "fa-spinner";
            }
            return "";

        };

        openAttachment = (attachment) => {

            var httpPath = this.config.apiHost + "/" + this.userApplication.id + "/attachment/" + attachment.name;

            this.helper.openFile(httpPath);

        }

        updatePayment = () => {
            if (this.userApplication.payment.paymentReceived) {
                this.userApplication.payment.dateReceived = moment().toDate();
            } else {
                this.userApplication.payment.dateReceived = null;
            }

            this.saveChanges();
        }

        updateRlcp = () => {

            if (this.userApplication.applicationResponse[this.rlcpApprovalField]) {
                if (this.userApplication.applicationResponse[this.rlcpApprovedOnField] == null) {
                    this.userApplication.applicationResponse[this.rlcpApprovedOnField] = moment().toDate();
                }
            } else {
                this.userApplication.applicationResponse[this.rlcpApprovedOnField] = null;
            }

            if (this.userApplication.applicationResponse[this.rlcpApprovedCollegeField] && this.userApplication.applicationResponse[this.rlcpApprovedCollegeOnField] == null) {
                this.userApplication.applicationResponse[this.rlcpApprovedCollegeOnField] = moment().toDate();
            }

            this.saveChanges();
        }

        clearRlcpCollegeApproval = () => {
            this.userApplication.applicationResponse[this.rlcpApprovedCollegeOnField] = null;
            this.userApplication.applicationResponse[this.rlcpApprovedCollegeField] = null;

            this.updateRlcp();
        }

        saveChanges = () => {
            this.userApplication.put().then((result) => {
                this.logSuccess("Saved changes to application");
            }, (reason) => {
                this.logError("An error occurred while updating the application.", reason, true);
            });
        };

        taskStatus = (task) => {
            if (task.completed) {
                return "status-list-success";
            } else if (moment().isAfter(task.dueDate)) {
                return "status-list-danger";
            } else if (moment(task.dueDate).isBefore(moment().add(7, "day"))) {
                return "status-list-warning";
            } else {
                return "status-list-info";
            }
        };

        toggleComplete = (task) => {
            task.completed = !task.completed;
            task.completedDate = task.completed ? moment() : null;
            this.saveChanges();
        };

        viewUserTaskAttachment = (task) => {
            var httpPath = this.config.apiHost + "/" + this.userApplication.id + "/" + task.applicationTaskId + "/attachment";
            this.helper.openFile(httpPath);
        }

        uploadAttachment = (file, task) => {
            if (file && file.length > 0) {
                this.uploading = true;
                this.uploadingTaskId = task.applicationTaskId;
                this.Upload.upload(<any>{
                    url: this.config.apiHost + "/" + this.userApplication.id + "/" + task.applicationTaskId + "/attachment",
                    method: "POST",
                    file: file
                }).then((response) => {
                    task.attachment = response.data;
                    if (task.applicationTask.uploadCompletesTask) {
                        task.completed = true;
                        task.completedDate = moment();
                    }
                    this.uploading = false;
                    this.uploadingTaskId = null;
                }, (reason) => {
                    this.logError("Failed to upload attachment", reason);
                    this.uploading = false;
                    this.uploadingTaskId = null;
                });
            }
        };

        isUpperCase = (value: string): boolean => {
            if (!isNaN(<any>value * 1)) {
                return false;
            } else {
                if (value === value.toUpperCase()) {
                    return true;
                } else {
                    return false;
                }
            }
        };

        toCamelCase = (value: string): string => {
            if (!value) {
                return value;
            }

            if (!this.isUpperCase(value.charAt(0))) {
                return value;
            }

            var chars = value.split("");

            for (var i = 0; i < chars.length; i++) {
                var hasNext = (i + 1 < chars.length);

                if (i > 0 && hasNext && !this.isUpperCase(chars[i + 1])) {
                    break;
                }

                chars[i] = chars[i].toLowerCase();
            }

            return chars.join("");
        };

        getRLCPSchoolsList = () => {

            this.Restangular
                .one(this.common.getUser().activeApplication).one(this.applicationDefinition.rlcpMajorListId)
                .get().then((result) => {
                    this.majorList = result;
                });
        }
    }

    angular.module("app").controller(UserApplicationDetail.controllerId, UserApplicationDetail);
}