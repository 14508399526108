// Install the angularjs.TypeScript.DefinitelyTyped NuGet package
namespace Advant.Crossroads {
    "use strict";

    interface IAdvFileBrowser extends angular.IDirective {
    }

    interface IAdvFileBrowserScope extends angular.IScope {
    }

    interface IAdvFileBrowserAttributes extends angular.IAttributes {
        ngModel: string;
        placeholder: string;
        required: string;
    }


    advFileBrowser.$inject = ["$timeout"];

    function advFileBrowser($timeout: angular.ITimeoutService): IAdvFileBrowser {
        return {
            restrict: "E",
            require: ["?ngModel"],
            replace: true,
            link: link,
            template: (tElement, tAttrs: IAdvFileBrowserAttributes) => {
                return "<div class=\"input-group\"><input type=\"url\" class=\"form-control\" ng-model=\"" + tAttrs.ngModel + "\" " + (tAttrs.placeholder ? " placeholder = \"" + tAttrs.placeholder + "\" " : "") + (angular.isDefined(tAttrs.required) ? "required=\"required\" " : "") + "/><span class=\"input-group-btn\"><button name=\"browseButton\" class=\"btn btn-default\" type=\"button\"><i class=\"fa fa-folder-open\"></i></button></span></div>";
            }
        }

        function link(scope: IAdvFileBrowserScope, element: angular.IAugmentedJQuery, attrs: IAdvFileBrowserAttributes, ctrls: angular.INgModelController) {
            var input = element.find(".form-control");

            element.find(":button").click((event: JQueryEventObject) => {
                moxman.browse({
                    title: "CrossRoads File Manager",
                    url: input.val(),
                    fullscreen: true,
                    oninsert: (args) => {
                        $timeout(() => {
                            ctrls[0].$setViewValue(args.focusedFile.url);
                        });
                    }
                });
            });
        }
    }

    angular.module("app").directive("advFileBrowser", advFileBrowser);
}