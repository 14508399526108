/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface ITranscriptExportProjectDownloadScope extends angular.IScope {
        vm: TranscriptExportProjectDownload;
        transcriptExportProjectForm: angular.IFormController;
    }

    interface ITranscriptExportProjectDownloadRouteParams extends angular.route.IRouteParamsService {
        exportProjectId: string;
        applicationDefinitionId: string;
        fileId: string;
        count: number; 
    }

    interface ITranscriptExportProjectDownload {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProject: boolean;
        project: ITranscriptExportProject;
        studentId: string;
        dateStart: string;
        dateEnd: string;
        newOnly: boolean;
        fileId: string;
        generatingFile: boolean;
        totalExported: number;
        newTranscriptCount: number;
        clearFilter: () => void;
    }


    class TranscriptExportProjectDownload implements ITranscriptExportProjectDownload {
        static controllerId: string = "transcriptExportProjectDownload";
        static $inject = ["$scope", "$routeParams", "$timeout", "$interval", "$http", "$location", "dialogs", "Restangular", "common", "config", "helper", "signalRService", "Upload"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProject: boolean;
        loadingSettings: boolean;
        isSaving: boolean;
        project: ITranscriptExportProject;
        rules: Array<IRuleSet>;
        exportProjectId: any;
        studentId: string;
        dateStart: string;
        dateEnd: string;
        newOnly: boolean;
        fileId: string;
        generatingFile: boolean;
        totalExported: number;
        newTranscriptCount: number;
        selectedFile: any;
        firstLineHeader: boolean;

        hub: ExportProjectDownloadHub;

        constructor(private $scope: ITranscriptExportProjectDownloadScope,
            private $routeParams: ITranscriptExportProjectDownloadRouteParams,
            private $timeout: angular.ITimeoutService,
            private $interval: angular.IIntervalService,
            private $http: angular.IHttpService,
            private $location: angular.ILocationService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private helper: IHelper,
            private signalRService: ISignalRService,
            private Upload: angular.angularFileUpload.IUploadService) {

            this.log = common.logger.getLogFn(TranscriptExportProjectDownload.controllerId);
            this.logError = common.logger.getLogFn(TranscriptExportProjectDownload.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(TranscriptExportProjectDownload.controllerId, "success");

            this.loadingSettings = false;
            this.newOnly = false;
            this.exportProjectId = $routeParams.exportProjectId;
            this.totalExported = $routeParams.count;

            common.$on(config.events.applicationChanged, (event) => {
                if (!this.$routeParams.applicationDefinitionId) {
                    this.$location.path("/");
                } 
            });

            this.hub = this.signalRService.exportProjectDownloadHub();
            this.hub.on("exportCompleted", this.exportCompleted);
            this.hub.on("exportError", this.exportError);

            // Remove the event listener when the controller is removed from the scope
            $scope.$on("$destroy", () => {
                this.hub.off("exportCompleted", this.exportCompleted);
                this.hub.off("exportError", this.exportError);
            })

            this.fileId = $routeParams.fileId;

            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getProject(), this.getNewAppCount()], TranscriptExportProjectDownload.controllerId)
                .then(result => {
                    self.log("Activated Transcript Export Project Details View");
                    return result;
                });
        }

        clearFilter = () => {
            this.studentId = null;
            this.dateStart = null;
            this.dateEnd = null;
            this.selectedFile = null;
            this.firstLineHeader = false;
        };

        downloadExport = () => {

            var httpPath = this.config.apiHost + "/" + this.common.getUser().activeApplication +
                "/transcriptExportProjects/" + this.exportProjectId + "/export?fileId=" + encodeURI(this.fileId);

            this.openFile(httpPath);

        }

        generateFile = (): angular.IPromise<string> => {
            this.generatingFile = true;
            this.fileId = null;

            if (this.config.useSignalR) {
                this.generateFileWithHub();
            } else {
                return this.Restangular.one(this.common.getUser().activeApplication).one("transcriptExportProjects/" + this.exportProjectId + "/export")
                    .get(this.getQueryParams()).then((result) => {

                        this.fileId = result.fileId;
                        this.totalExported = result.totalExported;

                        this.generatingFile = false;
                        this.getNewAppCount();
                        return result;
                    }, (reason) => {
                        this.generatingFile = false;
                        this.logError("An error occurred while generating the export", reason, true);
                    });
            }
        };


        getNewAppCount = (): angular.IPromise<IExportProject> => {
            var appId = this.$routeParams.applicationDefinitionId || this.common.getUser().activeApplication;
            return this.Restangular.one(appId).one("transcriptExportProjects/" + this.exportProjectId + "/new").get().then((result: any) => {
                this.newTranscriptCount = result ? result : 0;
                return result;
            }, (reason) => {
                this.logError("An error occurred while trying to retrieve the count of new transcripts for the project. Please try again later", reason, true);
                return;
            });
        };

        getProject = (): angular.IPromise<IExportProject> => {
            var appId = this.$routeParams.applicationDefinitionId || this.common.getUser().activeApplication;
            return this.Restangular.one(appId).one("transcriptExportProjects/" + this.exportProjectId).get().then((result: any) => {
                this.project = result;
                return result;
            }, (reason) => {
                this.logError("An error occurred while trying to retrieve your project. Please try again later", reason, true);
                return;
            });
        };

        getQueryParams = (): any => {
            var queryParams = {
                newOnly: (!this.dateStart && !this.dateEnd && !this.studentId),
                startDate: this.dateStart ? moment(this.dateStart).format("YYYY-MM-DDTHH:mm:ss.sss") : "",
                endDate: this.dateEnd ? moment(this.dateEnd).format("YYYY-MM-DDTHH:mm:ss.sss") : "",
                studentId: this.studentId,
                applicationDefinitionId: this.common.getUser().activeApplication,
                projectId: "transcriptExportProjects/" + this.exportProjectId,
                firstLineHeader: this.firstLineHeader
            };

            return queryParams;
        }

        openFile = (httpPath) => {
            this.$http.get<any>(httpPath, { responseType: "arraybuffer" })
                .then((response) => {
                    var octetStreamMime = "application/octet-stream";
                    var success = false;

                    // Get the headers
                    var headerValues = response.headers();

                    // Get the filename from the x-filename header or default to "download.bin"
                    var filename = headerValues["x-filename"] || "download.pdf";

                    // Determine the content type from the header or default to "application/octet-stream"
                    var contentType = headerValues["content-type"] || octetStreamMime;
                    var blob: Blob;
                    blob = new Blob([response.data], { type: contentType });

                    saveAs(blob, filename);

                }, (reason) => {
                    console.log("Request failed with status: " + status);
                });
        };

        generateFileWithHub() {
            this.hub.connect().then(() => {
                var q = this.getQueryParams();
                q.connectionId = this.hub.getConnectionId();

                if (this.selectedFile != null) {
                    this.Upload.upload(<any>{
                        url: this.config.apiHost + "/" + this.common.getUser().activeApplication + "/transcriptExportProjects/" + this.exportProjectId + "/exportbystudents",
                        params: q,
                        method: "POST",
                        file: this.selectedFile
                    }).then(response => {
                        this.logSuccess("Your export has been queued. You will be notified when it is ready to download.", null, true);
                    }, reason => {
                            this.generatingFile = false;
                            this.logError(reason.data.message, reason.data, true)
                    });
                }
                else {
                    this.Restangular.one(this.common.getUser().activeApplication).one("transcriptExportProjects/" + this.exportProjectId + "/export")
                        .get(q).then((response) => {
                                if (response.isQueued) {
                                    this.logSuccess("Your export has been queued. You will be notified when it is ready to download.", null, true);
                                }
                                else {
                                    this.fileId = response.Data.FileId;
                                    this.totalExported = response.Data.TotalExported;
                                    this.generatingFile = false;
                                    this.getNewAppCount();
                                    this.hub.disconnect();
                                }
                        }, (reason) => {
                            this.logError("An error occurred while generating the export.", reason, true);
                            this.generatingFile = false;
                            this.hub.disconnect();


                        }, (progress) => { console.log(progress); });
                }
            }, (reason) => {
                this.generatingFile = false;
                this.logError("An error occurred while connecting to the server.", reason, true)
            });
        }


        exportCompleted = (result) => {
            this.generatingFile = false;
            this.logSuccess("Your export is ready to download.", null, true);
            this.fileId = result.FileId;
            this.totalExported = result.TotalExported;
            this.getNewAppCount();
            this.hub.disconnect();
        }

        exportError = (reason) => {
            this.generatingFile = false;
            this.logError("An error occurred while generating the export.", reason, true)
            this.hub.disconnect();
        }

        fileSelected(file: any) {
            if (file && file.length > 0) {
                this.selectedFile = file;
            }
        }
    }

    angular.module("app").controller(TranscriptExportProjectDownload.controllerId, TranscriptExportProjectDownload);
} 