/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict"

    interface ITAAPInformation {
    }

    interface ITAAPInformationScope extends ng.IScope {
        vm: TAAPInformation;
    }

    class TAAPInformation implements ITAAPInformation {
        static controllerId: string = "taapInformation";
        static $inject: Array<string> = ["config", "dialogs", "$routeParams", "common", "Restangular", "$scope"];
        private log;
        lastUpdated: any;
        tinymceOptions: any;
        test: string;
        organizationId: any;
        organizationInfo: any;
        taapSchool: boolean;
        isTaapSchool: boolean;
        originalText: string;
        logSuccess: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;

        constructor(private config: Advant.Crossroads.ICrossroadsConfig, private dialogs: ng.dialogs.IDialogService, private $routeParams, private common: ICommonService, private Restangular: Restangular.IService, private $scope: ITAAPInformationScope) {
            this.log = common.logger.getLogFn(TAAPInformation.controllerId);
            this.logSuccess = this.common.logger.getLogFn(TAAPInformation.controllerId, "success");
            this.logError = this.common.logger.getLogFn(TAAPInformation.controllerId, "error");

            this.tinymceOptions = {
                plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime table contextmenu paste",
                    "template textcolor colorpicker textpattern "
                ],
                toolbar: "undo redo | styleselect | bold italic | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify |" +
                " bullist numlist outdent indent | link image | preview | forecolor backcolor",
                menubar: "edit insert view format table tools ",
                height: 300,
                relative_urls: false
            };

            this.activate();
            common.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });
        }

        activate() {
            this.common.activateController([], TAAPInformation.controllerId)
                .then(() => {
                    this.log("TAAP Information");
                    this.isTaapSchool = false;
                    var app = sessionStorage.getItem("activeApplication");
                    var splitOrgId = app.split("/");
                    this.organizationId = splitOrgId[1];
                    this.checkTaapSchool();
                    this.getTaapInformation();
                });
        }

        checkTaapSchool() {
            this.Restangular.one("TaapAdmin/CheckTaapSchool/" + this.organizationId).get().then((results) => {
                console.log(results);
                this.isTaapSchool = results;
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        getTaapInformation = () => {
            this.Restangular.one("TaapAdmin/GetTaapInformation/" + this.organizationId).get().then((results) => {
                console.log(results);
                this.organizationInfo = results;
                this.originalText = this.organizationInfo.organizationMessage;
                this.taapSchool = results.applicationDefinitionId != "" ? true : false;
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        UpdateTaapInformation = () => {
            this.Restangular.all("TaapAdmin/UpdateTaapInformation").post(this.organizationInfo).then((results) => {
                this.logSuccess("TAAP information has been updated", null, true);
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }
    }
    angular.module("app").controller(TAAPInformation.controllerId, TAAPInformation);
}