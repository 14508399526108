/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    interface IAdvHtmlCompile extends angular.IDirective {
    }

    interface IAdvHtmlCompileScope extends angular.IScope {
    }

    class AdvHtmlCompile implements IAdvHtmlCompile {
        static directiveId: string = "advHtmlCompile";
        restrict: string = "A";

        constructor(private $compile: angular.ICompileService) {
        }

        link = (scope: IAdvHtmlCompileScope, element, attrs) => {
            scope.$watch(attrs.advHtmlCompile, (newValue) => {
                element.html(newValue);
                this.$compile(element.contents())(scope);
            });

        }
    }
    angular.module("app").directive(AdvHtmlCompile.directiveId, ["$compile", $compile =>
        new AdvHtmlCompile($compile)
    ]);
}
