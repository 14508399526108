/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    interface IAdvUniqueKey extends angular.IDirective {

    }

    interface IAdvUniqueKeyScope extends angular.IScope {
        advUniqueKey: IApplicationForm;
        ngModel: any;
    }

    class AdvUniqueKey implements IAdvUniqueKey {
        static directiveId: string = "advUniqueKey";
        restrict: string = "A";
        require: string = "?ngModel";
        scope: any = {
            advUniqueKey: "=",
            ngModel: "="
        };

        constructor(private $parse: angular.IParseService, private Restangular: Restangular.IService, private common: ICommonService) {
        }

        link = (scope: IAdvUniqueKeyScope, element, attrs, controller) => {

            scope.$watch("ngModel", (newModelValue, oldModleValue, scope: IAdvUniqueKeyScope) => {
                this.common.debouncedThrottle("UniqueKey", () => {
                    if (newModelValue !== oldModleValue) {
                        var tempKeyArray = [];
                        angular.forEach(scope.advUniqueKey.sections, (section, key) => {
                            if (section !== scope.ngModel) {
                                tempKeyArray.push(section.name);
                            }
                            angular.forEach(section.groups, (group, key) => {
                                if (group !== scope.ngModel) {
                                    tempKeyArray.push(group.name);
                                }
                                angular.forEach(group.fields, (field, key) => {
                                    if (field !== scope.ngModel) {
                                        tempKeyArray.push(field.key);
                                    }
                                });
                            });

                            var duplicateKey = _.filter(tempKeyArray, (value) => {
                                return value === newModelValue;
                            });
                            if (duplicateKey.length > 1) {
                                controller.$setValidity("unique", false);
                            } else {
                                controller.$setValidity("unique", true);
                            }
                        });
                    }
                }, 500);
            });

        };
    }

    angular.module("app").directive(AdvUniqueKey.directiveId, ["$parse", "Restangular", "common",
        ($parse: angular.IParseService, Restangular: Restangular.IService, common: ICommonService) =>
            new AdvUniqueKey($parse, Restangular, common)
    ]);
}