/// <reference path="../app.ts" />
// Install the angularjs.TypeScript.DefinitelyTyped NuGet package
namespace Advant.Crossroads {
    "use strict";

    interface IApplicationReportsRouteParams extends angular.route.IRouteParamsService {
        reportKey: string;
    }

    interface IApplicationReports {
        reports: any[];
        activeReport: any;
        activate: () => void;
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
    }

    class ApplicationReports implements IApplicationReports {
        reports: any[];
        activeReport: any;
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        applicationDefinitionId: string;
        applicationDefinition: IApplicationDefinition;

        static $inject: string[] = ["$location", "$routeParams", "common", "config", "Restangular"];

        constructor(private $location: angular.ILocationService, private $routeParams: IApplicationReportsRouteParams, private common: ICommonService, private config: Advant.Crossroads.ICrossroadsConfig, private Restangular: Restangular.IService) {
            this.log = common.logger.getLogFn("applicationReports");
            this.logError = common.logger.getLogFn("applicationReports", "error");
            this.logSuccess = common.logger.getLogFn("applicationReports", "success");
            this.setAppId();

            if ($location.state()) {
                this.activeReport = $location.state().activeReport;
                this.reports = $location.state().reports;
            } else {
                this.activate();
                if ($routeParams.reportKey) {
                    this.activeReport = (<any>_).filter(this.reports, { key: $routeParams.reportKey.toLowerCase() })[0]; // The stupid <any> wrapper was because somebody created a terrible definition for filter and this was the only way around it. -kc
                }
            }



        }

        activate = () => {
            this.common.activateController([this.getRequestedApplication()], "applicationReports")
                .then(result => {
                    this.log("Activated UserApplications View");
                });
        };

        getReports = (): angular.IPromise<any> => {
            var deferred = this.common.$q.defer();
            this.reports = [];

            var submittedReport = {
                key: "submittedapps",
                name: "Submitted Applications",
                templatePath: "/app/reports/static/submittedApplicationsReport.html"
            };

            var startedReport = {
                key: "startedapps",
                name: "In-Progress Applications",
                templatePath: "/app/reports/static/startedApplicationsReport.html"
            };

            var rdsStatus = {
                key: "rdsstatus",
                name: "Applications by Residency Status",
                templatePath: "/app/reports/static/applicantsWithRdsStatus.html"
            };

            var paymentHistory = {
                key: "paymentHistory",
                name: "Payment History",
                templatePath: "/app/reports/static/applicationPaymentReport.html"
            };

            var creditCardPayment = {
                key: "creditcardpayments",
                name: "Credit Card Payments",
                templatePath: "/app/reports/static/processorPayments.html"
            };

            var applicationHistory = {
                key: "applicationHistory",
                name: "Application Change History",
                templatePath: "/app/reports/static/applicationHistory.html"
            };

            var applicationsByWeek = {
                key: "applicationsByWeek",
                name: "Applications By Week",
                templatePath: "/app/reports/static/applicationsByWeek.html"
            };

            var applicationPrompts = {
                key: "applicationPrompts",
                name: "Application Prompts",
                templatePath: "/app/reports/static/applicationPrompts.html"
            }

            this.reports.push(submittedReport, startedReport, rdsStatus, paymentHistory, applicationHistory, applicationsByWeek, applicationPrompts);

            var userInfo = this.common.getUser();
            if (this.applicationDefinition.paymentProcessor !== null && userInfo["canViewCreditCardReports"] && userInfo["canViewCreditCardReports"] === true) {
                this.reports.push(creditCardPayment);
            }

            deferred.resolve(this.reports);

            return deferred.promise;
        };

        getRequestedApplication = (): any => {
            var self = this;
            return self.Restangular
                .one(this.applicationDefinitionId).get()
                .then((data) => {
                    // Will either get back an entity or an {entity:, key:}
                    //wipEntityKey = data.key;
                    console.log(data);
                    self.applicationDefinition = data;
                    this.getReports();
                });
        };

        setActiveReport = (report) => {
            this.$location.state({ activeReport: report, reports: this.reports });
        };

        private setAppId = (): any => {
            this.applicationDefinitionId = this.common.getUser().activeApplication;
            this.common.$on(this.config.events.applicationChanged, (event) => {
                this.applicationDefinitionId = this.common.getUser().activeApplication;
                this.activate();
            });
        };
    }

    angular.module("app").controller("applicationReports", ApplicationReports);
}