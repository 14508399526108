/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    export interface IFieldParserResult {
        itemName: string;
        source: angular.ICompiledExpression;
        viewMapper: angular.ICompiledExpression;
        modelMapper: angular.ICompiledExpression;
    }

    export interface IFieldAutoCompleteParser {
        parse: (input: string) => IFieldParserResult;
    }

    function fieldAutoCompleteParser($parse: angular.IParseService): IFieldAutoCompleteParser {
        //                      00000111000000000000022200000000000000003333333333333330000000000044000
        var TYPEAHEAD_REGEXP = /^\s*([\s\S]+?)(?:\s+as\s+([\s\S]+?))?\s+for\s+(?:([\$\w][\$\w\d]*))\s+in\s+([\s\S]+?)$/;

        function parse(input: string) {
            var match = input.match(TYPEAHEAD_REGEXP);
            if (!match) {
                throw new Error(
                    "Expected typeahead specification in form of \"_modelValue_ (as _label_)? for _item_ in _collection_\"" +
                    " but got \"" + input + "\".");
            }

            return {
                itemName: match[3],
                source: $parse(match[4]),
                viewMapper: $parse(match[2] || match[1]),
                modelMapper: $parse(match[1])
            };
        }
        return  {
            parse: parse
        };
    }

    fieldAutoCompleteParser.$inject = ["$parse"];
    angular.module("app").factory("fieldAutoCompleteParser", fieldAutoCompleteParser);
}