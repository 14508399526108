namespace Advant.Crossroads {
    "use strict";

    interface IApplicationExportSettingsDetailsScope extends angular.IScope {
        vm: ApplicationExportSettingsDetails;
        applicationExportSettingsForm: angular.IFormController;
    }

    export interface IApplicationExportSettingsDetailsRouteParams extends angular.route.IRouteParamsService {
        exportSettingsId: string;
        exportSettingsType: string;
    }

    interface IApplicationExportSettingsDetails {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingSettings: boolean;
        isSaving: boolean;
        setting: IExportSettings;
        originalSetting: IExportSettings;
        applicationDefinition: IApplicationDefinition;
        exportTypes: any[];
        layoutTypes: any[];
        treeOptions: any;
        headerTreeOptions: any;
        fieldCompleter: any;
        langTools: any;
        exportSettingsId: string;
        rules: Array<IRuleSet>;
        dateStart: string;
        dateEnd: string;
        ruleIdFilter: string;
        termCode: string;
        testFileId: string;
        generatingFile: boolean;
        totalExported: number;
        singleFile: boolean;
        illegalFilenameCharacters: RegExp;
        isNew: boolean;
    }

    class ApplicationExportSettingsDetails implements IApplicationExportSettingsDetails {
        static controllerId: string = "applicationExportSettingsDetails";
        static $inject = ["$scope", "$routeParams", "$location", "$http", "dialogs", "Restangular", "common", "config", "helper", "$timeout"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingSettings: boolean;
        isSaving: boolean;
        setting: IExportSettings;
        originalSetting: IExportSettings;
        applicationDefinition: IApplicationDefinition;
        exportTypes: any[];
        layoutTypes: any[];
        treeOptions: any;
        headerTreeOptions: any;
        fieldCompleter: any;
        langTools: any;
        exportSettingsId: string;
        rules: Array<IRuleSet>;
        dateStart: string;
        dateEnd: string;
        ruleIdFilter: string;
        termCode: string;
        testFileId: string;
        generatingFile: boolean;
        totalExported: number;
        singleFile: boolean;
        applicationTasks: Array<IApplicationTask>;
        illegalFilenameCharacters = /^[^\\/:*?<>|\"]+$/;
        rcnFields: Array<IField>;
        isNew: boolean;
        altColors: boolean;

        constructor(private $scope: IApplicationExportSettingsDetailsScope,
            private $routeParams: IApplicationExportSettingsDetailsRouteParams,
            private $location: angular.ILocationService,
            private $http: angular.IHttpService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private helper: IHelper,
            private $timeout: angular.ITimeoutService) {

            this.log = common.logger.getLogFn(ApplicationExportSettingsDetails.controllerId);
            this.logError = common.logger.getLogFn(ApplicationExportSettingsDetails.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(ApplicationExportSettingsDetails.controllerId, "success");

            this.rcnFields = [];

            this.loadingSettings = false;
            this.generatingFile = false;
            this.singleFile = false;
            var self = this;
            this.altColors = false;

            this.exportSettingsId = $routeParams.exportSettingsType + "/" + $routeParams.exportSettingsId;

            $scope.$on(config.events.applicationChanged, (event) => {
                this.$location.path("/application/export/settings");
            });

            this.createLayoutTypes();

            this.headerTreeOptions = {
                dropped: (event) => {
                    // If anything in the tree changes make the form dirty
                    this.$scope.applicationExportSettingsForm.$setDirty();
                }
            };

            this.treeOptions = {
                accept: (sourceNodeScope, destNodesScope, destIndex) => {
                    var destType = destNodesScope.$element.attr("type");

                    if (destType === "Root") {
                        return sourceNodeScope.$modelValue.$type !== "Advant.Crossroads.Core.Export.GroupExportField, Advant.Crossroads.Core";
                    } else if (destType === "Group") {
                        return sourceNodeScope.$modelValue.$type === "Advant.Crossroads.Core.Export.GroupExportField, Advant.Crossroads.Core";
                    } else if (destType === "Field") {
                        return sourceNodeScope.$modelValue.$type !== "Advant.Crossroads.Core.Export.GroupExportField, Advant.Crossroads.Core"
                            && sourceNodeScope.$modelValue.$type !== "Advant.Crossroads.Core.Export.SectionExportField, Advant.Crossroads.Core";
                    }
                    return this.helper.isType(sourceNodeScope.$modelValue.type, destType);
                }, dropped: (event) => {
                    // If anything in the tree changes make the form dirty
                    this.$scope.applicationExportSettingsForm.$setDirty();
                    this.createLayoutTypes();
                }
            };

            this.fieldCompleter = {
                getCompletions: (editor, session, pos, prefix, callback) => {
                    callback(null, self.getAutoCompleteList(prefix));

                }
            };

            this.langTools = ace.require("ace/ext/language_tools");
            this.langTools.addCompleter(this.fieldCompleter);

            this.isNew = this.$routeParams.exportSettingsId.toLowerCase() === "new";

            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getApplicationDefinition().then(() => {
                this.getTasksFromServer();
            }), this.getSetting(), this.getRules()], ApplicationExportSettingsDetails.controllerId)
                .then(result => {
                    this.createExportTypes();
                    self.log("Activated Application Export Settings Details View");
                });
        }

        private getAutoCompleteList(prefix: string) {
            if (!prefix) {
                return [];
            }
            var matchingFields = [];
            angular.forEach(this.setting.exportFields, (v: IExportField, k: any) => {
                var exportFieldKey = "[[" + v.fieldKey + "]]";
                var test = exportFieldKey.toLocaleLowerCase().indexOf(prefix.toLocaleLowerCase());
                if (test > -1) {
                    matchingFields.push({ name: exportFieldKey, value: exportFieldKey, score: 100, meta: "export field" });
                }
                if ((<ISectionExportField>v).groups) {
                    var sectionField: ISectionExportField = (<ISectionExportField>v);
                    angular.forEach(sectionField.groups, (group) => {
                        var groupKey = "[[" + group.fieldKey + "]]";
                        test = groupKey.toLocaleLowerCase().indexOf(prefix.toLocaleLowerCase());
                        if (test > -1) {
                            matchingFields.push({ name: groupKey, value: groupKey, score: 100, meta: "export group" });
                        }

                        if (group.fields) {
                            angular.forEach(group.fields, (field) => {
                                var fieldKey = "[[" + field.fieldKey + "]]";
                                test = fieldKey.toLocaleLowerCase().indexOf(prefix.toLocaleLowerCase());
                                if (test > -1) {
                                    matchingFields.push({ name: fieldKey, value: fieldKey, score: 100, meta: "export field" });
                                }
                            });
                        }
                    });
                }
            });

            return matchingFields;
        }

        aceLayoutEditorLoaded = (aceEditor: AceAjax.Editor) => {
            aceEditor.setShowPrintMargin(false);
            aceEditor.setTheme("ace/theme/chrome");
            aceEditor.getSession().setMode("ace/mode/html");
            (<any>aceEditor).setOptions({
                enableSnippets: true,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true
            });
        };

        addCommonExportFields = (commonType: string, toHeader?: boolean) => {
            var exportField: IExportField;
            var fieldCollection: IExportField[];

            if (toHeader) {
                fieldCollection = (<IApplicationHtmlFileSettings>this.setting).headerFields;
            } else {
                fieldCollection = this.setting.exportFields;
            }

            switch (commonType) {
                case "Id":
                    exportField = {
                        $type: "Advant.Crossroads.Core.Export.CommonFields.ApplicationIdExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey("Id", fieldCollection),
                        title: "User Application Id",
                        exportFormatter: null
                    };
                    break;
                case "DateStarted":
                    exportField = {
                        $type: "Advant.Crossroads.Core.Export.CommonFields.ApplicationStartedExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey("DateStarted", fieldCollection),
                        title: "Date Started",
                        exportFormatter: null
                    };
                    break;
                case "DateCompleted":
                    exportField = {
                        $type: "Advant.Crossroads.Core.Export.CommonFields.ApplicationCompletedExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey("DateCompleted", fieldCollection),
                        title: "Date Completed",
                        exportFormatter: null
                    };
                    break;
                case "Term":
                    exportField = {
                        $type: "Advant.Crossroads.Core.Export.CommonFields.ApplicationTermExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey("Term", fieldCollection),
                        title: "Term",
                        exportFormatter: null
                    };
                    break;
                case "PaymentId":
                    exportField = {
                        $type: "Advant.Crossroads.Core.Export.CommonFields.ApplicationPaymentIdExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey("PaymentId", fieldCollection),
                        title: "Payment Id",
                        exportFormatter: null
                    };
                    break;
                case "PaymentMethod":
                    exportField = {
                        $type: "Advant.Crossroads.Core.Export.CommonFields.ApplicationPaymentMethodExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey("PaymentMethod", fieldCollection),
                        title: "Payment Method",
                        exportFormatter: null
                    };
                    break;
                case "PaymentReceived":
                    exportField = {
                        $type: "Advant.Crossroads.Core.Export.CommonFields.ApplicationPaymentReceivedExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey("PaymentReceived", fieldCollection),
                        title: "Payment Received",
                        exportFormatter: null
                    };
                    break;
                case "PaymentDateReceived":
                    exportField = {
                        $type: "Advant.Crossroads.Core.Export.CommonFields.ApplicationPaymentDateReceived, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey("PaymentDateReceived", fieldCollection),
                        title: "Payment Received On",
                        exportFormatter: null
                    };
                    break;
                case "PaymentAmount":
                    exportField = {
                        $type: "Advant.Crossroads.Core.Export.CommonFields.ApplicationPaymentAmountExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey("PaymentAmount", fieldCollection),
                        title: "Payment Amount",
                        exportFormatter: null
                    };
                    break;
                case "StaticString":
                    (exportField as IStaticStringExportField) = {
                        $type: "Advant.Crossroads.Core.Export.CommonFields.StringExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey("StaticString", fieldCollection),
                        title: "",
                        value: "",
                        exportFormatter: null
                    };
                    break;
                case "CalculatedField":
                    exportField = {
                        $type: "Advant.Crossroads.Core.Export.CommonFields.CalculatedField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey("CalculatedField", fieldCollection),
                        title: "",
                        exportFormatter: null
                    };
                    break;
                case "LastExportedOn":
                    exportField = {
                        $type: "Advant.Crossroads.Core.Export.CommonFields.ApplicationLastExportedOnField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey("LastExportedOn", fieldCollection),
                        title: "Last Exported On",
                        exportFormatter: null
                    };
                    break;
                case "LastUpdated":
                    exportField = {
                        $type: "Advant.Crossroads.Core.Export.CommonFields.ApplicationLastUpdatedExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey("LastUpdated", fieldCollection),
                        title: "Last Updated",
                        exportFormatter: null
                    };
                    break;
                default:
                    exportField = null;
            }

            if (toHeader) {
                (<IApplicationHtmlFileSettings>this.setting).headerFields.push(exportField);
            } else {
                this.setting.exportFields.push(exportField);
            }
            this.$scope.applicationExportSettingsForm.$setDirty();
            this.createLayoutTypes();
        };

        addExportFields = () => {
            var allowSectionAndGroupSelection = this.setting.$type === "Advant.Crossroads.Core.Export.ApplicationHtmlFileSettings, Advant.Crossroads.Core";
            angular.forEach(this.applicationDefinition.form.sections, (section: any) => {
                if (section.selected && allowSectionAndGroupSelection) {
                    var exportSection: ISectionExportField = {
                        $type: "Advant.Crossroads.Core.Export.SectionExportField, Advant.Crossroads.Core",
                        title: section.text,
                        fieldKey: this.generateFieldKey(section.name),
                        exportFormatter: null,
                        groups: []
                    };
                    this.setting.exportFields.push(exportSection);
                }
                angular.forEach(section.groups, (group: any) => {
                    if (section.selected && group.selected && allowSectionAndGroupSelection) {
                        var exportGroup: IGroupExportField = {
                            $type: "Advant.Crossroads.Core.Export.GroupExportField, Advant.Crossroads.Core",
                            title: group.text,
                            fieldKey: this.generateFieldKey(group.name),
                            exportFormatter: null,
                            groupLayout: group.layout,
                            fields: []
                        };
                        exportSection.groups.push(exportGroup);
                    }

                    angular.forEach(group.fields, (field: any) => {
                        if (field.selected) {
                            field.selected = false;
                            var newExportField: IResponseExportField = {
                                $type: "Advant.Crossroads.Core.Export.ResponseExportField, Advant.Crossroads.Core",
                                fieldKey: this.generateFieldKey(field.key),
                                applicationFieldKey: field.key,
                                title: field.title,
                                exportFormatter: null
                            };
                            if (section.selected && group.selected && allowSectionAndGroupSelection) {
                                exportGroup.fields.push(newExportField);
                            } else {
                                this.setting.exportFields.push(newExportField);
                            }
                            this.$scope.applicationExportSettingsForm.$setDirty();
                        }
                    });
                    group.selected = false;
                });

                section.selected = false;
            });
            this.createLayoutTypes();
        };

        addHeaderFields = () => {
            if (!(<IApplicationHtmlFileSettings>this.setting).headerFields) {
                (<IApplicationHtmlFileSettings>this.setting).headerFields = [];
            }
            angular.forEach(this.applicationDefinition.form.sections, (section: any) => {
                section.selected = false;
                angular.forEach(section.groups, (group: any) => {
                    group.selected = false;
                    angular.forEach(group.fields, (field: any) => {
                        if (field.selected) {
                            field.selected = false;
                            var newExportField: IResponseExportField = {
                                $type: "Advant.Crossroads.Core.Export.ResponseExportField, Advant.Crossroads.Core",
                                fieldKey: this.generateFieldKey(field.key, (<IApplicationHtmlFileSettings>this.setting).headerFields),
                                applicationFieldKey: field.key,
                                title: field.title,
                                exportFormatter: null
                            };

                            (<IApplicationHtmlFileSettings>this.setting).headerFields.push(newExportField);
                            this.$scope.applicationExportSettingsForm.$setDirty();
                        }
                    });
                });
            });
        };

        cancel() {
            this.setting = angular.copy(this.originalSetting);
            this.$scope.applicationExportSettingsForm.$setPristine();
        }

        clearFilter = () => {
            this.termCode = null;
            this.dateStart = null;
            this.dateEnd = null;
            this.ruleIdFilter = null;
        };

        generateFieldKey = (potentionalKey: string, fieldCollection?: IExportField[]): string => {
            var hasConflict: boolean;
            var checkCollection: IExportField[];
            if (fieldCollection) {
                checkCollection = fieldCollection;
            } else {
                checkCollection = this.setting.exportFields;
            }

            angular.forEach(checkCollection, (field) => {
                if (field.fieldKey === potentionalKey) {
                    hasConflict = true;
                }
            });

            if (!hasConflict) {
                return potentionalKey;
            }

            var appendNumber = 1;
            var lastCharacter = potentionalKey.substring(potentionalKey.length - 1);
            if (Number(lastCharacter)) {
                appendNumber = Number(lastCharacter) + 1;
                potentionalKey = potentionalKey.substring(0, potentionalKey.length - 1) + appendNumber;
            } else {
                potentionalKey = potentionalKey + appendNumber;
            }

            return this.generateFieldKey(potentionalKey, checkCollection);
        };

        createExportTypes = () => {
            this.exportTypes = [
                {
                    displayName: "Flat File",
                    typeName: "Advant.Crossroads.Core.Export.ApplicationFlatFileSettings, Advant.Crossroads.Core"
                }, {
                    displayName: "PDF File",
                    typeName: "Advant.Crossroads.Core.Export.ApplicationHtmlFileSettings, Advant.Crossroads.Core"
                }, {
                    displayName: "JSON File",
                    typeName: "Advant.Crossroads.Core.Export.ApplicationJsonFileSettings, Advant.Crossroads.Core"
                }, {
                    displayName: "Xml File",
                    typeName: "Advant.Crossroads.Core.Export.ApplicationXmlFileSettings, Advant.Crossroads.Core"
                }, {
                    displayName: "Transcript",
                    typeName: "Advant.Crossroads.Core.Export.ApplicationTranscriptSettings, Advant.Crossroads.Core"
                }, {
                    displayName: "Residency Determination",
                    typeName: "Advant.Crossroads.Core.Export.ApplicationResidencyDeterminationSettings, Advant.Crossroads.Core"
                }
            ];

            if (this.getAttachmentFields().length > 0) {
                this.exportTypes.push({
                    displayName: "Attachment",
                    typeName: "Advant.Crossroads.Core.Export.ApplicationAttachmentSettings, Advant.Crossroads.Core"
                });
                }
        };

        createLayoutTypes = () => {
            var layoutTypes: { value: string; displayName: string }[];
            var hasfieldInRoot = false;
            if (this.setting) {
                angular.forEach(this.setting.exportFields, (value: IExportField) => {
                    if (value.$type === "Advant.Crossroads.Core.Export.ResponseExportField, Advant.Crossroads.Core" ||
                        value.$type === "Advant.Crossroads.Core.Export.CommonFields.ApplicationIdExportField, Advant.Crossroads.Core" ||
                        value.$type === "Advant.Crossroads.Core.Export.CommonFields.ApplicationStartedExportField, Advant.Crossroads.Core" ||
                        value.$type === "Advant.Crossroads.Core.Export.CommonFields.ApplicationCompletedExportField, Advant.Crossroads.Core" ||
                        value.$type === "Advant.Crossroads.Core.Export.CommonFields.ApplicationTermExportField, Advant.Crossroads.Core" ||
                        value.$type === "Advant.Crossroads.Core.Export.CommonFields.ApplicationPaymentIdExportField, Advant.Crossroads.Core" ||
                        value.$type === "Advant.Crossroads.Core.Export.CommonFields.ApplicationPaymentMethodExportField, Advant.Crossroads.Core" ||
                        value.$type === "Advant.Crossroads.Core.Export.CommonFields.ApplicationPaymentReceivedExportField, Advant.Crossroads.Core" ||
                        value.$type === "Advant.Crossroads.Core.Export.CommonFields.ApplicationPaymentDateReceived, Advant.Crossroads.Core" ||
                        value.$type === "Advant.Crossroads.Core.Export.CommonFields.ApplicationPaymentAmountExportField, Advant.Crossroads.Core" ||
                        value.$type === "Advant.Crossroads.Core.Export.CommonFields.StringExportField, Advant.Crossroads.Core" ||
                        value.$type === "Advant.Crossroads.Core.Export.CommonFields.CalculatedField, Advant.Crossroads.Core") {
                        hasfieldInRoot = true;
                    }
                });
            }

            if (hasfieldInRoot) {
                layoutTypes = [
                    {
                        value: "TwoColumn",
                        displayName: "Two Column"
                    }, {
                        value: "ThreeColumn",
                        displayName: "Three Column"
                    }, {
                        value: "FourColumn",
                        displayName: "Four Column"
                    },
                    {
                        value: "Custom",
                        displayName: "Use a Custom layout"
                    }
                ];
                (<any>this.setting).layoutType = "Custom";
            } else {
                layoutTypes = [
                    {
                        value: "",
                        displayName: "Choose a layout.."
                    }, {
                        value: "UseAppLayout",
                        displayName: "Use the application layout"
                    }, {
                        value: "TwoColumn",
                        displayName: "Two Column"
                    }, {
                        value: "ThreeColumn",
                        displayName: "Three Column"
                    }, {
                        value: "FourColumn",
                        displayName: "Four Column"
                    }, {
                        value: "Custom",
                        displayName: "Use a Custom layout"
                    }
                ];
            }
            this.layoutTypes = layoutTypes;
        };

        deleteExportField = (field) => {
            field.remove();
            this.$scope.applicationExportSettingsForm.$setDirty();
            this.createLayoutTypes();
        };

        deleteHeaderField = (field: IExportField) => {
            _.remove((<IApplicationHtmlFileSettings>this.setting).headerFields, { fieldKey: field.fieldKey });
            this.$scope.applicationExportSettingsForm.$setDirty();
        };


        downloadExport = () => {

            var httpPath = this.config.apiHost + "/" + this.common.getUser().activeApplication +
                "/applicationExportSettings/" + this.exportSettingsId + "/testExport?fileId=" + encodeURI(this.testFileId);

            this.helper.openFile(httpPath);
        };

        editExportField = (field: any) => {
            var exportFieldDialog = this.dialogs.create("/app/exportSettings/exportFieldDialog.html", "exportFieldDialog", field, { copy: true });

            exportFieldDialog.result.then((result: any) => {
                this.$scope.applicationExportSettingsForm.$setDirty();
                field.exportFormatter = result.exportFormatter;
                field.fieldKey = result.fieldKey;
                field.title = result.title;

                if (result.value !== undefined) {
                    field.value = result.value;
                }
                if (result.script) {
                    field.script = result.script;
                }
            });
        };

        generateTestFile = (): angular.IPromise<string> => {
            this.generatingFile = true;
            this.testFileId = null;

            return this.Restangular.one(this.common.getUser().activeApplication).one("applicationExportSettings/" + this.exportSettingsId + "/testExport")
                .get(this.getQueryParams()).then((result) => {

                    this.testFileId = result.fileId;
                    this.totalExported = result.totalExported;

                    this.generatingFile = false;
                    return result;
                }, (reason) => {
                    this.generatingFile = false;
                    this.logError("An error occurred while generating the export", reason, true);
                });
        };

        getApplicationDefinition = (): angular.IPromise<IApplicationDefinition> => {
            return this.Restangular.one(this.common.getUser().activeApplication).get().then((result) => {
                this.applicationDefinition = result;
                this.getRcnFields();
                return result;
            }, (reason) => {
                this.logError("Failed to retrieve the application fields", reason);
            });
        };

        getAttachmentFields = () => {
            var attachmentFields = [];
            if (this.applicationDefinition) {
                angular.forEach(this.applicationDefinition.form.sections, (section: ISection) => {
                    angular.forEach(section.groups, (group: IGroup) => {
                        angular.forEach(group.fields, (field: IField) => {
                            if (this.helper.isType(field.type, "DocumentUploadField")) {
                                attachmentFields.push(field);
                            }
                        });
                    });
                });
            }
            return attachmentFields;
        };

        getRcnFields = () => {
            this.rcnFields = [];           
            if (this.applicationDefinition) {
                angular.forEach(this.applicationDefinition.form.sections, (section: ISection) => {
                    angular.forEach(section.groups, (group: IGroup) => {
                        angular.forEach(group.fields, (field: IField) => {
                            if (this.helper.isType(field.type, "RcnField")) {
                                this.rcnFields.push(field);
                            }
                        });
                    });
                });
            }
        };

        getQueryParams = (): any => {
            var queryParams = {
                termCode: this.termCode,
                startDate: this.dateStart ? moment(this.dateStart).toISOString() : "",
                endDate: this.dateEnd ? moment(this.dateEnd).add(1, "day").subtract(1, "second").toISOString() : "",
                ruleId: this.ruleIdFilter,
                singleFile: this.singleFile
            };

            return queryParams;
        };

        getRules = (): angular.IPromise<Array<IRuleSet>> => {

            this.rules = [];
            return this.Restangular.one(this.common.getUser().activeApplication).all("rules").getList().then((result) => {
                this.rules = result;
                this.rules.unshift({ id: null, name: "" });
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the rules", reason);
                return this.rules;
            });
        };

        getSetting = (): angular.IPromise<IExportSettings> => {
            var deferred;
            if (this.$routeParams.exportSettingsId.toLowerCase() === "new") {
                deferred = this.common.$q.defer<IExportSettings>();
                var empty: any = {
                    $type: null,
                    id: null,
                    applicationDefinitionId: this.common.getUser().activeApplication,
                    name: null,
                    fileNamePattern: null,
                    singleFileNamePattern: null,
                    exportType: "Application",
                    createdOn: moment().toDate(),
                    createdBy: null,
                    lastUpdated: moment().toDate(),
                    lastUpdatedBy: null,
                    type: null,
                    exportFields: [],
                    headerFields: []
                };
                this.setting = empty;
                this.originalSetting = angular.copy(this.setting);
                deferred.resolve(this.setting);

                return deferred.promise;
            }


            return this.Restangular.one(this.common.getUser().activeApplication).one("applicationExportSettings", this.exportSettingsId).get().then((result) => {
                this.setting = result;
                this.originalSetting = angular.copy(this.setting);
                return result;
            }, (reason) => {
                this.logError("An error occurred while trying to retrieve your settings. Please try again later", reason, true);
            });

        };

        getTasks = (): void => {
            if (this.applicationTasks.length > 0) {
                this.exportTypes.push({
                    displayName: "Task Attachment",
                    typeName: "Advant.Crossroads.Core.Export.ApplicationTaskAttachmentSettings, Advant.Crossroads.Core"
                });
            }
        };
        getTasksFromServer = (): angular.IPromise<Array<IApplicationTask>> => {
            this.applicationTasks = [];
            return this.Restangular.one(this.applicationDefinition.applicationDefinitionId).all("applicationTasks").getList().then((result) => {
                this.applicationTasks = result;
                this.getTasks();
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the tasks", reason);
                return this.applicationTasks;
            });
        };


        rootClass = (field) => {
            if (field.hasOwnProperty("groups")) {
                return "fa-folder";
            }
            return "fa-arrows";
        };

        save = () => {
            if (this.$scope.applicationExportSettingsForm.$valid) {
                this.isSaving = true;

                if (this.setting.id === null) {
                    this.Restangular.all(this.common.getUser().activeApplication).all("applicationExportSettings").post(this.setting).then((result) => {
                        this.isSaving = false;
                        this.originalSetting = angular.copy(result);
                        this.setting = result;
                        this.exportSettingsId = this.setting.id;
                        this.logSuccess("Your application export settings have been saved", result, true);
                        this.$scope.applicationExportSettingsForm.$setPristine();
                        return result;
                    }, (reason) => {
                        var message = "Error saving new setting!";
                        if (reason.data.modelState) {
                            angular.forEach(reason.data.modelState, (value: any, key: any) => {
                                message += "<br/>" + value;
                            });
                        }
                        if (reason.data.exceptionMessage) {
                            message += "<br/>" + reason.data.exceptionMessage;
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                    });
                } else {
                    this.setting.put().then((result) => {
                        this.logSuccess("Your application export settings have been saved", result, true);
                        this.$scope.applicationExportSettingsForm.$setPristine();
                        this.isSaving = false;
                        this.originalSetting = angular.copy(this.setting);
                        return result;
                    }, (reason) => {
                        var message = "An error occurred while trying to save your settings. Please try again later.";
                        if (reason.data.exceptionMessage) {
                            message += "<br/>" + reason.data.exceptionMessage;
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                        return;
                    });
                }
            }
        };

        selectSection = (section, select?) => {
            section.selected = !section.selected;

            if (select !== undefined) {
                section.selected = select;
            }

            angular.forEach(section.groups, (group) => {
                this.selectGroup(group, section.selected);
            });
        };

        selectGroup = (group, select?) => {
            group.selected = !group.selected;

            if (select !== undefined) {
                group.selected = select;
            }

            angular.forEach(group.fields, (field) => {
                field.selected = group.selected;
            });
        };

        toggleTree(node) {
            node.toggle();
        }

        exportSetting = () => {
            var exportFile: ExportSettingsFile = new ExportSettingsFile(this.setting);
                
            var blob: Blob = new Blob([exportFile.toJson()], { type: "application/octet-stream" });
            saveAs(blob, this.setting.name + ".json");
        };

        importSetting = () => {
            var currentExportSettings = new ExportSettingsFile(this.setting);

            var currentSettingData: ICurrentExportSettingData = {
                currentExportSettingsFile: currentExportSettings,
                appDefId: this.applicationDefinition.applicationDefinitionId
            };


            var importFile = this.dialogs.create("/app/exportSettings/importSetting.html", "importSetting", currentSettingData, { size: "lg" });

            importFile.result.then((result: ExportSettingsFile) => {
                this.setting = result.setting;
                this.$timeout(this.save);
                this.isNew = false;
            });            
        }

    }

    angular.module("app").controller(ApplicationExportSettingsDetails.controllerId, ApplicationExportSettingsDetails);
}