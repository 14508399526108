namespace Advant.Crossroads {
    "use strict";

    interface IExportSettingsCopyDialogScope extends angular.IScope {
        exportSetting: { name: string };
        save: () => void;
        cancel: () => void;
        hitEnter: (evt: any) => void;
    }


    class ExportSettingsCopyDialog {
        static $inject: any = ["$scope", "$uibModalInstance", "data"];

        constructor(private $scope: IExportSettingsCopyDialogScope,
            private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
            private data?: any) {

            $scope.exportSetting = { name: data ? `Copy of ${data.name}` : "Setting Copy" };

            $scope.save = () => {

                $uibModalInstance.close($scope.exportSetting.name);

            };

            $scope.hitEnter = (evt) => {

                if (angular.equals(evt.keyCode, 13) && !(angular.equals($scope.exportSetting.name, null) || angular.equals($scope.exportSetting.name, "")))

                    $scope.save();

            };

            $scope.cancel = () => {
                this.$uibModalInstance.dismiss("cancel");
            };
        }
    }

    angular.module("app").controller("exportSettingsCopyDialog", ExportSettingsCopyDialog);
}