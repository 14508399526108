/// <reference path="../app.ts" />
/// <reference path="../config.ts" />

namespace Advant.Crossroads {

    export interface ISignalRService {
        exportProjectDownloadHub: () => ExportProjectDownloadHub;
    }

    export interface IHub {
        connect: (queryParams?: any) => angular.IPromise<any>;
        disconnect: () => void;
        invoke: (method: string, args: any) => angular.IPromise<any>;
        on: (event, callback: (...msg: any[]) => void) => void;
        off: (event: string, callback: (...msg: any[]) => void) => void;
        methods: any;
        queryParams: any;
        getConnectionId: () => string;
    }

    export interface IHubOptions {
        listeners?: any;
        serverMethods?: string[]
        queryParams?: any;
        errorHandler?: (err) => any;
    }

    export interface IExportProjectDownloadHubMethods {
        GenerateTranscriptDownload: (projectId: string, applicationDefinitionId: string, newOnly: boolean, studentId: string, startDate: string, endDate: string) => angular.IPromise<any>;
        GenerateApplicationDownload: (projectId: string, applicationDefinitionId: string, newOnly: boolean, userApplicationId: string, startDate: string, endDate: string) => angular.IPromise<any>;
        GenerateProspectDownload: (projectId: string, applicationDefinitionId: string, newOnly: boolean, createdStartDate: string, createdEndDate: string,
            closedStartDate:string, closedEndDate: string, reOpenedStartDate: string, reOpenedEndDate: string) => angular.IPromise<any>;
    }

    
    export class Hub implements IHub {
        private proxy: SignalR.Hub.Proxy;
        queryParams: any;
        methods: any;
        private log: (msg, data?, showHowl?) => void;
        private logError: (msg, data?, showHowl?) => void;
        private logSuccess: (msg, data?, showHowl?) => void;

        constructor(private hubName: string,
            private connection: SignalR.Hub.Connection,
            private options: IHubOptions,
            private $q: angular.IQService,
            private authentication: IAuthenticationProviderService,
            private $cookies: angular.cookies.ICookiesService,
            private $interval: angular.IIntervalService,
            private logger: ILogger) {
            this.log = logger.getLogFn(SignalRService.serviceId);
            this.logError = logger.getLogFn(SignalRService.serviceId, "error");
            this.logSuccess = logger.getLogFn(SignalRService.serviceId, "success");
            this.proxy = this.connection.createHubProxy(hubName);
            var qs;
            this.methods = {};
            var hub = this;

            if (options && options.serverMethods) {
                angular.forEach(options.serverMethods, (method) => {
                    this.methods[method] = function () {
                        var args = $.makeArray(arguments);
                        args.unshift(method);
                        return hub.invoke.apply(hub, args);
                    }
                })
            }

            if (options && options.listeners) {
                Object.getOwnPropertyNames(options.listeners)
                    .filter((propName) => {
                        return typeof options.listeners[propName] === "function";
                    }).forEach((propName) => {
                        this.proxy.on(propName, options.listeners[propName]);
                    });
            }

            if (options && options.errorHandler) {
                this.proxy.connection.error(options.errorHandler);
            }
        }

        connect(queryParams?: any): angular.IPromise<any> {
            var defer = this.$q.defer();
            var resolved = false;
            var value = this.authentication.refreshToken().then(() => {
                this.$cookies.put("BearerToken", this.authentication.accessToken(), { domain: "cfnc.org", path: "/" });
                if (queryParams) {
                    this.proxy.connection.qs = queryParams;
                }
                this.proxy.connection.start()
                    .done(() => {
                        defer.resolve();
                    })
                    .fail((result) => {
                        defer.reject(result);
                    })
                    .progress((i) => {
                        defer.notify(i);
                    });

            });

            return defer.promise;
        }

        disconnect() {
            this.proxy.connection.stop(false, true);
        }

        invoke(method, args): angular.IPromise<any> {
            var defer = this.$q.defer();
            if (this.proxy.connection.state != SignalR.ConnectionState.Connected) {
                this.logError("An error occurred invoking the method.", "The hub is currently disconnected. You must connect to the server by calling 'connect()' before invoking server methods.", true);
                defer.reject();
            }
            else {
                if (this.queryParams) {
                    this.proxy.connection.qs = this.queryParams;
                }

                var refreshToken = () => {
                    this.authentication.refreshToken();
                }

                var refresh = this.$interval(this.refreshToken, moment.duration(10, "minutes").asMilliseconds());


                this.proxy.invoke.apply(this.proxy, arguments)
                    .done((result) => {
                        this.$interval.cancel(refresh);
                        defer.resolve(result);
                    })
                    .fail((result) => {
                        this.$interval.cancel(refresh);
                        defer.reject(result);
                    })
                    .progress(i => defer.notify(i));
            }
            return defer.promise;

        }

        on(event: string, callback: (...msg:any[]) => void) {
            this.proxy.on(event, callback);
        }

        off(event, callback) {
            this.proxy.off(event, callback);
        }

        refreshToken() {
            this.authentication.refreshToken();
        }

        getConnectionId() {
            if (this.connection) {
                return this.connection.id;
            }

            return null;
        }
    }

    export class ExportProjectDownloadHub extends Hub {
        methods: IExportProjectDownloadHubMethods;
    }

    export class SignalRService implements ISignalRService {
        static serviceId: string = "signalRService";
        private connection: SignalR.Hub.Connection;
        private config: ICrossroadsConfig;
        private log: (msg, data?, showHowl?) => void;
        private logError: (msg, data?, showHowl?) => void;
        private logSuccess: (msg, data?, showHowl?) => void;


        constructor(private $injector: angular.auto.IInjectorService,
            private authentication: IAuthenticationProviderService,
            private $cookies: angular.cookies.ICookiesService,
            private $interval: angular.IIntervalService,
            private $q: angular.IQService,
            private logger: ILogger) {

            this.log = logger.getLogFn(SignalRService.serviceId);
            this.logError = logger.getLogFn(SignalRService.serviceId, "error");
            this.logSuccess = logger.getLogFn(SignalRService.serviceId, "success");

            this.config = this.$injector.get<ICrossroadsConfig>("config");
            this.connection = $.hubConnection(this.config.apiHost + "/signalr2", {useDefaultPath: false});
            this.connection.disconnected(() => {
                this.$cookies.remove("BearerToken", { domain: ".cfnc.org" });
            });

        }

        exportProjectDownloadHub = () => {
            return new Hub("ExportProjectDownloadHub", this.connection, {
                serverMethods: ["GenerateTranscriptDownload", "GenerateApplicationDownload", "GenerateProspectDownload"],
                errorHandler: error => this.logError("An error occurred in the signalR service", error, false)
            }, this.$q, this.authentication, this.$cookies, this.$interval, this.logger);
        }
    }

    angular.module("app").factory(SignalRService.serviceId, ["$injector", "authentication", "$cookies", "$interval", "$q", "logger", ($injector: angular.auto.IInjectorService, authentication: IAuthenticationProviderService, $cookies: angular.cookies.ICookiesService, $interval: angular.IIntervalService, $q: angular.IQService, logger: ILogger) =>
        new SignalRService($injector, authentication, $cookies, $interval, $q, logger)]);
}