namespace Advant.Crossroads {
    "use strict";

    interface IApplicationHistoryFilter {
        startDate?: string;
        endDate?: string;
        searchTerm?: string;
    }

    class ApplicationHistory {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingApplicationHistory: boolean = false;;
        applicationChanges: Array<ApplicationChange>;
        applicationDefinition: IApplicationDefinition;
        page: number;
        pageSize: number;
        sortBy: string;
        searchFilter: string;
        sortDirection: string;
        totalCount: number;
        totalPages: number;
        startRow: number;
        endRow: number;
        showFilter: boolean;
        filter: IApplicationHistoryFilter;

        static $inject: any = ["$scope", "$location", "$http", "Restangular", "dialogs", "common", "config"];

        constructor(public $scope: angular.IScope,
            public $location: angular.ILocationService,
            public $http: angular.IHttpService,
            public Restangular: Restangular.IService,
            public dialogs: angular.dialogs.IDialogService,
            public common: ICommonService,
            public config: ICrossroadsConfig) {

            this.log = common.logger.getLogFn("applicantsWithRdsStatus");
            this.logError = common.logger.getLogFn("applicantsWithRdsStatus", "error");
            this.logSuccess = common.logger.getLogFn("applicantsWithRdsStatus", "success");

            this.page = 1;
            this.pageSize = 25;
            this.sortBy = "ChangedOn";
            this.sortDirection = "DESC";
            this.filter = {};

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });
           
            this.activate();
        }

        activate = () => {
            this.common.activateController([this.getApplicationChanges()], "applicationHistoryReport")
                .then(result => {
                    this.log("Activated ApplicationHistory View");
                });
        };

        getApplicationChanges = (): angular.IPromise<Array<Advant.Crossroads.ApplicationChange>> => {
            this.loadingApplicationHistory = true;
            this.applicationChanges = [];

            return this.Restangular.one(this.common.getUser().activeApplication).getList("applicationHistory", this.getQueryParams()).then((result: IRestangularResult<Advant.Crossroads.ApplicationChange>) => {
                this.applicationChanges = result;
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingApplicationHistory = false;
                return result;
            }, (reason) => {
                this.loadingApplicationHistory = false;
                this.logError("An error occurred while getting the user applications", reason);
                return null;
                });
        };

        getQueryParams = (): any => {
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                startDate: this.filter.startDate ? moment(this.filter.startDate).toISOString() : "2016-08-20T00:00:00Z",
                endDate: this.filter.endDate ? moment(this.filter.endDate).toISOString() : "",
                searchTerm: this.filter.searchTerm
            };

            return queryParams;
        };

        

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.getApplicationChanges();
        };

        applyFilter = () => {
            this.page = 1;
            this.getApplicationChanges();
        };

        clearFilter = () => {
            this.filter = {};
        };
    }

    angular.module("app").controller("applicationHistoryReport", ApplicationHistory);

}