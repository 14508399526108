/// <reference path="app.ts" />
namespace Advant.Crossroads {
    "use strict";

    export interface IRouteConfigSettings {
        navOrder?: number;
        content?: string;
    }

    export interface IRouteConfig {
        templateUrl?: string;
        title?: string;
        section?: string;
        settings: IRouteConfigSettings;
        caseInsensitiveMatch?: boolean;
        controller?: string;
        controllerAs?: string;
        reloadOnSearch?: boolean;
    }

    export interface IRoute {
        url?: string;
        config?: IRouteConfig;
        subNavs?: Array<IRoute>;
        permission?: Array<string>;
    }


    export class RouteConfig {
        routes: Array<IRoute>;
        constructor() {
            this.routes = [
                {
                    url: "/login",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/loginForm.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/login/resetPassword",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/passwordChange.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/login/loginWithTfaCode",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/loginWithTfaCode.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/login/tfaSetup",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/tfaSetup.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/login/confirmPasswordChange",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/confirmPasswordChange.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/login/changeQuestion",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/securityQuestionChange.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/login/forgotPassword",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/forgotPassword.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/login/:tenant",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/loginForm.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/manage",
                    config: {
                        title: "Profile",
                        templateUrl: "/app/user/profile.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/manage/:activeTab",
                    config: {
                        title: "Profile",
                        templateUrl: "/app/user/profile.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/helpFiles",
                    config: {
                        title: "Help",
                        templateUrl: "/app/help/helpFiles.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/",
                    config: {
                        templateUrl: "/app/dashboard/dashboard.html",
                        title: "Dashboard",
                        section: "Dashboard",
                        caseInsensitiveMatch: true,
                        settings: {
                            navOrder: 1,
                            content: "<i class=\"fa fa-dashboard\"></i>Dashboard"
                        }
                    }
                }, {
                    config: {
                        section: "Applicants",
                        settings: {
                            navOrder: 2,
                            content: "<i class=\"fa fa-users\"></i> Applicants"
                        }
                    },
                    permission: ["canViewApplicants", "canCreateAppExports", "canExportApplicants", "canSearchResidency"],
                    subNavs: [{
                        url: "/applicants/started",
                        config: {
                            templateUrl: "/app/userApplications/viewApplications.html",
                            controller: "startedApplications",
                            controllerAs: "vm",
                            section: "Applicants",
                            title: "In-Progress Applications",
                            caseInsensitiveMatch: true,
                            settings: {
                                navOrder: 1,
                                content: "<div class='row'><div class='col-xs-2'><i class=\"fa fa-paper-plane\"></i></div><div class='col-xs-10'> In-Progress Applications</div></div>"
                            }
                        },
                        permission: ["canViewApplicants"]
                    }, {
                            url: "/applicants/submitted",
                            config: {
                                templateUrl: "/app/userApplications/viewApplications.html",
                                controller: "submittedApplications",
                                controllerAs: "vm",
                                section: "Applicants",
                                title: "Submitted Applications",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 2,
                                    content: "<i class=\"fa fa-inbox\"></i> Submitted Applications"
                                }
                            },
                            permission: ["canViewApplicants"]
                        }, {
                            url: "/applicants/userApplications/:userAppId",
                            config: {
                                templateUrl: "/app/userApplications/userApplicationDetail.html",
                                controller: "userApplicationDetail",
                                controllerAs: "vm",
                                title: "User Application Detail",
                                section: "Applicants",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/application/export/settings",
                            config: {
                                templateUrl: "/app/exportSettings/applicationExportSettings.html",
                                title: "Export Settings",
                                section: "Applicants",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 3,
                                    content: "<i class=\"fa fa-gear\"></i> Export Settings"
                                }
                            },
                            permission: ["canCreateAppExports"]
                        }, {
                            url: "/application/export/settings/:exportSettingsType/:exportSettingsId",
                            config: {
                                templateUrl: "/app/exportSettings/applicationExportSettingsDetails.html",
                                controller: "applicationExportSettingsDetails",
                                controllerAs: "vm",
                                title: "Export Setting Detail",
                                section: "Applicants",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/application/export/projects/applicationExportProjects",
                            config: {
                                templateUrl: "/app/exportProjects/applicationExportProjects.html",
                                title: "Export Projects",
                                section: "Applicants",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 4,
                                    content: "<i class=\"fa fa-hdd-o\"></i> Export Projects"
                                }
                            },
                            permission: ["canCreateAppExports", "canExportApplicants"]
                        }, {
                            url: "/application/export/projects/sharedApplicationExportProjects",
                            config: {
                                templateUrl: "/app/exportProjects/sharedApplicationExportProjects.html",
                                title: "Shared Export Projects",
                                section: "Applicants",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 5,
                                    content: "<i class=\"fa fa-hdd-o\"></i>Shared Export Projects"
                                }
                            },
                            permission: ["hasSharedExportProject"]
                        }, {
                            url: "/application/export/projects/applicationExportProjects/:exportProjectId",
                            config: {
                                templateUrl: "/app/exportProjects/applicationExportProjectDetails.html",
                                controller: "applicationExportProjectDetails",
                                controllerAs: "vm",
                                title: "Export Project Details",
                                section: "Applicants",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/application/export/projects/applicationExportProjects/:exportProjectId/download",
                            config: {
                                templateUrl: "/app/exportProjects/applicationExportProjectDownload.html",
                                controller: "applicationExportProjectDownload",
                                controllerAs: "vm",
                                title: "Download Export Project",
                                section: "Applicants",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/application/residency",
                            config: {
                                templateUrl: "/app/residency/residencySearch.html",
                                title: "Find Residency Status",
                                section: "Applicants",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 5,
                                    content: "<i class=\"fa fa-id-card\"></i> Find Residency Status"
                                }
                            },
                            permission: ["canSearchResidency"]
                        }, {
                            url: "/application/residencyLookup",
                            config: {
                                templateUrl: "/app/residency/residencyLookup.html",
                                title: "Lookup In-Progress Residency Status",
                                section: "Applicants",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 6,
                                    content: "<i class=\"fa fa-id-card\"></i> Lookup In-Progress Residency Status"
                                }
                            },
                            permission: ["canSearchResidency"]
                        }
                    ]
                }, {
                    config: {
                        section: "Recruitment",
                        settings: {
                            navOrder: 3,
                            content: "<i class=\"fa fa-user-plus\"></i> Recruitment"
                        }
                    },
                    permission: ["canViewProspects", "canEditProspects", "canExportProspects", "canCreateProspectExports"],
                    subNavs: [{
                        url: "/prospects",
                        config: {
                            templateUrl: "/app/recruitment/prospects/prospects.html",
                            title: "Prospects",
                            section: "Recruitment",
                            caseInsensitiveMatch: true,
                            settings: {
                                navOrder: 1,
                                content: "<i class=\"fa fa-male\"></i> Prospects"
                            }
                        },
                        permission: ["canViewProspects", "canEditProspects"]
                    }, {
                            url: "/prospects/:id",
                            config: {
                                templateUrl: "/app/recruitment/prospects/prospectDetail.html",
                                controller: "prospectDetail",
                                controllerAs: "vm",
                                title: "Prospect Detail",
                                section: "Recruitment",
                                caseInsensitiveMatch: true,
                                settings: {
                                }
                            }
                        }, {
                            url: "/prospect/rules",
                            config: {
                                templateUrl: "/app/rules/rules.html",
                                title: "Rules Manager",
                                controller: "prospectRules",
                                controllerAs: "vm",
                                section: "Recruitment",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 2,
                                    content: "<i class=\"fa fa-list-ol\"></i> Rules"
                                }
                            },
                            permission: ["canExportProspects"]
                        }, {
                            url: "/prospect/export/settings",
                            config: {
                                templateUrl: "/app/exportSettings/prospectExportSettings.html",
                                title: "Export Settings",
                                section: "Recruitment",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 3,
                                    content: "<i class=\"fa fa-gear\"></i> Export Settings"
                                }
                            },
                            permission: ["canCreateProspectExports"]
                        }, {
                            url: "/prospect/export/settings/:exportSettingsType/:exportSettingsId",
                            config: {
                                templateUrl: "/app/exportSettings/prospectExportSettingsDetails.html",
                                controller: "prospectExportSettingsDetails",
                                controllerAs: "vm",
                                title: "Export Setting Detail",
                                section: "Recruitment",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/prospect/export/projects/prospectExportProjects",
                            config: {
                                templateUrl: "/app/exportProjects/prospectExportProjects.html",
                                title: "Export Projects",
                                section: "Recruitment",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 4,
                                    content: "<i class=\"fa fa-hdd-o\"></i> Export Projects"
                                }
                            },
                            permission: ["canExportProspects", "canCreateProspectExports"]
                        }, {
                            url: "/prospect/export/projects/prospectExportProjects/:exportProjectId",
                            config: {
                                templateUrl: "/app/exportProjects/prospectExportProjectDetails.html",
                                controller: "prospectExportProjectDetails",
                                controllerAs: "vm",
                                title: "Export Project Details",
                                section: "Recruitment",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/prospect/export/projects/prospectExportProjects/:exportProjectId/download",
                            config: {
                                templateUrl: "/app/exportProjects/prospectExportProjectDownload.html",
                                controller: "prospectExportProjectDownload",
                                controllerAs: "vm",
                                title: "Download Export Project",
                                section: "Recruitment",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/importprospects",
                            config: {
                                templateUrl: "/app/recruitment/prospects/importprospects.html",
                                title: "Import Prospects",
                                section: "Recruitment",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 5,
                                    content: "<i class=\"fa fa-users\"></i> Import Prospects"
                                }
                            },
                            permission: ["canViewProspects", "canEditProspects"]
                        }]
                }, {
                    config: {
                        section: "Transcripts",
                        settings: {
                            navOrder: 4,
                            content: "<i class=\"fa fa-files-o\"></i> Transcripts"
                        }
                    },
                    permission: ["canCreateTranscriptExports", "canExportTranscripts"],
                    subNavs: [{
                        url: "/transcript/export/settings",
                        config: {
                            templateUrl: "/app/exportSettings/transcriptExportSettings.html",
                            title: "Export Settings",
                            section: "Transcripts",
                            caseInsensitiveMatch: true,
                            settings: {
                                navOrder: 3,
                                content: "<i class=\"fa fa-gear\"></i> Export Settings"
                            }
                        },
                        permission: ["canCreateTranscriptExports"],
                    }, {
                            url: "/transcript/export/settings/:exportSettingsType/:exportSettingsId",
                            config: {
                                templateUrl: "/app/exportSettings/transcriptExportSettingsDetails.html",
                                controller: "transcriptExportSettingsDetails",
                                controllerAs: "vm",
                                title: "Export Setting Detail",
                                section: "Transcripts",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/transcript/export/projects/transcriptExportProjects",
                            config: {
                                templateUrl: "/app/exportProjects/transcriptExportProjects.html",
                                title: "Export Projects",
                                section: "Transcripts",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 4,
                                    content: "<i class=\"fa fa-hdd-o\"></i> Export Projects"
                                }
                            }
                        }, {
                            url: "/transcript/export/projects/transcriptExportProjects/:exportProjectId",
                            config: {
                                templateUrl: "/app/exportProjects/transcriptExportProjectDetails.html",
                                controller: "transcriptExportProjectDetails",
                                controllerAs: "vm",
                                title: "Export Project Details",
                                section: "Transcripts",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/transcript/export/projects/transcriptExportProjects/:exportProjectId/download",
                            config: {
                                templateUrl: "/app/exportProjects/transcriptExportProjectDownload.html",
                                controller: "transcriptExportProjectDownload",
                                controllerAs: "vm",
                                title: "Download Export Project",
                                section: "Transcripts",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }]
                }, {
                    url: "/crossroute",
                    config: {
                        templateUrl: "/app/crossroute/crossroute.html",
                        title: "Applicants",
                        caseInsensitiveMatch: true,
                        settings: {
                            navOrder: 6,
                            content: "<i class=\"fa fa-cloud-download\"></i> CrossRoute"
                        }
                    }
                }, {
                    url: "/crossroute/new",
                    config: {
                        templateUrl: "/app/crossroute/scheduledetails.html",
                        title: "Applicants",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/crossroute/ExportProjectSchedules/:id/key/:key",
                    config: {
                        templateUrl: "/app/crossroute/scheduledetails.html",
                        title: "Applicants",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/crossroute/connection",
                    config: {
                        templateUrl: "/app/crossroute/connectionConfiguration.html",
                        title: "Applicants",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }

                },
                {
                    url: "/crossroute/crossroutedownload",
                    config: {
                        templateUrl: "/app/crossroute/crossroutedownload.html",
                        title: "Applicants",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }
                , {
                    config: {
                        section: "Application",
                        settings: {
                            navOrder: 5,
                            content: "<i class=\"fa fa-file-text-o\"></i> Application"
                        }
                    },
                    permission: ["canEditApplications"],
                    subNavs: [{
                        url: "/application/edit",
                        config: {
                            templateUrl: "/app/admin/applications/applicationdetail.html",
                            title: "Edit Application",
                            section: "Application",
                            caseInsensitiveMatch: true,
                            reloadOnSearch: false,
                            settings: {
                                navOrder: 1,
                                content: "<i class=\"fa fa-pencil-square-o\"></i> Edit Application"
                            }
                        }
                    }, {
                            url: "/rules",
                            config: {
                                templateUrl: "/app/rules/rules.html",
                                title: "Rules Manager",
                                controller: "applicationRules",
                                controllerAs: "vm",
                                section: "Application",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 2,
                                    content: "<i class=\"fa fa-list-ol\"></i> Rules"
                                }
                            }
                        }, {
                            url: "/majors",
                            config: {
                                templateUrl: "/app/majors/majors.html",
                                title: "Majors",
                                section: "Application",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 3,
                                    content: "<i class=\"fa fa-archive\"></i> Majors"
                                }
                            }
                        }, {
                            url: "/majorlists",
                            config: {
                                templateUrl: "/app/majors/majorlists.html",
                                title: "Major Lists",
                                section: "Application",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 4,
                                    content: "<i class=\"fa fa-list-alt\"></i> Major Lists"
                                }
                            }
                        }, {
                            url: "/notifications",
                            config: {
                                templateUrl: "/app/appEvents/notifications.html",
                                title: "Notifications",
                                section: "Application",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 4,
                                    content: "<i class=\"fa fa-bell\"></i> Notifications"
                                }
                            }
                        }, {
                            url: "/calendar",
                            config: {
                                templateUrl: "/app/calendar/calendar.html",
                                title: "Edit Calendar",
                                section: "Application",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 5,
                                    content: "<i class=\"fa fa-calendar\"></i> Calendar"
                                }
                            }
                        }]

                }, {
                    config: {
                        section: "Campus Profile",
                        settings: {
                            navOrder: 7,
                            content: "<i class=\"fa fa-university\"></i> College Profile & Search"
                        }
                    },
                    permission: ["canUpdateCollegeProfile"],
                    subNavs: [{
                        url: "/profileSettings",
                        config: {
                            templateUrl: "/app/profileSettings/profileSettings.html",
                            title: "Campus Profile",
                            caseInsensitiveMatch: true,
                            settings: {
                                navOrder: 1,
                                content: "<i class=\"fa fa-pencil-square-o\"></i> Edit College Profile"
                            }
                        }
                    }, {
                            url: "/programsMajors",
                            config: {
                                templateUrl: "/app/campusProgramsMajors/campusProgramsMajors.html",
                                title: "Campus Profile",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 2,
                                    content: "<i class=\"fa fa-pencil-square-o\"></i> Edit College Profile Programs & Majors"
                                }
                            }
                        }, {
                            url: "/campusBasics",
                            config: {
                                templateUrl: "/app/profileSettings/campusProfileBasics.html",
                                title: "Campus Profile",
                                caseInsensitiveMatch: true,
                                reloadOnSearch: false,
                                settings: {
                                    navOrder: 3,
                                    content: "<i class=\"fa fa-pencil-square-o\"></i> Edit College Profile Basics & Search"
                                }
                            }
                        }]
                }, {
                    config: {
                        title: "TAAP Administration",
                        settings: {
                            navOrder: 8,
                            content: "<i class=\"fa fa-cogs\"></i> TAAP Administration"
                        }
                    },
                    permission: ["canUpdateTaap"],
                    subNavs: [{
                        url: "/taapInformation",
                        config: {
                            templateUrl: "/app/taapAdministration/taapInformation.html",
                            title: "TAAP Administration",
                            caseInsensitiveMatch: true,
                            settings: {
                                navOrder: 1,
                                content: "<i class=\"fa fa-pencil-square-o\"></i> Edit TAAP Information"
                            }
                        },
                        permission: ["canUpdateTaap"]
                    }, {
                            url: "/taapMajors",
                            config: {
                                templateUrl: "/app/taapAdministration/taapMajors.html",
                                title: "TAAP Administration",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 2,
                                    content: "<i class=\"fa fa-pencil-square-o\"></i> Edit TAAP Majors"
                                }
                            },
                            permission: ["canUpdateTaap"]
                        }]
                }, {
                    config: {
                        section: "Reports",
                        settings: {
                            navOrder: 9,
                            content: "<i class=\"fa fa-book\"></i> Reports"
                        }
                    },
                    permission: ["canViewReports"],
                    subNavs: [{
                        url: "/reports/applications",
                        config: {
                            templateUrl: "/app/reports/applicationReports.html",
                            controller: "applicationReports",
                            controllerAs: "vm",
                            title: "Application Reports",
                            section: "Reports",
                            caseInsensitiveMatch: true,
                            settings: {
                                navOrder: 1,
                                content: "<i class=\"fa fa-file-text-o\"></i> Applications"
                            }
                        }
                    }, {
                            url: "/reports/applications/:reportKey",
                            config: {
                                templateUrl: "/app/reports/applicationReports.html",
                                controller: "applicationReports",
                                controllerAs: "vm",
                                title: "Application Reports",
                                section: "Reports",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/reports/transcripts",
                            config: {
                                templateUrl: "/app/reports/transcriptReports.html",
                                controller: "transcriptReports",
                                controllerAs: "vm",
                                title: "Transcript Reports",
                                section: "Reports",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 1,
                                    content: "<i class=\"fa fa-pencil-square-o\"></i> Transcripts"
                                }
                            }
                        }, {
                            url: "/reports/transcripts/:reportKey",
                            config: {
                                templateUrl: "/app/reports/transcriptReports.html",
                                controller: "transcriptReports",
                                controllerAs: "vm",
                                title: "Transcript Reports",
                                section: "Reports",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/reports/dynamic",
                            config: {
                                templateUrl: "/app/reports/dynamicReports.html",
                                title: "Dynamic Reports",
                                section: "Reports",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 1,
                                    content: "<i class=\"fa fa-database\"></i> Dynamic"
                                }
                            }
                        }]
                }, {
                    config: {
                        section: "Administration",
                        settings: {
                            navOrder: 10,
                            content: "<i class=\"fa fa-cogs\"></i> Administration"
                        }
                    },
                    permission: ["canEditApplications", "canViewUsers", "canEditStudentEnrichment"],
                    subNavs: [{
                        url: "/admin/organizations",
                        config: {
                            templateUrl: "/app/admin/organizations/organizations.html",
                            title: "Organizations",
                            section: "Administration",
                            caseInsensitiveMatch: true,
                            settings: {
                                navOrder: 1,
                                content: "<i class=\"fa fa-university\"></i> Organization"
                            }
                        },
                        permission: ["canEditApplications"]
                    }, {
                            url: "/admin/organizations/:organizationId",
                            config: {
                                templateUrl: "/app/admin/organizations/organizations.html",
                                title: "Edit Organization",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/admin/organizations/:organizationId/programs/:programId/:applications/:applicationId",
                            config: {
                                templateUrl: "/app/admin/applications/applicationdetail.html",
                                title: "Edit Application",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/admin/users",
                            config: {
                                templateUrl: "/app/admin/users/users.html",
                                title: "Users",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 2,
                                    content: "<i class=\"fa fa-group\"></i> Users"
                                }
                            },
                            permission: ["canViewUsers"]
                        }, {
                            url: "/admin/users/new",
                            config: {
                                templateUrl: "/app/admin/users/userdetail.html",
                                title: "New User",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/admin/users/:tenant/:email",
                            config: {
                                templateUrl: "/app/admin/users/userdetail.html",
                                title: "User Detail",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/admin/reports",
                            config: {
                                templateUrl: "/app/admin/reports/adminReports.html",
                                title: "Reports",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 3,
                                    content: "<i class=\"fa fa-database\"></i> Reports"
                                }
                            },
                            permission: ["systemAdmin"]
                        }, {
                            url: "/admin/highSchools",
                            config: {
                                templateUrl: "/app/admin/highSchools/highSchools.html",
                                title: "High Schools",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 4,
                                    content: "<i class=\"fa fa-bus\"></i> High Schools"
                                }
                            },
                            permission: ["systemAdmin"]
                        }, {
                            url: "/admin/highSchools/:id",
                            config: {
                                templateUrl: "/app/admin/highschools/highSchoolDetails.html",
                                title: "High School Details",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {}
                            },
                            permission: ["systemAdmin"]
                        }, {
                            url: "/admin/colleges",
                            config: {
                                templateUrl: "/app/admin/colleges/colleges.html",
                                title: "Colleges",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 5,
                                    content: "<i class=\"fa fa-graduation-cap\"></i> Colleges"
                                }
                            },
                            permission: ["systemAdmin"]
                        }, {
                            url: "/admin/colleges/:id",
                            config: {
                                templateUrl: "/app/admin/colleges/collegeDetails.html",
                                title: "College Details",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {}
                            },
                            permission: ["systemAdmin"]
                        }, {
                            url: "/organizations/:organizationId/profileSettings",
                            config: {
                                templateUrl: "/app/profileSettings/profileSettings.html",
                                title: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/organizations/:organizationId/programsMajors",
                            config: {
                                templateUrl: "/app/campusProgramsMajors/campusProgramsMajors.html",
                                title: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/organizations/:organizationId/campusBasics",
                            config: {
                                templateUrl: "/app/profileSettings/campusProfileBasics.html",
                                title: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {}
                            }
                        }, {
                            url: "/admin/uploadMajors",
                            config: {
                                templateUrl: "/app/campusProgramsMajors/uploadProgramsMajorsFile.html",
                                title: "Upload Programs Majors File",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 6,
                                    content: "<i class=\"fa fa-database\"></i> Majors"
                                }
                            },
                            permission: ["systemAdmin"]
                        }, {
                            url: "/admin/settings",
                            config: {
                                templateUrl: "/app/admin/settings/settings.html",
                                title: "Admin Settings",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 6,
                                    content: "<i class=\"fa fa-cog\"></i> Settings"
                                }
                            },
                            permission: ["systemAdmin"]
                        }, {
                            url: "/admin/studentEnrichment",
                            config: {
                                templateUrl: "/app/studentEnrichment/studentEnrichment.html",
                                title: "Enrichment Programs",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 6,
                                    content: "<i class=\"fa fa-cog\"></i> SEP"
                                }
                            },
                            permission: ["canEditStudentEnrichment"]
                        }, {
                            url: "/admin/uploadCountyData",
                            config: {
                                templateUrl: "/app/admin/counties/uploadCountyTier.html",
                                title: "County Tiers",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder: 6,
                                    content: "<i class=\"fa fa-cog\"></i> Counties"
                                }
                            },
                            permission: ["systemAdmin"]
                        }, {
                            url: "/admin/importprospects",
                            config: {
                                templateUrl: "/app/admin/prospects/importprospects.html",
                                title: "Import Prospects",
                                section: "Administration",
                                caseInsensitiveMatch: true,
                                settings: {
                                    navOrder:7,
                                    content: "<i class=\"fa fa-users\"></i> Import Prospects"
                                }
                            },
                            permission: ["systemAdmin"]
                        }]
                }
            ];
        }
    }

    var advantRoutes = new RouteConfig();

    // Collect the routes
    angular.module("app").constant("routes", advantRoutes.routes);

    // Configure the routes and route resolvers
    function routeConfigurator($routeProvider: any, routes: any) {
        function setRoute(r: IRoute) {

            if (r.url) {
                $routeProvider.when(r.url, r.config);
            }
            if (r.subNavs) {
                r.subNavs.forEach(sn => setRoute(sn));
            }
            return $routeProvider;
        }

        routes.forEach(r => setRoute(r));

        $routeProvider.otherwise({ redirectTo: "/" });
    }

    angular.module("app").config(["$routeProvider", "routes", routeConfigurator]);
}