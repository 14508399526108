/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict"

    export interface ICrossRouteScope extends ng.IScope {
        vm: any;
    }

    export interface ICrossRouteController {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        exportProjectSchedules: Array<any>;
        schedules: any;
        startDate?: Date;
        endDate?: Date;
        startTime?: Date;
        endTime?: Date;
        id: string;
        minDate: any;
        minTime: any;
        maxDate: any;
        maxTime: any;
        canExportApplicants: boolean;
        canExportProspects: boolean;
        canExportTranscripts: boolean;
    }

    export class CrossRouteController implements ICrossRouteController {
        static controllerId: string = "crossRoute";
        static $inject: any = ["$scope", "$location", "Restangular", "common", "config", "$modal", "Upload"];
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        error: (msg, data?, show?) => void;
        schedules: any;
        exportProjectSchedules: Array<any>;
        startDate: Date;
        endDate: Date;
        startTime: Date;
        endTime: Date;
        id: string;
        minDate: any;
        minTime: any;
        maxDate: any;
        maxTime: any;
        canExportApplicants: boolean;
        canExportProspects: boolean;
        canExportTranscripts: boolean;
        clientConnected: boolean;
        gettingClientStatus: boolean;
        selectedFile: any;
        firstLineHeader: boolean;

        constructor(private $scope: ICrossRouteScope,
            private $location: ng.ILocationService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private $modal: ng.ui.bootstrap.IModalService,
            private Upload: angular.angularFileUpload.IUploadService) {

            this.log = common.logger.getLogFn(CrossRouteController.controllerId);
            this.logError = this.common.logger.getLogFn(CrossRouteController.controllerId, "error");
            this.logSuccess = this.common.logger.getLogFn(CrossRouteController.controllerId, "success");
            this.error = common.logger.logError;

            common.$on(config.events.applicationChanged,(event) => {
                this.activate();
            });

            this.canExportApplicants = this.common.getUser().canExportApplicants;
            this.canExportProspects = this.common.getUser().canExportProspects;
            this.canExportTranscripts = this.common.getUser().canExportTranscripts;

            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([], CrossRouteController.controllerId)
                .then(result=> {
                self.log("Activated CrossRoute View");
                var startDate = new Date("1/1/2015 5:00:00 PM");
                var nextRunDate = new Date("2/20/2015 5:00:00 PM");
                self.schedules = [{
                    name: "Default", startDate: startDate, interval: 1440, lastRun: null, nextRun: nextRunDate
                }];
                this.getSchedules();
                this.getClientStatus();
            });
        }

        openScheduler = () => {
            var modalInstance = this.$modal.open({
                templateUrl: '/app/crossRoute/addSchedule.html',
                controller: 'addScheduleController',
                resolve: {}
            });
        };

        getSchedules = () => {
            this.Restangular.one("crossRoute/").one(sessionStorage.getItem("activeApplication")).one("exportProjectSchedules").getList()
                .then((result) => {
                this.exportProjectSchedules = result;
            },(reason) => {
                    this.log(reason.statusText);
                });

        };

        getClientStatus = () => {
            this.gettingClientStatus = true;
            this.Restangular.one("crossroute/connection").get()
                .then((result) => {
                    this.clientConnected = result.connected;
                }, (reason) => {
                    console.log(reason);
                }).finally(() => {
                    this.gettingClientStatus = false;
                });
        }

        forceUpdate = () => {
            this.Restangular.all("/crossRoute/forceUpdate").customPOST()
                .then((result) => {
                this.log("An update was sent to the CrossRoute Service.", result, true);
            },(reason) => {
                    this.error("An error occurred while updating the CrossRoute Service.", reason, true);
                });
        };

        getTime = (hour, minute) => {
            var mm = minute > 9 ? "" + minute : "0" + minute;
            var hh = hour > 12 ? hour - 12 : hour == 0 ? 12 : hour;
            var meridian = hour >= 12 ? "PM" : "AM"
            return hh + ":" + mm + " " + meridian;
        };

        getDayOfMonth = (stringDate: string) => {
            var suffixes = ["th", "st", "nd", "rd"];
            var date = new Date(stringDate);
            var day = date.getDate();
            var relevantDigits = (day < 30) ? day % 20 : day % 30;
            var suffix = (relevantDigits <= 3) ? suffixes[relevantDigits] : suffixes[0];
            return day + suffix;
        };

        editSchedule = (exportProjectScheduleId, scheduleKey) => {
            this.$location.path("/crossroute/" + exportProjectScheduleId + "/key/" + scheduleKey);
        };

        runNow = (ExportProjectSchedulesId, scheduleKey, exportProjectType, id, startDate, endDate, exportProjectId) => {
            
            if (this.selectedFile != null) {
                var q = {
                    newOnly: false,
                    connectionId: "",
                    startDate: null,
                    endDate: null,
                    filter: null,
                    firstLineHeader: this.firstLineHeader,
                    downloadWithCrossRoute: true,
                    exportProjectSchedulesId: ExportProjectSchedulesId,
                    triggerName: scheduleKey
                };

                this.Upload.upload(<any>{
                    url: this.config.apiHost + "/" + this.common.getUser().activeApplication + "/" + exportProjectId + "/exportbystudents",
                    params: q,
                    method: "POST",
                    file: this.selectedFile
                }).then(response => {
                    this.logSuccess("Your export has been queued. You will be notified when it is ready to download.", null, true);
                }, reason => {
                    //this.generatingFile = false;
                    this.logError(reason.data.message, reason.data, true)
                });
            }
            else {
                var model = {
                    id: id,
                    startDate: startDate ? moment(startDate).toISOString() : null,
                    endDate: endDate ? moment(endDate).toISOString() : null,
                    exportProjectType: exportProjectType,

                };

                this.Restangular.one("crossRoute").one(ExportProjectSchedulesId).one("key", scheduleKey).all("run").post(model)
                    .then((result) => {
                        this.log("Command sent to CrossRoute Service", "Command sent to CrossRoute Service", true);
                    }, (reason) => {
                        this.logError(reason.data.message, reason.data, true);
                    });
            }

        };

        clearRunNowParams = () => {
            this.startDate = null;
            this.endDate = null;
            this.id = null;
            this.selectedFile = null;
        };

        getExportProjectType = (exportProjectId: string): string => {
            var parts = exportProjectId.split("/");
            var prefix = parts[0].replace("ExportProjects", "");

            return prefix;
        };

        setMinAndMax = () => {
            if (this.startDate) {
                this.minDate = moment.utc(this.startDate).hours(0).minutes(0).seconds(0);


                if (moment(this.startDate).isSame(this.endDate, "days")) {
                    var utcStartDate = moment.utc(this.startDate);
                    this.minTime = moment.utc(new Date(1970, 1, 1, utcStartDate.hours(), utcStartDate.minutes(), utcStartDate.seconds()));
                }
                else {
                    this.minTime = null;
                }
            }
            else {
                this.minDate = null;
                this.minTime = null;
            }

            if (this.endDate) {
                this.maxDate = moment.utc(this.endDate).add("days", 1).hours(0).minutes(0).seconds(0);

                if (moment(this.startDate).isSame(this.endDate, "days")) {
                    var utcEndDate = moment.utc(this.endDate);
                    this.maxTime = moment.utc(new Date(1970, 1, 1, utcEndDate.hours(), utcEndDate.minutes(), utcEndDate.seconds()));
                }
                else {
                    this.maxTime = null;
                }

            }
            else {
                this.maxDate = null;
                this.maxTime = null;
            }
           
        };

        fileSelected(file: any) {
            if (file && file.length > 0) {
                this.selectedFile = file;
            }
        }
     
    }

    angular.module("app").controller(CrossRouteController.controllerId, CrossRouteController);
} 