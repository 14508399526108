/// <reference path="../../config.exceptionhandler.ts" />
namespace Advant.Crossroads {
    export interface IResetPasswordDialogScope extends angular.IScope {
        passwordResetInfo: IResetPasswordViewModel;
        yes: () => void;
        no: () => void;
    }

    export interface IResetPasswordDialog {

    }

    export class ResetPasswordDialog implements IResetPasswordDialog {
        static controllerId: string = "resetPasswordDialog";
        static $inject: any = ["$scope", "$uibModalInstance", "data"];

        constructor(private $scope: IResetPasswordDialogScope, private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance, private data: IResetPasswordViewModel) {
            $scope.yes = () => this.yes();
            $scope.no = () => this.no();
            $scope.passwordResetInfo = data;
        }

        yes() {
            this.$uibModalInstance.close("reset");
        }

        no() {
            this.$uibModalInstance.dismiss("cancel");
        }
    }

    // Update the app1 variable name to be that of your module variable
    angular.module("app").controller(ResetPasswordDialog.controllerId, ResetPasswordDialog);
}
