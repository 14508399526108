namespace Advant.Crossroads {

    export interface IApplicationDetailRouteParams extends angular.route.IRouteParamsService {
        organizationId: any;
        programId: any;
        applicationId: any;
    }

    export interface IApplicationDetailScope extends angular.IScope {
        vm: AdminApplicationDetailController;
        appDefForm: angular.IFormController;
    }

    export interface IApplicationDetailController {
        applicationDefinition: IApplicationDefinition;
        originalApplicationDefinition: IApplicationDefinition;
        acceptedPayments: IAcceptedPayments;
        processors: Array<IProcessor>;
        currentSection: ISection;
        currentGroup: IGroup;
        currentField: IField;
        isSaving: boolean;
        treeOptions: any;
        save: () => angular.IPromise<void>;
        selectSection: (section: ISection) => void;
        selectGroup: (group: IGroup) => void;
        selectField: (section: IField, parentGroup: IGroup) => void;
        parentGroup: IGroup;
        addGroup: ($event, section: ISection) => void;
        addField: ($event, group: IGroup, type: string) => void;
        createChoiceList: () => void;
        termsLoaded: boolean;
        selectedTerm: ITerm;
        logoPath: string;
        excLogoPath: string;
        rules: Array<IRuleSet>;
        applicationDefinitionId: string;
        majorLists: Array<IMajorList>;
        applicationTasks: Array<IApplicationTask>;
        selectedTask: IApplicationTask;
        tabs: any;
        inputFields: Array<IInputField>;
        currentProcessor: IProcessor;
        isPaymentSelected: boolean;

    }

    export interface IAcceptedPayments {
        noPayment: boolean;
        check: boolean;
        creditCard: boolean;
        feeWaiver: boolean;
        payLater: boolean;
    }

    export class AdminApplicationDetailController implements IApplicationDetailController {
        static controllerId: string = "adminApplicationDetailController";
        static $inject: any = ["$scope", "$routeParams", "$location", "$modal", "Upload", "dialogs", "Restangular", "common", "config", "helper", "$uibModal"];
        applicationDefinition: IApplicationDefinition;
        originalApplicationDefinition: IApplicationDefinition;
        acceptedPayments: IAcceptedPayments;
        originalAcceptedPayments: IAcceptedPayments;
        processors: Array<IProcessor>;
        choiceLists: Array<IChoiceList>;
        currentSection: ISection;
        currentGroup: IGroup;
        currentField: IField;
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        isSaving: boolean;
        treeOptions: any;
        groupOptions: any;
        fieldOptions: any;
        termsLoaded: boolean;
        selectedTerm: ITerm;
        termOpenDateCalOpened: boolean;
        termCloseDateCalOpened: boolean;
        logoPath: string;
        excLogoPath: string;
        styleSheet: string;
        fieldCompleter: any;
        langTools: any;
        rules: Array<IRuleSet>;
        applicationDefinitionId: string;
        majorLists: Array<IMajorList>;
        originalApplicationTasks: Array<IApplicationTask>;
        applicationTasks: Array<IApplicationTask>;
        selectedTask: IApplicationTask;
        taskEditor: AceAjax.Editor;
        taskSortOptions: any;
        viewLink: string;
        parentGroup: IGroup;
        tabs: any;
        inputFields: Array<IInputField>;
        currentProcessor: IProcessor;
        tinymceOptions: any;
        isPaymentSelected: boolean = false;
        creditCards: string[];

        constructor(private $scope: IApplicationDetailScope,
            private $routeParams: IApplicationDetailRouteParams,
            private $location: angular.ILocationService,
            private $modal,
            private Upload: angular.angularFileUpload.IUploadService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private helper: IHelper,
            private $uibModal: ng.ui.bootstrap.IModalService) {
            var self = this;

            if ($location.state()) {
                this.tabs = $location.state();
            } else {
                this.tabs = {
                    settingTabActive: true,
                    termTabActive: false,
                    formTabActive: false,
                    styleTabActive: false,
                    tasksTabActive: false,
                    messageTabActive: false,
                    activeTab: "Settings"
                };
            }

            this.$scope.$on("$routeUpdate", (event) => {
                if (this.$location.state()) {
                    this.tabs = $location.state();
                }
            });

            this.log = common.logger.getLogFn(AdminApplicationDetailController.controllerId);
            this.logError = this.common.logger.getLogFn(AdminApplicationDetailController.controllerId, "error");
            this.logSuccess = this.common.logger.getLogFn(AdminApplicationDetailController.controllerId, "success");
            this.isSaving = false;
            this.termsLoaded = false;
            this.termOpenDateCalOpened = false;
            this.termCloseDateCalOpened = false;
            this.creditCards = ['American Express', 'Discover', 'Mastercard', 'Visa'];
            this.setAppId();

            this.acceptedPayments = {
                noPayment: false,
                check: false,
                creditCard: false,
                feeWaiver: false,
                payLater: false
            };

            this.originalAcceptedPayments = {
                noPayment: false,
                check: false,
                creditCard: false,
                feeWaiver: false,
                payLater: false
            };

            this.taskSortOptions = {
                update: this.taskSorted
            };
            this.fieldCompleter = {
                getCompletions: (editor, session, pos, prefix, callback) => {
                    if (prefix.length === 0 || self.currentGroup === null || session.$modeId === "ace/mode/css" || self.selectedTask != null) {
                        callback(null, []);
                        return;
                    }
                    callback(null, self.getAutoCompleteList(prefix));

                }
            };

            this.langTools = ace.require("ace/ext/language_tools");
            this.langTools.addCompleter(this.fieldCompleter);

            this.configSortableList();

            this.tinymceOptions = {
                plugins: [
                    "autolink lists moxiemanager link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table contextmenu paste",
                    "template textcolor colorpicker textpattern "
                ],
                toolbar: "undo redo | styleselect | bold italic | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify |" +
                    " bullist numlist outdent indent | link image | preview | forecolor backcolor ",
                menubar: "edit insert view format table tools ",
                height: 300,
                external_plugins: {
                    "moxiemanager": "/scripts/moxiemanager/plugin.js",
                    "appfieldselector": "/scripts/appfieldselector/plugin.js"
                },
                moxiemanager_title: "CrossRoads File Manager",
                moxiemanager_fullscreen: true,
                relative_urls: false
            };
            this.activate();
        }

        private getAutoCompleteList(prefix: string) {
            var matchingFields = [];
            if (this.currentGroup) {
                angular.forEach(this.currentGroup.fields, (v: IField, k: any) => {
                    var test = v.key.toLocaleLowerCase().indexOf(prefix.toLocaleLowerCase());
                    if (test > -1) {
                        matchingFields.push({ name: v.key, value: "[[" + v.key + "]]", score: 100, meta: "field" });
                    }
                });
            }
            return matchingFields;
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getRequestedApplication().then(() => {
                return this.common.$q.all([this.initLookups(), this.getFields(), this.getRules(), this.getMajorLists(), this.getTasks()]);
            })], AdminApplicationDetailController.controllerId)
                .then(result => {
                    self.selectCurrentProcessor();
                    self.log("Activated Admin Application Details View");
                    this.paymentSelected();
                });
        }

        private setAppId = () => {
            if (this.$routeParams.applicationId) {
                this.applicationDefinitionId = "organizations/"
                    + this.$routeParams.organizationId
                    + "/programs/" + this.$routeParams.programId
                    + "/applications/" + this.$routeParams.applicationId;
            } else {
                this.applicationDefinitionId = this.common.getUser().activeApplication;

            }

            this.$scope.$on(this.config.events.applicationChanged, (event) => {
                this.applicationDefinitionId = this.common.getUser().activeApplication;
                this.currentField = null;
                this.currentGroup = null;
                this.currentSection = null;
                this.$scope.appDefForm.$setPristine();
                this.activate();
            });
        };

        //  Sets the configuration options for the sortable list used in the application form area
        private configSortableList = () => {
            var previousDestIndex;
            var count;

            this.treeOptions = {
                accept: (sourceNodeScope, destNodesScope, destIndex) => {
                    var destType = destNodesScope.$element.attr("type");
                    return this.helper.isType(sourceNodeScope.$modelValue.type, destType);
                },
                dropped: (event) => {
                    // If anything in the tree changes make the form dirty
                    this.$scope.appDefForm.$setDirty();
                },
                dragMove: (event) => {
                    if (event.dest.index === previousDestIndex) {
                        count++;
                        if (count > 20) {
                            event.dest.nodesScope.$nodeScope.collapsed = false;
                        }
                    }
                    if (event.dest.nodesScope.$nodeScope && event.dest.nodesScope.$nodeScope.collapsed) {
                        if (previousDestIndex !== event.dest.index) {
                            count = 0;
                        }
                        previousDestIndex = event.dest.index;
                    }
                }
            };
        };

        aceStyleEditorLoaded = (aceEditor: AceAjax.Editor) => {
            aceEditor.setShowPrintMargin(false);
            aceEditor.getSession().setMode("ace/mode/css");
            (<any>aceEditor).setOptions({
                enableSnippets: true,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true
            });
        };

        aceGroupEditorLoaded = (aceEditor: AceAjax.Editor) => {
            aceEditor.setShowPrintMargin(false);
            aceEditor.setTheme("ace/theme/chrome");
            aceEditor.getSession().setMode("ace/mode/html");
            (<any>aceEditor).setOptions({
                enableSnippets: true,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true
            });

            (<any>aceEditor).setAutoScrollEditorIntoView(true);
            (<any>aceEditor).setOption("maxLines", 50);
            (<any>aceEditor).setOption("minLines", 5);
        };

        aceTaskDescriptionLoaded = (aceEditor: AceAjax.Editor) => {
            aceEditor.setShowPrintMargin(false);
            aceEditor.setTheme("ace/theme/chrome");
            aceEditor.getSession().setMode("ace/mode/html");
            (<any>aceEditor).setOptions({
                enableSnippets: true,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true
            });

            (<any>aceEditor).setAutoScrollEditorIntoView(true);
            (<any>aceEditor).setOption("maxLines", 30);
            (<any>aceEditor).setOption("minLines", 3);
            this.taskEditor = aceEditor;
        };

        addFee = () => {
            if (!this.applicationDefinition.dynamicFees) {
                this.applicationDefinition.dynamicFees = [];
            }

            this.applicationDefinition.dynamicFees.push({
                ruleId: null,
                fee: null
            });

        };

        removeFee = (index: number) => {
            this.applicationDefinition.dynamicFees.splice(index, 1);
        };

        addField($event: angular.IAngularEvent, group: IGroup, type: string) {
            var field;
            switch (type) {
                case "text":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.TextField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        inputClass: "form-control",
                        promptClass: "control-label",
                        type: {
                            name: "TextField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.TextField",
                            baseType: {
                                name: "InputField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                baseType: {
                                    name: "Field",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                    baseType: {
                                        name: "Object",
                                        namespace: "System",
                                        fullName: "System.Object",
                                        baseType: null
                                    }
                                }
                            }
                        }
                    };
                    break;
                case "textArea":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.TextAreaField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        inputClass: "form-control",
                        promptClass: "control-label",
                        type: {
                            name: "TextAreaField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.TextAreaField",
                            baseType: {
                                name: "TextField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.TextField",
                                baseType: {
                                    name: "InputField",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                    baseType: {
                                        name: "Field",
                                        namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                        fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                        baseType: {
                                            name: "Object",
                                            namespace: "System",
                                            fullName: "System.Object",
                                            baseType: null
                                        }
                                    }
                                }
                            }
                        }
                    };
                    break;
                case "date":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.DateField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        inputClass: "form-control",
                        promptClass: "control-label",
                        type: {
                            name: "DateField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.DateField",
                            baseType: {
                                name: "TextField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.TextField",
                                baseType: {
                                    name: "InputField",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                    baseType: {
                                        name: "Field",
                                        namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                        fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                        baseType: {
                                            name: "Object",
                                            namespace: "System",
                                            fullName: "System.Object",
                                            baseType: null
                                        }
                                    }
                                }
                            }
                        }
                    };
                    break;
                case "list":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.SelectField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        promptClass: "control-label",
                        inputClass: "",
                        size: 1,
                        useSmartSearch: true,
                        type: {
                            name: "SelectField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.SelectField",
                            baseType: {
                                name: "ListField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.ListField",
                                baseType: {
                                    name: "InputField",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                    baseType: {
                                        name: "Field",
                                        namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                        fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                        baseType: {
                                            name: "Object",
                                            namespace: "System",
                                            fullName: "System.Object",
                                            baseType: null
                                        }
                                    }
                                }
                            }
                        }
                    };
                    field.listType = "SelectList";
                    break;
                case "radio":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.OrientableField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        inputClass: "form-control",
                        promptClass: "control-label",
                        type: {
                            name: "OrientableField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.OrientableField",
                            baseType: {
                                name: "ListField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.ListField",
                                baseType: {
                                    name: "InputField",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                    baseType: {
                                        name: "Field",
                                        namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                        fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                        baseType: {
                                            name: "Object",
                                            namespace: "System",
                                            fullName: "System.Object",
                                            baseType: null
                                        }
                                    }
                                }
                            }
                        }
                    };
                    field.listType = "RadioButtons";
                    break;
                case "checkboxlist":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.OrientableField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        inputClass: "form-control",
                        promptClass: "control-label",
                        type: {
                            name: "OrientableField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.OrientableField",
                            baseType: {
                                name: "ListField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.ListField",
                                baseType: {
                                    name: "InputField",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                    baseType: {
                                        name: "Field",
                                        namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                        fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                        baseType: {
                                            name: "Object",
                                            namespace: "System",
                                            fullName: "System.Object",
                                            baseType: null
                                        }
                                    }
                                }
                            }
                        }
                    };
                    field.listType = "CheckBoxList";
                    break;
                case "major":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.MajorField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        promptClass: "control-label",
                        inputClass: "",
                        majorListIds: [],
                        type: {
                            name: "MajorField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.MajorField",
                            baseType: {
                                name: "InputField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                baseType: {
                                    name: "Field",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                    baseType: {
                                        name: "Object",
                                        namespace: "System",
                                        fullName: "System.Object",
                                        baseType: null
                                    }
                                }
                            }
                        }
                    };
                    field.listType = "MajorField";
                    break;
                case "term":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.TermField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        inputClass: "",
                        promptClass: "control-label",
                        majorListIds: [],
                        type: {
                            name: "TermField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.TermField",
                            baseType: {
                                name: "InputField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                baseType: {
                                    name: "Field",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                    baseType: {
                                        name: "Object",
                                        namespace: "System",
                                        fullName: "System.Object",
                                        baseType: null
                                    }
                                }
                            }
                        }
                    };
                    field.listType = "TermField";
                    break;
                case "document":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.DocumentUploadField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        inputClass: "btn btn-default",
                        promptClass: "control-label",
                        actionText: "Upload Document",
                        viewText: "View Document",
                        clearText: "Remove Document",
                        acceptedMimeTypes: "application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        maxFileSizeInMB: 1,
                        type: {
                            name: "DocumentUploadField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.DocumentUploadField",
                            baseType: {
                                name: "InputField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                baseType: {
                                    name: "Field",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                    baseType: {
                                        name: "Object",
                                        namespace: "System",
                                        fullName: "System.Object",
                                        baseType: null
                                    }
                                }
                            }
                        }
                    };
                    break;
                case "highSchool":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.HighSchoolSelectorField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        inputClass: "",
                        promptClass: "control-label",
                        type: {
                            name: "HighSchoolSelectorField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.HighSchoolSelectorField",
                            baseType: {
                                name: "InputField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                baseType: {
                                    name: "Field",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                    baseType: {
                                        name: "Object",
                                        namespace: "System",
                                        fullName: "System.Object",
                                        baseType: null
                                    }
                                }
                            }
                        }
                    };
                    break;
                case "college":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.CollegeSelectorField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        inputClass: "",
                        promptClass: "control-label",
                        type: {
                            name: "CollegeSelectorField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.CollegeSelectorField",
                            baseType: {
                                name: "InputField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                baseType: {
                                    name: "Field",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                    baseType: {
                                        name: "Object",
                                        namespace: "System",
                                        fullName: "System.Object",
                                        baseType: null
                                    }
                                }
                            }
                        }
                    };
                    break;
                case "ccp":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.CcpEligibleField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        inputClass: "",
                        promptClass: "control-label",
                        type: {
                            name: "CcpEligibleField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.CcpEligibleField",
                            baseType: {
                                name: "InputField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                baseType: {
                                    name: "Field",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                    baseType: {
                                        name: "Object",
                                        namespace: "System",
                                        fullName: "System.Object",
                                        baseType: null
                                    }
                                }
                            }
                        }
                    };
                    break;
                case "ccResidency":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.CommunityCollegeResidencyField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        inputClass: "",
                        promptClass: "control-label",
                        northCarolinaResidentField: null,
                        livedInNCContinuouslyField: null,
                        stateLivedContinuouslyField: null,
                        dateOfBirthField: null,
                        citizenshipField: null,
                        militaryStatusField: null,
                        dateLivedInNCField: null,
                        highSchoolStateField: null,
                        driverLicenseStateField: null,
                        driversLicenseNumberField: null,
                        driversLicenseDateIssuedField: null,
                        college1EnrolledNameField: null,
                        college1EnrolledDateToField: null,
                        college2EnrolledNameField: null,
                        college2EnrolledDateToField: null,
                        college3EnrolledNameField: null,
                        college3EnrolledDateToField: null,
                        college4EnrolledNameField: null,
                        college4EnrolledDateToField: null,
                        college5EnrolledNameField: null,
                        college5EnrolledDateToField: null,
                        college6EnrolledNameField: null,
                        college6EnrolledDateToField: null,
                        type: {
                            name: "CommunityCollegeResidencyField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.CommunityCollegeResidencyField",
                            baseType: {
                                name: "InputField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                baseType: {
                                    name: "Field",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                    baseType: {
                                        name: "Object",
                                        namespace: "System",
                                        fullName: "System.Object",
                                        baseType: null
                                    }
                                }
                            }
                        }
                    };
                    break;
                case "label":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.LabelField, Advant.Crossroads.Core",
                        type: {
                            name: "LabelField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.LabelField",
                            baseType: {
                                name: "Field",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                baseType: {
                                    name: "Object",
                                    namespace: "System",
                                    fullName: "System.Object",
                                    baseType: null
                                }
                            }
                        }
                    };
                    break;
                case "copy":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.CopyField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "copy-wrapper",
                        cssClass: "btn btn-primary",
                        dynamicCopyFields: [],
                        type: {
                            name: "CopyField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.CopyField",
                            baseType: {
                                name: "Field",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                baseType: {
                                    name: "Object",
                                    namespace: "System",
                                    fullName: "System.Object",
                                    baseType: null
                                }

                            }
                        }
                    };
                    break;
                case "rcn":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.RcnField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        inputClass: "form-control",
                        promptClass: "control-label",
                        type: {
                            name: "RcnField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.RcnField",
                            baseType: {
                                name: "TextField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.TextField",
                                baseType: {
                                    name: "InputField",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                    baseType: {
                                        name: "Field",
                                        namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                        fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                        baseType: {
                                            name: "Object",
                                            namespace: "System",
                                            fullName: "System.Object",
                                            baseType: null
                                        }
                                    }
                                }
                            }
                        }
                    };
                    break;
                case "campusmessage":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.MessageFromCampusField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "row",
                        title: "Campus Message",
                        type: {
                            name: "MessageFromCampusField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.MessageFromCampusField",
                            baseType: {
                                name: "Field",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                baseType: {
                                    name: "Object",
                                    namespace: "System",
                                    fullName: "System.Object",
                                    baseType: null
                                }
                            }
                        }
                    };
                    break;
                case "rlcpsponsoringhighschool":
                    field = {
                        $type: "Advant.Crossroads.Core.DynamicForm.Fields.RLCPSponsoringSchoolField, Advant.Crossroads.Core",
                        fieldWrapper: "div",
                        fieldWrapperClass: "form-group",
                        inputClass: "",
                        promptClass: "control-label",
                        districtFieldKey: "rlcpDistrict",
                        highSchoolFieldKey: "rlcpHighSchool",
                        type: {
                            name: "RLCPSponsoringSchoolField",
                            namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                            fullName: "Advant.Crossroads.Core.DynamicForm.Fields.RLCPSponsoringSchoolField",
                            baseType: {
                                name: "InputField",
                                namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                fullName: "Advant.Crossroads.Core.DynamicForm.Fields.InputField",
                                baseType: {
                                    name: "Field",
                                    namespace: "Advant.Crossroads.Core.DynamicForm.Fields",
                                    fullName: "Advant.Crossroads.Core.DynamicForm.Fields.Field",
                                    baseType: {
                                        name: "Object",
                                        namespace: "System",
                                        fullName: "System.Object",
                                        baseType: null
                                    }
                                }
                            }
                        }
                    };
                    break;
            }
            field.isValidDefinition = true;
            group.fields.push(field);
            field.displayOrder = group.fields.length + 1;
            this.$scope.appDefForm.$setDirty();
            this.selectField(field, group);
            $event.preventDefault();
            $event.stopPropagation();
        }

        addCopyField = (currentField: ICopyField) => {
            currentField.dynamicCopyFields.push({ copyFromKey: null, copyToKey: null });
            this.$scope.appDefForm.$setDirty();
        };

        addGroup($event: angular.IAngularEvent, section: ISection) {
            var group: IGroup = {
                name: "",
                text: "",
                order: section.groups.length + 1,
                defaultExpanded: true,
                validClass: "fa fa-check-circle",
                toggleClass: "fa fa-plus-square",
                expandedToggleClass: "fa fa-minus-square",
                type: {
                    name: "Group",
                    nameSpace: "Advant.Crossroads.Core.DynamicForm",
                    fullName: "Advant.Crossroads.Core.DynamicForm.Group"
                },
                displayRule: null,
                fields: [],
                hasError: false,
                invalid: false
            };
            section.groups.push(group);
            //group.order = 
            this.$scope.appDefForm.$setDirty();
            this.selectGroup(group);
            $event.preventDefault();
            $event.stopPropagation();
        }

        addSection($event: angular.IAngularEvent) {
            var section: ISection = {
                name: "",
                text: "",
                textClass: "",
                type: {
                    name: "Section",
                    nameSpace: "Advant.Crossroads.Core.DynamicForm",
                    fullName: "Advant.Crossroads.Core.DynamicForm.Section"
                },
                isValidClass: "fa fa-check-circle",
                default: false,
                step: this.applicationDefinition.form.sections.length + 1,
                displayRule: null,
                groups: [],
                hasError: false,
                invalid: false
            };
            this.applicationDefinition.form.sections.push(section);
            this.$scope.appDefForm.$setDirty();
            this.selectSection(section);
            $event.preventDefault();
            $event.stopPropagation();
        }


        addTask($event: angular.IAngularEvent) {
            var empty: any = {};
            var newTask = empty;
            newTask.hasLocalFileAttachment = false;
            newTask.order = this.applicationTasks.length + 1;
            newTask.userCanComplete = false;
            newTask.userCanUpload = false;
            newTask.uploadCompletesTask = false;
            newTask.applicationDefinitionId = this.applicationDefinitionId;
            this.applicationTasks.push(newTask);
            this.selectedTask = newTask;
            this.$scope.appDefForm.$setDirty();
            $event.preventDefault();
            $event.stopPropagation();
        }

        addTerm($event: angular.IAngularEvent) {
            var empty: any = {};
            var newTerm = empty;
            this.applicationDefinition.availableTerms.push(newTerm);
            this.selectedTerm = newTerm;
            this.$scope.appDefForm.$setDirty();
            $event.preventDefault();
            $event.stopPropagation();
        }

        clearDisplayRule = (displayType) => {
            if (!displayType.displayRule.ruleId) {
                delete (displayType.displayRule);
            }
        };

        checkDefaultTerm = () => {
            var hasDefault = false;
            angular.forEach(this.applicationDefinition.availableTerms, (term: ITerm) => {
                if (term.isDefault && term !== this.selectedTerm) {
                    hasDefault = true;
                }
            });

            return hasDefault;
        };

        convertAcceptedPayments() {
            this.acceptedPayments.noPayment = this.applicationDefinition.acceptedPayments.indexOf("NoPayment") !== -1;
            this.acceptedPayments.check = this.applicationDefinition.acceptedPayments.indexOf("Check") !== -1;
            this.acceptedPayments.creditCard = this.applicationDefinition.acceptedPayments.indexOf("CreditCard") !== -1;
            this.acceptedPayments.feeWaiver = this.applicationDefinition.acceptedPayments.indexOf("FeeWaiver") !== -1;
            this.acceptedPayments.payLater = this.applicationDefinition.acceptedPayments.indexOf("PayLater") !== -1;
        }

        convertAcceptedPaymentsToStringArray() {
            this.applicationDefinition.acceptedPayments = "";
            if (this.acceptedPayments.noPayment) {
                this.applicationDefinition.acceptedPayments = "NoPayment";
            }
            if (this.acceptedPayments.check) {
                if (this.applicationDefinition.acceptedPayments !== "") {
                    this.applicationDefinition.acceptedPayments += ",";
                }
                this.applicationDefinition.acceptedPayments += "Check";

            }
            if (this.acceptedPayments.creditCard) {
                if (this.applicationDefinition.acceptedPayments !== "") {
                    this.applicationDefinition.acceptedPayments += ",";
                }
                this.applicationDefinition.acceptedPayments += "CreditCard";

            }
            if (this.acceptedPayments.feeWaiver) {
                if (this.applicationDefinition.acceptedPayments !== "") {
                    this.applicationDefinition.acceptedPayments += ",";
                }
                this.applicationDefinition.acceptedPayments += "FeeWaiver";

            }
            if (this.acceptedPayments.payLater) {
                if (this.applicationDefinition.acceptedPayments !== "") {
                    this.applicationDefinition.acceptedPayments += ",";
                }
                this.applicationDefinition.acceptedPayments += "PayLater";

            }
        }

        editChoiceList() {
            var selectField: IListField = <IListField>this.currentField;

            var choice = _.find(this.choiceLists, (choiceList) => {
                if (choiceList.id === selectField.choiceListId) {
                    return true;
                }
                return false;
            });

            var choiceList = this.dialogs.create("/app/admin/applications/choiceLists.html", "choiceLists", choice);

            choiceList.result.then((result: IChoiceList) => {
                var restResult: any = result;
                restResult.put();
            });
        }

        exportForm = () => {
            // Refresh the rule list and choices list prior to exporting the data
            this.common.$q.all([this.initLookups(), this.getRules()]).then(() => {
                var exportFile: ApplicationFormFile = new ApplicationFormFile(
                    this.applicationDefinition.form,
                    this.choiceListsInUse(),
                    this.rulesInUse());

                var blob: Blob = new Blob([exportFile.toJson()], { type: "application/octet-stream" });
                saveAs(blob, this.applicationDefinition.name + ".json");
            });

        };

        choiceListsInUse = (): Array<IChoiceList> => {
            let returnChoiceLists: Array<IChoiceList> = [];
            let choiceListFound: boolean;
            _.forEach(this.choiceLists, (choiceList) => {
                choiceListFound = false;
                _.forEach(this.applicationDefinition.form.sections, (section) => {
                    if (choiceListFound) {
                        return false; // break the for loop
                    }
                    _.forEach(section.groups, (group) => {
                        if (choiceListFound) {
                            return false;
                        }
                        _.forEach(group.fields, (field) => {
                            if (this.helper.isType(field.type, "ListField") && (<IListField>field).choiceListId === choiceList.id) {
                                if (returnChoiceLists.indexOf(choiceList) === -1) {
                                    returnChoiceLists.push(choiceList);
                                }
                                return false;
                            }
                        });
                    });
                });
            });
            return returnChoiceLists
        };

        rulesInUse = (): Array<IRuleSet> => {
            let returnRules: Array<IRuleSet> = [];
            let ruleFound: boolean;
            _.forEach(this.rules, (rule) => {
                ruleFound = false;
                _.forEach(this.applicationDefinition.form.sections, (section) => {
                    if (ruleFound) {
                        return false;
                    }

                    if (section.displayRule && section.displayRule.ruleId === rule.id) {
                        if (returnRules.indexOf(rule) === -1) {
                            returnRules.push(rule);
                        }
                        return false; // break forEach iteration
                    }
                    _.forEach(section.groups, (group) => {
                        if (ruleFound) {
                            return false;
                        }

                        if (group.displayRule && group.displayRule.ruleId === rule.id) {
                            ruleFound = true;
                            if (returnRules.indexOf(rule) === -1) {
                                returnRules.push(rule);
                            }
                            return false;
                        }
                        _.forEach(group.fields, (field) => {
                            if (field.displayRule && field.displayRule.ruleId === rule.id) {
                                if (returnRules.indexOf(rule) === -1) {
                                    returnRules.push(rule);
                                }
                                ruleFound = true;
                                return false;
                            }
                            if (this.helper.isType(field.type, "InputField") && (<IInputField>field).ruleId === rule.id) {
                                if (returnRules.indexOf(rule) === -1) {
                                    returnRules.push(rule);
                                }
                                ruleFound = true;
                                return false;
                            }
                            if (this.helper.isType(field.type, "InputField") && (<IInputField>field).requiredIf && (<IInputField>field).requiredIf.id === rule.id) {
                                if (returnRules.indexOf(rule) === -1) {
                                    returnRules.push(rule);
                                }
                                ruleFound = true;
                                return false;
                            }
                        });
                    });
                });
            });
            return returnRules;
        };

        hasPermission = (permission: string): boolean => {
            var userInfo = this.common.getUser();
            if (userInfo) {
                return userInfo[permission] && userInfo[permission] === true;
            } else {
                return false;
            }
        };

        importForm = () => {
            // Refresh the rules and choice lists prior to importing from a file
            this.common.$q.all([this.initLookups(), this.getRules()]).then(() => {
                var currentFormFile = new ApplicationFormFile(
                    this.applicationDefinition.form,
                    this.choiceLists,
                    this.rules);

                var currentAppData: ICurrentAppData = {
                    currentFormFile: currentFormFile,
                    appDefId: this.applicationDefinitionId
                };

                var importDialog = this.$modal({
                    templateUrl: "/app/admin/applications/importForm.html",
                    backdrop: "static",
                    controller: ImportForm.controllerId,
                    locals: {
                        instance: {
                            confirm: (result: ApplicationFormFile) => {
                                this.applicationDefinition.form = result.form;
                                this.choiceLists = result.choiceLists;
                                this.rules = result.rules;

                                this.save();
                                importDialog.hide();
                            },
                            cancel: () => {
                                importDialog.hide();
                            }
                        },
                        data: currentAppData
                    }
                });
            });
        };



        createChoiceList() {
            var choiceList = this.dialogs.create("/app/admin/applications/choiceLists.html", "choiceLists");

            choiceList.result.then(dialogResult => {
                this.Restangular.all(this.applicationDefinitionId)
                    .all("choicelists").post(dialogResult).then((result) => {
                        this.choiceLists.push(result);
                        (<any>this.currentField).choiceListId = result.id;
                    });
            });
        }

        cancel() {
            this.applicationDefinition = <IApplicationDefinition>this.Restangular.restangularizeElement(null, angular.copy(this.originalApplicationDefinition), this.applicationDefinitionId);
            this.applicationTasks = angular.copy(this.originalApplicationTasks);
            this.acceptedPayments = angular.copy(this.originalAcceptedPayments);
            this.convertAcceptedPayments();
            this.$scope.appDefForm.$setPristine();
            this.currentGroup = null;
            this.currentSection = null;
            this.currentField = null;
            this.selectedTerm = null;
            this.selectedTask = null;
        }

        deleteSection(section: ISection) {
            var dlg = this.dialogs.confirm("Please Confirm",
                "Are you sure you want to delete this section and all of its groups and fields?");
            dlg.result.then((btn) => {
                this.Restangular.all("application").all(this.applicationDefinitionId).all("associatedrules").post(section)
                    .then((result: IRuleSet[]) => {
                        if (result.length > 0) {

                            this.openFieldInUseModal(result, "section", _.flatMap(section.groups, (g) => { return _.map(g.fields, "key") }));
                        }
                        else {
                            this.applicationDefinition.form.sections.splice(this.applicationDefinition.form.sections.indexOf(section, 0), 1);

                            this.$scope.appDefForm.$setDirty();

                            this.currentGroup = null;
                            this.currentSection = null;
                            this.currentField = null;
                        }

                    }, (reason) => {
                        this.logError("An error occurred getting the associated rules.", reason, true);
                    });





            });
        }

        deleteGroup(group: IGroup, section: ISection) {
            var dlg = this.dialogs.confirm("Please Confirm",
                "Are you sure you want to delete this group and all of its fields?");
            dlg.result.then((btn) => {
                this.Restangular.all("application").all(this.applicationDefinitionId).all("associatedrules").post(group)
                    .then((result: IRuleSet[]) => {
                        if (result.length > 0) {

                            this.openFieldInUseModal(result, "group", _.map(group.fields, "key"));
                        }
                        else {
                            section.groups.splice(section.groups.indexOf(group, 0), 1);
                            this.$scope.appDefForm.$setDirty();
                            this.selectSection(section);
                        }

                    }, (reason) => {
                        this.logError("An error occurred getting the associated rules.", reason, true);
                    });
            });
        }

        deleteField(field: IField, group: IGroup) {
            var dlg = this.dialogs.confirm("Please Confirm", "Are you sure you want to delete this field?");

            dlg.result.then((btn) => {
                this.Restangular.all("application").all(this.applicationDefinitionId).all("associatedrules").post(field)
                    .then((result: IRuleSet[]) => {
                        if (result.length > 0) {

                            this.openFieldInUseModal(result, "field", [field.key]);
                        }
                        else {
                            group.fields.splice(group.fields.indexOf(field, 0), 1);
                            this.$scope.appDefForm.$setDirty();
                            this.selectGroup(group);
                        }

                    }, (reason) => {
                        this.logError("An error occurred getting the associated rules.", reason, true);
                    });


            });
        }

        deleteTerm(term: ITerm, termFormInvalid: boolean) {
            if (!termFormInvalid || angular.equals(term, this.selectedTerm)) {
                var dlg = this.dialogs.confirm("Please Confirm", "Are you sure you want to delete this term?");
                dlg.result.then((btn) => {
                    this.applicationDefinition.availableTerms.splice(this.applicationDefinition.availableTerms.indexOf(term, 0), 1);
                    this.$scope.appDefForm.$setDirty();
                    this.selectedTerm = null;
                });
            }
        }

        deleteTask(task: IApplicationTask) {
            var dlg = this.dialogs.confirm("Please Confirm", "Are you sure you want to delete this task?");
            dlg.result.then((btn) => {
                this.applicationTasks.splice(this.applicationTasks.indexOf(task, 0), 1);
                this.$scope.appDefForm.$setDirty();
                this.selectedTask = null;
            });
        }

        getRequestedApplication = (): any => {

            var val = this.$routeParams.applicationId;
            if (val === "new") {
                var defered = this.common.$q.defer();

                var empty: any = {
                    formId: "new",
                    form: {
                        sections: []
                    },
                    acceptedPayments: [],
                    availableTerms: []
                };
                this.applicationDefinition = empty;
                defered.resolve(this.applicationDefinition);
                return defered.promise;
            };

            return this.Restangular
                .one(this.applicationDefinitionId).get()
                .then((data) => {
                    // Will either get back an entity or an {entity:, key:}
                    //wipEntityKey = data.key;
                    this.applicationDefinition = data;
                    this.originalApplicationDefinition = angular.copy(data);
                    this.viewLink = this.config.crossRoadsUrl + "/" + this.applicationDefinition.viewUrl;

                    this.logoPath = this.config.apiHost + "/application/" + this.applicationDefinition.applicationDefinitionId + "/logo?antiCache=" + this.getAntiCache();
                    this.excLogoPath = this.config.apiHost + "/application/" + this.applicationDefinition.applicationDefinitionId + "/exclusiveLogo?antiCache=" + this.getAntiCache();
                    this.Restangular
                        .all(this.applicationDefinitionId)
                        .one("stylesheet").get()
                        .then((result) => {
                            if (result) {
                                this.styleSheet = result.css;
                            }
                        }, error => {
                            this.styleSheet = null;
                            this.logError("An error occurred while trying to retrieve the application style sheet", error, true);
                        });

                    this.convertAcceptedPayments();
                    this.originalAcceptedPayments = angular.copy(this.acceptedPayments);
                }, (error) => {
                    this.logError("An error occurred while attempting to retrieve the application information.", error);
                });
        };

        getFields = (): angular.IPromise<Array<IInputField>> => {
            this.inputFields = [];

            if (!this.applicationDefinition.applicationDefinitionId) {
                var defered = this.common.$q.defer<IInputField[]>();

                defered.resolve(this.inputFields);

                return defered.promise;
            }

            return this.Restangular.all(this.applicationDefinition.applicationDefinitionId).all("fields").getList()
                .then((result) => {
                    this.inputFields = result;
                    return result;
                },
                    (reason) => {
                        this.logError("An error occurred while getting the application fields.", reason);
                        return this.inputFields;
                    });

        };

        getMajorLists = (): angular.IPromise<Array<IMajorList>> => {
            this.majorLists = [];
            if (!this.applicationDefinition.applicationDefinitionId) {
                var defered = this.common.$q.defer<IMajorList[]>();

                defered.resolve(this.majorLists);

                return defered.promise;
            }
            return this.Restangular.all(this.applicationDefinition.applicationDefinitionId).all("majorlists").getList().then((result) => {
                this.majorLists = _.forEach(result, (value) => {
                    value.id = value.id.toLowerCase();
                });
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the major lists", reason);
                return this.majorLists;
            });
        };


        getRules = (): angular.IPromise<Array<IRuleSet>> => {
            this.rules = [];
            if (!this.applicationDefinition.applicationDefinitionId) {
                var defered = this.common.$q.defer<IRuleSet[]>();

                defered.resolve(this.rules);

                return defered.promise;
            }
            return this.Restangular.one(this.applicationDefinition.applicationDefinitionId).all("rules").getList().then((result) => {
                this.rules = result;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the rules", reason);
                return this.rules;
            });
        };

        getTasks = (): angular.IPromise<Array<IApplicationTask>> => {
            this.applicationTasks = [];
            if (!this.applicationDefinition.applicationDefinitionId) {
                var defered = this.common.$q.defer<IApplicationTask[]>();

                defered.resolve(this.applicationTasks);

                return defered.promise;
            }
            return this.Restangular.one(this.applicationDefinition.applicationDefinitionId).all("applicationTasks").getList().then((result) => {
                this.applicationTasks = result;
                this.originalApplicationTasks = angular.copy(result);
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the tasks", reason);
                return this.applicationTasks;
            });
        };



        getAntiCache() {
            return new Date().getTime();
        }

        initLookups() {
            this.Restangular.all("processors").getList().then(result => {
                this.processors = result;
            });

            return this.Restangular
                .all(this.applicationDefinitionId)
                .all("choicelists").getList().then(result => {
                    this.choiceLists = result;
                });
        }

        removeCopyField = (index: number, currentField: ICopyField) => {
            currentField.dynamicCopyFields.splice(index, 1);
            this.$scope.appDefForm.$setDirty();
        };

        getComments() {
            var modalInstance = this.$uibModal.open({
                templateUrl: '/app/admin/applications/commentsModal.html',
                backdrop: "static",
                controller: 'CommentsModal',
            });
            modalInstance.result.then((value: string) => {
                if (value != '') {
                    this.applicationDefinition.comments = value;
                    this.save();
                }
            });
        }

        save() {
            this.isSaving = true;
            this.convertAcceptedPaymentsToStringArray();
            if (this.$routeParams.applicationId === "new") {
                this.applicationDefinition.canReceiveTranscripts = this.applicationDefinition.canReceiveTranscripts == null ? false : this.applicationDefinition.canReceiveTranscripts;
                this.applicationDefinition.recruiterParticipation = this.applicationDefinition.recruiterParticipation == null ? false : this.applicationDefinition.recruiterParticipation;
                this.applicationDefinition.redirectionParticipation = this.applicationDefinition.redirectionParticipation == null ? false : this.applicationDefinition.redirectionParticipation;
                this.applicationDefinition.isLinkedApplication = this.applicationDefinition.isLinkedApplication == null ? false : this.applicationDefinition.isLinkedApplication;
                this.applicationDefinition.isHiddenApplication = this.applicationDefinition.isHiddenApplication == null ? false : this.applicationDefinition.isHiddenApplication;
                this.applicationDefinition.disableAutoScrollMenu = this.applicationDefinition.disableAutoScrollMenu == null ? false : this.applicationDefinition.disableAutoScrollMenu;
                this.applicationDefinition.openDates = [];
                this.applicationDefinition.closeDates = [];
                if (this.applicationDefinition.isLinkedApplication == false && this.applicationDefinition.isHiddenApplication == false) {
                    this.applicationDefinition.openDates.push(moment().toDate());
                }

                return this.Restangular
                    .all(this.applicationDefinitionId.replace("/new", ""))
                    .post(this.applicationDefinition).then(result => {
                        this.logSuccess("Your application changes have been saved", null, true);
                        this.$location.path("/admin/" + result.applicationDefinitionId);
                    }, error => {
                        this.isSaving = false;
                        this.logError("An error occurred while trying to save the application", error, true);
                    });
            } else {
                //reset invalid property to false on all fields that were previously invalid
                this.applicationDefinition.form.sections.forEach((section) => {
                    if (section.invalid) {
                        section.invalid = false;
                        section.groups.forEach((group) => {
                            if (group.invalid) {
                                group.invalid = false;
                                group.fields.forEach((field) => {
                                    if (field.invalid) {
                                        field.invalid = false;
                                    }
                                });
                            }
                        });
                    }
                });

                if ((this.applicationDefinition.isLinkedApplication == true && this.originalApplicationDefinition.isLinkedApplication == false)
                    || (this.applicationDefinition.isHiddenApplication == true && this.originalApplicationDefinition.isHiddenApplication == false)) {
                    if (this.applicationDefinition.closeDates == null)
                        this.applicationDefinition.closeDates = [];
                    this.applicationDefinition.closeDates.push(moment().toDate());
                }

                if ((this.applicationDefinition.isLinkedApplication == false && this.originalApplicationDefinition.isLinkedApplication == true)
                    || (this.applicationDefinition.isHiddenApplication == false && this.originalApplicationDefinition.isHiddenApplication == true)) {
                    if (this.applicationDefinition.openDates == null)
                        this.applicationDefinition.openDates = [];
                    this.applicationDefinition.openDates.push(moment().toDate());
                }

                return this.applicationDefinition.put().then(result => {
                    this.logSuccess("Your application changes have been saved", null, true);
                    this.$scope.appDefForm.$setPristine();

                    this.originalApplicationDefinition = _.cloneDeep(this.applicationDefinition); // Reset the backup copy to the new definition
                    this.originalAcceptedPayments = _.cloneDeep(this.acceptedPayments);
                    this.getFields();
                    this.saveTasks();
                    this.saveStyleSheet();

                }, (error: Restangular.IResponse) => {
                    this.isSaving = false;
                    if (error.data && error.data.modelState) {

                        for (var state in error.data.modelState) {
                            var index = state.match(/\d/g);
                            if (state.indexOf("Sections") > -1 && index.length > 0) {
                                this.applicationDefinition.form.sections[index[0]].invalid = true;
                            }

                            if (state.indexOf("Groups") > -1 && index.length > 1) {
                                this.applicationDefinition.form.sections[index[0]].groups[index[1]].invalid = true;
                            }

                            if (state.indexOf("Fields") > -1 && index.length > 2) {
                                this.applicationDefinition.form.sections[index[0]].groups[index[1]].fields[index[2]].invalid = true;
                            }
                        }


                        this.logError("The application contains invalid information. Please verify your changes and try again.", error, true);
                    } else {
                        this.logError("An error occurred while trying to save the application", error, true);
                    }

                });
            }
        }

        saveState = () => {
            if (!this.$location.state() || (angular.toJson(this.tabs) !== angular.toJson(this.$location.state()))) {
                this.$location.state(this.tabs);
            }
        };

        saveTasks = () => {
            angular.forEach(this.applicationTasks, (value, key) => {
                value.order = key + 1;
                if (this.originalApplicationTasks && _.findIndex(this.originalApplicationTasks, { "id": value.id }) > -1) {
                    (<any>value).put();
                } else {
                    this.Restangular
                        .all(this.applicationDefinitionId)
                        .all("applicationTasks").post(value).then((result) => {
                            this.applicationTasks[key] = result;
                            this.originalApplicationTasks = angular.copy(this.applicationTasks);
                        }, error => {
                            this.isSaving = false;
                            this.logError("An error occurred while trying to save the application tasks", error, true);
                        });
                }
            });

            angular.forEach(this.originalApplicationTasks, (value, key) => {
                if (_.findIndex(this.applicationTasks, { "id": value.id }) === -1) {
                    (<any>value).remove().then(result => {
                        this.originalApplicationTasks = angular.copy(this.applicationTasks);
                    }, error => {
                        this.logError("An error occurred while trying to delete the task", error, true);
                    });
                }
            });
            this.selectedTask = null;
            this.originalApplicationTasks = angular.copy(this.applicationTasks);


        };

        saveStyleSheet = () => {
            /*  If the stylesheet is null than put an empty string in it so that there will
                    *  at least be a placeholder value
                    */
            if (!this.styleSheet) {
                this.styleSheet = "";
            }

            this.Restangular
                .all(this.applicationDefinitionId)
                .all("stylesheet").post(`=${this.styleSheet}`, null, { "Content-Type": "application/x-www-form-urlencoded" }).then((result) => {
                    this.isSaving = false;

                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to save the application style sheet", error, true);
                });
        };

        selectSection(section: ISection) {
            this.currentGroup = null;
            this.currentField = null;
            this.currentSection = section;
            this.parentGroup = null;
        }

        selectGroup(group: IGroup) {
            this.currentSection = null;
            this.currentField = null;
            this.currentGroup = group;
            this.parentGroup = null;
        }

        selectField(field: IField, parentGroup: IGroup) {
            this.currentGroup = null;
            this.currentSection = null;
            this.currentField = field;
            this.parentGroup = parentGroup;
        }

        selectCurrentProcessor() {
            if (this.applicationDefinition
                .paymentProcessor &&
                this.applicationDefinition.paymentProcessor.processorId) {
                this.currentProcessor = _.find(this.processors,
                    { "id": this.applicationDefinition.paymentProcessor.processorId });

                switch (this.currentProcessor.processorType) {
                    case "Payeezy":
                        this.applicationDefinition.paymentProcessor
                            .$type = "Advant.Crossroads.Core.Payment.PayeezyApplicationProcessor, Advant.Crossroads.Core";
                        break;
                    case "PayPal":
                        this.applicationDefinition.paymentProcessor
                            .$type = "Advant.Crossroads.Core.Payment.PayPalApplicationProcessor, Advant.Crossroads.Core";
                }

            }

        }

        selectTerm(term: ITerm, termFormInvalid: boolean) {
            if (!termFormInvalid) {
                this.selectedTerm = term;
            } else if (termFormInvalid && !angular.equals(term, this.selectedTerm)) {
                this.dialogs.error("Invalid Term", "There are some invalid fields in the currently selected term. Please correct these errors before continuing.");
            }
        }

        selectTask(task: IApplicationTask) {
            this.selectedTask = task;
        }

        setDefaultGroupExpanded = (value: boolean) => {
            this.currentGroup.defaultExpanded = value;
            this.$scope.appDefForm.$setDirty();
        };



        taskBlur = (element) => {
            if (element && element[0] && element[0].completer && element[0].completer.popup) {
                element[0].completer.popup.hide();
            }
        };

        taskSorted = (e, ui) => {
            this.$scope.appDefForm.$setDirty();
        };

        termStatus = (term: ITerm) => {
            if (!term.openDate && !term.closeDate) {
                return "status-list-success";
            }
            if (term.openDate && term.closeDate) {
                if (moment().isAfter(term.openDate) && moment().isBefore(term.closeDate)) {
                    return "status-list-success";
                }
                return "status-list-danger";
            }
            if (term.openDate && moment().isAfter(term.openDate)) {
                return "status-list-success";
            }
            if (term.closeDate && moment().isBefore(term.closeDate)) {
                return "status-list-success";
            }
            return "status-list-danger";
        };


        toggleCalendar($event: angular.IAngularEvent, calOpenedVar: string): void {
            $event.preventDefault();
            $event.stopPropagation();
            this[calOpenedVar] = !this[calOpenedVar];
        }

        toggleTree(node) {
            node.toggle();
        }

        toggleUploadCompletesTask() {
            this.selectedTask.uploadCompletesTask = !this.selectedTask.uploadCompletesTask;
            this.$scope.appDefForm.$setDirty();
        }
        toggleUserCanComplete() {
            this.selectedTask.userCanComplete = !this.selectedTask.userCanComplete;
            this.$scope.appDefForm.$setDirty();
        }

        toggleUserCanUpload() {
            this.selectedTask.userCanUpload = !this.selectedTask.userCanUpload;
            this.$scope.appDefForm.$setDirty();
        }

        toggleThirdPartyCanUpload() {
            this.selectedTask.allowThirdPartyDocuments = !this.selectedTask.allowThirdPartyDocuments;
            this.$scope.appDefForm.$setDirty();
        }

        uploadLogo(file) {
            this.Upload.upload(<any>{
                url: this.config.apiHost + "/" + this.applicationDefinition.applicationDefinitionId + "/logo",
                method: "POST",
                file: file
            }).then((response) => {
                this.logoPath = this.config.apiHost + "/application/" + this.applicationDefinition.applicationDefinitionId + "/logo?antiCache=" + this.getAntiCache();
            });
        }

        uploadExclusiveLogo(file) {
            this.Upload.upload(<any>{
                url: this.config.apiHost + "/" + this.applicationDefinition.applicationDefinitionId + "/exclusiveLogo",
                method: "POST",
                file: file
            }).then((response) => {
                this.excLogoPath = this.config.apiHost + "/application/" + this.applicationDefinition.applicationDefinitionId + "/exclusiveLogo?antiCache=" + this.getAntiCache();
            });
        }

        resetPaymentProcessor = () => {
            if (this.acceptedPayments.creditCard === false) {
                this.applicationDefinition.paymentProcessor = null;
            }

            this.paymentSelected();
        };

        paymentSelected = () => {
            if (this.acceptedPayments.check || this.acceptedPayments.noPayment
                || this.acceptedPayments.creditCard || this.acceptedPayments.feeWaiver || this.acceptedPayments.payLater) {
                this.isPaymentSelected = true;
            }
            else {
                this.isPaymentSelected = false;
            }
        };

        exportChoiceLists = () => {
            var httpPath = this.config.apiHost + "/" + this.applicationDefinitionId + "/choicelists/export";

            this.helper.openFile(httpPath).then(() => {

            }, (reason) => {
                this.logError("An error occurred while saving the report to excel", reason);
            });

        };

        private openFieldInUseModal(ruleSets: IRuleSet[], elementType: string, fields: string[]) {
            var modalInstance = this.$uibModal.open({
                templateUrl: '/app/admin/applications/fieldinuseModal.html',
                backdrop: "static",
                controller: "FieldInUseModal",
                controllerAs: "vm",
                size: "lg",
                resolve: {
                    ruleSets: () => ruleSets,
                    elementType: () => elementType,
                    fields: () => fields
                }
            });
        }
    }


    angular.module("app").controller(AdminApplicationDetailController.controllerId, AdminApplicationDetailController);

}