// Install the angularjs.TypeScript.DefinitelyTyped NuGet package
/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IResidencySearch {
        activate: () => void;
    }

    interface ResidencyDetermination {
        rcn: string;
        firstName: string;
        middleName: string;
        lastName: string;
        addressLine1: string;
        addressLine2: string;
        city: string;
        countyCode: string;
        stateCode: string;
        countryCode: string;
        zipCode: string;
        dob: Date;
        uid: string;
        ssn: string;
        tin: string;
        arn: string;
        uscis: string;
        i94: string;
        residencyCode: string;
        tuitionCode: string;
        recordType: string;
        effectiveDate: Date;
        validated: string;
        statusChangeDate: Date;
        docsCompleted: string;
        categoryCode: string;
        uncExCode: string;
        ccExCode: string;
        residencyExp: Date;
        trackingFlag: string;
        trackingDate: Date;
        attendingSchoolCeeb: string;
    }

    interface ResidencySearchViewModel {
        rcn?: string;
        firstName?: string;
        lastName?: string;
        dob?: Date;
        ssn?: string;
        campusIdentifier?: string;
        schoolCeeb?: string;
        effectiveDateBegin?: Date;
        effectiveDateEnd?: Date;
        recordType?: string;
        statusChangeDateBegin?: Date;
        statusChangeDateEnd?: Date;
    }

    class ResidencySearch implements IResidencySearch {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingResidencyDeterminations: boolean;
        residencyDeterminations: Array<ResidencyDetermination>;
        searchParams: ResidencySearchViewModel;
        ceebCode: string;
        hasBeenSearched: boolean;

        static $inject: string[] = ["$location", "Restangular", "common", "config"];

        constructor(private $location: angular.ILocationService,
            public Restangular: Restangular.IService,
            public common: ICommonService,
            public config: ICrossroadsConfig) {

            this.log = common.logger.getLogFn("residencySearch");
            this.logError = common.logger.getLogFn("residencySearch", "error");
            this.logSuccess = common.logger.getLogFn("residencySearch", "success");
            this.loadingResidencyDeterminations = false;
            this.residencyDeterminations = [];
            this.hasBeenSearched = false;
            this.activate();
        }

        activate() {
            this.common.activateController([this.getCeebCode()], "residencySearch")
                .then(result => {
                    this.log("Activated Residency Search View");
                });
        }


        search = () => {
            this.residencyDeterminations = [];
            this.loadingResidencyDeterminations = true;

            if (this.searchParams.recordType || this.searchParams.effectiveDateBegin || this.searchParams.effectiveDateEnd
                || this.searchParams.statusChangeDateBegin || this.searchParams.statusChangeDateEnd) {
                this.searchParams.schoolCeeb = this.ceebCode;
            } else {
                this.searchParams.schoolCeeb = null;
            }
            this.Restangular.one("residency").getList("search", this.searchParams).then((result) => {
                this.residencyDeterminations = result;
                this.loadingResidencyDeterminations = false;
                this.hasBeenSearched = true;
            }, (reason) => {
                this.loadingResidencyDeterminations = false;
                this.residencyDeterminations = [];
                this.hasBeenSearched = true;
                this.logError("An error occurred while getting the residency determination", reason);
            });

        };

        getCeebCode = () => {
            var appId = this.common.getUser().activeApplication;

            return this.Restangular
                .one(appId).get()
                .then((data) => {
                    var paddedCeeb = data.ceebCode;
                    this.ceebCode = paddedCeeb.replace(/^0+(?!$)/, "");
                }, (error) => {
                    this.logError("An error occurred while attempting to retrieve the application information.", error);
                });

        };

        clearFilter = () => {
            this.searchParams = {};
        };

        redirectToInProgress = () => {
            var params: any = {};

            if (this.searchParams.rcn) {
                params.rcn = this.searchParams.rcn;
            } else {
                params.lastName = this.searchParams.lastName;
                params.dob = this.searchParams.dob;

                if (this.searchParams.ssn) {
                    params.ssn = this.searchParams.ssn;
                }
                if (this.searchParams.firstName) {
                    params.firstName = this.searchParams.firstName;
                }

            }

            this.$location.path("/application/residencyLookup").search(params);
        };
    }

    angular.module("app").controller("residencySearch", ResidencySearch);
}