/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface ITranscriptExportSettingsDetailsScope extends angular.IScope {
        vm: TranscriptExportSettingsDetails;
        transcriptExportSettingsForm: angular.IFormController;
    }

    export interface ITranscriptExportSettingsDetailsRouteParams extends angular.route.IRouteParamsService {
        exportSettingsId: string;
        exportSettingsType: string;
    }

    interface ITranscriptExportSettingsDetails {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingSettings: boolean;
        isSaving: boolean;
        setting: IExportSettings;
        originalSetting: IExportSettings;
        applicationDefinition: IApplicationDefinition;
        exportSettingsId: string;
        dateStart: Date;
        dateEnd: Date;
        testFileId: string;
        generatingFile: boolean;
        totalExported: number;
        singleFile: boolean;
        illegalFilenameCharacters: RegExp;
        isNew: boolean;
    }

    class TranscriptExportSettingsDetails implements ITranscriptExportSettingsDetails {
        static controllerId: string = "transcriptExportSettingsDetails";
        static $inject = ["$scope", "$routeParams", "$location", "$http", "dialogs", "Restangular", "common", "config", "helper", "$timeout"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingSettings: boolean;
        isSaving: boolean;
        setting: IExportSettings;
        originalSetting: IExportSettings;
        applicationDefinition: IApplicationDefinition;
        exportSettingsId: string;
        dateStart: Date;
        dateEnd: Date;
        testFileId: string;
        generatingFile: boolean;
        totalExported: number;
        singleFile: boolean;
        fieldList: Array<string>;
        illegalFilenameCharacters = /^[^\\/:*?<>|\"]+$/;
        isNew: boolean;

        constructor(private $scope: ITranscriptExportSettingsDetailsScope,
            private $routeParams: ITranscriptExportSettingsDetailsRouteParams,
            private $location: angular.ILocationService,
            private $http: angular.IHttpService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private helper: IHelper,
            private $timeout: angular.ITimeoutService) {

            this.log = common.logger.getLogFn(TranscriptExportSettingsDetails.controllerId);
            this.logError = common.logger.getLogFn(TranscriptExportSettingsDetails.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(TranscriptExportSettingsDetails.controllerId, "success");

            this.loadingSettings = false;
            this.generatingFile = false;
            this.singleFile = false;
            var self = this;

            this.fieldList = [
                "FirstName",
                "MiddleName",
                "LastName",
                "DateOfBirth",
                "StudentId",
                "TransactionId"
            ];
            this.exportSettingsId = $routeParams.exportSettingsType + "/" + $routeParams.exportSettingsId;

            common.$on(config.events.applicationChanged, (event) => {
                $location.path("/");
            });

            this.dateEnd = moment().toDate();
            this.dateStart = moment().add("months", -1).toDate();

            this.isNew = this.$routeParams.exportSettingsId.toLowerCase() === "new";

            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getApplicationDefinition(), this.getSetting()], TranscriptExportSettingsDetails.controllerId)
                .then(result => {
                    self.log("Activated Application Export Settings Details View");
                });
        }

        private getAutoCompleteList(prefix: string) {
            if (!prefix) {
                return [];
            }
            var matchingFields = [];
            angular.forEach(this.fieldList, (v: string, k: any) => {
                var exportFieldKey = "[[" + v + "]]";
                var test = exportFieldKey.toLocaleLowerCase().indexOf(prefix.toLocaleLowerCase());
                if (test > -1) {
                    matchingFields.push({ name: exportFieldKey, value: exportFieldKey, score: 100, meta: "export field" });
                }
            });

            return matchingFields;
        }

        cancel() {
            this.setting = angular.copy(this.originalSetting);
            this.$scope.transcriptExportSettingsForm.$setPristine();
        }

        clearFilter = () => {
            this.dateStart = null;
            this.dateEnd = null;
        };

        generateFieldKey = (potentionalKey: string, fieldCollection?: IExportField[]): string => {
            var hasConflict: boolean;
            var checkCollection: IExportField[];
            if (fieldCollection) {
                checkCollection = fieldCollection;
            } else {
                checkCollection = this.setting.exportFields;
            }

            angular.forEach(checkCollection, (field) => {
                if (field.fieldKey === potentionalKey) {
                    hasConflict = true;
                }
            });

            if (!hasConflict) {
                return potentionalKey;
            }

            var appendNumber = 1;
            var lastCharacter = potentionalKey.substring(potentionalKey.length - 1);
            if (Number(lastCharacter)) {
                appendNumber = Number(lastCharacter) + 1;
                potentionalKey = potentionalKey.substring(0, potentionalKey.length - 1) + appendNumber;
            } else {
                potentionalKey = potentionalKey + appendNumber;
            }

            return this.generateFieldKey(potentionalKey, checkCollection);
        };


        downloadExport = () => {

            var httpPath = this.config.apiHost + "/" + this.common.getUser().activeApplication +
                "/transcriptExportSettings/" + this.exportSettingsId + "/testExport?fileId=" + encodeURI(this.testFileId);

            this.openFile(httpPath);

        }

        generateTestFile = (): angular.IPromise<string> => {
            this.generatingFile = true;
            this.testFileId = null;

            return this.Restangular.one(this.common.getUser().activeApplication).one("transcriptExportSettings/" + this.exportSettingsId + "/testExport")
                .get(this.getQueryParams()).then((result) => {

                    this.testFileId = result.fileId;
                    this.totalExported = result.totalExported;

                    this.generatingFile = false;
                    return result;
                }, (reason) => {
                    this.generatingFile = false;
                    this.logError("An error occurred while generating the export", reason, true);
                });
        };

        getApplicationDefinition = (): angular.IPromise<IApplicationDefinition> => {
            return this.Restangular.one(this.common.getUser().activeApplication).get().then((result) => {
                this.applicationDefinition = result;

                return result;
            }, (reason) => {
                this.logError("Failed to retrieve the application fields", reason);
            });
        };

        getQueryParams = (): any => {
            var queryParams = {
                startDate: this.dateStart ? moment(this.dateStart).format("YYYY-MM-DDTHH:mm:ss.sss") : "",
                endDate: this.dateEnd ? moment(this.dateEnd).add(1, "day").subtract(1, "second").format("YYYY-MM-DDTHH:mm:ss.sss") : "",
                singleFile: this.singleFile
            };

            return queryParams;
        }

        getSetting = (): angular.IPromise<IExportSettings> => {
            var deferred: angular.IDeferred<IExportSettings>;
            if (this.$routeParams.exportSettingsId.toLowerCase() === "new") {
                deferred = this.common.$q.defer<IExportSettings>();
                var empty: any = {
                    $type: "Advant.Crossroads.Core.Export.TranscriptExportSettings, Advant.Crossroads.Core",
                    id: null,
                    applicationDefinitionId: this.common.getUser().activeApplication,
                    name: null,
                    fileNamePattern: null,
                    singleFileNamePattern: null,
                    exportType: "Transcript",
                    createdOn: moment().toDate(),
                    createdBy: null,
                    lastUpdated: moment().toDate(),
                    lastUpdatedBy: null,
                    type: null
                };
                this.setting = empty;
                this.originalSetting = angular.copy(this.setting);
                deferred.resolve(this.setting);

                return deferred.promise;
            }


            return this.Restangular.one(this.common.getUser().activeApplication).one("transcriptExportSettings", this.exportSettingsId).get().then((result) => {
                this.setting = result;
                this.originalSetting = angular.copy(this.setting);
                return result;
            }, (reason) => {
                this.logError("An error occurred while trying to retrieve your settings. Please try again later", reason, true);
            });

        };

        save = () => {
            if (this.$scope.transcriptExportSettingsForm.$valid) {
                this.isSaving = true;

                if (this.setting.id === null) {
                    this.Restangular.all(this.common.getUser().activeApplication).all("transcriptExportSettings").post(this.setting).then((result) => {
                        this.isSaving = false;
                        this.originalSetting = angular.copy(result);
                        this.setting = result;
                        this.exportSettingsId = this.setting.id;
                        this.logSuccess("Your transcript export settings have been saved", result, true);
                        this.$scope.transcriptExportSettingsForm.$setPristine();
                        return result;
                    }, (reason) => {
                        var message = "Error saving new setting!";
                        if (reason.data.modelState) {
                            angular.forEach(reason.data.modelState, (value: any, key: any) => {
                                message += "<br/>" + value;
                            });
                        }
                        if (reason.data.exceptionMessage) {
                            message += "<br/>" + reason.data.exceptionMessage;
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                    });
                } else {
                    this.setting.put().then((result) => {
                        this.logSuccess("Your transcript export settings have been saved", result, true);
                        this.$scope.transcriptExportSettingsForm.$setPristine();
                        this.isSaving = false;
                        this.originalSetting = angular.copy(this.setting);
                        return result;
                    }, (reason) => {
                        var message = "An error occurred while trying to save your settings. Please try again later.";
                        if (reason.data.exceptionMessage) {
                            message += "<br/>" + reason.data.exceptionMessage;
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                        return;
                    });
                }
            }
        };

        exportSetting = () => {
            var exportFile: ExportSettingsFile = new ExportSettingsFile(this.setting);

            var blob: Blob = new Blob([exportFile.toJson()], { type: "application/octet-stream" });
            saveAs(blob, this.setting.name + ".json");
        };

        importSetting = () => {
            var currentExportSettings = new ExportSettingsFile(this.setting);

            var currentSettingData: ICurrentExportSettingData = {
                currentExportSettingsFile: currentExportSettings,
                appDefId: this.applicationDefinition.applicationDefinitionId
            };


            var importFile = this.dialogs.create("/app/exportSettings/importSetting.html", "importSetting", currentSettingData, { size: "lg" });

            importFile.result.then((result: ExportSettingsFile) => {
                this.setting = result.setting;
                this.$timeout(this.save);
                this.isNew = false;
            });


        }

        openFile = (httpPath) => {
            this.$http.get<any>(httpPath, { responseType: "arraybuffer" })
                .then((response) => {
                    var octetStreamMime = "application/octet-stream";
                    var success = false;

                    // Get the headers
                    var headerValues = response.headers();

                    // Get the filename from the x-filename header or default to "download.bin"
                    var filename = headerValues["x-filename"] || "download.pdf";

                    // Determine the content type from the header or default to "application/octet-stream"
                    var contentType = headerValues["content-type"] || octetStreamMime;
                    var blob: Blob;
                    blob = new Blob([response.data], { type: contentType });

                    saveAs(blob, filename);

                }, (reason) => {
                    console.log("Request failed with status: " + status);
                });
        };

    }

    angular.module("app").controller(TranscriptExportSettingsDetails.controllerId, TranscriptExportSettingsDetails);
}