namespace Advant.Crossroads {

    interface IChoiceListsScope extends angular.IScope {
        currentChoiceList: IChoiceList;
        addChoice: () => void;
        removeChoice: (choiceIndex: number) => void;
        save: () => void;
        cancel: () => void;

    }

    interface IChoiceLists {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;

    }

    class ChoiceLists implements IChoiceLists {
        static controllerId: string = "choiceLists";
        static $inject: any = ["$scope", "$uibModalInstance", "dialogs", "common", "config", "Restangular", "data"];
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;

        constructor(private $scope: IChoiceListsScope,
            private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
            private dialogs: angular.dialogs.IDialogService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private Restangular: Restangular.IService,
            private choiceList?: IChoiceList) {
            this.log = common.logger.getLogFn(ChoiceLists.controllerId);
            this.logError = this.common.logger.getLogFn(ChoiceLists.controllerId, "error");

            $scope.addChoice = () => this.addChoice();
            $scope.save = () => this.save();
            $scope.cancel = () => this.cancel();
            $scope.removeChoice = (choiceIndex: number) => this.removeChoice(choiceIndex);

            if (this.choiceList) {
                $scope.currentChoiceList = choiceList;
            } else {
                $scope.currentChoiceList = { choices: [] };
            }
        }

        private addChoice() {
            this.$scope.currentChoiceList.choices.push({});
        }

        private removeChoice(choiceIndex: number) {
            this.$scope.currentChoiceList.choices.splice(choiceIndex, 1);
        }

        private save() {
            if (this.$scope.currentChoiceList.shared) {

                var dlg = this.dialogs.confirm("Shared List Warning",
                    "You are about to update a choicelist shared by other schools.  Do you wish to proceed?");

                dlg.result.then((btn) => {
                    this.$uibModalInstance.close(this.$scope.currentChoiceList);
                });
            }
            else {
                this.$uibModalInstance.close(this.$scope.currentChoiceList);
            }
        }

        private cancel() {
            this.$uibModalInstance.dismiss("cancel");
        }


    }

    angular.module("app").controller(ChoiceLists.controllerId, ChoiceLists);
}
