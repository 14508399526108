/// <reference path="../../app.ts" />
namespace Advant.Crossroads {

    export interface IOrganizationsScope extends angular.IScope {
        vm: IOrganizations;
    }

    export interface OrganizationsRouteParams extends angular.route.IRouteParamsService {
        organization?: string;
        programs: Array<any>;
        currentOrganization: string;

    }

    export interface IOrganizations {
        organizations: Array<IOrganization>;
        search: any;
        goToApplication: (app: IApplicationDefinition) => void;
        loadingOrgs: boolean;
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;

    }

    export class Organizations implements IOrganizations {
        static controllerId: string = "organizations";
        static $inject: any = ["$scope", "$routeParams", "$location", "dialogs", "Restangular", "common"];
        search: any;
        organizations: Array<IOrganization> = [];
        programs: Array<any>;
        currentOrganization: IOrganization;
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingOrgs: boolean;

        constructor(private $scope: IOrganizationsScope,
            private $routeParams: OrganizationsRouteParams,
            private $location: angular.ILocationService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService) {

            this.log = common.logger.getLogFn(Organizations.controllerId);
            this.logError = common.logger.getLogFn(Organizations.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(Organizations.controllerId, "success");

            this.loadingOrgs = false;
            this.activate();
        }

        activate() {
            var self = this;
            self.common.activateController([self.getOrganizations()], Organizations.controllerId)
                .then(result => {
                    if (self.$routeParams.organization) {
                        self.getCurrentOrganization(self.$routeParams.organization);
                    }
                    self.log("Activated Organizations View");
                });
        }

        createNewApp(prog: IProgram) {
            var path = "/admin/" + prog.programId + "/applications/new";
            this.$location.path("/admin/" + prog.programId + "/applications/new");
        }

        createNewOrg() {
            var dialog = this.dialogs.create("/app/admin/organizations/organizationDetailDialog.html", "organizationDetailDialog");

            dialog.result.then((organization: IOrganization) => {
                this.Restangular.all("organizations").post(organization).then((result) => {
                    this.logSuccess(organization.name + " has been created");
                    this.organizations.push(result);
                }, (reason: any) => {
                    this.logError("An error occurred while trying to create the new organization.", reason, true);
                });
            });
        }

        createNewProg(org: IOrganization) {
            var dialog = this.dialogs.create("/app/admin/organizations/programDetailDialog.html", "programDetailDialog");

            dialog.result.then((program: IProgram) => {
                org.programs.push(program);
                org.put().then((result) => {
                    this.logSuccess(org.name + " has been updated");
                    this.getOrganization(org.id).then((result) => {
                        org = result;
                        this.currentOrganization = org;
                    });
                }, (reason: any) => {
                    this.logError("An error occurred while trying to edit the organization.", reason, true);
                });
            });
        }

        deleteProg(prog: IProgram) {
            var confirm = this.dialogs.confirm("Delete Program",
                "Are you sure you want to delete this program? This operation cannot be undone and will delete all application definitions associated with this program.");

            confirm.result.then((button) => {
                var progIndex = this.currentOrganization.programs.indexOf(prog);
                this.currentOrganization.programs.splice(progIndex, 1);

                this.currentOrganization.put().then((result) => {
                    this.logSuccess(prog.name + " has been removed");
                }, (reason: any) => {
                    this.logError("An error occurred while trying to remove the program.", reason, true);
                });
            });
        }

        editOrg(org: IOrganization) {
            var dialog = this.dialogs.create("/app/admin/organizations/organizationDetailDialog.html", "organizationDetailDialog", org);

            dialog.result.then((organization: IOrganization) => {
                organization.put().then((result) => {
                    this.logSuccess(organization.name + " has been updated");
                }, (reason: any) => {
                    this.logError("An error occurred while trying to edit the organization.", reason, true);
                });
            });
        }

        editProgram(prog: IProgram) {
            var dialog = this.dialogs.create("/app/admin/organizations/programDetailDialog.html", "programDetailDialog", prog);

            dialog.result.then((program: IProgram) => {
                this.currentOrganization.put().then((result) => {
                    this.logSuccess(this.currentOrganization.name + " has been updated");
                }, (reason: any) => {
                    this.logError("An error occurred while trying to edit the organization.", reason, true);
                });
            });
        }

        getOrganization(orgId: string): angular.IPromise<IOrganization> {
            var self = this;
            self.loadingOrgs = true;


            return self.Restangular.one("organizations", orgId).get().then(result => {
                self.loadingOrgs = false;
                return result;
            }, (reason) => {
                self.logError("An error occurred while getting the organizations", reason);
            });
        }

        getOrganizations(): angular.IPromise<IOrganization[]> {
            var self = this;
            self.loadingOrgs = true;
            self.organizations = [];
            self.currentOrganization = undefined;

            return self.Restangular.all("organizations").getList().then(result => {
                self.organizations = result;
                self.loadingOrgs = false;
                if (!self.common.getUser().systemAdmin) {
                    self.currentOrganization = self.organizations[0];
                }
                return result;
            }, (reason) => {
                self.logError("An error occurred while getting the organizations", reason);
                return self.organizations;
            });
        }

        getCurrentOrganization(organizationName: string) {
            this.currentOrganization = this.organizations.filter(org => {
                var isMatch = org.tenantName === organizationName;
                return isMatch;
            })[0];
        }

        selectOrg(org) {
            this.currentOrganization = org;
        }

        goToApplication(app: IApplicationDefinition) {
            if (app && app.applicationDefinitionId) {
                this.$location.path("/admin/" + app.applicationDefinitionId);
            }
        }
    }


    angular.module("app").controller(Organizations.controllerId, Organizations);
}