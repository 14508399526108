/// <reference path="../services/advant-auth.ts" />

// Create the module and define its dependencies.
var common: angular.IModule = angular.module("common", ["authentication"]);

// Must configure the common service and set its 
// events via the commonConfigProvider
common.provider("commonConfig", () =>
    new CommonServiceProvider()
    );
/* tslint:disable: no-empty */
// Execute bootstrapping code and any dependencies.
common.run(["$q", "$rootScope", ($q, $rootScope) => {

}]);

class CommonServiceProvider implements angular.IServiceProvider {
    config = {
        // These are the properties we need to set
        //controllerActivateSuccessEvent: "",
        //spinnerToggleEvent: ""
    };

    $get = () => {
        return {
            config: this.config
        };
    };
}