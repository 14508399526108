namespace Advant.Crossroads {

    interface ITiersUploadScope extends ng.IScope {
        vm: TiersUpload;
    }

    interface ITiersUpload {
    }

    class TiersUpload implements ITiersUpload {
        static controllerId: string = "tiersUpload";
        static $inject: Array<string> = ["config", "Upload", "$scope", "$routeParams", "common", "Restangular", "spinner", "dialogs"];
        private log;
        years: number[];
        selectedYear: number;
        logSuccess: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;

        constructor(private config: Advant.Crossroads.ICrossroadsConfig,
            private Upload: angular.angularFileUpload.IUploadService,
            private $scope: ITiersUploadScope, private $routeParams,
            private common: ICommonService,
            private Restangular: Restangular.IService,
            private spinner: ISpinnerService,
            private dialogs: angular.dialogs.IDialogService) {
            this.log = common.logger.getLogFn(TiersUpload.controllerId);

            this.activate();
        }

        activate() {
            this.common.activateController([], TiersUpload.controllerId)
                .then(() => {
                    this.log("County tiers upload");
                    this.logSuccess = this.common.logger.getLogFn(TiersUpload.controllerId, "success");
                    this.logError = this.common.logger.getLogFn(TiersUpload.controllerId, "error");
                    this.fillYears();
                });
        }

        fillYears() {
            var currentDate = new Date().getFullYear();
            this.years = [];
            while (currentDate >= 2014) {
                this.years.push(currentDate);
                currentDate -= 1;
            }
        }

        uploadTiers = (file: any) => {
            if (file.length > 0) {
                this.spinner.spinnerShow();
                this.Upload.upload(<any>{
                    url: this.config.apiHost + "/CountyTiers/UploadTierFile/" + this.selectedYear + "/upload",
                    method: "POST",
                    file: file,
                    //fields: { 'name': file[0].name }
                }).then((response) => {
                    this.spinner.spinnerHide();
                    this.logSuccess("File was uploaded successfully", null, true);
                }, (reason) => {
                    this.spinner.spinnerHide();
                    console.error("Failed to upload attachment", reason.message);
                    this.logError("Failed to upload attachment", reason.message, true);
                });
            }
        }
    }

    angular.module("app").controller(TiersUpload.controllerId, TiersUpload);
}