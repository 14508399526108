/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IHighSchools {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingHighSchools: boolean;
        isSaving: boolean;
        uploadingFile: boolean;
        highSchools: IHighSchool[];
        currentHighSchool: IHighSchool;

        page: number;
        pageSize: number;
        totalCount: number;
        sortByRelevance: boolean;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;
    }

    class HighSchools implements IHighSchools {
        static controllerId: string = "highSchools";
        static $inject: string[] = ["Upload", "Restangular", "common", "config", "$location", "dialogs"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingHighSchools: boolean;
        isSaving: boolean;
        uploadingFile: boolean;
        highSchools: IHighSchool[];
        currentHighSchool: IHighSchool;

        page: number;
        pageSize: number;
        totalCount: number;
        sortByRelevance: boolean;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;

        constructor(private Upload: angular.angularFileUpload.IUploadService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private $location: ng.ILocationService,
            private dialogs: angular.dialogs.IDialogService) {

            this.log = common.logger.getLogFn(HighSchools.controllerId);
            this.logError = common.logger.getLogFn(HighSchools.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(HighSchools.controllerId, "success");

            this.page = 1;
            this.pageSize = 25;
            this.sortByRelevance = false;
            this.sortBy = "Name";
            this.sortDirection = "ASC";
            this.searchFilter = "";

            this.activate();

        }

        private activate = () => {
            this.common.activateController([this.getHighSchools()], HighSchools.controllerId)
                .then(result => {
                    this.log("Activated HighSchools View");
                });
        };

        getHighSchools = (): angular.IPromise<void | IHighSchool[]> => {
            this.loadingHighSchools = true;
            this.highSchools = [];
            this.currentHighSchool = null;
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortByRelevance: this.sortByRelevance,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                filter: this.searchFilter
            };


            return this.Restangular.one("lookup").one("school").getList("highSchools", queryParams).then((result: any) => {
                this.highSchools = result;
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingHighSchools = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the high schools", reason);
                return null;
            });
        };

        importHighSchools(file: any) {
            this.uploadingFile = true;
            this.Upload.upload(<any>{
                url: this.config.apiHost + "/lookup/school/highSchools/import",
                method: "POST",
                file: file
            }).then((response) => {
                this.uploadingFile = false;
                this.getHighSchools();
                }, (reason) => {
                    this.logError(reason, reason, true);
                });
        }

        searchHighSchools = (): void => {
            this.common.debouncedThrottle("HighSchoolsSearch", () => {
                this.page = 1;
                this.sortByRelevance = this.searchFilter ? true : false;
                this.getHighSchools();
            }, 500);
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.searchFilter = "";
            this.sortByRelevance = false;
            this.getHighSchools();
        };

        selectHighSchool = (highSchool: IHighSchool) => {
            this.currentHighSchool = highSchool;
        };

        createNewHighSchool = () => {
            this.$location.path("/admin/highSchools/new");
        };

        editHighSchool = () => {
            this.$location.path("/admin/" + this.currentHighSchool.id);
        };

        deleteHighSchool = () => {
            var confirm = this.dialogs.confirm("Delete High School", "Are you sure you want to delete this high school?");

            confirm.result.then((button) => {
                this.Restangular.one("lookup").one("school").one(this.currentHighSchool.id).remove().then((result) => {
                    this.getHighSchools();
                    this.currentHighSchool = null;
                    this.logSuccess("High School Deleted Successfully", null, true);
                }, (reason) => {
                    this.logError(reason.message, reason, true);
                });
            });

            
        };
    }
    angular.module("app").controller(HighSchools.controllerId, HighSchools);
}