/// <reference path="../../app.ts" />

namespace Advant.Crossroads {
    "use strict";

    interface ITranscriptsSubmittedReport {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingUserApplications: boolean;
        transactions: Array<ITranscriptReportViewModel>;
        applicationDefinition: IApplicationDefinition;
        page: number;
        pageSize: number;
        sortBy: string;
        searchFilter: string;
        sortDirection: string;
        totalCount: number;
        totalPages: number;
        startRow: number;
        endRow: number;
        dataPath: string;
        dateLabel: string;
        dateStart: string;
        dateEnd: string;
        showFilter: boolean;
        newOnly: boolean;
        filter: any;

        activate: () => void;
        getTransactions: () => ng.IPromise<Array<ITranscriptReportViewModel>>;
        searchApplications: () => void;
        sort: (sortValue: string) => void;
    }

    class TranscriptsSubmittedReport implements ITranscriptsSubmittedReport {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingUserApplications: boolean;
        transactions: Array<ITranscriptReportViewModel>;
        applicationDefinition: IApplicationDefinition;
        page: number;
        pageSize: number;
        sortBy: string;
        searchFilter: string;
        sortDirection: string;
        totalCount: number;
        totalPages: number;
        startRow: number;
        endRow: number;
        dataPath: string = "transcripts/report/submitted";
        dateLabel: string;
        dateStart: string;
        dateEnd: string;
        firstName: string;
        lastName: string;
        showFilter: boolean;
        exporting: boolean = false;
        newOnly: boolean = false;
        transcriptType: string;
        filter: any;
        dateOfBirth: string;
        studentId: string;
        ceebCode: string;

        static $inject: any = ["$scope", "$location", "$http", "Restangular", "dialogs", "common", "config"];

        constructor(public $scope: IUserApplicationsScope,
            public $location: ng.ILocationService,
            public $http: ng.IHttpService,
            public Restangular: Restangular.IService,
            public dialogs: ng.dialogs.IDialogService,
            public common: ICommonService,
            public config: ICrossroadsConfig) {

            this.log = common.logger.getLogFn("transcriptsSubmittedReport");
            this.logError = common.logger.getLogFn("transcriptsSubmittedReport", "error");
            this.logSuccess = common.logger.getLogFn("transcriptsSubmittedReport", "success");

            this.dataPath = "transcripts/report/submitted";
            this.dateLabel = "Completed Between";

            this.page = 1;
            this.pageSize = 25;
            this.sortBy = "DateCompleted";
            this.sortDirection = "DESC";
            this.searchFilter = "";
            this.showFilter = false;

            this.loadingUserApplications = false;

            common.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.filter = {
                dateEnd: moment().hours(23).minutes(59).seconds(59).toISOString(),
                dateStart: moment().hours(0).minutes(0).seconds(0).toISOString()
            };

            this.dateEnd = this.filter.dateEnd;
            this.dateStart = this.filter.dateStart;

            this.activate();
        }

        activate = () => {
            this.common.activateController([this.getTransactions(), this.getApplicationDefinition()], "transcriptsSubmittedReport")
                .then(result => {
                    this.log("Activated UserApplications View");
                });
        };

        applyFilter = () => {
            if (this.filter) {
                this.newOnly = this.filter.newOnly ? this.filter.newOnly : false;
                this.dateEnd = this.filter.dateEnd ? this.filter.dateEnd : "";
                this.dateStart = this.filter.dateStart ? this.filter.dateStart : "";
                this.firstName = this.filter.firstName;
                this.lastName = this.filter.lastName;
                this.transcriptType = this.filter.transcriptType;
                this.dateOfBirth = this.filter.dateOfBirth;
                this.studentId = this.filter.studentId;
                this.ceebCode = this.filter.ceebCode;
            }
            this.page = 1;
            this.getTransactions();
        };

        clearFilter = () => {
            this.dateStart = null;
            this.dateEnd = null;
            this.firstName = null;
            this.lastName = null;
            this.filter = null;
            this.startRow = 0;
            this.endRow = 0;
            this.totalCount = 0;
            this.totalPages = 0;
            this.transactions = [];
            this.transcriptType = null;
            this.dateOfBirth = null;
            this.studentId = null;
            this.ceebCode = null;
        };

        exportToExcel = () => {
            this.exporting = true;
            var queryParams = this.getQueryParams();
            var httpPath = this.config.apiHost + "/" + this.common.getUser().activeApplication +
                "/transcripts/report/submitted/export?sortBy=" + encodeURI(queryParams.sortBy) + "&sortDirection=" + queryParams.sortDirection + "&pageSize=0&page=1" +
                "&startDate=" + queryParams.startDate +
                "&endDate=" + queryParams.endDate;
            if (queryParams.termCode) {
                httpPath += "&termCode=" + queryParams.termCode;
            }

            if (queryParams.ruleId) {
                httpPath += "&ruleId=" + queryParams.ruleId;
            }

            if (queryParams.filter) {
                httpPath += "&filter=" + queryParams.filter;
            }




            this.openFile(httpPath).then(() => {
                this.exporting = false;
            }, (reason) => {
                this.logError("An error occurred while saving the report to excel", reason);
            });
        }

        getTransactions = (): ng.IPromise<Array<ITranscriptReportViewModel>> => {
            this.loadingUserApplications = true;
            this.transactions = [];

            return this.Restangular.one(this.common.getUser().activeApplication).getList(this.dataPath, this.getQueryParams()).then((result: IRestangularResult<ITranscriptReportViewModel>) => {
                this.transactions = result;
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingUserApplications = false;
                return result;
            }, (reason) => {
                this.loadingUserApplications = false;
                this.logError("An error occurred while getting the user applications", reason);
                return null;
            });
        };

        getApplicationDefinition = (): ng.IPromise<IApplicationDefinition> => {
            return this.Restangular
                .one(this.common.getUser().activeApplication).get()
                .then((result) => {
                    this.applicationDefinition = result;

                    return result;
                }, (error) => {
                    this.logError("An error occurred while attempting to retrieve the application information.", error);
                });
        }


        getQueryParams = (): any => {
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                startDate: this.dateStart ? moment(this.dateStart).toISOString() : "",
                endDate: this.dateEnd ? moment(this.dateEnd).hours(23).minutes(59).seconds(59).toISOString() : "",
                newOnly: this.newOnly,
                firstName: this.firstName,
                lastName: this.lastName,
                transcriptType: this.transcriptType,
                dateOfBirth: this.dateOfBirth,
                ceebCode: this.ceebCode,
                studentId: this.studentId

            };

            return queryParams;
        }

        searchApplications = (): void => {
            this.common.debouncedThrottle("UserApplicationSearch", () => { this.getTransactions(); }, 400);
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.getTransactions();
        };

        openFile = (httpPath) => {
            return this.$http.get<any>(httpPath, { responseType: "arraybuffer" })
                .then((response) => {
                    var octetStreamMime = "application/octet-stream";
                    var success = false;

                    // Get the headers
                    var headerValues = response.headers();

                    // Get the filename from the x-filename header or default to "download.bin"
                    var filename = headerValues["x-filename"] || "download.pdf";

                    // Determine the content type from the header or default to "application/octet-stream"
                    var contentType = headerValues["content-type"] || octetStreamMime;
                    var blob: Blob;
                    blob = new Blob([response.data], { type: contentType });

                    saveAs(blob, filename);

                }, (reason) => {
                    console.log("Request failed with status: " + status);
                });
        };

        openTranscript(transcriptId) {

            this.Restangular.all(this.common.getUser().activeApplication).one("transcripts/view", transcriptId).get().then(
                (result) => {
                    var transcriptWindow: any = window.open("/content/viewtranscript.html", "", "menubar=no, toolbar=no, width=800, height=600, scrollbars=yes, resizable=yes");
                    transcriptWindow.onload = function () { transcriptWindow.document.getElementById("transcriptContainer").innerHTML = result; };
                    transcriptWindow.focus();
                },
                (reason) => {
                    this.logError("There was an error getting the transcript.", reason, true);
                });

        }

    }



    angular.module("app").controller("transcriptsSubmittedReport", TranscriptsSubmittedReport);
}