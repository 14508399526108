/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    
    interface IImportReplacementLookupScope extends angular.IScope {
        importSetting: (files: Array<File>) => void;
        cancel: () => void;
        save: () => void;
        data: any;
        importError: string;
    }

    class ImportReplacementLookupForm {
        static controllerId: string = "importReplacementLookup";
        static $inject: any = ["$scope", "$uibModalInstance", "$q", "common", "config", "Restangular", "helper", "data"];
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        importedFile: ReplacementLookupFile;
        

        constructor(private $scope: IImportReplacementLookupScope,
            private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
            private $q: angular.IQService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private Restangular: Restangular.IService,
            private helper: IHelper,
            private currentExportSettingData?: ICurrentExportSettingData) {
            this.log = common.logger.getLogFn(ImportReplacementLookupForm.controllerId);
            this.logError = this.common.logger.getLogFn(ImportReplacementLookupForm.controllerId, "error");
            this.logSuccess = this.common.logger.getLogFn(ImportReplacementLookupForm.controllerId, "success");

            $scope.importSetting = this.importSetting;
            $scope.data = {};
            $scope.data.importing = false;

            $scope.cancel = this.cancel;
            $scope.save = this.save;
        }

        importSetting = (files) => {
            this.$scope.importError = null;
            if (files && files.length > 0) {
                this.$scope.data.importing = true;
                this.$scope.data.progress = 0;
                var file = files[0];
                var blob = new Blob([file], { type: "application/json" });
                var reader = new FileReader();
                reader.onprogress = this.updateProgres;
                reader.onload = this.completedImportProcessing;
                reader.readAsText(blob);
            }
        };

        private cancel = () => {
            this.$uibModalInstance.dismiss("cancel");
        };

        completedImportProcessing = (ev) => {
            this.$scope.$applyAsync((scope: IImportReplacementLookupScope) => {
                scope.data.progress = 100;
            });
            this.importedFile = ReplacementLookupFile.fromJson(ev.currentTarget.result);
            this.$scope.data.parsingProgress = 0;

            this.$scope.data.parsingProgress = 100;
            this.$scope.data.importing = false;
            this.$scope.data.parsed = true;


        };
        updateProgres = (evt) => {
            if (evt.lengthComputable) {
                var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
                // Increase the progress bar length.
                if (percentLoaded < 100) {
                    this.$scope.$applyAsync((scope: IImportReplacementLookupScope) => {
                        scope.data.progress = percentLoaded;
                    });

                }
            }
        };

        private save = () => {
            this.$uibModalInstance.close(this.importedFile);
        };
    }

    angular.module("app").controller(ImportReplacementLookupForm.controllerId, ImportReplacementLookupForm);
}