/// <reference path="crossroadsstorage.ts" />
namespace Advant.Crossroads {
    /* tslint:disable: no-string-literal */
    export interface IAuthUrls {
        apiHost: string;
        site: string;
        register: string;
        login: string;
        logout: string;
        userInfo: string;
        changePassword: string;
        forcePasswordChange: string;
        forceSecurityQuestionChange: string;
        getSecurityQuestion: string;
        getTwoFactorKey: string;
        verifyTwoFactorKey: string;
        changePasswordWithSecret: string;
        changePasswordWithKey: string;
        externalLogins: string;
        registerExternal: string;
    }

    export interface IAuthenticationFactory {
        getUserInfo(accessToken: string): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        login(data: ILoginModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        refreshToken(data: IRefreshTokenModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        logout(accessToken: string): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        register(data: RegistrationModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        changePassword(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        forcePasswordChange(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        forceSecurityQuestionChange(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        getSecurityQuestion(data: { email: string, tenant: string }): angular.IPromise<angular.IHttpPromiseCallbackArg<Advant.Crossroads.IPasswordResetSecretViewModel>>;
        getTwoFactorKey(data: IForceTfaSetupViewModel): angular.IPromise<angular.IHttpPromiseCallbackArg<ITwoFactorKeyResults>>;
        verifyTwoFactorKey(data: IForceTfaSetupViewModel): angular.IPromise<angular.IHttpPromiseCallbackArg<boolean>>;
        changePasswordWithSecret(data: IPasswordResetWithSecretInputModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        changePasswordWithKey(data: IChangeUserPasswordWithKeyModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        getExternalLogins(clientId: string): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        registerExternal(accesToken: string, data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
    }

    export interface IAuthenticationProviderUrls {
        login: string;
        registerExternal: string;
        postLogout: string;
        home: string;
    }

    export interface IAuthenticationEvents {
        login: any;
        logout: any;
        register: any;
        reloadUser: any;
        closeOAuthWindow: any;
    }

    export interface IAuthenticationProviderService {
        user: any;
        externalUser: any;
        externalLogins: Array<any>;
        login(data: ILoginModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        refreshToken(): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        loginWithExternal(login: any, data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        logout(): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        register(data: RegistrationModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        registerExternal(): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        changePassword(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        forcePasswordChange(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        forceSecurityQuestionChange(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        getSecurityQuestion(data: { email: string, tenant: string }): angular.IPromise<angular.IHttpPromiseCallbackArg<IPasswordResetSecretViewModel>>;
        getTwoFactorKey(data: IForceTfaSetupViewModel): angular.IPromise<angular.IHttpPromiseCallbackArg<ITwoFactorKeyResults>>;
        verifyTwoFactorKey(data: IForceTfaSetupViewModel): angular.IPromise<angular.IHttpPromiseCallbackArg<boolean>>;
        changePasswordWithSecret(data: IPasswordResetWithSecretInputModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        changePasswordWithKey(data: IChangeUserPasswordWithKeyModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        authenticate(): boolean;
        redirectAuthenticated(url: string): void;
        getUser(): IUserInfoModel;
        accessToken: (accessToken?: string, refreshToken?: string, persist?: boolean) => string;
    }

    //  Angular Authorization Factory
    export class AuthenticationFactory implements IAuthenticationFactory {
        private formHeader: any;

        static $inject: any = ["$http", "authentication.urls"];

        constructor(private $http: angular.IHttpService, private urls: IAuthUrls) {
            this.formHeader = { "Content-Type": "application/x-www-form-urlencoded" };

            var api = this.urls.apiHost;
            if (api) {
                this.urls.login = api + this.urls.login;
                this.urls.register = api + this.urls.register;
                this.urls.logout = api + this.urls.logout;
                this.urls.userInfo = api + this.urls.userInfo;
                this.urls.changePassword = api + this.urls.changePassword;
                this.urls.forcePasswordChange = api + this.urls.forcePasswordChange;
                this.urls.forceSecurityQuestionChange = api + this.urls.forceSecurityQuestionChange;
                this.urls.getSecurityQuestion = api + this.urls.getSecurityQuestion;
                this.urls.getTwoFactorKey = api + this.urls.getTwoFactorKey;
                this.urls.verifyTwoFactorKey = api + this.urls.verifyTwoFactorKey;
                this.urls.changePasswordWithSecret = api + this.urls.changePasswordWithSecret;
                this.urls.changePasswordWithKey = api + this.urls.changePasswordWithKey;
                this.urls.externalLogins = api + this.urls.externalLogins;
                this.urls.registerExternal = api + this.urls.registerExternal;
            }
        }

        // Form Encode login data
        private formEncode(data: any) {
            var param = obj => {
                var query = "";
                var subValue, fullSubName, innerObj, i;
                angular.forEach(obj, (value, name: any) => {
                    if (value instanceof Array) {
                        for (i = 0; i < value.length; ++i) {
                            subValue = value[i];
                            fullSubName = name + "[" + i + "]";
                            innerObj = {};
                            innerObj[fullSubName] = subValue;
                            query += param(innerObj) + "&";
                        }
                    } else if (value instanceof Object) {
                        angular.forEach(value, (subValue, subName) => {
                            fullSubName = name + "[" + subName + "]";
                            innerObj = {};
                            innerObj[fullSubName] = subValue;
                            query += param(innerObj) + "&";
                        });
                    } else if (value !== undefined && value !== null) {
                        query += encodeURIComponent(name) + "=" + encodeURIComponent(value) + "&";
                    }
                });

                return query.length ? query.substr(0, query.length - 1) : query;
            };
            return angular.isObject(data) && String(data) !== "[object File]" ? param(data) : data;
        }

        getUserInfo(accessToken: string): angular.IPromise<angular.IHttpPromiseCallbackArg<IUserInfoModel>> {
            return this.$http({
                url: this.urls.userInfo,
                method: "GET",
                headers: { "Authorization": "Bearer " + accessToken }
            });
        }

        login(data: ILoginModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({
                method: "POST",
                url: this.urls.login,
                data: this.formEncode(data),
                headers: this.formHeader,
                withCredentials: true
            });
        }

        refreshToken(data: IRefreshTokenModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({
                method: "POST",
                url: this.urls.login,
                data: this.formEncode(data),
                headers: this.formHeader
            });
        }

        logout(accessToken: string): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({
                method: "POST",
                url: this.urls.logout,
                headers: { "Authorization": "Bearer " + accessToken }
            });
        }

        register(data: RegistrationModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({ method: "POST", url: this.urls.register, data: data });
        }

        changePassword(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({ method: "POST", url: this.urls.changePassword, data: data });
        }

        forcePasswordChange(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({ method: "POST", url: this.urls.forcePasswordChange, data: data });
        }

        forceSecurityQuestionChange(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({ method: "POST", url: this.urls.forceSecurityQuestionChange, data: data });
        }

        getSecurityQuestion(data: { email: string, tenant: string }): angular.IPromise<angular.IHttpPromiseCallbackArg<IPasswordResetSecretViewModel>> {
            return this.$http({
                url: this.urls.getSecurityQuestion + "?email=" + data.email + "&tenant=" + data.tenant,
                method: "GET"
            });
        }

        getTwoFactorKey(data: IForceTfaSetupViewModel): angular.IPromise<angular.IHttpPromiseCallbackArg<ITwoFactorKeyResults>> {
            return this.$http({ method: "POST", url: this.urls.getTwoFactorKey, data: data });
        }

        verifyTwoFactorKey(data: IForceTfaSetupViewModel): angular.IPromise<angular.IHttpPromiseCallbackArg<boolean>> {
            return this.$http({ method: "POST", url: this.urls.verifyTwoFactorKey, data: data });
        }

        changePasswordWithSecret(data: IPasswordResetWithSecretInputModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({ method: "POST", url: this.urls.changePasswordWithSecret, data: data });
        }

        changePasswordWithKey(data: IChangeUserPasswordWithKeyModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({ method: "POST", url: this.urls.changePasswordWithKey, data: data });
        }

        getExternalLogins(clientId: string): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({
                method: "GET", url: this.urls.externalLogins + "?returnUrl=" + encodeURIComponent(this.urls.site)
                    + "&clientId=" + clientId + "&generateState=true"
            });
        }

        registerExternal(accessToken: string, data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({
                method: "POST",
                url: this.urls.registerExternal,
                data: data,
                headers: { "Authorization": "Bearer " + accessToken }
            });
        }
    }

    export class AuthenticationProvider {
        registerThenLogin: boolean = true;
        usePopups: boolean = false;
        useFullRefreshForLoginUrl = false;
        useFullRefreshForRedirect = false;
        providerUrls: IAuthenticationProviderUrls = {
            login: "/login",
            registerExternal: "/registerExternal",
            postLogout: "/login",
            home: "/"
        };
        events: IAuthenticationEvents = {
            login: null,
            logout: null,
            register: null,
            reloadUser: null,
            closeOAuthWindow: null
        };
        clientId: string;


        $get: Array<any>;

        constructor(public apiUrls: IAuthUrls) {
            this.clientId = "CrossConnect";

            this.$get = ["authentication.api", "$rootScope", "$q", "$http", "$location", "$timeout", "$window", "Idle", "dialogs", (api, $rootScope, $q, $http, $location, $timeout, $window, Idle, dialogs) => {
                return new AuthenticationProviderService(api, this, $rootScope, $q, $http, $location, $timeout, $window, Idle, dialogs);
            }];
        }
    }

    export class AuthenticationProviderService implements IAuthenticationProviderService {
        private externalLoginWindowTimer: any;

        user: IUserInfoModel;
        externalUser: any;
        externalLogins: Array<any>;
        warningDialog: angular.ui.bootstrap.IModalServiceInstance;

        constructor(private api: AuthenticationFactory,
            private authProvider: AuthenticationProvider,
            private $rootScope: ng.IRootScopeService,
            private $q: angular.IQService,
            private $http: angular.IHttpService,
            private $location: angular.ILocationService,
            private $timeout: angular.ITimeoutService,
            private $window: angular.IWindowService,
            private Idle: angular.idle.IIdleService,
            private dialogs: angular.dialogs.IDialogService) {


            $rootScope.$on("IdleStart", () => {
                this.warningDialog = this.dialogs.create("/app/login/logoutDialog.html", "logoutDialog");
            });

            $rootScope.$on("IdleEnd", () => {
                this.warningDialog.close();
            });

            $rootScope.$on("IdleTimeout", () => {
                this.warningDialog.close();
                this.logout();
            });

            this.initialize();
        }

        accessToken(accessToken?: string, refreshToken?: string, persist?: boolean): string {
            if (accessToken) {
                if (accessToken === "clear") {
                    localStorage.removeItem(CrossroadsStorage.refreshToken);
                    sessionStorage.removeItem(CrossroadsStorage.accessToken);
                } else {
                    localStorage[CrossroadsStorage.refreshToken] = refreshToken;
                    sessionStorage[CrossroadsStorage.accessToken] = accessToken;
                    this.$http.defaults.headers.common.Authorization = "Bearer " + accessToken;
                }
            }
            this.$http.defaults.headers.common.Authorization = "Bearer " + sessionStorage[Crossroads.CrossroadsStorage.accessToken];
            return sessionStorage[CrossroadsStorage.accessToken];
        }

        private handleExternalData(externalData: any, provider: string, rememberMe: boolean): angular.IPromise<IUserInfoModel> {
            var deferred = this.$q.defer<IUserInfoModel>();

            //Return if there was an error
            if (externalData.error) {
                deferred.reject({ message: externalData.error });
            } else {
                //Get user info and login or show external register screen
                this.api.getUserInfo(externalData.access_token).then(user => {
                    if (user.data.hasRegistered) {
                        this.accessToken(externalData.access_token);
                        this.user = user.data;
                        appInsights.setAuthenticatedUserContext(user.data.userName);
                        this.redirectAuthenticated(this.redirectTarget() || this.authProvider.providerUrls.home);
                        if (this.authProvider.events.login) {
                            this.authProvider.events.login(this, this.user); // Your Login events
                        }
                        deferred.resolve(this.user);
                    } else {
                        this.externalUser = user.data;
                        this.externalUser.access_token = externalData.access_token;
                        this.externalUser.provider = provider;

                        if (rememberMe != null) {
                            localStorage[CrossroadsStorage.rememberMe] = rememberMe;
                        }

                        this.$location.path(this.authProvider.providerUrls.registerExternal);
                        deferred.reject();
                    }
                });
            }
            return deferred.promise;
        }

        private initialize(): void {
            if (this.$location.path().indexOf("access_token") !== -1) {
                var externalData = this.parseQueryString(this.$location.path().substring(1));
                this.$location.path("/");
                if (window.opener) {
                    window.opener.sessionStorage["external_data"] = externalData;
                    window.close();
                } else {
                    var login = JSON.parse(localStorage[CrossroadsStorage.loginProvider]);
                    var rememberMe = false;
                    if (localStorage[CrossroadsStorage.rememberMe]) {
                        rememberMe = JSON.parse(localStorage[CrossroadsStorage.rememberMe]);
                        delete localStorage[CrossroadsStorage.rememberMe];
                    }
                    delete localStorage[CrossroadsStorage.loginProvider];
                    this.handleExternalData(externalData, login, rememberMe);
                }
            }

            //Check for access token and get user info
            if (this.accessToken()) {
                this.api.getUserInfo(this.accessToken()).then((user) => {
                    this.user = user.data;

                    if (this.authProvider.events.reloadUser) {
                        this.authProvider.events.reloadUser(this, user); // Your Register events
                    }
                });
            }

            //Fetch list of external logins
            this.api.getExternalLogins(this.authProvider.clientId).then((logins) => {
                this.externalLogins = logins.data;
            });
        }

        private parseQueryString(q: string): any {
            var data = {};
            var pair, separatorIndex, escapedKey, escapedValue, key, value;

            if (q === null) {
                return data;
            }

            var pairs = q.split("&");

            for (var i = 0; i < pairs.length; i++) {
                pair = pairs[i];
                separatorIndex = pair.indexOf("=");

                if (separatorIndex === -1) {
                    escapedKey = pair;
                    escapedValue = null;
                } else {
                    escapedKey = pair.substr(0, separatorIndex);
                    escapedValue = pair.substr(separatorIndex + 1);
                }

                key = decodeURIComponent(escapedKey);
                value = decodeURIComponent(escapedValue);

                data[key] = value;
            }

            return data;
        }

        private redirectTarget(newTarget?: string): string {
            if (newTarget === "clear") {
                localStorage.removeItem(CrossroadsStorage.redirectTarget);
                return null;
            }
            if (newTarget && newTarget != "/login") {
                localStorage[CrossroadsStorage.redirectTarget] = newTarget;
            }
            return localStorage[CrossroadsStorage.redirectTarget];
        }

        private setUserInfo(userInfo: IUserInfoModel) {
            sessionStorage[CrossroadsStorage.userName] = userInfo.userName;
            sessionStorage[CrossroadsStorage.firstName] = userInfo.firstName;
            sessionStorage[CrossroadsStorage.lastName] = userInfo.lastName;
            sessionStorage[CrossroadsStorage.fullName] = userInfo.fullName;
            sessionStorage[CrossroadsStorage.tenant] = userInfo.tenant;
            sessionStorage[CrossroadsStorage.hasRegistered] = userInfo.hasRegistered;
            sessionStorage[CrossroadsStorage.loginProvider] = userInfo.loginProvider;
            sessionStorage[CrossroadsStorage.passwordChangedOn] = userInfo.passwordChangedOn;
            sessionStorage[CrossroadsStorage.timeZone] = userInfo.timeZone;
            sessionStorage[CrossroadsStorage.canEditOrganization] = userInfo.canEditOrganization;
            sessionStorage[CrossroadsStorage.canEditProgram] = userInfo.canEditProgram;
            sessionStorage[CrossroadsStorage.canViewUsers] = userInfo.canViewUsers;
            sessionStorage[CrossroadsStorage.canViewReports] = userInfo.canViewReports;
            sessionStorage[CrossroadsStorage.canEditReports] = userInfo.canEditReports;
            sessionStorage[CrossroadsStorage.canViewCreditCardReports] = userInfo.canViewCreditCardReports;
            sessionStorage[CrossroadsStorage.canEditApplications] = userInfo.canEditApplications;
            sessionStorage[CrossroadsStorage.canEditApplicationForms] = userInfo.canEditApplicationForms;
            sessionStorage[CrossroadsStorage.canViewApplicants] = userInfo.canViewApplicants;
            sessionStorage[CrossroadsStorage.canCreateAppExports] = userInfo.canCreateAppExports;
            sessionStorage[CrossroadsStorage.canShareAppExports] = userInfo.canShareAppExports;
            sessionStorage[CrossroadsStorage.canExportApplicants] = userInfo.canExportApplicants;
            sessionStorage[CrossroadsStorage.canViewProspects] = userInfo.canViewProspects;
            sessionStorage[CrossroadsStorage.canEditProspects] = userInfo.canEditProspects;
            sessionStorage[CrossroadsStorage.canCreateProspectExports] = userInfo.canCreateProspectExports;
            sessionStorage[CrossroadsStorage.canExportProspects] = userInfo.canExportProspects;
            sessionStorage[CrossroadsStorage.canCreateTranscriptExports] = userInfo.canCreateTranscriptExports;
            sessionStorage[CrossroadsStorage.canExportTranscripts] = userInfo.canExportTranscripts;
            sessionStorage[CrossroadsStorage.canUpdateTaap] = userInfo.canUpdateTaap;
            sessionStorage[CrossroadsStorage.canUpdateCollegeProfile] = userInfo.canUpdateCollegeProfile;
            sessionStorage[CrossroadsStorage.canSearchResidency] = userInfo.canSearchResidency;
            sessionStorage[CrossroadsStorage.systemAdmin] = userInfo.systemAdmin;
            sessionStorage[CrossroadsStorage.hasSharedExportProject] = userInfo.hasSharedExportProject;
            sessionStorage[CrossroadsStorage.canEditStudentEnrichment] = userInfo.canEditStudentEnrichment;
        }

        private clearUserInfo() {
            sessionStorage.removeItem(CrossroadsStorage.userName);
            sessionStorage.removeItem(CrossroadsStorage.firstName);
            sessionStorage.removeItem(CrossroadsStorage.lastName);
            sessionStorage.removeItem(CrossroadsStorage.fullName);
            sessionStorage.removeItem(CrossroadsStorage.tenant);
            sessionStorage.removeItem(CrossroadsStorage.hasRegistered);
            sessionStorage.removeItem(CrossroadsStorage.loginProvider);
            sessionStorage.removeItem(CrossroadsStorage.passwordChangedOn);
            sessionStorage.removeItem(CrossroadsStorage.timeZone);
            sessionStorage.removeItem(CrossroadsStorage.canEditOrganization);
            sessionStorage.removeItem(CrossroadsStorage.canEditProgram);
            sessionStorage.removeItem(CrossroadsStorage.canViewUsers);
            sessionStorage.removeItem(CrossroadsStorage.canViewReports);
            sessionStorage.removeItem(CrossroadsStorage.canEditReports);
            sessionStorage.removeItem(CrossroadsStorage.canViewCreditCardReports);
            sessionStorage.removeItem(CrossroadsStorage.canEditApplications);
            sessionStorage.removeItem(CrossroadsStorage.canEditApplicationForms);
            sessionStorage.removeItem(CrossroadsStorage.canViewApplicants);
            sessionStorage.removeItem(CrossroadsStorage.canCreateAppExports);
            sessionStorage.removeItem(CrossroadsStorage.canShareAppExports);
            sessionStorage.removeItem(CrossroadsStorage.canExportApplicants);
            sessionStorage.removeItem(CrossroadsStorage.canViewProspects);
            sessionStorage.removeItem(CrossroadsStorage.canEditProspects);
            sessionStorage.removeItem(CrossroadsStorage.canExportProspects);
            sessionStorage.removeItem(CrossroadsStorage.canCreateProspectExports);
            sessionStorage.removeItem(CrossroadsStorage.canCreateTranscriptExports);
            sessionStorage.removeItem(CrossroadsStorage.canExportTranscripts);
            sessionStorage.removeItem(CrossroadsStorage.canUpdateTaap);
            sessionStorage.removeItem(CrossroadsStorage.canUpdateCollegeProfile);
            sessionStorage.removeItem(CrossroadsStorage.canSearchResidency);
            sessionStorage.removeItem(CrossroadsStorage.systemAdmin);
            sessionStorage.removeItem(CrossroadsStorage.activeApplication);
            sessionStorage.removeItem(CrossroadsStorage.hasSharedExportProject);
            sessionStorage.removeItem(CrossroadsStorage.canEditStudentEnrichment);
        }

        getUserInfo(): angular.IPromise<IUserInfoModel> {
            var deferred: angular.IDeferred<IUserInfoModel> = this.$q.defer();
            var userInfo: IUserInfoModel;
            var auth = this;
            this.api.getUserInfo(this.accessToken()).then(result => {
                userInfo = result.data;
                auth.setUserInfo(userInfo);
                deferred.resolve(userInfo);
            }, reason => {
                deferred.reject(reason);
            });
            return deferred.promise;
        }

        getUser(): IUserInfoModel {
            var userInfo: IUserInfoModel;

            if (sessionStorage[CrossroadsStorage.userName]) {
                userInfo = {
                    userName: sessionStorage[CrossroadsStorage.userName],
                    firstName: sessionStorage[CrossroadsStorage.firstName],
                    lastName: sessionStorage[CrossroadsStorage.lastName],
                    fullName: sessionStorage[CrossroadsStorage.fullName],
                    tenant: sessionStorage[CrossroadsStorage.tenant],
                    hasRegistered: sessionStorage[CrossroadsStorage.hasRegistered],
                    loginProvider: sessionStorage[CrossroadsStorage.loginProvider],
                    passwordChangedOn: sessionStorage[CrossroadsStorage.passwordChangedOn],
                    timeZone: sessionStorage[CrossroadsStorage.timeZone],
                    canEditOrganization: sessionStorage[CrossroadsStorage.canEditOrganization] === "true",
                    canEditProgram: sessionStorage[CrossroadsStorage.canEditProgram] === "true",
                    canViewUsers: sessionStorage[CrossroadsStorage.canViewUsers] === "true",
                    canViewReports: sessionStorage[CrossroadsStorage.canViewReports] === "true",
                    canEditReports: sessionStorage[CrossroadsStorage.canEditReports] === "true",
                    canViewCreditCardReports: sessionStorage[CrossroadsStorage.canViewCreditCardReports] === "true",
                    canEditApplications: sessionStorage[CrossroadsStorage.canEditApplications] === "true",
                    canEditApplicationForms: sessionStorage[CrossroadsStorage.canEditApplicationForms] === "true",
                    canViewApplicants: sessionStorage[CrossroadsStorage.canViewApplicants] === "true",
                    canCreateAppExports: sessionStorage[CrossroadsStorage.canCreateAppExports] === "true",
                    canShareAppExports: sessionStorage[CrossroadsStorage.canShareAppExports] === "true",
                    canExportApplicants: sessionStorage[CrossroadsStorage.canExportApplicants] === "true",
                    canViewProspects: sessionStorage[CrossroadsStorage.canViewProspects] === "true",
                    canEditProspects: sessionStorage[CrossroadsStorage.canEditProspects] === "true",
                    canExportProspects: sessionStorage[CrossroadsStorage.canExportProspects] === "true",
                    canCreateProspectExports: sessionStorage[CrossroadsStorage.canCreateProspectExports] === "true",
                    canCreateTranscriptExports: sessionStorage[CrossroadsStorage.canCreateTranscriptExports] === "true",
                    canExportTranscripts: sessionStorage[CrossroadsStorage.canExportTranscripts] === "true",
                    canUpdateTaap: sessionStorage[CrossroadsStorage.canUpdateTaap] === "true",
                    canUpdateCollegeProfile: sessionStorage[CrossroadsStorage.canUpdateCollegeProfile] === "true",
                    canSearchResidency: sessionStorage[CrossroadsStorage.canSearchResidency] === "true",
                    systemAdmin: sessionStorage[CrossroadsStorage.systemAdmin] === "true",
                    activeApplication: sessionStorage[CrossroadsStorage.activeApplication],
                    hasSharedExportProject: sessionStorage[CrossroadsStorage.hasSharedExportProject] === "true",
                    canEditStudentEnrichment: sessionStorage[CrossroadsStorage.canEditStudentEnrichment] === "true"
                };
            }
            return userInfo;
        }

        login(data: ILoginModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();
            var auth = this;

            data.grant_type = "password";
            data.client_id = this.authProvider.clientId;
            if (!data.tenant) {
                data.tenant = "AdvantSystems";
            }

            this.api.login(data)
                .then(result => {
                    auth.accessToken(result.data.access_token, result.data.refresh_token, data.rememberMe);
                    auth.getUserInfo()
                        .then(userInfo => {
                            auth.user = userInfo;
                            appInsights.setAuthenticatedUserContext(userInfo.userName);
                            auth.redirectAuthenticated(this.redirectTarget() || this.authProvider.providerUrls.home);
                            if (auth.authProvider.events.login) {
                                auth.authProvider.events.login(auth, userInfo);
                            }
                        });
                    deferred.resolve(result);
                },
                    reason => {
                        deferred.reject(reason);
                    });

            return deferred.promise;
        }

        loginWithExternal(login: IExternalLoginViewModel, data?: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();
            var self = this;
            if (this.authProvider.usePopups) {
                var loginWindow: Window = window.open(login.url, "frame", "resizeable,height=510,width=380");
                var auth = this;
                // Watch for close event
                this.$timeout.cancel(this.externalLoginWindowTimer);
                this.externalLoginWindowTimer = this.$timeout((closeWatcher) => {
                    if (!loginWindow.closed) {
                        auth.externalLoginWindowTimer = auth.$timeout(closeWatcher, 500);
                        return;
                    }
                    //closeOAuthWindow handler - passes external_data if there is any
                    if (auth.authProvider.events.closeOAuthWindow) {
                        auth.authProvider.events.closeOAuthWindow(auth, window.sessionStorage["external_data"]);
                    }

                    //Return if the window was closed and external data wasn't added
                    if (typeof (window.sessionStorage["external_data"]) === "undefined") {
                        deferred.reject();
                        return;
                    }

                    //Move external_data from global to local
                    var externalData = window.sessionStorage["external_data"];
                    window.sessionStorage.removeItem("external_data");

                    deferred.resolve(self.handleExternalData(externalData, login.url, data.rememberMe));
                }, 500);
            } else {
                if (data != null && data.rememberMe != null) {
                    localStorage[CrossroadsStorage.rememberMe] = JSON.stringify(data.rememberMe);
                }
                localStorage[CrossroadsStorage.loginProvider] = JSON.stringify(login);
                window.location.href = login.url;
            }

            return deferred.promise;
        }

        refreshToken(): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();
            var auth = this;
            var data: IRefreshTokenModel = {
                grant_type: "refresh_token",
                client_id: this.authProvider.clientId,
                refresh_token: localStorage[CrossroadsStorage.refreshToken]
            };

            this.api.refreshToken(data)
                .then((result) => {
                    auth.accessToken(result.data.access_token, result.data.refresh_token);
                    deferred.resolve(result);
                }, (reason) => {
                    this.logout();
                    deferred.reject(reason);
                });

            return deferred.promise;
        }

        logout(): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.user = null;
            this.clearUserInfo();
            appInsights.clearAuthenticatedUserContext();
            this.accessToken("clear");
            this.redirectTarget("clear");
            if (this.authProvider.events.logout) {
                this.authProvider.events.logout(this);
            }
            this.Idle.unwatch();
            window.location.href = this.authProvider.providerUrls.postLogout;

            deferred.resolve();

            return deferred.promise;
        }

        register(data: RegistrationModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.api.register(data).then(result => {
                if (this.authProvider.events.register) {
                    this.authProvider.events.register(this);
                }

                if (this.authProvider.registerThenLogin) {
                    var loginInfo: ILoginModel = {
                        grant_type: "password",
                        userName: data.userName,
                        password: data.password,
                        rememberMe: false
                    };
                    this.login(loginInfo).then(user => {
                        deferred.resolve(user);
                    },
                        reason => {
                            deferred.reject(reason);
                        });
                } else {
                    deferred.resolve();
                }
            }, reason => {
                deferred.reject(reason);
            });
            return deferred.promise;
        }

        registerExternal(): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            if (!this.externalUser) {
                deferred.reject();
            } else {
                this.api.registerExternal(this.externalUser.access_token, this.externalUser).then(result => {
                    deferred.resolve(this.loginWithExternal(this.externalUser.provider));
                }, reason => {
                    deferred.reject(reason);
                });
            }

            return deferred.promise;
        }

        changePassword(data): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.api.changePassword(data).then(result => {
                deferred.resolve();
            }, reason => {
                deferred.reject(reason);
            });

            return deferred.promise;
        }

        changePasswordWithSecret(data): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.api.changePasswordWithSecret(data).then(result => {
                deferred.resolve();
            }, reason => {
                deferred.reject(reason);
            });

            return deferred.promise;
        }

        changePasswordWithKey(data): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.api.changePasswordWithKey(data).then(result => {
                deferred.resolve();
            }, reason => {
                deferred.reject(reason);
            });

            return deferred.promise;
        }

        getSecurityQuestion(data): angular.IPromise<angular.IHttpPromiseCallbackArg<IPasswordResetSecretViewModel>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.api.getSecurityQuestion(data).then(result => {
                deferred.resolve(result);
            }, reason => {
                deferred.reject(reason);
            });

            return deferred.promise;
        }

        forceSecurityQuestionChange(data): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.api.forceSecurityQuestionChange(data).then(result => {
                deferred.resolve();
            }, reason => {
                deferred.reject(reason);
            });

            return deferred.promise;
        }

        getTwoFactorKey(data): angular.IPromise<angular.IHttpPromiseCallbackArg<ITwoFactorKeyResults>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.api.getTwoFactorKey(data).then(result => {
                deferred.resolve(result);
            }, reason => {
                deferred.reject(reason);
            });

            return deferred.promise;
        }

        verifyTwoFactorKey(data): angular.IPromise<angular.IHttpPromiseCallbackArg<boolean>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.api.verifyTwoFactorKey(data).then(result => {
                deferred.resolve(result);
            }, reason => {
                deferred.reject(reason);
            });

            return deferred.promise;
        }

        forcePasswordChange(data): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.api.forcePasswordChange(data).then(result => {
                deferred.resolve();
            }, reason => {
                deferred.reject(reason);
            });

            return deferred.promise;
        }

        authenticate(): boolean {
            if (this.accessToken()) {
                return true;
            }

            if (this.$location.url().indexOf("/login") === -1) {
                this.redirectTarget(this.$location.url());
            }

            if (this.$location.path().indexOf(this.authProvider.providerUrls.login) === -1) {
                if (this.authProvider.useFullRefreshForLoginUrl) {
                    this.$window.location.href = this.authProvider.providerUrls.login;
                } else {
                    this.$location.path(this.authProvider.providerUrls.login);
                }
            }

            return false;
        }

        redirectAuthenticated(url: string): void {
            if (!this.accessToken()) {
                return;
            }
            if (this.redirectTarget()) {
                this.redirectTarget("clear");
            }

            if (this.authProvider.useFullRefreshForRedirect) {
                this.$window.location.href = url;
            } else {
                this.$location.path(url);
            }
        }
    }
}

angular.module("authentication", [])
    .constant("authentication.urls", {
        site: "/",
        register: "/account/register",
        login: "/token",
        logout: "/account/logout",
        userInfo: "/account/userInfo",
        changePassword: "/account/changePassword",
        forcePasswordChange: "/account/forcePasswordChange",
        forceSecurityQuestionChange: "/account/forceChangeQuestion",
        getSecurityQuestion: "/account/getSecurityQuestion",
        getTwoFactorKey: "/account/forceGetTwoFactorKey",
        verifyTwoFactorKey: "/account/forceVerifyTwoFactorKey",
        changePasswordWithSecret: "/account/changePasswordWithSecret",
        changePasswordWithKey: "/account/changePasswordWithKey",
        externalLogins: "/account/externalLogins",
        registerExternal: "/account/registerExternal"
    })
    .factory("authentication.api", ["$http", "authentication.urls", ($http, urls) => new Advant.Crossroads.AuthenticationFactory($http, urls)])
    .provider("authentication", ["authentication.urls", urls => new Advant.Crossroads.AuthenticationProvider(urls)]);