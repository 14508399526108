/// <reference path="../app.ts" />

namespace Advant.Crossroads {
    "use strict";

    interface ILoginWithTfaCodeScope extends angular.IScope {
        vm: ITfaSetup;
        tfaSetupForm: angular.IFormController;
    }

    interface ITfaSetup {
        tfaCodeInfo: IForceTfaSetupViewModel;
        twoFactorKey: string;
        twoFactorQrCodeImage: string;
    }


    class TfaSetup implements ITfaSetup {
        tfaCodeInfo: IForceTfaSetupViewModel;
        errors: Array<any> = [];
        busy: boolean;
        loginInfo: ILoginModel;
        twoFactorKey: string;
        twoFactorQrCodeImage: string;
        twoFactorVerificationFailed = false;

        static $inject: any = ["$scope", "$location", "authentication", "resetPassword"];

        constructor(private $scope: ILoginWithTfaCodeScope,
            private $location: angular.ILocationService,
            private authentication: IAuthenticationProviderService,
            private resetPassword: IResetPassword) {

            this.tfaCodeInfo = {
                userName: this.resetPassword.resetPasswordInfo.userName,
                password: this.resetPassword.resetPasswordInfo.password,
                tenant: this.resetPassword.resetPasswordInfo.tenant,
                tfaCode: null
            };

            this.loginInfo = {
                client_id: this.resetPassword.resetPasswordInfo.client_id,
                userName: this.resetPassword.resetPasswordInfo.userName,
                password: this.resetPassword.resetPasswordInfo.password,
                rememberMe: this.resetPassword.resetPasswordInfo.rememberMe,
                tenant: this.resetPassword.resetPasswordInfo.tenant,
                grant_type: this.resetPassword.resetPasswordInfo.grant_type
            };

            this.getTfaKey();
            this.busy = false;
        }

        getTfaKey() {
            var self = this;

            self.authentication.getTwoFactorKey(self.tfaCodeInfo).then(result => {
                self.twoFactorKey = result.data.twoFactorKey;
                self.twoFactorQrCodeImage = result.data.twoFactorQrCodeImage;
            }, reason => {
                if (reason.data.error_description) {
                    self.errors.push(reason.data.error_description);
                } else {
                    self.errors.push("An error occurred while getting the two-factor information. Please try again later.");
                }
            });
        }

        verifyTfaKey() {
            var self = this;
            self.busy = true;
            self.twoFactorVerificationFailed = false;
            self.authentication.verifyTwoFactorKey(self.tfaCodeInfo).then(result => {
                self.busy = false;
                if (result.data) {
                    self.loginInfo.tfaCode = self.tfaCodeInfo.tfaCode;
                    self.login();
                }
            }, reason => {
                self.twoFactorVerificationFailed = true;                
                if (reason.data.error_description) {
                    self.errors.push(reason.data.error_description);
                } else if(reason.status !== 400) {
                    self.errors.push("An error occurred while getting the two-factor information. Please try again later.");
                }
                self.busy = false;
            });

        }


        login() {
            var self = this;

            self.errors = [];

            self.busy = true;

            self.authentication.login(self.loginInfo).then(result => {
                self.busy = false;
            }, reason => {
                if (reason.data) {
                    if (reason.data.error === "expired_password_missing_secret") {
                        self.resetPassword.resetPasswordInfo = {
                            client_id: self.loginInfo.client_id,
                            userName: self.loginInfo.userName,
                            password: self.loginInfo.password,
                            rememberMe: self.loginInfo.rememberMe,
                            grant_type: self.loginInfo.grant_type,
                            tenant: self.loginInfo.tenant,
                            tfaCode: self.loginInfo.tfaCode,
                            needSecurityQuestions: true
                        };
                        self.$location.path("/login/resetPassword");
                    }

                    if (reason.data.error === "expired_password") {
                        self.resetPassword.resetPasswordInfo = {
                            client_id: self.loginInfo.client_id,
                            userName: self.loginInfo.userName,
                            password: self.loginInfo.password,
                            rememberMe: self.loginInfo.rememberMe,
                            grant_type: self.loginInfo.grant_type,
                            tenant: self.loginInfo.tenant,
                            tfaCode: self.loginInfo.tfaCode,
                            needSecurityQuestions: false
                        };
                        self.$location.path("/login/resetPassword");
                    }

                    if (reason.data.error === "missing_secret") {
                        self.resetPassword.resetPasswordInfo = {
                            client_id: self.loginInfo.client_id,
                            userName: self.loginInfo.userName,
                            password: self.loginInfo.password,
                            rememberMe: self.loginInfo.rememberMe,
                            grant_type: self.loginInfo.grant_type,
                            tenant: self.loginInfo.tenant,
                            tfaCode: self.loginInfo.tfaCode,
                            needSecurityQuestions: false
                        };
                        self.$location.path("/login/changeQuestion");
                    }

                    if (reason.data.error_description) {
                        self.errors.push(reason.data.error_description);
                    }
                }
                else {
                    self.errors.push("An error occurred while logging in. Please try again later.");
                    self.busy = false;
                }

                self.busy = false;
            });
        }
    }

    angular.module("app").controller("tfaSetup", TfaSetup);
}