// Install the angularjs.TypeScript.DefinitelyTyped NuGet package
/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IResidencyLookup {
        activate: () => void;
    }

    interface ResidencyLookup {
        rcn: string;
        firstName: string;
        middleName: string;
        lastName: string;
        addressLine1: string;
        addressLine2: string;
        city: string;
        countyCode: string;
        stateCode: string;
        countryCode: string;
        zipCode: string;
        dob: Date;
        uid: string;
        ssn: string;
        tin: string;
        arn: string;
        uscis: string;
        i94: string;
        statusCode: string;
        recordType: string;
        submitDate: Date;
        startDate: Date;

    }

    interface ResidencyLookupViewModel {
        rcn?: string;
        firstName?: string;
        lastName?: string;
        dob?: Date;
        ssn?: string;
    }

    class ResidencyLookup implements IResidencyLookup {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingResidencyDeterminations: boolean;
        residencyDeterminations: Array<ResidencyLookup>;
        lookupParams: ResidencyLookupViewModel;
        ceebCode: string;
        hasBeenSearched: boolean;

        static $inject: string[] = ["$location", "Restangular", "common", "config"];

        constructor(private $location: angular.ILocationService,
            public Restangular: Restangular.IService,
            public common: ICommonService,
            public config: ICrossroadsConfig) {

            this.log = common.logger.getLogFn("residencyLookup");
            this.logError = common.logger.getLogFn("residencyLookup", "error");
            this.logSuccess = common.logger.getLogFn("residencyLookup", "success");
            this.loadingResidencyDeterminations = false;
            this.residencyDeterminations = [];
            this.hasBeenSearched = false;

            this.lookupParams = {};

            if (this.$location.search().rcn) {
                this.lookupParams.rcn = this.$location.search().rcn;
            }

            if (this.$location.search().lastName) {
                this.lookupParams.lastName = this.$location.search().lastName;
            }

            if (this.$location.search().firstName) {
                this.lookupParams.firstName = this.$location.search().firstName;
            }

            if (this.$location.search().dob) {
                this.lookupParams.dob = this.$location.search().dob;
            }

            if (this.$location.search().ssn) {
                this.lookupParams.ssn = this.$location.search().ssn;
            }

            if (this.$location.search().lastName || this.$location.search().rcn) {
                this.lookup();
            }

            this.activate();
        }

        activate() {
            this.common.activateController([], "residencyLookup")
                .then(result => {
                    this.log("Activated Residency Lookup View");
                });
        }


        lookup = () => {
            this.residencyDeterminations = [];
            this.loadingResidencyDeterminations = true;

            this.Restangular.one("residency").getList("lookup", this.lookupParams).then((result) => {
                this.residencyDeterminations = result;
                this.loadingResidencyDeterminations = false;
                this.hasBeenSearched = true;
            }, (reason) => {
                this.loadingResidencyDeterminations = false;
                this.residencyDeterminations = [];
                this.hasBeenSearched = true;
                this.logError("An error occurred while getting the residency determination", reason);
            });

        };

        clearFilter = () => {
            this.lookupParams = {};
        };
    }

    angular.module("app").controller("residencyLookup", ResidencyLookup);
}