/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IProspectExportProjectDetailsScope extends angular.IScope {
        vm: ProspectExportProjectDetails;
        prospectExportProjectForm: angular.IFormController;
    }

    interface IProspectExportProjectDetailsRouteParams extends angular.route.IRouteParamsService {
        exportProjectId: string;
    }

    interface IProspectExportProjectDetails {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProject: boolean;
        loadingSettings: boolean;
        isSaving: boolean;
        project: IProspectExportProject;
        originalProject: IProspectExportProject;
        rules: Array<IRuleSet>;
        sources: string[];
        includeClosed: boolean;
        fields: Array<IProspectField>;
        illegalFilenameCharacters: RegExp;
    }


    class ProspectExportProjectDetails implements IProspectExportProjectDetails {
        static controllerId: string = "prospectExportProjectDetails";
        static $inject = ["$scope", "$routeParams", "$timeout", "$http", "dialogs", "Restangular", "common", "config", "helper"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProject: boolean;
        loadingSettings: boolean;
        isSaving: boolean;
        project: Advant.Crossroads.IProspectExportProject;
        originalProject: Advant.Crossroads.IProspectExportProject;
        rules: Array<IRuleSet>;
        exportProjectId: any;
        sources: string[];
        includeClosed: boolean;
        fields: Array<IProspectField> = [];
        illegalFilenameCharacters = /^[^\\/:*?<>|\"]+$/;
        outOfDate: boolean;

        constructor(private $scope: IProspectExportProjectDetailsScope,
            private $routeParams: IProspectExportProjectDetailsRouteParams,
            private $timeout: angular.ITimeoutService,
            private $http: angular.IHttpService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: Advant.Crossroads.ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private helper: Advant.Crossroads.IHelper) {

            this.log = common.logger.getLogFn(ProspectExportProjectDetails.controllerId);
            this.logError = common.logger.getLogFn(ProspectExportProjectDetails.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(ProspectExportProjectDetails.controllerId, "success");

            this.loadingSettings = false;

            this.exportProjectId = $routeParams.exportProjectId;

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getProject().then(() => { this.getDynamicFields() }), this.getSources(), this.getRules(), this.checkDates()], ProspectExportProjectDetails.controllerId)
                .then(result => {
                    this.includeClosed = !this.project.openOnly;
                    self.log("Activated Prospect Export Project Details View");
                    return result;
                });
        }

        private getAutoCompleteList(prefix: string) {
            if (!prefix) {
                return [];
            }
            var matchingFields = [];
            angular.forEach(this.fields, (v: Advant.Crossroads.IField, k: any) => {
                var exportFieldKey = "[[" + v.key + "]]";
                var test = exportFieldKey.toLocaleLowerCase().indexOf(prefix.toLocaleLowerCase());
                if (test > -1) {
                    matchingFields.push({ name: exportFieldKey, value: exportFieldKey, score: 100, meta: "export field" });
                }
            });

            return matchingFields;
        }

        private getFields = () => {

            this.fields.push({ key: "Source", title: "Source", selected: false });
            this.fields.push({ key: "DateCreated", title: "DateCreated", selected: false });
            this.fields.push({ key: "DateLastUpdated", title: "DateLastUpdated", selected: false });
            this.fields.push({ key: "ClosedDate", title: "ClosedDate", selected: false });
            this.fields.push({ key: "ReOpenDate", title: "ReOpenDate", selected: false });
            this.fields.push({ key: "Email", title: "Email", selected: false });
            this.fields.push({ key: "Name.First", title: "First Name", selected: false });
            this.fields.push({ key: "Name.Middle", title: "Middle Name", selected: false });
            this.fields.push({ key: "Name.Last", title: "Last Name", selected: false });
            this.fields.push({ key: "Name.Suffix", title: "Suffix", selected: false });
            this.fields.push({ key: "MailingAddress.Street1", title: "Mailing Address Street", selected: false });
            this.fields.push({ key: "MailingAddress.Street2", title: "Mailing Address Street2", selected: false });
            this.fields.push({ key: "MailingAddress.City", title: "Mailing Address City", selected: false });
            this.fields.push({ key: "MailingAddress.State", title: "Mailing Address State", selected: false });
            this.fields.push({ key: "MailingAddress.Country", title: "Mailing Address Country", selected: false });
            this.fields.push({ key: "MailingAddress.Zip", title: "Mailing Address Postal Code", selected: false });
            this.fields.push({ key: "PhysicalAddress.Street1", title: "Physical Address Street", selected: false });
            this.fields.push({ key: "PhysicalAddress.Street2", title: "Physical Address Street2", selected: false });
            this.fields.push({ key: "PhysicalAddress.City", title: "Physical Address City", selected: false });
            this.fields.push({ key: "PhysicalAddress.State", title: "Physical Address State", selected: false });
            this.fields.push({ key: "PhysicalAddress.Country", title: "Physical Address Country", selected: false });
            this.fields.push({ key: "PhysicalAddress.Zip", title: "Physical Address Postal Code", selected: false });
        };

        getDynamicFields = (): angular.IPromise<Object> => {
            var deferred = this.common.$q.defer();

            this.fields = [];
            this.getFields();

            if (this.project.sourceName) {
                this.getAdditionalFields(this.project.sourceName).then((result) => {
                    angular.forEach(result, (value: string) => {
                        this.fields.push({ key: value, title: value, selected: false });
                    });
                    this.fields = _.sortBy(this.fields, "key");
                    deferred.resolve();
                }, (reason) => {
                    deferred.reject(reason);
                });
            }

            return deferred.promise;
        };

        getAdditionalFields = (source: string): angular.IPromise<string[]> => {
            return this.Restangular.one(this.common.getUser().activeApplication).all("prospects").one("sources", source).getList("fields").then((result) => {
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting additional fields for the source", reason);
                return [];
            });
        };

        addSettings = () => {
            var exportSettingsdDialog =
                this.dialogs.create("/app/exportProjects/addExportSettings.html", "addProspectExportSettings", angular.copy(this.project.settings), { size: "lg" });

            exportSettingsdDialog.result.then((result: Advant.Crossroads.IExportSettings[]) => {
                this.$timeout(() => {
                    this.project.settings = result;
                    this.$scope.prospectExportProjectForm.$setDirty();
                });
            });
        };

        cancel() {
            this.project = angular.copy(this.originalProject);
            this.$scope.prospectExportProjectForm.$setPristine();
        }

        deleteSetting = (setting: Advant.Crossroads.IExportSettings): void => {
            _.remove(this.project.settings, { "id": setting.id });
            this.$scope.prospectExportProjectForm.$setDirty();
        };

        getProject = (): angular.IPromise<IExportProject> => {
            var deferred;
            if (this.$routeParams.exportProjectId.toLowerCase() === "new") {
                deferred = this.common.$q.defer<IExportProject>();
                var empty: any = {
                    id: null,
                    applicationDefinitionId: this.common.getUser().activeApplication,
                    name: null,
                    fileNamePattern: null,
                    exportType: "Prospect",
                    filter: null,
                    createdOn: moment().toDate(),
                    createdBy: null,
                    lastUpdated: moment().toDate(),
                    lastUpdatedBy: null,
                    combineEachSettingIntoOnePdfFile: false,
                    combineAllResultsIntoOnePdfFile: false,
                    folderNamePattern: null,
                    maxNumberToDownload: 50,
                    settings: [],
                    openOnly: true
                };
                this.project = empty;
                this.originalProject = angular.copy(this.project);

                deferred.resolve(this.project);


                return deferred.promise;
            }

            return this.Restangular.one(this.common.getUser().activeApplication).one("prospectExportProjects", this.exportProjectId).get().then((result: any) => {
                this.project = result;
                this.originalProject = angular.copy(this.project);
                return result;
            }, (reason) => {
                this.logError("An error occurred while trying to retrieve your settings. Please try again later", reason, true);
                return;
            });
        };

        checkDates = (): angular.IPromise<boolean> => {
            if (this.$routeParams.exportProjectId.toLowerCase() != "new") {
                return this.Restangular.one(this.common.getUser().activeApplication).one("prospectExportProjects", this.exportProjectId).one("check").get().then((result: any) => {
                    this.outOfDate = result;
                    return result;
                }, (reason) => {
                        var message = "Error checking export settings dates!";
                        if (reason.data.modelState) {
                            console.log(reason.data.modelState);
                            angular.forEach(reason.data.modelState, (value: any, key: any) => {
                                if (!_.startsWith(value, 'System.Web.'))
                                    message += "<br/>" + value;
                            });
                        }
                        this.logError(message, reason, true);
                    return;
                });
            }
        }

        updateSettings = () => {
            if (this.$routeParams.exportProjectId.toLowerCase() != "new") {
                return this.Restangular.all(this.common.getUser().activeApplication).all("prospectExportProjects/" + this.exportProjectId).all("updateSettings").post(this.project).then((result: any) => {
                    this.logSuccess("Your prospect export project settings have been updated", result, true);
                    this.getProject();
                    this.checkDates();
                    return result;
                }, (reason) => {
                        var message = "Error updating export settings!";
                        if (reason.data.modelState) {
                            console.log(reason.data.modelState);
                            angular.forEach(reason.data.modelState, (value: any, key: any) => {
                                if (!_.startsWith(value, 'System.Web.'))
                                    message += "<br/>" + value;
                            });
                        }
                        this.logError(message, reason, true);
                    return;
                });
            }
        }

        getSources = (): angular.IPromise<string[]> => {
            return this.Restangular.one(this.common.getUser().activeApplication).all("prospects").all("sources").getList().then((result) => {
                this.sources = result;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting sources", reason);
                return [];
            });
        };

        getRules = (): angular.IPromise<Array<IRuleSet>> => {

            this.rules = [];
            return this.Restangular.one(this.common.getUser().activeApplication).all("rules").getList({ type: "Prospect" }).then((result: any) => {
                this.rules = result;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the rules", reason);
                return;
            });
        };

        save = () => {
            if (this.$scope.prospectExportProjectForm.$valid) {
                this.isSaving = true;
                this.project.openOnly = !this.includeClosed;
                if (this.$routeParams.exportProjectId.toLowerCase() === "new") {
                    this.Restangular.all(this.common.getUser().activeApplication).all("prospectExportProjects").post(this.project).then((result) => {
                        this.isSaving = false;
                        this.originalProject = angular.copy(result);
                        this.project = result;
                        this.logSuccess("Your prospect export project has been saved", result, true);
                        this.$scope.prospectExportProjectForm.$setPristine();
                        return result;
                    }, (reason) => {
                        var message = "Error saving new project!";
                        if (reason.data.modelState) {
                            angular.forEach(reason.data.modelState, (value: any, key: number) => {
                                message += "<br/>" + value;
                            });
                        }
                        if (reason.data.exceptionMessage) {
                            message += "<br/>" + reason.data.exceptionMessage;
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                    });
                } else {
                    this.project.put().then((result) => {
                        this.logSuccess("Your prospect export project has been saved", result, true);
                        this.$scope.prospectExportProjectForm.$setPristine();
                        this.isSaving = false;
                        this.originalProject = angular.copy(this.project);
                        return result;
                    }, (reason) => {
                        var message = "An error occurred while trying to save your project. Please try again later.";
                        if (reason.data.exceptionMessage) {
                            message += "<br/>" + reason.data.exceptionMessage;
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                        return;
                    });
                }
            }
        };
    }
    angular.module("app").controller(ProspectExportProjectDetails.controllerId, ProspectExportProjectDetails);
}