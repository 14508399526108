/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    interface ISideNav extends angular.IDirective {
    }

    interface ISideNavScope extends angular.IScope {
    }

    interface ISideNavAttribute extends angular.IAttributes {
        advSidenav: string;
    }

    class AdvSidenav implements ISideNav {
        static directiveId: string = "advSidenav";
        restrict: string = "A";

        constructor(private $window: angular.IWindowService) {
        }

        link(scope: ISideNavScope, element: angular.IAugmentedJQuery, attrs: ISideNavAttribute) {
            function closeAll() {
                $(".sub-nav").slideUp().parents("li").removeClass("opened");
            }

            function navClick(e: any) {
                e.preventDefault();

                var li = $(this).parents("li");

                if (li.is(".opened")) {
                    closeAll();
                } else {
                    closeAll();
                    li.addClass("opened").find(".sub-nav").slideDown();
                }
            }

            scope.$watch(() => element.attr("class"), classAttr => {
                if (classAttr && classAttr.indexOf("uib-dropdown") !== -1) {
                    element.find("> a").off("click").click(navClick);
                    var mainnav = $("#main-nav"),
                        openActive = mainnav.is(".open-active"),
                        navActive = mainnav.find("> .active");
                    if (openActive && navActive.is(".uib-dropdown")) {
                        navActive.addClass("opened").find(".sub-nav").show();
                    }
                }
            });
        }
    }

    // Update the app1 variable name to be that of your module variable
    angular.module("app").directive(AdvSidenav.directiveId, ["$window", $window =>
        new AdvSidenav($window)
    ]);
}
