/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    export interface ISpinnerDirective extends angular.IDirective {
    }

    export interface IadvSpinnerScope extends angular.IScope {
        spinner: any;
    }

    // Description:
    //  Creates a new Spinner and sets its options
    // Usage:
    //  <div data-adv-spinner="vm.spinnerOptions"></div>
    export class AdvSpinner implements ISpinnerDirective {
        static directiveId: string = "advSpinner";
        restrict: string = "A";

        constructor(public $window) {
        }

        link = (scope: IadvSpinnerScope, element, attrs) => {
            scope.spinner = null;
            scope.$watch(attrs.advSpinner, options => {
                if (scope.spinner) {
                    scope.spinner.stop();
                }
                scope.spinner = new Spinner(options);
                scope.spinner.spin(element[0]);
            }, true);
        };
    }

    // Update the app1 variable name to be that of your module variable
    angular.module("app").directive(AdvSpinner.directiveId, ["$window", $window =>
        new AdvSpinner($window)
    ]);
}
