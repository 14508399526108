/// <reference path="services/advant-auth.ts" />
/// <reference path="app.ts" />
namespace Advant.Crossroads {
    "use strict";

    export interface ICrossroadsEvents {
        controllerActivateSuccess: string;
        spinnerToggle: string;
        applicationChanged: string;
    }

    export interface IKeyCodes {
        backspace: number;
        tab: number;
        enter: number;
        esc: number;
        space: number;
        pageup: number;
        pagedown: number;
        end: number;
        home: number;
        left: number;
        up: number;
        right: number;
        down: number;
        insert: number;
        del: number;
    }

    export interface ICrossroadsConfig {
        appErrorPrefix: string;
        docTitle: string;
        events: ICrossroadsEvents;
        keyCodes: IKeyCodes;
        remoteServiceName: string;
        version: string;
        apiHost: string;
        crossRoadsUrl: string;
        cfncUrl: string;
        environment: string;
        useSignalR: boolean;
    }


    export class Config implements ICrossroadsConfig {
        public appErrorPrefix: string;
        public docTitle: string;
        public events: ICrossroadsEvents;
        public keyCodes: IKeyCodes;
        public remoteServiceName: string;
        public version: string;
        public apiHost: string;
        public crossRoadsUrl: string;
        public cfncUrl: string;
        public environment: string;
        public useSignalR: boolean;

        constructor() {
            this.apiHost = "https://localhost:44353";
            this.crossRoadsUrl = "https://localhost:44389";
            this.cfncUrl = "http://local.cfnc.org/#/";
            this.appErrorPrefix = "[Crossroads Error]";
            this.docTitle = "CrossConnect: ";
            this.environment = "Local";
            this.useSignalR = true;

            this.keyCodes = {
                backspace: 8,
                tab: 9,
                enter: 13,
                esc: 27,
                space: 32,
                pageup: 33,
                pagedown: 34,
                end: 35,
                home: 36,
                left: 37,
                up: 38,
                right: 39,
                down: 40,
                insert: 45,
                del: 46
            };

            this.events = {
                controllerActivateSuccess: "controller.activateSuccess",
                spinnerToggle: "spinner.toggle",
                applicationChanged: "app.applicationChanged"
            };

            this.remoteServiceName = this.apiHost + "/breeze/Breeze";

            this.version = "1.0.0";
        }
    }

    var advConfig = new Advant.Crossroads.Config();
    var app = angular.module("app");

    app.value("config", advConfig);

    app.config(["$httpProvider", "$locationProvider", "authenticationProvider",
        ($httpProvider: angular.IHttpProvider, $locationProvider: angular.ILocationProvider, authenticationProvider: Advant.Crossroads.AuthenticationProvider) => {
            $locationProvider.html5Mode(true);
            authenticationProvider.clientId = "CrossConnectLocal";
            authenticationProvider.useFullRefreshForLoginUrl = true;
            authenticationProvider.useFullRefreshForRedirect = true;

            authenticationProvider.apiUrls.apiHost = advConfig.apiHost;
            $httpProvider.interceptors.push(AuthInterceptor.serviceId);

            moment.updateLocale("en", {
                week: {
                    dow: 0, // First day of week is Monday
                    doy: 6  // First week of year must contain 1 January (7 + 1 - 1)
                }
            });
        }]);

    app.config(["$logProvider", $logProvider => {
        // turn debugging off/on (no info or warn)
        if ($logProvider.debugEnabled) {
            $logProvider.debugEnabled(true);
        }
    }]);

    //#region Configure the common services via commonConfig
    app.config(["commonConfigProvider", cfg => {
        cfg.config.controllerActivateSuccessEvent = advConfig.events.controllerActivateSuccess;
        cfg.config.spinnerToggleEvent = advConfig.events.spinnerToggle;
    }]);
    //#endregion

    app.config(["IdleProvider", "KeepaliveProvider", (IdleProvider, KeepaliveProvider) => {
        // configure Idle settings
        IdleProvider.idle(1800); // in seconds
        IdleProvider.timeout(60); // in seconds 
        KeepaliveProvider.interval(2); // in seconds
    }
    ]);

    //#region Configure the Restangular Service
    app.config(["RestangularProvider", (RestangularProvider: Restangular.IProvider) => {
        RestangularProvider.setBaseUrl(advConfig.apiHost);

        // add a response interceptor
        RestangularProvider.addResponseInterceptor((data, operation, what, url, response, deferred) => {
            var extractedData;
            // .. to look for getList operations
            var test = url;

            if (operation === "getList" &&
                (_.includes(url, "/rules")
                    || _.includes(url, "/majors")
                    || _.includes(url, "/highSchools")
                    || _.includes(url, "/colleges")
                    || _.includes(url, "/majorlists")
                    || _.includes(url, "/userApplications")
                    || _.includes(url, "/applicationEventSubscriptions")
                    || _.includes(url, "/applicationExportSettings")
                    || _.includes(url, "/applicationExportProjects")
                    || _.includes(url, "/prospectExportSettings")
                    || _.includes(url, "/prospectExportProjects")
                    || _.includes(url, "/transcriptExportSettings")
                    || _.includes(url, "/transcriptExportProjects")
                    || _.includes(url, "/transcripts/report/submitted")
                    || _.endsWith(url, "/prospects")
                    || _.endsWith(url, "/applicationHistory")
                    || _.endsWith(url, "/share")
                    || _.endsWith(url, "/promptreport"))
            ){
                // .. and handle the data and meta data
                extractedData = data.results;
                extractedData.totalCount = data.totalCount;
                extractedData.totalPages = data.totalPages;
                return extractedData;
            }

            return data;
        });
    }]);
    //#endregion

    app.config(["$qProvider", ($qProvider) => {
        $qProvider.errorOnUnhandledRejections(false);
    }]);

    app.config(["$compileProvider", ($compileProvider) => {
        $compileProvider.debugInfoEnabled(false);
    }]);

    app.config(["dialogsProvider", "$translateProvider", (dialogsProvider: angular.dialogs.IDialogProvider, $translateProvider: angular.translate.ITranslateProvider) => {
        dialogsProvider.useBackdrop("static");
        dialogsProvider.useEscClose(false);
        dialogsProvider.useCopy(false);
        dialogsProvider.setSize("md");
        dialogsProvider.useFontAwesome();
    }]);

    app.config(["uibDatepickerConfig", (datePickerConfig: angular.ui.bootstrap.IDatepickerConfig) => {
        datePickerConfig.showWeeks = false;
    }]);

    app.config(["$uibModalProvider", ($modalProvider: angular.ui.bootstrap.IModalProvider) => {
        $modalProvider.options.backdrop = "static";
    }]);
}