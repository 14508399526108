/// <reference path="../app.ts" />
// Install the angularjs.TypeScript.DefinitelyTyped NuGet package
namespace Advant.Crossroads {
    "use strict";

    interface ITranscriptReportsRouteParams extends ng.route.IRouteParamsService {
        reportKey: string;
    }

    interface ITranscriptReports {
        reports: any[];
        activeReport: any;
        activate: () => void;
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
    }

    class TranscriptReports implements ITranscriptReports {
        reports: any[];
        activeReport: any;
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;

        static $inject: string[] = ["$location", "$routeParams", "common"];

        constructor(private $location: ng.ILocationService, private $routeParams: ITranscriptReportsRouteParams, private common: ICommonService) {
            this.log = common.logger.getLogFn("transcriptReports");
            this.logError = common.logger.getLogFn("transcriptReports", "error");
            this.logSuccess = common.logger.getLogFn("transcriptReports", "success");

            if ($location.state()) {
                this.activeReport = $location.state().activeReport;
                this.reports = $location.state().reports;
            } else {
                this.activate();
                if ($routeParams.reportKey) {
                    this.activeReport = (<any>_).filter(this.reports, { key: $routeParams.reportKey.toLowerCase() })[0]; // The stupid <any> wrapper was because somebody created a terrible definition for filter and this was the only way around it. -kc
                }
            }

        }

        activate = () => {
            this.common.activateController([this.getReports()], "transcriptReports")
                .then(result => {
                    this.log("Activated Transcript Report View");
                });
        };

        getReports = (): ng.IPromise<any> => {
            var deferred = this.common.$q.defer();
            this.reports = [];

            var transcriptReport = {
                key: "transcriptsubmissions",
                name: "Transcript Submissions",
                templatePath: "/app/reports/static/transcriptsubmittedReport.html"
            };

            this.reports.push(transcriptReport);

            deferred.resolve(this.reports);

            return deferred.promise;
        };

        setActiveReport = (report) => {
            this.$location.state({ activeReport: report, reports: this.reports });
        };
    }

    angular.module("app").controller("transcriptReports", TranscriptReports);
}