/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    export interface ICollegeDetailsRouteParams extends angular.route.IRouteParamsService {
        id: string;
    }
    
    interface ICollegeDetails {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        isSaving: boolean;
        college: ICollege;
        originalCollege: ICollege;
    }

    class CollegeDetails implements ICollegeDetails {
        static controllerId: string = "collegeDetails";
        static $inject: string[] = ["$routeParams", "Restangular", "common", "config","$location"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        isSaving: boolean;
        college: ICollege;
        $q: ng.IQService;
        originalCollege: ICollege;


        constructor(private $routeParams: ICollegeDetailsRouteParams,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private $location: ng.ILocationService) {

            this.log = common.logger.getLogFn(CollegeDetails.controllerId);
            this.logError = common.logger.getLogFn(CollegeDetails.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(CollegeDetails.controllerId, "success")

            this.$q = common.$q;

            this.activate();

        }

        private activate = () => {
            this.common.activateController([this.getCollege()], CollegeDetails.controllerId)
                .then(result => {
                    this.log("Activated Colleges View");
                });
        };


        getCollege = (): angular.IPromise<void> => {
            var id = this.$routeParams.id;

            if (id != "new"){
                return this.Restangular.one("lookup").one("school").one("colleges", id).get().then((result) => {
                    this.college = result;
                    this.originalCollege = angular.copy(this.college);
                }, (reason) => {
                    this.logError("An Error Occurred Getting the High School", reason, true);
                });
            } else {
                return this.$q.when();
            }
        }

        saveCollege = (): angular.IPromise<void> => {
            var id = this.$routeParams.id;
            this.isSaving = true;
            if (id == "new") {
                return this.Restangular
                    .one("lookup")
                    .one("school")
                    .all("colleges")
                    .post(this.college)
                    .then((result) => {
                        this.logSuccess("College Saved Successfully", null, true);
                        this.isSaving = false;
                        this.$location.path("/admin/" + result.id);
                    }, (reason) => {
                        this.logError(reason.data.message, reason, true);
                        this.isSaving = false;
                    });
            } else {
                return this.college.put()
                    .then((result) => {
                        this.logSuccess("College Saved Successfully", null, true);
                        this.isSaving = false;
                        angular.copy(this.college, this.originalCollege);
                    }, (reason) => {
                        this.logError(reason.data.message, reason, true);
                        this.isSaving = false;
                    });
            }

        }

        cancel = () => {
            this.college = angular.copy(this.originalCollege);
        };
    }

    angular.module("app").controller(CollegeDetails.controllerId, CollegeDetails);
}