
/// <reference path="../../app.ts" />
namespace Advant.Crossroads {


    export interface IUserDetailRouteParams extends angular.route.IRouteParamsService {
        email: string;
        tenant: string;
    }

    interface IUserDetailScope extends angular.IScope {
        vm: UserDetail;
        userForm: angular.IFormController;
    }

    interface IUserDetail {
        newUserInfo: IRegistrationViewModel;
        userInfo: IUserViewModel;
        organizations: Array<IOrganization>;
        timeZones: Array<ITimeZone>;
        resources: Array<any>;
        activeResource: any;
        cancel: () => void;
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        selectResource: (r: any) => void;
        saveUser: () => void;
        isSaving: boolean;
        getClaims: () => angular.IPromise<void>;
        getOrganizations: () => angular.IPromise<void>;
        getTimeZones: () => angular.IPromise<void>;
        showPermissions: () => boolean;
        setResourceList: () => void;
        reopen: () => void;
        disableAccount: () => void;
    }

    interface ICrossRouteConnection extends Restangular.IElement {
        connectionId: string;
        key: string;
        secret: string;
        enabled: boolean;
    }

    class UserDetail implements IUserDetail {
        static controllerId: string = "userDetail";
        static $inject: any = ["$scope", "$routeParams", "$uibModal", "dialogs", "$location", "Restangular", "common", "config", "userService"];
        public newUserInfo: IRegistrationViewModel;
        public userInfo: IUserViewModel;
        public organizations: Array<IOrganization> = [];
        public resources: Array<any> = [];
        public activeResource: any;
        public isSaving: boolean;
        public claims: IClaimTypes;
        public timeZones: Array<ITimeZone>;
        public originalUser: any;
        public crossRouteConnection: ICrossRouteConnection;
        public isSavingCRC: boolean;
        public showResetWarning: boolean = false;

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        $q: angular.IQService;

        constructor(private $scope: IUserDetailScope,
            private $routeParams: IUserDetailRouteParams,
            private $uibModal: angular.ui.bootstrap.IModalService,
            private dialogs: angular.dialogs.IDialogService,
            private $location: angular.ILocationService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private userService: IUserService) {

            this.log = common.logger.getLogFn(UserDetail.controllerId);
            this.logError = common.logger.getLogFn(UserDetail.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(UserDetail.controllerId, "success");
            this.$q = common.$q;

            this.originalUser = {};


            this.activate();
        }

        private activate(): void {
            var self = this;
            this.common.activateController([self.getClaims(), self.getTimeZones().then(() => {
                self.getUser().then(() => {
                    self.getOrganizations();
                    self.getCrossRouteConnection();
                });
            })], UserDetail.controllerId)
                .then(result => {
                    self.log("Activated User View", null, false);

                });
        }

        cancel(): void {
            if (this.userInfo) {
                this.userInfo = _.cloneDeep(this.originalUser);
            } else {
                this.newUserInfo = _.cloneDeep(this.originalUser);
            }
        }

        disableAccount(): void {

            var confirm = this.dialogs.confirm("Close Account", "Are you sure you want to close this account?");

            confirm.result.then((button) => {
                this.userService.user.delete({ tenant: this.userInfo.tenant, email: this.userInfo.email },
                    (result) => {
                        this.userInfo.isAccountClosed = true;
                        this.logSuccess(this.userInfo.username + " has been disabled.");
                    }, (reason: any) => {
                        this.logError("An error occurred while trying to disable the user's account!", reason);
                    });
            });
        }

        getClaims = (): angular.IPromise<void> => {
            var self = this;
            self.claims = self.userService.availableClaims.get();
            return self.claims.$promise.then(
                (result) => {
                    self.log("Got Claims Data", null, false);
                    self.claims = result;
                }, reason => {
                    self.logError("Error getting claims", reason, true);
                });
        };

        getTimeZones = (): angular.IPromise<void> => {
            var self = this;

            return self.Restangular.all("timeZones").getList().then(result => {
                self.timeZones = result;
            });
        };

        getOrganizations = (): angular.IPromise<void> => {
            var self = this;

            return self.Restangular.all("organizations").getList().then(result => {
                self.organizations = result;
                self.setResourceList();
            });
        };

        getUser = (): angular.IPromise<void> => {
            var self = this;
            if (self.$routeParams.email) {
                var userResource = self.userService.user.get({ tenant: this.$routeParams.tenant, email: this.$routeParams.email });

                return userResource.$promise.then((result: any) => {
                    self.log("Got User Data", null, false);
                    self.userInfo = result;
                    self.originalUser = _.cloneDeep(self.userInfo);

                }, reason => {
                    self.logError("Error getting user", reason, true);
                });
            } else {
                //  Creates a default claims array for a new user
                var defaultTimeZone = _.find(self.timeZones, { name: 'US/Eastern' });

                self.newUserInfo = {
                    email: "", // needed in order to allow the cancel button to clear out the email address
                    claims: [],
                    timeZone: defaultTimeZone.name

                };

                if (!self.common.getUser().systemAdmin) {
                    self.newUserInfo = {
                        email: "", // needed in order to allow the cancel button to clear out the email address
                        claims: [],
                        tenant: self.common.getUser().tenant,
                        timeZone: defaultTimeZone.name
                    };
                }

                self.originalUser = _.cloneDeep(self.newUserInfo);
                return self.$q.when();
            }
        };

        reopen = () => {
            this.userService.user.reopenAccount(this.userInfo, (result) => {
                this.userInfo.isAccountClosed = false;
                this.logSuccess(this.userInfo.username + " has been reopened.");
            }, (reason: any) => {
                this.logError("An error occurred while trying to reopen the user's account.", reason);
            });
        };

        resetPassword = () => {
            var passwordResetInfo: IResetPasswordViewModel = {
                email: this.userInfo.username,
                tenant: this.userInfo.tenant,
                password: "",
                confirmPassword: "",
                forcePasswordChange: false
            };

            var dialog = this.dialogs.create(
                "/app/admin/users/resetPasswordDialog.html",
                "resetPasswordDialog",
                passwordResetInfo,
                { size: "lg" });

            dialog.result.then((yes) => {
                this.userService.user.resetPassword(passwordResetInfo, (result) => {
                    this.userInfo.isAccountClosed = false;
                    this.logSuccess(this.userInfo.username + "'s password has been reset.");
                }, (reason: any) => {
                    this.logError("An error occurred while trying to reset the user's password.", reason);
                });
            });
        };

        resetTwoFactorKey = () => {
            this.showResetWarning = false;
            this.Restangular.all("users").all(this.userInfo.tenant).all(this.userInfo.email).one("resetTfa").get()
                .then((result) => {
                    this.userInfo.twoFactorConfigured = false;
                    this.logSuccess("Reset the users two-factor authentication key.", null, true);
                }, (reason) => {
                    this.logError("An error occurred while trying to reset the users two-factor authentication key.", reason, false);
                })
        };

        saveUser = () => {
            if (this.$scope.userForm.$valid) {
                this.isSaving = true;
                if (this.$routeParams.email) {
                    this.userService.user.update({ email: this.$routeParams.email }, this.userInfo, (result) => {
                        this.logSuccess("User Saved!");
                        this.isSaving = false;
                        this.$location.path("/admin/users");
                    }, (reason) => {
                        var message = "Error saving new user!";
                        if (reason.data.modelState) {
                            angular.forEach(reason.data.modelState, (value: any, key: any) => {
                                message += "<br/>" + value;
                            });
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                    });

                } else {
                    this.userService.user.save(this.newUserInfo, (result) => {
                        this.logSuccess("User Saved!");
                        this.isSaving = false;
                        this.$location.path("/admin/users");
                    }, (reason) => {
                        var message = "Error saving new user!";
                        if (reason.data.modelState) {
                            angular.forEach(reason.data.modelState, (value: any, key: any) => {
                                message += "<br/>" + value;
                            });
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                    });
                }
            }
        };

        selectResource = (r: any) => {
            this.activeResource = r;

        };


        setResourceList = () => {
            var self = this;

            var activeUser;
            if (self.userInfo) {
                activeUser = self.userInfo;
            } else {
                activeUser = self.newUserInfo;
            }

            if (activeUser && activeUser.tenant && activeUser.tenant !== "AdvantSystems") {
                self.resources = [];
                var activeOrg: IOrganization = <IOrganization>_.find(self.organizations, { tenantName: activeUser.tenant });

                if (!activeOrg) {
                    self.logError("Invalid organization. The selected organizations tenant name could not be found in the available organizations list", null, true);
                    return;
                }

                self.resources.push(
                    {
                        id: activeOrg.id,
                        name: activeOrg.name,
                        type: "Organization"
                    });
                angular.forEach(activeOrg.programs, (progValue: IProgram, progKey) => {
                    self.resources.push(
                        {
                            id: progValue.programId,
                            name: progValue.name,
                            type: "Program"
                        });
                    angular.forEach(progValue.applications, (appValue: IApplicationDefinition, appKey) => {
                        self.resources.push(
                            {
                                id: appValue.applicationDefinitionId,
                                name: appValue.name,
                                type: "ApplicationDefinition"
                            });
                    });
                });
            }
        };

        showPermissions = (): boolean => {
            if (this.common.getUser().systemAdmin) {
                var activeUser;
                if (this.userInfo) {
                    activeUser = this.userInfo;
                } else {
                    activeUser = this.newUserInfo;
                }
                return activeUser && activeUser.tenant && activeUser.tenant !== "AdvantSystems";
            } else {
                return true;
            }
        };

        permissionChecked(claimType: string, claimValue: string) {
            var activeUser;
            if (this.userInfo) {
                activeUser = this.userInfo;
            } else {
                activeUser = this.newUserInfo;
            }
            if (activeUser) {
                var claim = _.find(activeUser.claims, { type: claimType, value: claimValue });
                if (claim) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        removeAppFormPerssions = (claimType: string, claimValue: string, resource: string) => {
            switch (claimType) {
                case this.claims.editOrgAppDef:
                    if (this.permissionChecked(this.claims.editOrgAppDefForm, claimValue)) {
                        this.togglePermission(this.claims.editOrgAppDefForm, claimValue, resource);
                    }
                    break;
                case this.claims.editProgramAppDef:
                    if (this.permissionChecked(this.claims.editProgramAppDefForm, claimValue)) {
                        this.togglePermission(this.claims.editProgramAppDefForm, claimValue, resource);
                    }
                    break;
                case this.claims.editAppDef:
                    if (this.permissionChecked(this.claims.editAppDefForm, claimValue)) {
                        this.togglePermission(this.claims.editAppDefForm, claimValue, resource);
                    }
                    break;
                default:
            }
        };

        addViewProspectPerssions = (claimType: string, claimValue: string, resource: string) => {
            switch (claimType) {
                case this.claims.editOrgProspects:
                    if (!this.permissionChecked(this.claims.viewOrgProspects, claimValue)) {
                        this.togglePermission(this.claims.viewOrgProspects, claimValue, resource);
                    }
                    break;
                case this.claims.editProgramProspects:
                    if (!this.permissionChecked(this.claims.viewProgramProspects, claimValue)) {
                        this.togglePermission(this.claims.viewProgramProspects, claimValue, resource);
                    }
                    break;
                case this.claims.editProspects:
                    if (!this.permissionChecked(this.claims.viewProspects, claimValue)) {
                        this.togglePermission(this.claims.viewProspects, claimValue, resource);
                    }
                    break;
                default:
            }
        };

        addReportPerssions = (claimType: string, claimValue: string, resource: string) => {
            switch (claimType) {
                case this.claims.viewOrgCreditCardReports:
                    if (!this.permissionChecked(this.claims.viewOrgReports, claimValue)) {
                        this.togglePermission(this.claims.viewOrgReports, claimValue, resource);
                    }
                    break;
                case this.claims.viewProgramCreditCardReports:
                    if (!this.permissionChecked(this.claims.viewProgramReports, claimValue)) {
                        this.togglePermission(this.claims.viewProgramReports, claimValue, resource);
                    }
                    break;
                case this.claims.viewCreditCardReports:
                    if (!this.permissionChecked(this.claims.viewReports, claimValue)) {
                        this.togglePermission(this.claims.viewReports, claimValue, resource);
                    }
                    break;
                default:
            }
        };

        addAppExportSettingsPerssions = (claimType: string, claimValue: string, resource: string) => {
            switch (claimType) {
                case this.claims.createOrgApplicantExport:
                    if (!this.permissionChecked(this.claims.exportOrgApplicants, claimValue)) {
                        this.togglePermission(this.claims.exportOrgApplicants, claimValue, resource);
                    }
                    break;
                case this.claims.createProgramApplicantExport:
                    if (!this.permissionChecked(this.claims.exportProgramApplicants, claimValue)) {
                        this.togglePermission(this.claims.exportProgramApplicants, claimValue, resource);
                    }
                    break;
                case this.claims.createApplicantExport:
                    if (!this.permissionChecked(this.claims.exportApplicants, claimValue)) {
                        this.togglePermission(this.claims.exportApplicants, claimValue, resource);
                    }
                    break;
                default:
            }
        };

        addProspectExportSettingsPerssions = (claimType: string, claimValue: string, resource: string) => {
            switch (claimType) {
                case this.claims.createOrgExportProspects:
                    if (!this.permissionChecked(this.claims.exportOrgProspects, claimValue)) {
                        this.togglePermission(this.claims.exportOrgProspects, claimValue, resource);
                    }
                    break;
                case this.claims.createProgramProspectExports:
                    if (!this.permissionChecked(this.claims.exportProgramProspects, claimValue)) {
                        this.togglePermission(this.claims.exportProgramProspects, claimValue, resource);
                    }
                    break;
                case this.claims.createProspectExports:
                    if (!this.permissionChecked(this.claims.exportProspects, claimValue)) {
                        this.togglePermission(this.claims.exportProspects, claimValue, resource);
                    }
                    break;
                default:
            }
        };

        addTranscriptExportSettingsPerssions = (claimType: string, claimValue: string, resource: string) => {
            switch (claimType) {
                case this.claims.createOrgTranscriptExport:
                    if (!this.permissionChecked(this.claims.exportOrgTranscripts, claimValue)) {
                        this.togglePermission(this.claims.exportOrgTranscripts, claimValue, resource);
                    }
                    break;
                case this.claims.createProgramTranscriptExport:
                    if (!this.permissionChecked(this.claims.exportProgramTranscripts, claimValue)) {
                        this.togglePermission(this.claims.exportProgramTranscripts, claimValue, resource);
                    }
                    break;
                case this.claims.createTranscriptExport:
                    if (!this.permissionChecked(this.claims.exportTranscripts, claimValue)) {
                        this.togglePermission(this.claims.exportTranscripts, claimValue, resource);
                    }
                    break;
                default:
            }
        };


        togglePermission(claimType: string, claimValue: string, resource: string) {
            var activeUser;
            if (this.userInfo) {
                activeUser = this.userInfo;
            } else {
                activeUser = this.newUserInfo;
            }

            var claim = _.find(activeUser.claims, { type: claimType, value: claimValue });
            if (claim) {
                var claimLocation = activeUser.claims.indexOf(claim);
                activeUser.claims.splice(claimLocation, 1);
                this.removeAppFormPerssions(claimType, claimValue, resource);
            } else {
                if (claimType === this.claims.role && claimValue === "TenantAdmin") {
                    activeUser.claims = [];
                }
                this.addViewProspectPerssions(claimType, claimValue, resource);
                this.addReportPerssions(claimType, claimValue, resource);
                this.addAppExportSettingsPerssions(claimType, claimValue, resource);
                this.addProspectExportSettingsPerssions(claimType, claimValue, resource);
                this.addTranscriptExportSettingsPerssions(claimType, claimValue, resource);
                claim = {
                    type: claimType,
                    value: claimValue
                };
                activeUser.claims.push(claim);
            }

            this.toggleResourceAccess(activeUser, claimValue, resource);
        }

        toggleResourceAccess = (user, claimValue: string, resource: string) => {

            if (claimValue === "TenantAdmin") {
                return;
            }
            var claimType;

            switch (resource) {
                case "Organization":
                    claimType = this.claims.organizationId;
                    break;
                case "Program":
                    claimType = this.claims.programId;
                    break;
                case "ApplicationDefinition":
                    claimType = this.claims.applicationDefinitionId;
                    break;
            }
            var userClaims = _.find(user.claims, (claim: any) => {
                return claim.value === claimValue && claim.type !== claimType;
            });

            if (userClaims) {
                var resourceClaim = _.find(user.claims, { type: claimType, value: claimValue });
                if (!resourceClaim) {
                    var claim = {
                        type: claimType,
                        value: claimValue
                    };
                    user.claims.push(claim);
                }
            } else {
                _.remove(user.claims, { type: claimType, value: claimValue });
            }

        };

        getCrossRouteConnection() {
            if (this.userInfo.tenant != "CFNC-User") {
                this.Restangular.all("crossrouteadmin").one("crossrouteconnection").get({ userId: this.userInfo.crossroadsUserId })
                    .then((result) => {
                        this.crossRouteConnection = result;
                    }, (reason) => {
                        this.logError("An error occurred while trying to get the CrossRoute Connection.", reason, false);
                    })
            }
        }

        saveCrossRouteConnection() {
            this.isSavingCRC = true;
            this.crossRouteConnection.save({ userId: this.userInfo.crossroadsUserId }).then(() => {
                this.isSavingCRC = false;
                this.logSuccess("CrossRoute Connection Saved!");
            }, (reason) => {
                this.isSavingCRC = false;
                this.logError("An error occurred while trying to save the CrossRoute Connection.", reason, true);
            });

        }
    }

    angular.module("app").controller(UserDetail.controllerId, UserDetail);
}
