/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    interface IAdvEmailAvailable extends angular.IDirective {

    }

    interface IadvEmailAvailableScope extends angular.IScope {
        email: string;
        tenantName: string;
    }

    class AdvEmailAvailable implements IAdvEmailAvailable {
        static directiveId: string = "advEmailAvailable";
        restrict: string = "A";
        require: string = "?ngModel";

        constructor(private $parse: angular.IParseService, private Restangular: Restangular.IService, private common: ICommonService) {
        }

        link = (scope: IadvEmailAvailableScope, element, attrs, controller) => {
            var parsed = this.$parse(attrs.advEmailAvailable);


            scope.$watch(attrs.ngModel, (model: any) => {
                this.common.debouncedThrottle("UserNameAvailibilty", () => {
                    var tenant = this.$parse(attrs.advEmailAvailable)(scope);
                    if (attrs.advOriginalEmail) {
                        var original = this.$parse(attrs.advOriginalEmail)(scope);
                        if (model === original) {
                            controller.$setValidity("available", true);
                            return;
                        }
                    }
                    if (model && tenant) {
                        this.Restangular.all("users")
                            .all(tenant)
                            .all(model)
                            .one("check").get().then((result) => {
                                controller.$setValidity("available", true);
                            }, (reason) => {
                                if (reason.status == 409) {
                                    controller.$setValidity("available", false);
                                } else {
                                    controller.$setValidity("available", true);
                                }
                            });
                    }
                }, 500);
            });
            scope.$watch(attrs.advEmailAvailable, (tenant: string) => {
                this.common.debouncedThrottle("UserNameAvailibilty", () => {
                    var email = this.$parse(attrs.ngModel)(scope);
                    if (attrs.advOriginalEmail) {
                        var original = this.$parse(attrs.advOriginalEmail)(scope);
                        if (email === original) {
                            controller.$setValidity("available", true);
                            return;
                        }
                    }
                    if (tenant && email) {
                        this.Restangular.all("users")
                            .all(tenant)
                            .all(email)
                            .one("check").get().then((result) => {
                                controller.$setValidity("available", true);
                            }, (reason) => {
                                if (reason.status == 409) {
                                    controller.$setValidity("available", false);
                                } else {
                                    controller.$setValidity("available", true);
                                }
                            });
                    }
                }, 500);
            });

        };
    }

    angular.module("app").directive(AdvEmailAvailable.directiveId, ["$parse", "Restangular", "common",
        ($parse: angular.IParseService, Restangular: Restangular.IService, common: ICommonService) =>
            new AdvEmailAvailable($parse, Restangular, common)
    ]);
}