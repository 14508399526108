/// <reference path="../../app.ts" />
namespace Advant.Crossroads {

    export class ApplicationFormFile {

        constructor(public form?: IApplicationForm, public choiceLists?: Array<IChoiceList>, public rules?: Array<IRuleSet>) {
        }


        toJson = () => {
            return angular.toJson(this);
        };

        public static fromJson = (json: string): ApplicationFormFile => {
            var importValue = angular.fromJson(json);
            var returnValue: ApplicationFormFile = new ApplicationFormFile();
            if (importValue) {
                if (importValue.hasOwnProperty("form")) {
                    returnValue.form = importValue.form;
                };

                if (importValue.hasOwnProperty("choiceLists")) {
                    returnValue.choiceLists = importValue.choiceLists;
                };

                if (importValue.hasOwnProperty("rules")) {
                    returnValue.rules = importValue.rules;
                };
            }

            return returnValue;
        }
    }
} 