/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IApplicationExportProjectDetailsScope extends angular.IScope {
        vm: ApplicationExportProjectDetails;
        applicationExportProjectForm: angular.IFormController;
    }

    interface IApplicationExportProjectDetailsRouteParams extends angular.route.IRouteParamsService {
        exportProjectId: string;
    }

    interface IApplicationExportProjectDetails {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProject: boolean;
        loadingSettings: boolean;
        isSaving: boolean;
        project: IApplicationExportProject;
        originalProject: IApplicationExportProject;
        rules: Array<IRuleSet>;
        illegalFilenameCharacters: RegExp;
    }


    class ApplicationExportProjectDetails implements IApplicationExportProjectDetails {
        static controllerId: string = "applicationExportProjectDetails";
        static $inject = ["$scope", "$routeParams", "$timeout", "$http", "dialogs", "Restangular", "common", "config", "helper"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProject: boolean;
        loadingSettings: boolean;
        isSaving: boolean;
        project: IApplicationExportProject;
        originalProject: IApplicationExportProject;
        rules: Array<IRuleSet>;
        exportProjectId: any;
        applicationDefinition: IApplicationDefinition;
        fields: Array<IField>;
        illegalFilenameCharacters = /^[^\\/:*?<>|\"]+$/;
        maxNumberOfDownloads: number;
        outOfDate: boolean;

        constructor(private $scope: IApplicationExportProjectDetailsScope,
            private $routeParams: IApplicationExportProjectDetailsRouteParams,
            private $timeout: angular.ITimeoutService,
            private $http: angular.IHttpService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private helper: IHelper) {

            this.log = common.logger.getLogFn(ApplicationExportProjectDetails.controllerId);
            this.logError = common.logger.getLogFn(ApplicationExportProjectDetails.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(ApplicationExportProjectDetails.controllerId, "success");

            this.loadingSettings = false;

            this.exportProjectId = $routeParams.exportProjectId;

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });
            this.maxNumberOfDownloads = 500;
            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getProject(), this.getRules(), this.getApplicationDefinition(), this.checkDates()], ApplicationExportProjectDetails.controllerId)
                .then(result => {
                    self.log("Activated Application Export Project Details View");
                    return result;
                });
        }

        private getAutoCompleteList(prefix: string) {
            if (!prefix) {
                return [];
            }
            var matchingFields = [];
            angular.forEach(this.fields, (v: IField, k: any) => {
                var exportFieldKey = "[[" + v.key + "]]";
                var test = exportFieldKey.toLocaleLowerCase().indexOf(prefix.toLocaleLowerCase());
                if (test > -1) {
                    matchingFields.push({ name: exportFieldKey, value: exportFieldKey, score: 100, meta: "export field" });
                }
            });

            return matchingFields;
        }

        addSettings = () => {
            var exportSettingsdDialog =
                this.dialogs.create("/app/exportProjects/addExportSettings.html", "addApplicationExportSettings", angular.copy(this.project.settings), { size: "lg" });

            exportSettingsdDialog.result.then((result: IExportSettings[]) => {
                this.$timeout(() => {
                    this.project.settings = result;
                    this.$scope.applicationExportProjectForm.$setDirty();
                    this.setMaxDownload();
                });
            });
        };

        cancel() {
            this.project = angular.copy(this.originalProject);
            this.setMaxDownload();
            this.$scope.applicationExportProjectForm.$setPristine();
        }

        setMaxDownload = () => {
            this.maxNumberOfDownloads = 500;
            angular.forEach(this.project.settings, (setting) => {
                if (setting.$type === "Advant.Crossroads.Core.Export.ApplicationHtmlFileSettings, Advant.Crossroads.Core") {
                    this.maxNumberOfDownloads = 200;
                    if (this.project.maxNumberToDownload > 200) {
                        this.project.maxNumberToDownload = 200;
                    }
                    return;
                }
            });

            if (this.project.settings.length == 1
                && this.project.settings[0].$type === "Advant.Crossroads.Core.Export.ApplicationFlatFileSettings, Advant.Crossroads.Core"
                && this.project.combineEachSettingIntoOneFile) {
                this.maxNumberOfDownloads = 7000
            }
        };

        deleteSetting = (setting: IExportSettings): void => {
            _.remove(this.project.settings, { "id": setting.id });
            this.$scope.applicationExportProjectForm.$setDirty();
            this.setMaxDownload();
        };

        getApplicationDefinition = (): angular.IPromise<IApplicationDefinition> => {
            return this.Restangular.one(this.common.getUser().activeApplication).get().then((result) => {
                this.applicationDefinition = result;
                this.fields = [];

                angular.forEach(this.applicationDefinition.form.sections, (section: ISection) => {
                    angular.forEach(section.groups, (group: IGroup) => {
                        angular.forEach(group.fields, (field) => {
                            this.fields.push(field);
                        });
                    });
                });
                return result;
            }, (reason) => {
                this.logError("Failed to retrieve the application fields", reason);
            });
        };

        getProject = (): angular.IPromise<IExportProject> => {
            var deferred;
            if (this.$routeParams.exportProjectId.toLowerCase() === "new") {
                deferred = this.common.$q.defer<IExportProject>();
                var empty: any = {
                    id: null,
                    applicationDefinitionId: this.common.getUser().activeApplication,
                    name: null,
                    fileNamePattern: null,
                    exportType: "Application",
                    filter: null,
                    createdOn: moment().toDate(),
                    createdBy: null,
                    lastUpdated: moment().toDate(),
                    lastUpdatedBy: null,
                    combineEachSettingIntoOnePdfFile: false,
                    combineAllResultsIntoOnePdfFile: false,
                    folderNamePattern: null,
                    maxNumberToDownload: 50,
                    settings: []
                };
                this.project = empty;
                this.originalProject = angular.copy(this.project);
                deferred.resolve(this.project);

                return deferred.promise;
            }

            return this.Restangular.one(this.common.getUser().activeApplication).one("applicationExportProjects", this.exportProjectId).get().then((result: any) => {
                this.project = result;
                this.project.newExportDate = moment(this.project.newExportDate).toDate();
                this.setMaxDownload();
                this.originalProject = angular.copy(this.project);
                return result;
            }, (reason) => {
                this.logError("An error occurred while trying to retrieve your settings. Please try again later", reason, true);
                return;
            });
        };

        checkDates = (): angular.IPromise<boolean> => {
            if (this.$routeParams.exportProjectId.toLowerCase() != "new") {
                return this.Restangular.one(this.common.getUser().activeApplication).one("applicationExportProjects", this.exportProjectId).one("check").get().then((result: any) => {
                    this.outOfDate = result;
                    return result;
                }, (reason) => {
                    var message = "Error checking export settings dates!";
                        if (reason.data.modelState) {
                            console.log(reason.data.modelState);
                            angular.forEach(reason.data.modelState, (value: any, key: any) => {
                                if (!_.startsWith(value, 'System.Web.'))
                                    message += "<br/>" + value;
                        });
                    }
                    this.logError(message, reason, true);
                    return;
                }); 
            }
        }

        updateSettings = () => {
            if (this.$routeParams.exportProjectId.toLowerCase() != "new") {
                return this.Restangular.all(this.common.getUser().activeApplication).all("applicationExportProjects/" + this.exportProjectId).all("updateSettings").post(this.project).then((result: any) => {
                    this.logSuccess("Your application export project settings have been updated", result, true);
                    this.getProject();
                    this.checkDates();
                    return result;
                }, (reason) => {
                        var message = "Error updating export settings!";
                        if (reason.data.modelState) {
                            console.log(reason.data.modelState);
                            angular.forEach(reason.data.modelState, (value: any, key: any) => {
                                if (!_.startsWith(value, 'System.Web.'))
                                    message += "<br/>" + value;
                            });
                        }
                        this.logError(message, reason, true);
                    return;
                });
            }
        }

        getRules = (): angular.IPromise<void | Array<IRuleSet>> => {

            this.rules = [];
            return this.Restangular.one(this.common.getUser().activeApplication).all("rules").getList().then((result: Array<IRuleSet>) => {
                this.rules = result;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the rules", reason);
                return;
            });
        };

        save = () => {
            if (this.$scope.applicationExportProjectForm.$valid) {
                this.isSaving = true;

                if (this.$routeParams.exportProjectId.toLowerCase() === "new") {
                    this.Restangular.all(this.common.getUser().activeApplication).all("applicationExportProjects").post(this.project).then((result) => {
                        this.isSaving = false;
                        this.originalProject = angular.copy(result);
                        this.project = result;
                        this.project.newExportDate = moment(this.project.newExportDate).toDate();
                        this.logSuccess("Your application export project has been saved", result, true);
                        this.$scope.applicationExportProjectForm.$setPristine();
                        return result;
                    }, (reason) => {
                        var message = "Error saving new project!";
                        if (reason.data.modelState) {
                            angular.forEach(reason.data.modelState, (value: any, key: number) => {
                                message += "<br/>" + value;
                            });
                        }
                        if (reason.data.exceptionMessage) {
                            message += "<br/>" + reason.data.exceptionMessage;
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                    });
                } else {
                    this.project.put().then((result) => {
                        this.logSuccess("Your application export project has been saved", result, true);
                        this.$scope.applicationExportProjectForm.$setPristine();
                        this.isSaving = false;
                        this.originalProject = angular.copy(this.project);
                        this.project.newExportDate = moment(this.project.newExportDate).toDate();
                        return result;
                    }, (reason) => {
                        var message = "An error occurred while trying to save your project. Please try again later.";
                        if (reason.data.exceptionMessage) {
                            message += "<br/>" + reason.data.exceptionMessage;
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                        return;
                    });
                }
            }
        };
    }
    angular.module("app").controller(ApplicationExportProjectDetails.controllerId, ApplicationExportProjectDetails);
}