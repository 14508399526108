/// <reference path="../../app.ts" />

namespace Advant.Crossroads {
    "use strict";

    interface IApplicationPromptsReport {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loading: boolean;
        page: number;
        pageSize: number;
        sortBy: string;
        searchFilter: string;
        sortDirection: string;
        totalCount: number;
        totalPages: number;
        startRow: number;
        endRow: number;
        showFilter: boolean;
        filter: any;
        prompts: Array<any>;
        dataPath: string;

        activate: () => void;
        getPrompts: () => angular.IPromise<void | Array<any>>;
        searchPrompts: () => void;
        sort: (sortValue: string) => void;
    }

    class ApplicationPromptsReport implements IApplicationPromptsReport {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loading: boolean;
        page: number;
        pageSize: number;
        sortBy: string;
        searchFilter: string;
        sortDirection: string;
        totalCount: number;
        totalPages: number;
        startRow: number;
        endRow: number;
        dateStart: string;
        dateEnd: string;
        showFilter: boolean;
        exporting: boolean = false;
        filter: any;
        prompts: Array<any>;
        dataPath = "promptreport";

        static $inject: any = ["$scope", "$location", "$http", "Restangular", "dialogs", "common", "config", "helper"];

        constructor(public $scope: IUserApplicationsScope,
            public $location: angular.ILocationService,
            public $http: angular.IHttpService,
            public Restangular: Restangular.IService,
            public dialogs: angular.dialogs.IDialogService,
            public common: ICommonService,
            public config: ICrossroadsConfig,
            private helper: IHelper) {

            this.log = common.logger.getLogFn("submittedApplicationsReport");
            this.logError = common.logger.getLogFn("submittedApplicationsReport", "error");
            this.logSuccess = common.logger.getLogFn("submittedApplicationsReport", "success");


            this.page = 1;
            this.pageSize = 25;
            this.sortBy = "Key";
            this.sortDirection = "DESC";
            this.searchFilter = "";
            this.showFilter = false;

            this.loading = false;

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.activate();
        }

        activate = () => {
            this.common.activateController([this.getPrompts()], "applicationPromptsReport")
                .then(result => {
                    this.log("Activated UserApplications View");
                });
        };

        applyFilter = () => {
            this.page = 1;
            this.getPrompts();
        };

        clearFilter = () => {
            this.filter = null;
            this.getPrompts();
        };

        exportToExcel = () => {
            this.exporting = true;
            var queryParams = this.getQueryParams();
            var httpPath = this.config.apiHost + "/" + this.common.getUser().activeApplication +
                "/promptreport/export?sortBy=" + encodeURI(queryParams.sortBy) + "&sortDirection=" + queryParams.sortDirection + "&pageSize=0&page=1";

            this.helper.openFile(httpPath).then(() => {
                this.exporting = false;
            }, (reason) => {
                this.logError("An error occurred while saving the report to excel", reason);
            });
        }

        getPrompts = (): angular.IPromise<void | Array<IUserApplicationViewModel>> => {
            this.loading = true;
            this.prompts = [];

            return this.Restangular.one(this.common.getUser().activeApplication).getList(this.dataPath, this.getQueryParams()).then((result: any) => {
                this.prompts = result;
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loading = false;
                return result;
            }, (reason) => {
                this.loading = false;
                this.logError("An error occurred while getting the user applications", reason);
            });
        };


        getQueryParams = (): any => {
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                filter: this.searchFilter
            };

            return queryParams;
        };

        searchPrompts = (): void => {
            this.common.debouncedThrottle("UserApplicationSearch", () => { this.page = 1; this.getPrompts(); }, 400);
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.getPrompts();
        };
    }

    angular.module("app").controller("applicationPromptsReport", ApplicationPromptsReport);
}