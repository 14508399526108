/// <reference path="../app.ts" />
namespace Advant.Crossroads {


    interface IAdvFormGroup extends angular.IDirective {

    }

    interface IAdvFormGroupScope extends angular.IScope {
        group: IGroup;
        response: any;
        formattedLayout: any;
    }

    class AdvFormGroup implements IAdvFormGroup {
        static directiveId: string = "advFormGroup";
        templateUrl = "/app/directives/advFormGroup.html";
        restrict: string = "E";
        scope: any = {
            group: "=",
            response: "="
        };

        link = (scope: IAdvFormGroupScope, element, attrs) => {
            scope.formattedLayout = {};
            scope.$watch("group", (newValue: IGroup, oldValue: IGroup, originalScope: IAdvFormGroupScope) => {
                if (newValue.layout) {
                    originalScope.formattedLayout.html = this.compileLayout(newValue.layout, newValue);
                }
            });
        };

        compileLayout(layout: string, group: IGroup): string {
            var formattedHtml: string;
            if (layout) {
                formattedHtml = layout;
                angular.forEach(group.fields, (field: IField, key: number) => {
                    formattedHtml = formattedHtml.replace("[[" + field.key + "]]", "<adv-form-field field=\"group.fields[" + key + "]\" response=\"response\"></adv-form-field>");
                });
            }
            return formattedHtml;
        }
    }

    angular.module("app").directive(AdvFormGroup.directiveId, () => new AdvFormGroup());
}