/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IFieldInUseModalScope extends ng.IScope {
        close: () => void;
    }

    interface IFieldInUseModal {
        
    }

    class FieldInUseModal implements IFieldInUseModal {
        static $inject: Array<string> = ["$uibModalInstance", "ruleSets", "elementType", "fields", "common"];
        static controllerId: string = "FieldInUseModal";
        private log;
        associatedRules: any;

        constructor(private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance, private ruleSets: IRuleSet[], private elementType: string, private fields: string[], common: ICommonService) {
            this.log = common.logger.getLogFn(FieldInUseModal.controllerId);

            this.associatedRules = [];

            fields.forEach(field => {
                var associatedRule = { field: field, ruleSets: [] };
                ruleSets.forEach(ruleSet => {
                    ruleSet.groups.forEach(group => {
                        if (_.some(group.rules, { codeLeftValue: field }) || _.some(group.rules, {valueRightValue: "[[" + field + "]]"})) {
                            associatedRule.ruleSets.push(ruleSet);
                        }
                    });
                });

                if (associatedRule.ruleSets.length > 0) {
                    this.associatedRules.push(associatedRule);
                }
            });
            
        }

        close() {
            this.$uibModalInstance.dismiss();
        }

      
    }

    angular.module("app").controller(FieldInUseModal.controllerId, FieldInUseModal);
} 