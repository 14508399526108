/// <reference path="../common/common.ts" />
/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    "use strict";

    interface IPasswordChangeModel {
        originalPassword: string;
        password: string;
        confirmPassword: string;
    }

    export interface IProfileRouteParams extends angular.route.IRouteParamsService {
        activeTab: any;
    }

    interface IProfile {
        profile: any;
        activate: () => void;
        changePasswordModel: IPasswordChangeModel;
    }

    class Profile implements IProfile {
        tabs: any;
        changePasswordActive: boolean;
        profile: IUserViewModel;
        originalProfile: IUserViewModel;
        isSaving = false;
        timeZones: Array<ITimeZone>;
        twoFactorKey: string;
        twoFactorQrCodeImage: string;
        twoFactorVerifyCode: string;
        twoFactorVerificationFailed = false;
        changePasswordModel: IPasswordChangeModel;
        changeQuestionModel: ISecurityQuestionChangeModel;
        availableApplications: Array<any>;
        showResetWarning: boolean;
        addAuthenticatorApp: boolean = false;

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;

        static $inject: string[] = ["$routeParams", "$location", "Restangular", "common"];

        constructor(private $routeParams: IProfileRouteParams,
            private $location: angular.ILocationService,
            private Restangular: Restangular.IService,
            private common: ICommonService) {

            this.log = common.logger.getLogFn("profile");
            this.logError = this.common.logger.getLogFn("profile", "error");
            this.logSuccess = this.common.logger.getLogFn("profile", "success");

            if (this.$routeParams.activeTab) {
                this.changePasswordActive = this.$routeParams.activeTab === "changePassword";
            }

            this.activate();
        }

        activate = () => {
            this.common.activateController([this.getProfile(), this.getTimeZones(), this.getTwoFactorKey(), this.loadAvailableApplications()], AdminApplicationDetailController.controllerId)
                .then(result => {
                    this.log("Activated Profile View");
                });
        };

        cancel = () => {
            this.profile = this.originalProfile;
        };

        cancelPassword = () => {
            this.changePasswordModel = {
                originalPassword: null,
                password: null,
                confirmPassword: null
            };
        };

        cancelQuestion = () => {
            this.changeQuestionModel = {
                question: null,
                answer: null
            };
        };

        getProfile = () => {
            return this.Restangular.all("account").one("profile").get().then((result: IUserViewModel) => {
                this.profile = result;
                this.originalProfile = angular.copy(this.profile);
            }, (reason) => {
                this.logError("Failed to retrieve profile data.", reason, true);
            });
        };

        getTimeZones = (): angular.IPromise<void> => {
            return this.Restangular.all("timeZones").getList().then(result => {
                this.timeZones = result;
            });
        };

        getTwoFactorKey = (): angular.IPromise<void> => {
            return this.Restangular.all("account").one("getTwoFactorKey").get({ generateNewKey: false }).then(result => {
                this.twoFactorKey = result.twoFactorKey;
                this.twoFactorQrCodeImage = result.twoFactorQrCodeImage;
            });
        };

        getNewTwoFactorKey = (): angular.IPromise<void> => {
            this.profile.twoFactorConfigured = false;
            this.showResetWarning = false;
            return this.Restangular.all("account").one("getTwoFactorKey").get({ generateNewKey: true }).then(result => {
                this.twoFactorKey = result.twoFactorKey;
                this.twoFactorQrCodeImage = result.twoFactorQrCodeImage;
            });
        };

        verifyTwoFactorKey = () => {
            this.isSaving = true;
            this.Restangular.all("account").one("verifyTwoFactorKey").get({ token: this.twoFactorVerifyCode }).then(result => {
                this.isSaving = false;
                this.profile.twoFactorConfigured = true;
                this.addAuthenticatorApp = false;
                this.twoFactorVerifyCode = null;
                this.logSuccess("Two-Factor Code Verified.", null, true);
            }, (reason) => {
                this.isSaving = false;
                this.twoFactorVerificationFailed = true;
            });
        };

        private loadAvailableApplications = (): angular.IPromise<any> => {
            return this.Restangular.all("account").all("organizations").getList().then(result => {
                this.availableApplications = [];

                angular.forEach(result, (org: IOrganization, key) => {
                    var sortedProg = _.sortBy(org.programs, "name");
                    angular.forEach(sortedProg, (prog: IProgram, progKey) => {
                        var sortedApps = _.sortBy(prog.applications, "name");
                        angular.forEach(sortedApps, (app: IApplicationDefinition, appKey) => {
                            var appView: any = {
                                applicationDefinitionId: app.applicationDefinitionId,
                                name: app.name,
                                program: null
                            };

                            if (this.common.getUser().systemAdmin) {
                                appView.program = org.name + " - " + prog.name;
                            } else {
                                appView.program = prog.name;
                            }
                            this.availableApplications.push(appView);
                        });
                    });
                });
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the organizations", reason);
            });
        };

        saveProfile = () => {
            this.isSaving = true;
            this.Restangular.all("account").all("profile").post(this.profile).then(() => {
                this.logSuccess("Saved profile changes.", null, true);
                this.originalProfile = this.profile;
                this.isSaving = false;
            }, (reason) => {
                if (reason.data.modelState[""][0] == "Username already in use.")
                    this.logError("Failed to save profile data. Username already in use.", reason, true);
                else
                    this.logError("Failed to save profile data.", reason, true);
                this.isSaving = false;
            });
        };

        changePassword = () => {
            this.isSaving = true;
            this.Restangular.all("account").all("ChangePassword").post(this.changePasswordModel).then(() => {
                this.isSaving = false;
                this.changePasswordModel = {
                    originalPassword: null,
                    password: null,
                    confirmPassword: null
                };
                this.logSuccess("Saved password changes.", null, true);
            }, (reason) => {
                this.isSaving = false;
                if (reason.status && reason.status === 400 && reason.data && reason.data.modelState) {
                    if (reason.data.modelState.originalPassword) {
                        this.logError(reason.data.modelState.originalPassword, reason, true);
                    }
                    else if (reason.data.modelState.password) {
                        this.logError(reason.data.modelState.password, reason, true);
                    }
                } else {
                    this.logError("Failed to save password changes.", reason, true);
                }
            });
        };

        changeQuestion = () => {
            this.isSaving = true;
            this.Restangular.all("account").all("ChangeQuestion").post(this.changeQuestionModel).then(() => {
                this.isSaving = false;
                this.changeQuestionModel = {
                    question: null,
                    answer: null
                };
                this.logSuccess("Saved security question changes.", null, true);
            }, (reason) => {
                this.isSaving = false;
                if (reason.status && reason.status === 400 && reason.data && reason.data.modelState) {
                    if (reason.data.modelState.currentPassword) {
                        this.logError(reason.data.modelState.currentPassword, reason, true);
                    }
                    else if (reason.data.modelState.password) {
                        this.logError(reason.data.modelState.password, reason, true);
                    }
                } else {
                    this.logError("Failed to save security question changes.", reason, true);
                }
            });
        };
    }

    angular.module("app").controller("profile", Profile);
}