/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict"

    export interface ICrossRouteDownloadScope extends ng.IScope {
        vm: any;
    }

    interface IReleaseNote {
        id: string;
        version: string;
        releaseDate: Date;
        remarks: [string];
    }

    export interface ICrossRouteDownloadController {
        qString: number;
        releaseNotes: IReleaseNote[]

    }

    export class CrossRouteDownloadController implements ICrossRouteDownloadController {
        static controllerId: string = "crossRouteDownload";
        static $inject = ["$scope", "$location", "common", "config", "Restangular"];

        log: (msg, data?, showHowl?) => void;
        qString: number = Date.now();
        releaseNotes: IReleaseNote[];

        constructor(
            private $scope: ICrossRouteDownloadScope,
            private $location: ng.ILocationService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private Restangular: Restangular.IService) {
            this.log = common.logger.getLogFn(CrossRouteDownloadController.controllerId);
            this.activate();
        }

        private activate() {
            var self = this;
            this.Restangular.one("crossroute").getList<IReleaseNote>("releasenotes", { page: 1, pageSize: 10 }).then(
                (response) => {
                    this.releaseNotes = response;
                },
                (reason) => {
                    this.log(reason, null, true);
                }
                
            );
            this.common.activateController([], CrossRouteDownloadController.controllerId)
                .then(result=> {
                self.log("Activated CrossRoute View");
                
            });
        }

    }

    angular.module("app").controller(CrossRouteDownloadController.controllerId, CrossRouteDownloadController);
}  