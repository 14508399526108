/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    interface ICfncUniqueTermCode extends ng.IDirective {

    }

    interface ICfncUniqueTermCodeScope extends ng.IScope {
        cfncUniqueTermCode: any;
        ngModel: any;
    }

    class CfncUniqueTermCode implements ICfncUniqueTermCode {
        static directiveId: string = "cfncUniqueTermCode";
        restrict: string = "A";
        require: string = "?ngModel";
        scope: any = {
            cfncUniqueTermCode: "=",
            ngModel: "="
        };

        constructor(private $parse: ng.IParseService, private Restangular: Restangular.IService, private common: ICommonService) {
        }

        link = (scope: ICfncUniqueTermCodeScope, element, attrs, controller) => {

            scope.$watch("ngModel", (newModelValue, oldModleValue, scope: ICfncUniqueTermCodeScope) => {
                this.common.debouncedThrottle("UniqueTermCode", () => {
                    if (newModelValue !== oldModleValue) {
                        var tempKeyArray = [];
                        angular.forEach(scope.cfncUniqueTermCode, (term, key) => {
                            if (term.code.toLowerCase() == scope.ngModel.toLowerCase()) {
                                tempKeyArray.push(term);
                            }
                        });
                        var compare = newModelValue.toString();
                        var duplicateName = _.filter(tempKeyArray, (value) => {
                            return value.code.toLowerCase() == compare.toLowerCase();
                        });

                        if (duplicateName.length > 1) {
                            controller.$setValidity("unique", false);
                        } else {
                            controller.$setValidity("unique", true);
                        }

                    }
                }, 500);
            });

        };
    }

    angular.module("app").directive(CfncUniqueTermCode.directiveId, ["$parse", "Restangular", "common",
        ($parse: ng.IParseService, Restangular: Restangular.IService, common: ICommonService) =>
            new CfncUniqueTermCode($parse, Restangular, common)
    ]);
}