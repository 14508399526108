namespace Advant.Crossroads {

    interface IShareExportProjectScope extends angular.IScope {
        save: () => void;
        cancel: () => void;
        delete: (share) => void;
        email: string;
        exportProject: IExportProject;
        shares: IShare[];
        busy: boolean;
        alert: ShareAlert;
        hasTranscriptSetting: boolean;
    }

    interface IShareExportProject {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
    }

    interface IShare {
        applicationDefinitionId: string;
        applicationName: string;
        email: string;
        exportProjectId: string;
        id: string;
        sharedBy: ILimitedUser;
        sharedDate: Date;
        UserId: string;

    }

    class Share {
        applicationDefinitionId: string;
        applicationName: string;
        email: string;
        exportProjectId: string;
        id: string;
        sharedBy: ILimitedUser;
        sharedDate: Date;
        UserId: string;
    }
    

    class ShareAlert {
        message: string;
        type: string;
        show: boolean;
        errors: any[];
    }

    

    export class ShareExportProject implements IShareExportProject {
        static controllerId: string = "shareExportProject";
        static $inject: any = ["$scope", "$uibModalInstance", "common", "config", "Restangular", "$timeout", "data"];
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        timer: angular.IPromise<void>;

        constructor(private $scope: IShareExportProjectScope,
            private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private Restangular: Restangular.IService,
            private $timeout: angular.ITimeoutService,
            private project?: IExportProject) {
            this.log = common.logger.getLogFn(ShareExportProject.controllerId);
            this.logError = this.common.logger.getLogFn(ShareExportProject.controllerId, "error");

            this.getShares();

            $scope.save = () => this.save();
            $scope.cancel = () => this.cancel();
            $scope.delete = (share) => this.delete(share);
            $scope.exportProject = project;
            $scope.busy = false;
            $scope.alert = new ShareAlert();
            $scope.hasTranscriptSetting = this.hasTranscriptSetting();

        }

        private save() {
            this.$scope.busy = true;
            var that = this;
            var s = _.find(this.$scope.shares, (share: IShare) => {
                return share.email.toLowerCase() == that.$scope.email.toLowerCase();
            });
            var share = new Share();
            
            share.email = this.$scope.email
            

            if (s == null) {
                this.$scope.shares.push(share);
            }

            this.Restangular.all(this.project.applicationDefinitionId).all(this.project.id).all("share")
                .post('"' + this.$scope.email + '"').then(() => {
                    this.log("Export project shared with " + this.$scope.email + ".");
                    
                    this.messageTimeout("Export project shared with " + this.$scope.email + ".", "success");

                    this.getShares();
                    this.$scope.busy = false;
                }, (reason) => {
                    _.remove(this.$scope.shares, (x) => { return x.email == share.email });
                    this.$scope.busy = false;
                    this.logError("An error has occurred while sharing this export project.", reason);
                    
                    this.messageTimeout("An error has occurred while sharing this export project.", "error", reason);
                });
        }

        private cancel() {
            this.$uibModalInstance.dismiss();
        }

        private getShares() {
            var that = this;
            this.Restangular.all(this.project.applicationDefinitionId).all(this.project.id).all("share")
                .getList().then((result) => {
                    that.$scope.shares = result;
                }, (reason) => {
                    this.logError("An error has occurred while sharing this export project.", reason);
                    this.messageTimeout("An error has occurred while sharing this export project.", "error", reason);
                })
        }

        private delete(share: any) {
            share.deleting = true;
            
            this.Restangular.all(this.project.applicationDefinitionId).all(this.project.id).all("unshare").one(share.id)
                .remove().then(() => {
                    this.log("Shared access removed.", null);
                    _.remove(this.$scope.shares, (x) => { return x.id == share.id });
                    this.messageTimeout("Shared access removed.", "success");
                }, (reason) => {
                    this.$scope.shares.push(share);
                    this.logError("An error has occurred removing the share.", reason);

                    this.messageTimeout("An error has occurred removing the share.", "error", reason);
                });
        }

        private messageTimeout(message: string, type: string, reason?:any) {
            
            this.$scope.alert = {
                message: message,
                type: type,
                show: true,
                errors: reason != null && reason.data != null && reason.data.modelState != null ? reason.data.modelState["an Error has occurred"] : null
            };

            this.$timeout.cancel(this.timer);

            this.timer = this.$timeout(() => {
                this.$scope.alert = null;
            }, 5000);

            
        }

        private hasTranscriptSetting() {

            return this.$scope.exportProject.settings.some(setting => setting.$type == "Advant.Crossroads.Core.Export.ApplicationTranscriptSettings, Advant.Crossroads.Core");
            
        }
        
    }
    angular.module("app").controller(ShareExportProject.controllerId, ShareExportProject);
}