// Install the angularjs.TypeScript.DefinitelyTyped NuGet package
namespace Advant.Crossroads {
    "use strict";

    interface IProcessorPaymentsScope extends angular.IScope {
        vm: ProcessorPayments;
    }

    interface IProcessorPayments {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingPayments: boolean;
        payments: IPaymentDetails[];
        page: number;
        pageSize: number;
        sortBy: string;
        searchFilter: string;
        sortDirection: string;
        totalCount: number;
        totalPages: number;
        startRow: number;
        endRow: number;
        dateStart: string;
        dateEnd: string;
        showFilter: boolean;
        email: string;
        exporting: boolean;
        refunding: boolean;
        filter: any;

        activate: () => void;
        getPaymentHistory: () => angular.IPromise<IPaymentDetails[]>;
        searchPaymentHistory: () => void;
        sort: (sortValue: string) => void;
    }

    class ProcessorPayments implements IProcessorPayments {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingPayments: boolean;
        payments: IPaymentDetails[];
        page: number;
        pageSize: number;
        sortBy: string;
        searchFilter: string;
        sortDirection: string;
        totalCount: number;
        totalPages: number;
        startRow: number;
        endRow: number;
        dateStart: string;
        dateEnd: string;
        showFilter: boolean;
        email: string;
        exporting: boolean = false;
        refunding: boolean = false;
        filter: any;

        static $inject: any = ["$scope", "$location", "$http", "Restangular", "dialogs", "common", "config", "helper"];

        constructor(private $scope: IProcessorPaymentsScope,
            public $location: angular.ILocationService,
            public $http: angular.IHttpService,
            public Restangular: Restangular.IService,
            public dialogs: angular.dialogs.IDialogService,
            public common: ICommonService,
            public config: ICrossroadsConfig,
            private helper: IHelper) {

            this.log = common.logger.getLogFn("processorPayments");
            this.logError = common.logger.getLogFn("processorPayments", "error");
            this.logSuccess = common.logger.getLogFn("processorPayments", "success");


            this.page = 1;
            this.pageSize = 20;
            this.sortBy = "create_time";
            this.sortDirection = "desc";
            this.searchFilter = "";
            this.showFilter = false;

            this.loadingPayments = false;

            this.$scope.$on(config.events.applicationChanged,(event) => {
                this.$location.path("/");
            });

            this.activate();
        }

        activate() {
            this.common.activateController([this.getPaymentHistory()], "processorPayments")
                .then(result => {
                    this.log("Activated Payment History View");
                });
        }

        applyFilter = () => {
            if (this.filter) {
                this.dateEnd = this.filter.dateEnd ? this.filter.dateEnd : "";
                this.dateStart = this.filter.dateStart ? this.filter.dateStart : "";
            }

            this.page = 1;
            this.getPaymentHistory();
        };

        clearFilter = () => {
            this.dateStart = null;
            this.dateEnd = null;
            this.email = null;
            this.filter = null;
            this.getPaymentHistory();
        };

        exportToExcel = () => {
            this.exporting = true;
            var queryParams = this.getQueryParams();
            var httpPath = this.config.apiHost + "/" + this.common.getUser().activeApplication +
                "/reports/payments/export?sortBy=" + encodeURI(queryParams.sortBy) + "&sortDirection=" + queryParams.sortDirection +
                "&startDate=" + queryParams.startDate +
                "&endDate=" + queryParams.endDate;

            this.helper.openFile(httpPath).then(() => {
                this.exporting = false;
            }, (reason) => {
                this.logError("An error occurred while saving the report to excel", reason);
            });
        }

        getPaymentHistory = (): angular.IPromise<IPaymentDetails[]> => {
            this.loadingPayments = true;
            this.payments = [];

            return this.Restangular.one(this.common.getUser().activeApplication).one("reports/payments").get(this.getQueryParams()).then((result) => {
                this.payments = result.results;
                this.totalCount = result.totalCount;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.results.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingPayments = false;
                return result;
            }, (reason) => {
                this.loadingPayments = false;
                if (reason.data && reason.data.exceptionType === "System.NotImplementedException") {
                    this.logError("Your current credit card processor does not support reporting.", reason, true);
                } else if (reason.data && reason.data.message) {
                    this.logError(reason.data.message, reason, true);
                } else {
                    this.logError("An error occurred while getting the payment history", reason, true);
                }
            });
        };

        getQueryParams = (): any => {
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                startDate: this.dateStart ? (<any>moment).tz(this.dateStart, this.common.getUser().timeZone).toDate() : "", /*Have to convert moment to any since the moment timezone definitions is not working right now*/
                endDate: this.dateEnd ? (<any>moment).tz(this.dateEnd, this.common.getUser().timeZone).add(23, "hours").add(59, "minutes").add(59, "seconds").toDate() : "",
                email: this.email
            };

            return queryParams;
        };

        refund = (paymentId: string) => {
            var confirm = this.dialogs.confirm("Refund Payment", "Are you sure you want to refund this payment? This action cannot be reversed.");

            confirm.result.then((button) => {
                this.refunding = true;
                return this.Restangular.one(this.common.getUser().activeApplication).one("payments", paymentId).one("refund").get().then((result) => {
                    this.refunding = false;
                    return result;
                }, (reason) => {
                    this.refunding = false;
                    this.logError("An error occurred while refunding the payment. Please go to your processors website and try to refund the payment from there.", reason, true);
                });
            });
        };

        searchPaymentHistory = (): void => {
            this.common.debouncedThrottle("UserApplicationSearch", () => { this.page = 1; this.getPaymentHistory(); }, 400);
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.getPaymentHistory();
        };
    }

    angular.module("app").controller("processorPayments", ProcessorPayments);

}