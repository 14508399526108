/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IApplicationExportProjectDownloadScope extends angular.IScope {
        vm: ApplicationExportProjectDownload;
        applicationExportProjectForm: angular.IFormController;
    }

    interface IApplicationExportProjectDownloadRouteParams extends angular.route.IRouteParamsService {
        applicationDefinitionId: string;
        exportProjectId: string;
        count: number;
        fileId: string;
    }

    interface IApplicationExportProjectDownload {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProject: boolean;
        project: IApplicationExportProject;
        userApplicationId: string;
        dateStart: string;
        dateEnd: string;
        newOnly: boolean;
        fileId: string;
        generatingFile: boolean;
        totalExported: number;
        newApplicationCount: number;
        clearFilter: () => void;
    }


    class ApplicationExportProjectDownload implements IApplicationExportProjectDownload {
        static controllerId: string = "applicationExportProjectDownload";
        static $inject = ["$scope", "$routeParams", "$timeout", "$http", "$location", "dialogs", "Restangular", "common", "config", "helper", "signalRService"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProject: boolean;
        loadingSettings: boolean;
        isSaving: boolean;
        project: IApplicationExportProject;
        rules: Array<IRuleSet>;
        exportProjectId: any;
        userApplicationId: string;
        dateStart: string;
        dateEnd: string;
        newOnly: boolean;
        fileId: string;
        generatingFile: boolean;
        totalExported: number;
        newApplicationCount: number;

        hub: ExportProjectDownloadHub;

        constructor(private $scope: IApplicationExportProjectDownloadScope,
            private $routeParams: IApplicationExportProjectDownloadRouteParams,
            private $timeout: angular.ITimeoutService,
            private $http: angular.IHttpService,
            private $location: angular.ILocationService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private helper: IHelper,
            private signalRService: ISignalRService) {

            this.log = common.logger.getLogFn(ApplicationExportProjectDownload.controllerId);
            this.logError = common.logger.getLogFn(ApplicationExportProjectDownload.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(ApplicationExportProjectDownload.controllerId, "success");

            this.loadingSettings = false;
            this.newOnly = false;
            this.exportProjectId = $routeParams.exportProjectId;
            this.fileId = $routeParams.fileId;
            this.totalExported = $routeParams.count;
            

            $scope.$on(config.events.applicationChanged, (event) => {
                if (!this.$routeParams.fileId) {
                    this.$location.path("/");
                }
            });

            this.hub = this.signalRService.exportProjectDownloadHub();
            this.hub.on("exportCompleted", this.exportCompleted);
            this.hub.on("exportError", this.exportError);

            // Remove the event listener when the controller is removed from the scope
            $scope.$on("$destroy", () => {
                this.hub.off("exportCompleted", this.exportCompleted);
                this.hub.off("exportError", this.exportError);
            })

            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getProject(), this.getNewAppCount()], ApplicationExportProjectDownload.controllerId)
                .then(result => {
                    self.log("Activated Application Export Project Details View");
                    return result;
                });
        }

        clearFilter = () => {
            this.userApplicationId = null;
            this.dateStart = null;
            this.dateEnd = null;
        };

        downloadExport = () => {

            var httpPath = this.config.apiHost + "/" + (this.$routeParams.applicationDefinitionId || this.common.getUser().activeApplication) +
                "/applicationExportProjects/" + this.exportProjectId + "/export?fileId=" + encodeURI(this.fileId);

            this.helper.openFile(httpPath);

        }

        generateFile = () => {
            this.generatingFile = true;
            this.fileId = null;

            if (1) {
                this.generateFileWithHub();
            }
            else {
                return this.Restangular.one(this.$routeParams.applicationDefinitionId || this.common.getUser().activeApplication).one("applicationExportProjects/" + this.exportProjectId + "/export")
                    .get(this.getQueryParams()).then((result) => {

                        this.fileId = result.fileId;
                        this.totalExported = result.totalExported;

                        this.generatingFile = false;
                        this.getNewAppCount();
                        return result;
                    }, (reason) => {
                        this.generatingFile = false;
                        this.logError("An error occurred while generating the export", reason, true);
                    });
            }
        };

        getNewAppCount = (): angular.IPromise<number> => {
            return this.Restangular.one(this.$routeParams.applicationDefinitionId || this.common.getUser().activeApplication).one("applicationExportProjects/" + this.exportProjectId + "/new").get().then((result: number) => {
                this.newApplicationCount = result ? result : 0;
                return result;
            }, (reason) => {
                this.logError("An error occurred while trying to retrieve the count of new applications for the project. Please try again later", reason, true);
                return 0;
            });
        };

        getProject = (): angular.IPromise<IExportProject> => {
            return this.Restangular.one(this.$routeParams.applicationDefinitionId || this.common.getUser().activeApplication).one("applicationExportProjects/" + this.exportProjectId).get().then((result: IExportProject) => {
                this.project = result;
                return result;
            }, (reason) => {
                this.logError("An error occurred while trying to retrieve your project. Please try again later", reason, true);
                return this.project;
            });
        };

        getQueryParams = (): any => {
            var queryParams = {
                newOnly: (!this.dateStart && !this.dateEnd && !this.userApplicationId),
                startDate: this.dateStart ? moment(this.dateStart).toISOString() : "",
                endDate: this.dateEnd ? moment(this.dateEnd).toISOString() : "",
                userApplicationId: this.userApplicationId,
                applicationDefinitionId: this.$routeParams.applicationDefinitionId || this.common.getUser().activeApplication,
                projectId: "applicationExportProjects/" + this.exportProjectId
            };

            
            return queryParams;
        }

        generateFileWithHub() {    
          
            this.hub.connect().then(() => {
                var q = this.getQueryParams();
                q.connectionId = this.hub.getConnectionId();

                this.Restangular.one(this.$routeParams.applicationDefinitionId || this.common.getUser().activeApplication).one("applicationExportProjects/" + this.exportProjectId + "/export")
                    .get(q).then((response) => {
                        if (response.isQueued) {
                            this.logSuccess("Your export has been queued. You will be notified when it is ready to download.", null, true);
                        }
                        else {
                            this.fileId = response.Data.FileId;
                            this.totalExported = response.Data.TotalExported;
                            this.generatingFile = false;
                            this.getNewAppCount();
                            this.hub.disconnect();
                        }
                    }, (reason) => {
                        this.generatingFile = false;
                        this.logError("An error occurred while generating the export", reason, true);
                    });
            }, (reason) => {
                this.generatingFile = false;
                this.logError("An error occurred while connecting to the server.", reason, true)
            });
        }

        exportCompleted = (result) => {
            this.generatingFile = false;
            this.logSuccess("Your export is ready to download.", null, true);
            this.fileId = result.FileId;
            this.totalExported = result.TotalExported;
            this.getNewAppCount();
            this.hub.disconnect();
        }

        exportError = (reason) => {
            this.generatingFile = false;
            this.logError("An error occurred while generating the export.", reason, true)
            this.hub.disconnect();
        }


    }

    angular.module("app").controller(ApplicationExportProjectDownload.controllerId, ApplicationExportProjectDownload);
} 