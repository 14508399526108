namespace Advant.Crossroads {
    "use strict";

    interface IAdvDateInput extends angular.IDirective {

    }

    interface IAdvDateInputScope extends angular.IScope {
    }

    interface IAdvDateInputAttribute extends angular.IAttributes {
        advDateInput: string;
        ngModel: string;
        minDate: string;
        maxDate: string;
    }

    function advDateInput($parse: angular.IParseService): IAdvDateInput {
        var validDate = (dateValue: string, inputFormat: string): boolean => {
            var dateMomentValue: moment.Moment;

            if (angular.isDate(dateValue)) {
                dateMomentValue = moment.utc(dateValue);
            } else {
                dateMomentValue = moment.utc(dateValue, inputFormat, true);
            }

            return dateMomentValue.isValid();
        };

        var validMinDate = (dateValue, inputFormat, minDate) => {
            var dateMomentValue: moment.Moment;

            if (angular.isDate(dateValue)) {
                dateMomentValue = moment.utc(dateValue);
            } else {
                dateMomentValue = moment.utc(dateValue, inputFormat, true);
            }

            var minValue = moment(minDate);
            if (dateMomentValue.diff(minValue) > -1) {
                return true;

            } else {
                return false;
            }
        };

        var validMaxDate = (dateValue, inputFormat, maxDate) => {
            var dateMomentValue: moment.Moment;

            if (angular.isDate(dateValue)) {
                dateMomentValue = moment.utc(dateValue);
            } else {
                dateMomentValue = moment.utc(dateValue, inputFormat, true);
            }

            var maxValue = moment(maxDate);
            if (dateMomentValue.diff(maxValue) < 1) {
                return true;

            } else {
                return false;
            }
        };

        return {
            restrict: "A",
            require: "ngModel",
            link: (scope: IAdvDateInputScope, element, attrs: IAdvDateInputAttribute, ngModelCtrl: angular.INgModelController) => {
                ngModelCtrl.$formatters.unshift((modelValue) => {
                    if (modelValue) {
                        return moment.utc(modelValue).format(attrs.advDateInput);
                    }
                    return modelValue;
                });

                scope.$watch(attrs.ngModel, (model: any) => {
                    if (model) {
                        var dateMomentValue: moment.Moment;

                        if (!angular.isDate(model)) {
                            dateMomentValue = moment.utc(model, attrs.advDateInput, true);
                            if (dateMomentValue.isValid()) {
                                model = dateMomentValue.toDate();
                            }
                        }
                    }
                });

                var minDate = $parse(attrs.minDate)(scope) || undefined;
                var maxDate = $parse(attrs.maxDate)(scope) || undefined;

                (ngModelCtrl.$validators as any).date = (modelValue, viewValue) => {
                    return ngModelCtrl.$isEmpty(viewValue) || validDate(viewValue, attrs.advDateInput);
                };

                (ngModelCtrl.$validators as any).minDate = (modelValue, viewValue) => {
                    return ngModelCtrl.$isEmpty(viewValue) || angular.isUndefined(minDate) || !validDate(viewValue, attrs.advDateInput) || validMinDate(viewValue, attrs.advDateInput, minDate);
                };

                (ngModelCtrl.$validators as any).maxDate = (modelValue, viewValue) => {
                    return ngModelCtrl.$isEmpty(viewValue) || angular.isUndefined(maxDate) || !validDate(viewValue, attrs.advDateInput) || validMaxDate(viewValue, attrs.advDateInput, maxDate);
                };
            }
        };
    }

    advDateInput.$inject = ["$parse"];
    angular.module("app").directive("advDateInput", advDateInput);

}