/// <reference path="common.ts" />

namespace Advant.Crossroads {

    export interface ICommonService {
        $broadcast: (...args: any[]) => any;
        $on: (name: string, listener: (event: angular.IAngularEvent, ...args: any[]) => any) => Function;
        activateController: (promises, controllerId) => angular.IPromise<any>;
        createSearchThrottle: (viewmodel, list, filteredList, filter, delay) => any;
        debouncedThrottle: (key: string, callback: Function, delay?: number, immediate?: boolean) => void;
        isNumber: (val) => boolean;
        logger: ILogger; // for accessibility
        textContains: (text, searchText) => boolean;
        $q: angular.IQService;
        getUser: () => IUserInfoModel;
    }

    export class CommonFactory implements ICommonService {
        static serviceId: string = "common";
        private throttles = {};
        public getUser: () => IUserInfoModel;


        constructor(public $q: angular.IQService,
            private $rootScope: angular.IRootScopeService,
            private $timeout: angular.ITimeoutService,
            private authentication: IAuthenticationProviderService,
            private commonConfig,
            public logger: ILogger) {

            this.getUser = authentication.getUser;
        }

        public activateController(promises, controllerId) {
            var self = this;
            return self.$q.all(promises).then(eventArgs => {
                var data = { controllerId: controllerId };
                this.$broadcast(this.commonConfig.config.controllerActivateSuccessEvent, data);
            });
        }

        $broadcast(...args: any[]) {
            return this.$rootScope.$broadcast.apply(this.$rootScope, arguments);
        }

        $on = (name: string, listener: (event: angular.IAngularEvent, ...args: any[]) => any) => {
            return this.$rootScope.$on(name, listener);
        };

        public createSearchThrottle(viewmodel, list, filteredList, filter, delay) {
            // custom delay or use default
            delay = +delay || 300;
            // if only vm and list parameters were passed, set others by naming convention 
            if (!filteredList) {
                // assuming list is named sessions,
                // filteredList is filteredSessions
                filteredList = "filtered" + list[0].toUpperCase() + list.substr(1).toLowerCase(); // string
                // filter function is named sessionFilter
                filter = list + "Filter"; // function in string form
            }

            // create the filtering function we will call from here
            var filterFn = () => {
                // translates to ...
                // vm.filteredSessions 
                //      = vm.sessions.filter(function(item( { returns vm.sessionFilter (item) } );
                viewmodel[filteredList] = viewmodel[list].filter(item => viewmodel[filter](item));
            };

            return ((): (searchNow: boolean) => void => {
                // Wrapped in outer IFFE so we can use closure 
                // over filterInputTimeout which references the timeout
                var filterInputTimeout;

                // return what becomes the "applyFilter" function in the controller
                return (searchNow: boolean) => {
                    if (filterInputTimeout) {
                        this.$timeout.cancel(filterInputTimeout);
                        filterInputTimeout = null;
                    }
                    if (searchNow || !delay) {
                        filterFn();
                    } else {
                        filterInputTimeout = this.$timeout(filterFn, delay);
                    }
                };
            })();
        }

        public debouncedThrottle(key: string, callback: any, delay?: number, immediate?: boolean) {
            // Perform some action (callback) after a delay. 
            // Track the callback by key, so if the same callback 
            // is issued again, restart the delay.

            var defaultDelay = 1000;
            delay = delay || defaultDelay;
            if (this.throttles[key]) {
                this.$timeout.cancel(this.throttles[key]);
                this.throttles[key] = undefined;
            }
            if (immediate) {
                callback();
            } else {
                this.throttles[key] = this.$timeout(callback, delay);
            }
        }

        public isNumber(val) {
            // negative or positive
            return /^[-]?\d+$/.test(val);
        }

        public textContains(text, searchText) {
            return text && -1 !== text.toLowerCase().indexOf(searchText.toLowerCase());
        }
    }

    common.factory(CommonFactory.serviceId, ["$q", "$rootScope", "$timeout", "authentication", "commonConfig", "logger",
        ($q, $rootScope, $timeout, authentication: IAuthenticationProviderService, commonConfig, logger) =>
            new CommonFactory($q, $rootScope, $timeout, authentication, commonConfig, logger)
    ]);
}