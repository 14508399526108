/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface ICalendar {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingEvents: boolean;
        isSaving: boolean;
        uiConfig: any;
        events: IEvent[];
        eventSources: any[];
        currentEvent: IEvent;

        activate: () => void;
        getEvents: (start, end, timezone, callback) => void;
    }

    class SchoolCalendar implements ICalendar {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingEvents: boolean;
        isSaving: boolean;
        uiConfig: any;
        eventSources: any;
        events: any;
        currentEvent: IEvent;
        categories: Array<any>;
        originalEvent: IEvent;
        originalEventName: string;
        originalCategory: string;
        originalAllDaySetting: boolean;
        originalDescription: string;
        originalStartTime: Date;
        originalEndTime: Date;

        static $inject: string[] = ["$scope", "Restangular", "dialogs", "common", "config", "uiCalendarConfig"];

        constructor(private $scope: angular.IScope,
            private Restangular: Restangular.IService,
            private dialogs: angular.dialogs.IDialogService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private uiCalendarConfig) {

            this.log = common.logger.getLogFn("calendar");
            this.logError = common.logger.getLogFn("calendar", "error");
            this.logSuccess = common.logger.getLogFn("calendar", "success");

            this.uiConfig = {
                calendar: {
                    header: {
                        left: "prev,next",
                        center: "title",
                        right: "month,agendaWeek,agendaDay"
                    },
                    timezone: "local",
                    editable: true,
                    droppable: true,
                    eventClick: this.eventClick,
                    eventResize: this.eventChanged,
                    eventDrop: this.eventChanged
                }
            };

            this.eventSources = [this.getEvents];
            $scope.$on(config.events.applicationChanged, (event) => {
                this.uiCalendarConfig.calendars.myCalendar.fullCalendar("refetchEvents");
                this.activate();
            });
            this.activate();
        }

        activate = () => {
            this.common.activateController([], "calendar")
                .then(result => {
                    this.currentEvent = { start: moment().startOf("hour").toDate(), end: moment().startOf("hour").add(1, "hour").toDate(), category: "Unfiled" };
                    this.log("Activated Calendar View");
                });
            this.categories = [
                {
                    name: "Unfiled",
                },
                {
                    name: "Admission"
                },
                {
                    name: "Enrollment"
                },
                {
                    name: "Registration"
                },
                {
                    name: "Financial Aid"
                },
                {
                    name: "Private"
                },
                {
                    name: "Sports"
                },
                {
                    name: "Chat"
                }
            ];            

        };

        addEvent = () => {
            this.currentEvent = { start: moment().startOf("hour").toDate(), end: moment().startOf("hour").add(1, "hour").toDate(), category: "Unfiled" };
        };

        cancelEvent = () => {
            this.currentEvent = _.find(this.events, <any>{ id: this.currentEvent.id });
            var index = _.indexOf(this.events, _.find(this.events, <any>{ id: this.currentEvent.id }));
            this.events.splice(index, 1, this.originalEvent);
            this.currentEvent = { start: moment().startOf("hour").toDate(), end: moment().startOf("hour").add(1, "hour").toDate(), category: "Unfiled" };
        };

        eventClick = (calEvent) => {
            this.currentEvent = _.find(this.events, <any>{ id: calEvent.id });
            this.originalEvent = _.cloneDeep(this.currentEvent);
        };

        eventChanged = (event, delta, revertFunc) => {
            var eventData = <any>_.find(this.events, { id: event.id });
            eventData.start = event.start.toISOString();
            eventData.end = event.end.toISOString();

            eventData.put().then(result => {
                this.logSuccess("Your event has been updated", null, true);
                this.isSaving = false;
            }, error => {
                this.isSaving = false;
                this.logError("An error occurred while trying to save the event", error, true);
            });
        };

        deleteEvent = () => {
            var dlg = this.dialogs.confirm("Please Confirm",
                "Are you sure you want to delete this event? This action is not reversible.");
            dlg.result.then((btn) => {
                this.isSaving = true;
                (<Restangular.IElement>this.currentEvent).remove().then(result => {
                    this.logSuccess("Your event has been deleted", null, true);
                    this.currentEvent = { start: moment().startOf("hour").toDate(), end: moment().startOf("hour").add(1, "hour").toDate(), category: "Unfiled" };
                    this.isSaving = false;
                    this.uiCalendarConfig.calendars.myCalendar.fullCalendar("refetchEvents");
                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to delete the event", error, true);
                });
            });
        };

        getEvents = (start, end, timezone, callback): void => {
            if (!start || !end) {
                return;
            }
            this.events = [];
            this.loadingEvents = true;
            var queryParams = {
                beginDate: moment(start).toISOString(),
                endDate: moment(end).toISOString()
            };

            this.Restangular.all(this.common.getUser().activeApplication).all("calendar/events").getList(queryParams).then((result: any) => {
                this.loadingEvents = false;
                this.events = result;
                callback(_.map(result, this.mapToFullCalendar));
                return result.results;
            }, (reason) => {
                this.logError("An error occurred while getting the events", reason);
            });
        };

        mapToFullCalendar = (event: IEvent): any => {
            return {
                id: event.id,
                title: event.name,
                start: event.start,
                end: event.end,
                allDay: event.allDayEvent
            };
        };
        save = () => {
            this.isSaving = true;
            if (!this.currentEvent.id) {
                this.Restangular.all(this.common.getUser().activeApplication).all("calendar/events").post(this.currentEvent).then(result => {
                    this.logSuccess("Your new event has been created", null, true);
                    this.currentEvent = result;
                    this.isSaving = false;
                    this.uiCalendarConfig.calendars.myCalendar.fullCalendar("refetchEvents");
                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to save the event", error, true);
                });
            } else {
                var eventRestangular: Restangular.IElement = <any>this.currentEvent;
                eventRestangular.put().then(result => {
                    this.logSuccess("Your event has been updated", null, true);
                    this.isSaving = false;
                    this.uiCalendarConfig.calendars.myCalendar.fullCalendar("refetchEvents");
                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to save the event", error, true);
                });
            }
        };

        validateEndDate = ($value) => {
            if (this.currentEvent && this.currentEvent.start) {
                var isValid = moment($value).isAfter(this.currentEvent.start);

                return isValid;
            }
            return true;
        };
    }

    angular.module("app").controller("calendar", SchoolCalendar);
}