/// <reference path="../app.ts" />
/// <reference path="userapplications.ts" />
namespace Advant.Crossroads {

    class SubmittedApplications extends UserApplications {
        static controllerId: string = "submittedApplications";
        static $inject: any = ["$scope", "$location", "Restangular", "dialogs", "common", "config"];
        pageTitle: string = "Submitted Applications";

        constructor(public $scope: IUserApplicationsScope,
            public $location: angular.ILocationService,
            public Restangular: Restangular.IService,
            public dialogs: angular.dialogs.IDialogService,
            public common: ICommonService,
            public config: Advant.Crossroads.ICrossroadsConfig) {

            super($scope, $location, Restangular, dialogs, common, config);


            this.sortBy = "Completed";
            this.sortDirection = "DESC";

            this.dataPath = "userApplications/submitted";
            this.dateLabel = "Submitted Between";
            this.showNewOnlyFilter = true;

            this.activate();
        }

        getQueryParams = (): any => {
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                termCode: this.termCode,
                startDate: this.dateStart ? moment(this.dateStart).toISOString() : "",
                endDate: this.dateEnd ? moment(this.dateEnd).add(1, "day").subtract(1, "second").toISOString() : "",
                newOnly: this.newOnly,
                ruleId: this.ruleIdFilter,
                filter: this.searchFilter,
                userApplicationId: this.userApplicationId
            };

            return queryParams;
        }

        update = (app: any) => {
            if (app.payment.paymentReceived) {
                app.payment.dateReceived = moment().toDate();
            } else {
                app.payment.dateReceived = null;
            }
            this.Restangular.all(this.common.getUser().activeApplication).all("userApplications").customPUT(app, "userApplications").then(() => {

                this.logSuccess("Saved payment changes.", null, false);
            }, (reason) => {
                this.logError("Failed to save payment data.", reason, true);
            });
        };
    }

    angular.module("app").controller(SubmittedApplications.controllerId, SubmittedApplications);
}