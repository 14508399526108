/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IApplicationExportProjectsScope extends angular.IScope {
        vm: SharedApplicationExportProjects;
    }

    interface ISharedApplicationExportProjects {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProjects: boolean;
        projects: any[];
        //currentProject: IApplicationExportProject;
        page: number;
        pageSize: number;
        totalCount: number;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;
    }

    class SharedApplicationExportProjects implements ISharedApplicationExportProjects {
        static controllerId: string = "sharedApplicationExportProjects";
        static $inject = ["$scope", "$location", "dialogs", "Restangular", "common", "config", "helper"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProjects: boolean;
        projects: IApplicationExportProject[];
        //currentProject: IApplicationExportProject;
        page: number;
        pageSize: number;
        totalCount: number;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;

        constructor(private $scope: IApplicationExportProjectsScope,
            private $location: angular.ILocationService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private helper: IHelper) {
            this.log = common.logger.getLogFn(SharedApplicationExportProjects.controllerId);
            this.logError = common.logger.getLogFn(SharedApplicationExportProjects.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(SharedApplicationExportProjects.controllerId, "success");

            this.loadingProjects = false;

            this.page = 1;
            this.pageSize = 25;
            this.sortBy = "ApplicationName";
            this.sortDirection = "ASC";
            this.searchFilter = "";

            this.$scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getProjects()], SharedApplicationExportProjects.controllerId)
                .then((result) => {
                self.log("Activated Application Export Projects View");
                return result;
            });
        }
      
        getProjects = (): angular.IPromise<void | Array<any>> => {
            this.loadingProjects = true;
            this.projects = [];

            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                filter: this.searchFilter

            };

            return this.Restangular.one("applicationExportProjects").getList("shared", queryParams).then((result: IRestangularResult<any>) => {
                this.projects = result;

                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingProjects = false;
                return result;
            }, (reason) => {
                    this.loadingProjects = false;
                    this.logError("An error occurred while getting the export projects", reason);
                });
        };

        searchExportProjects = (): void => {
            this.common.debouncedThrottle("ApplicationExportProjectsSearch", () => { this.page = 1; this.getProjects(); }, 400);
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.getProjects();
        };
    }


    angular.module("app").controller(SharedApplicationExportProjects.controllerId, SharedApplicationExportProjects);
}