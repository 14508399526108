/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    export interface ICurrentRuleSetData {
        currentRuleSets: IRuleSet[];
        ruleSetFile?: RuleSetFile;
        appDefId: string;
        ruleSetType: string;
    }

    interface IImportRuleSetScope extends angular.IScope {
        importRuleSet: (files: Array<File>) => void;
        cancel: () => void;
        save: () => void;
        data: any;
        importError: string;
        existingRules: IRuleSet[];
        importedRule: any;
        selectedId: string;
    }

    class ImportRuleSetForm {
        static controllerId: string = "importRuleSet";
        static $inject: any = ["$scope", "$uibModalInstance", "$q", "common", "config", "Restangular", "helper", "data"];
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        importedFile: RuleSetFile;


        constructor(private $scope: IImportRuleSetScope,
            private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
            private $q: angular.IQService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private Restangular: Restangular.IService,
            private helper: IHelper,
            private currentRuleSetData?: ICurrentRuleSetData) {
            this.log = common.logger.getLogFn(ImportRuleSetForm.controllerId);
            this.logError = this.common.logger.getLogFn(ImportRuleSetForm.controllerId, "error");
            this.logSuccess = this.common.logger.getLogFn(ImportRuleSetForm.controllerId, "success");

            $scope.importRuleSet = this.importRuleSet;
            $scope.data = {};
            $scope.data.importing = false;

            $scope.cancel = this.cancel;
            $scope.save = this.save;
        }

        importRuleSet = (files) => {
            this.$scope.importError = null;
            if (files && files.length > 0) {
                this.$scope.data.importing = true;
                this.$scope.data.progress = 0;
                var file = files[0];
                var blob = new Blob([file], { type: "application/json" });
                var reader = new FileReader();
                reader.onprogress = this.updateProgres;
                reader.onload = this.completedImportProcessing;
                reader.readAsText(blob);
            }
        };

        private cancel = () => {
            this.$uibModalInstance.dismiss("cancel");
        };

        completedImportProcessing = (ev) => {
            this.$scope.$applyAsync((scope: IImportRuleSetScope) => {
                scope.data.progress = 100;
            });
            this.importedFile = RuleSetFile.fromJson(ev.currentTarget.result);
            this.$scope.data.parsingProgress = 0;

            this.$scope.data.parsingProgress = 100;
            this.$scope.data.importing = false;
            this.$scope.data.parsed = true;
            this.createOrMapRuleSet();

        };

        updateProgres = (evt) => {
            if (evt.lengthComputable) {
                var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
                // Increase the progress bar length.
                if (percentLoaded < 100) {
                    this.$scope.$applyAsync((scope: IImportRuleSetScope) => {
                        scope.data.progress = percentLoaded;
                    });

                }
            }
        };

        createOrMapRuleSet = () => {
            if (this.importedFile.rule.type == this.currentRuleSetData.ruleSetType) {
                var match = _.filter(this.currentRuleSetData.currentRuleSets, { name: this.importedFile.rule.name });
                this.$scope.importedRule = this.importedFile.rule;

                if (match.length < 1) {
                    this.importedFile.rule.id = null;                 

                } else {
                    this.$scope.existingRules = match;
                    
                }
            }
            else {
                var type = this.currentRuleSetData.ruleSetType;
                this.$scope.importError = "Incorrect export setting type. Export type must be \"" + type + "\".";
            }
        };
    

        private save = () => {
            if (this.$scope.importedRule.mapTo == null) {
                this.importedFile.rule.id = null;
                this.currentRuleSetData.currentRuleSets.push(this.importedFile.rule);
            }
            else {
                this.importedFile.rule.id = this.$scope.importedRule.mapTo;
                var existingRule = _.find(this.currentRuleSetData.currentRuleSets, { id: this.importedFile.rule.id });
                existingRule.name = this.importedFile.rule.name;
                existingRule.description = this.importedFile.rule.description;
                existingRule.groups = this.importedFile.rule.groups;
            }

            this.currentRuleSetData.ruleSetFile = this.importedFile;
            
            this.$uibModalInstance.close(this.currentRuleSetData);
        };
    }

    angular.module("app").controller(ImportRuleSetForm.controllerId, ImportRuleSetForm);
}