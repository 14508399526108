/// <reference path="../app.ts" />

namespace Advant.Crossroads {

    export interface IAdvNavLink extends angular.IDirective {
    }

    export interface IAdvNavLinkScope extends angular.IScope {
        route: any;
    }

    export class AdvNavLink implements IAdvNavLink {
        static directiveId: string = "advNavLink";
        restrict: string = "E";
        replace: boolean = true;
        scope: any = {
            route: "="
        };
        template: string = "<a ng-show=\"route.allowed\" ng-href=\"{{route.url}}\" ng-bind-html=\"route.config.settings.content\"></a>";

        constructor(private $parse: angular.IParseService, private authentication: AuthenticationProviderService) {
        }

        link = (scope?: IAdvNavLinkScope, element?, attr?) => {
            var userInfo = this.authentication.getUser();
            if (scope.route.permission) {
                // Does an OR join on the permissions array
                scope.route.allowed = false;
                angular.forEach(scope.route.permission, (permission, key) => {
                    if (userInfo[permission]) {
                        scope.route.allowed = userInfo[permission];
                    }
                });

            } else {
                scope.route.allowed = true;
            }

        };
    }

    angular.module("app").directive(AdvNavLink.directiveId, ["$parse", "authentication", ($parse, authentication) =>
        new AdvNavLink($parse, authentication)
    ]);
}