/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    export interface IStudentEnrichmentScope extends angular.IScope {
        vm: StudentEnrichment;
        enrichmentProgram: angular.IFormController;
    }

    export interface IStudentEnrichmentController {
    }

    export class StudentEnrichment implements IStudentEnrichmentController {
        static controllerId: string = "studentEnrichment";
        static $inject: any = ["$scope", "dialogs", "Restangular", "common", "Upload", "config"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        public originalPrograms: any;
        public programs: any;
        public currentProgram: any;
        public page: number;
        public pageSize: number;
        public totalCount: number;
        public sortBy: string;
        public sortDirection: string;
        public totalPages: number;
        public startRow: number;
        public endRow: number;
        public searchFilter: string;
        public interests: any;
        public loadingLists: any;
        public editProgram: boolean;
        public otherHost: string;
        public organizationId: string;
        public originalProgram: any;
        public isSaving: boolean;
        public imageData: any;
        public grades: any;


        constructor(private $scope: IStudentEnrichmentScope,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private Upload: angular.angularFileUpload.IUploadService,
            private config: Advant.Crossroads.ICrossroadsConfig) {

            this.log = common.logger.getLogFn(StudentEnrichment.controllerId);
            this.logError = common.logger.getLogFn(StudentEnrichment.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(StudentEnrichment.controllerId, "success");

            this.loadingLists = false;
            this.editProgram = false;

            this.page = 1;
            this.pageSize = 25;
            this.sortBy = "Name";
            this.sortDirection = "ASC";
            this.searchFilter = "";
            this.currentProgram = null;

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getPrograms()], StudentEnrichment.controllerId)
                .then(result => {
                    self.log("Activated StudentEnrichment View");
                    this.organizationId = sessionStorage.getItem("activeApplication").split("/")[1];

                    this.grades = [{ id: "preK", name: "Pre-K" },
                        { id: "elementary", name: "K-5th" },
                        { id: "middleSchool", name: "6th-8th" },
                        { id: "highSchool", name: "9th-12th" },
                        { id: "college", name: "College" }];

                    this.interests = ['Academic',
                        'Academically Gifted',
                        'Accounting',
                        'Adventure',
                        'Aerospace',
                        'After School',
                        'Agriculture',
                        'Algebra',
                        'Anthropology',
                        'Apparel Design',
                        'Archaeology',
                        'Architecture',
                        'Art',
                        'Astronomy',
                        'ADD/ADHD/LD',
                        'Band',
                        'Behavioral Science',
                        'Biology',
                        'Business',
                        'Careers',
                        'Challenge',
                        'Chemistry',
                        'Choir',
                        'College Courses',
                        'College Preparation',
                        'Communications',
                        'Computers',
                        'Cosmetology',
                        'Creative',
                        'Culinary',
                        'Culture',
                        'CPR',
                        'Dance',
                        'Debate',
                        'Design',
                        'Disadvantaged',
                        'Diversity',
                        'Economics',
                        'Engineering',
                        'Enrichment',
                        'Entomology',
                        'Environment',
                        'ESL',
                        'Fine Arts',
                        'First Generation College Student',
                        'Geology',
                        'Geometry',
                        'Graphic Design',
                        'Health',
                        'History',
                        'Humanities',
                        'Internships',
                        'Leadership',
                        'Literacy',
                        'Literature',
                        'Low Income',
                        'Marine Biology',
                        'Math',
                        'Mechanics',
                        'Media',
                        'Medical/Medicine',
                        'Minorities',
                        'Music',
                        'North Carolina',
                        'Nuclear',
                        'Oceanography',
                        'Orchestra',
                        'Performing Arts',
                        'Pharmacy',
                        'Philosophy',
                        'Physics',
                        'Piano',
                        'Problem Solving',
                        'Programming',
                        'Psychology',
                        'Research',
                        'Robotics',
                        'Science',
                        'Service Learning',
                        'Social Science',
                        'Sports',
                        'String Instruments',
                        'Teaching',
                        'Teamwork',
                        'Technology',
                        'Test Preparation',
                        'Textiles',
                        'Transportation',
                        'Tutoring',
                        'Volunteering',
                        'Web Design',
                        'Writing',
                        'Youth',
                        '4-H'];
                });
        }

        getPrograms() {
            var queryParams = {
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
            };

            this.Restangular.one("studentEnrichment/getPrograms/" + sessionStorage.getItem("activeApplication").split("/")[1]).get(queryParams).then((result) => {
                this.programs = result;
                this.originalPrograms = angular.copy(this.programs);
                this.loadingLists = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the enrichment programs", reason);
            });
        };

        loadProgram(selected: any)
        {
            this.originalProgram = angular.copy(selected);
            this.currentProgram = selected;
            this.updateGradeLevels();
            this.editProgram = true;
        }

        updateGradeLevels() {
            var levels = [];
            this.currentProgram.gradeLevels.forEach(item => {
                var sport = _.findIndex(this.grades, { 'id': item });
                levels.push(this.grades[sport]);
            });
            this.currentProgram.gradeLevels = levels;
        }

        createNewProgram() {
            var org = sessionStorage.getItem("activeApplication").split("/");
            this.currentProgram = {
                id: '',
                organizationId: org[0] + '/' + org[1],
                name: '',
                description: '',
                interestAreas: {},
                host: '',
                address: {},
                fee: '',
                scholarshipAvailable: null,
                gradeLevel: '',
                contactName: '',
                contactEmail: '',
                contactPhone: '',
                websiteUrl: '',
                sessions: [],
                imageUrl: null,
                pending: null
            };
            
            this.currentProgram.sessions.push({ name: 'Session 1', timeFrame: null, start: null, end: null, deadline: null });
            this.editProgram = true;
        }


        addSession() {
            var test = _.size(this.currentProgram.sessions);
            var sessionName = 'Session ' + (test + 1);
            this.currentProgram.sessions.push({ name: sessionName, start: null, end: null, deadline: null });
        }

        deleteSession(deletedSession: any) {
            for (var i in this.currentProgram.sessions) {
                if (this.currentProgram.sessions[i].name == deletedSession.name) {
                    this.currentProgram.sessions.splice(i, 1);
                }
            }

            var num = 1;
            for (var i in this.currentProgram.sessions) {
                this.currentProgram.sessions[i].name = 'Session ' + (num + Number(i));
            }
        }

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.getPrograms();
        };

        save(file: any) {
            var tempGrades = angular.copy(this.currentProgram.gradeLevels);
            var result = this.currentProgram.gradeLevels.map(a => a.id);
            this.currentProgram.gradeLevels = result;
            this.Restangular.all("studentEnrichment/updateProgram").post(this.currentProgram).then((results) => {
                this.currentProgram = results;
                this.currentProgram.gradeLevels = tempGrades;
                this.getPrograms();
                this.isSaving = false;
                this.logSuccess("Program has been saved. Notification has been sent for approval.", null, true);
                if (file.size > 0) {
                    this.uploadAttachment(file);
                }
            }, (reason) => {
                    console.error(reason);
                    this.currentProgram.gradeLevels = tempGrades;
                this.logError("An error occurred", reason, true);
            });
        }

        uploadAttachment = (file: any) => {
            var id = this.currentProgram.id.split('/')[1];
            this.Upload.upload(<any>{
                
                url: this.config.apiHost + "/studentEnrichment/UploadImage/" + id + "/attachment",
                method: "POST",
                file: file,
                fields: { 'name': file.name }
            }).then((response) => {
                this.logSuccess("Photo was uploaded successfully", null, true);
            }, (reason) => {
                console.error("Failed to upload attachment", reason);
                this.logError("Failed to upload attachment", reason, true);
            });
        };

        approveProgram(program: any) {
            this.Restangular.all("studentEnrichment/approveProgram").post(program).then((results) => {
                this.isSaving = false;
                program.pendingApproval = false;
                this.logSuccess("Program has been approved.", null, true);
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        cancel() {
            angular.copy(this.originalPrograms, this.programs);
            this.$scope.enrichmentProgram.$setPristine();
            this.editProgram = false;
        }

        setupDelete(program: any) {
            this.Restangular.all("studentEnrichment/pendingDelete").post(program).then((results) => {
                this.isSaving = false;
                this.getPrograms();
                this.logSuccess("Program has been marked for deletion and notification has been sent.", null, true);
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        cancelDelete(program: any) {
            this.Restangular.all("studentEnrichment/cancelDelete").post(program).then((results) => {
                this.isSaving = false;
                this.getPrograms();
                this.logSuccess("Program deletion has been canceled.", null, true);
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        deleteProgram(program: any) {
            this.Restangular.all("studentEnrichment/deleteProgram").post(program).then((results) => {
                this.isSaving = false;
                this.getPrograms();
                this.logSuccess("Program has been deleted.", null, true);
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }
    }

    angular.module("app").controller(StudentEnrichment.controllerId, StudentEnrichment);
}