// Install the angularjs.TypeScript.DefinitelyTyped NuGet packageApplicationExportProjects.
namespace Advant.Crossroads {
    "use strict";

    interface IAdminReports {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingReports: boolean;
        reports: Array<IReportViewModel>;
        appDefId: string;
        reportBaseUrl: string;
        currentReport: IReportViewModel;
        page: number;
        pageSize: number;
        totalCount: number;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        activate: () => void;
        editReport: () => void;
    }

    class AdminReports implements IAdminReports {

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingReports: boolean;
        reports: Array<IReportViewModel>;
        currentReport: IReportViewModel;
        page: number;
        pageSize: number;
        totalCount: number;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        appDefId: string;
        reportBaseUrl: string;
        pollWindow: Window;

        static $inject = ["$scope", "$window", "$timeout", "dialogs", "Restangular", "common", "config", "helper"];
        constructor($scope: angular.IScope,
            private $window: angular.IWindowService,
            private $timeout: angular.ITimeoutService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private helper: IHelper) {

            this.log = common.logger.getLogFn("adminReports");
            this.logError = common.logger.getLogFn("adminReports", "error");
            this.logSuccess = common.logger.getLogFn("adminReports", "success");

            this.loadingReports = false;
            this.reports = [];
            this.appDefId = encodeURIComponent(this.common.getUser().activeApplication);
            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
                this.appDefId = encodeURIComponent(this.common.getUser().activeApplication);
            });

            this.reportBaseUrl = this.config.apiHost + "/reports";
            this.activate();
        }

        activate() {
            var self = this;
            this.common.activateController([this.getReports()], "adminReports")
                .then((result) => {
                    self.log("Activated Dynamic Reports View");
                    return result;
                });
        }

        createNewReport = (): void => {
            this.pollWindow = this.$window.open(this.reportBaseUrl + "/create", "_blank");
            this.pollWindowClosed();

        };

        pollWindowClosed = () => {
            if (this.pollWindow.closed) {
                this.getReports();
                this.pollWindow = null;
                return;
            }
            this.$timeout(this.pollWindowClosed, 500);
        };

        deleteReport = (): void => {
            var confirm = this.dialogs.confirm("Delete Report", "Are you sure you want to delete this report?");

            confirm.result.then((button) => {
                this.loadingReports = true;
                this.reports = [];
                return (<any>this.currentReport).remove().then((result) => {
                    this.getReports();
                    return result;
                }, (reason) => {
                    this.logError("An error occurred while deleting the report", reason, true);
                    return;
                });
            });
        };

        editReport = (): void => {
            var userInfo = this.common.getUser();

            if (userInfo["canEditReports"]) {
                this.$window.open(this.reportBaseUrl + "/edit?reportId=" + this.currentReport.reportId, "_blank");
            } else {
                this.$window.open(this.reportBaseUrl + "/?reportId=" + this.currentReport.reportId, "_blank");
            }

        };

        getReports = (): angular.IPromise<void> => {
            this.loadingReports = true;
            this.reports = [];
            return this.Restangular.one("admin").all("reports").getList().then((result: Array<IReportViewModel>) => {
                this.reports = result;
                this.loadingReports = false;
            }, (reason) => {
                this.loadingReports = false;
                this.logError("An error occurred while getting the dynamic reports", reason);
            });
        };
    }

    angular.module("app").controller("adminReports", AdminReports);
}