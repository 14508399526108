/// <reference path="app.ts" />
namespace Advant.Crossroads {
    "use strict";

    // Configure by setting an optional string value for appErrorPrefix.
    // Accessible via config.appErrorPrefix (via config value).
    var extendExceptionHandler = ($delegate, config, logger) => {
        var appErrorPrefix = config.appErrorPrefix;
        var logError = logger.getLogFn("app", "error");
        return (exception, cause) => {
            $delegate(exception, cause);
            if (appErrorPrefix && ((exception.message && exception.message.indexOf(appErrorPrefix) === 0) || (!exception.message && exception.indexOf(appErrorPrefix) === 0))) { return; }

            var errorData = { exception: exception, cause: cause };
            var msg = appErrorPrefix + (exception.message ? exception.message : exception);
            logError(msg, errorData, false);
        };
    }

    angular.module("app").config(["$provide", $provide => {
        $provide.decorator("$exceptionHandler",
            ["$delegate", "config", "logger", extendExceptionHandler]);
    }]);

    // Extend the $exceptionHandler service to also display a toast.
}