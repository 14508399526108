/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface ITaapMajorModalScope extends ng.IScope {
        close: () => void;
        finishAddMajor: () => void;
        cancel: () => void;
        addMajor: () => void;
        addedMajor: boolean;
        majorId: string;
        organizationId: string;
        selectedMajor: string;
        fallHasDeadline: string;
        fallDeadline: Date;
        springHasDeadline: string;
        springDeadline: Date;
        summerHasDeadline: string;
        summerDeadline: Date;
        isSaving: boolean;
    }

    interface ITaapMajorModal {

    }

    interface ITaapMajor { schoolName: string; terms: any; }

    interface IMajorData { id: string, organizationId: string, name: string, fallDeadline: string, fallDeadlineDate: string, springDeadline: string, springDeadlineDate: string, summerDeadline: string, summerDeadlineDate: string }

    class TaapMajorModal implements ITaapMajorModal {
        static $inject: Array<string> = ["$uibModalInstance", "common", "$scope", "$resource", "majorData", "Restangular"];
        static controllerId: string = "taapMajorModal";
        private log;
        isSaving: boolean;
        logSuccess: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;

        constructor(private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
            private common: ICommonService,
            private $scope: ITaapMajorModalScope,
            private $resource: ng.resource.IResourceService,
            majorData: IMajorData,
            private Restangular: Restangular.IService) {
            this.log = common.logger.getLogFn(TaapMajorModal.controllerId);
            this.logSuccess = this.common.logger.getLogFn(TaapMajorModal.controllerId, "success");
            this.logError = this.common.logger.getLogFn(TaapMajorModal.controllerId, "error");
            $scope.close = () => this.close();
            $scope.finishAddMajor = () => this.finishAddMajor();
            $scope.cancel = () => this.cancel();
            $scope.addMajor = () => this.addMajor();
            $scope.selectedMajor = majorData.name;
            $scope.fallHasDeadline = majorData.fallDeadline;
            $scope.fallDeadline = majorData.fallDeadlineDate != null ? new Date(majorData.fallDeadlineDate.toString()) : null;
            $scope.springHasDeadline = majorData.springDeadline;
            $scope.springDeadline = majorData.springDeadlineDate != null ? new Date(majorData.springDeadlineDate.toString()) : null;
            $scope.summerHasDeadline = majorData.summerDeadline;
            $scope.summerDeadline = majorData.summerDeadlineDate != null ? new Date(majorData.summerDeadlineDate.toString()) : null;
            $scope.organizationId = majorData.organizationId;
            $scope.majorId = majorData.id;
            $scope.isSaving = this.isSaving;

        }

        addMajor() {
            var newMajor = {
                Id: this.$scope.majorId,
                OrganizationId: this.$scope.organizationId,
                Name: this.$scope.selectedMajor,
                FallDeadline: this.$scope.fallHasDeadline,
                FallDeadlineDate: this.$scope.fallDeadline,
                SpringDeadline: this.$scope.springHasDeadline,
                SpringDeadlineDate: this.$scope.springDeadline,
                SummerDeadline: this.$scope.summerHasDeadline,
                SummerDeadlineDate: this.$scope.summerDeadline
            };
            this.Restangular.all("TaapAdmin/AddTaapMajor").post(newMajor).then((results) => {
                this.finishAddMajor();
            }, (reason) => {
                this.logError("An error occurred", reason, true);
            });
        }

        close() {
            this.$uibModalInstance.dismiss();
        }

        finishAddMajor() {
            this.$scope.addedMajor = true;
            this.$uibModalInstance.close(this.$scope.addedMajor);
        }

        cancel() {
            this.$scope.addedMajor = false;
            this.$uibModalInstance.close(this.$scope.addedMajor);
        }
    }

    angular.module("app").controller(TaapMajorModal.controllerId, TaapMajorModal);
}