/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface ITranscriptExportProjectDetailsScope extends angular.IScope {
        vm: TranscriptExportProjectDetails;
        transcriptExportProjectForm: angular.IFormController;
    }

    interface ITranscriptExportProjectDetailsRouteParams extends angular.route.IRouteParamsService {
        exportProjectId: string;
    }

    interface ITranscriptExportProjectDetails {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProject: boolean;
        loadingSettings: boolean;
        isSaving: boolean;
        project: ITranscriptExportProject;
        originalProject: ITranscriptExportProject;
        illegalFilenameCharacters: RegExp;
    }


    class TranscriptExportProjectDetails implements ITranscriptExportProjectDetails {
        static controllerId: string = "transcriptExportProjectDetails";
        static $inject = ["$scope", "$routeParams", "$timeout", "$http", "dialogs", "Restangular", "common", "config", "helper"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingProject: boolean;
        loadingSettings: boolean;
        isSaving: boolean;
        project: ITranscriptExportProject;
        originalProject: ITranscriptExportProject;
        exportProjectId: any;
        fieldList: Array<string>;
        illegalFilenameCharacters = /^[^\\/:*?<>|\"]+$/;
        outOfDate: boolean;

        constructor(private $scope: ITranscriptExportProjectDetailsScope,
            private $routeParams: ITranscriptExportProjectDetailsRouteParams,
            private $timeout: angular.ITimeoutService,
            private $http: angular.IHttpService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private helper: IHelper) {

            this.log = common.logger.getLogFn(TranscriptExportProjectDetails.controllerId);
            this.logError = common.logger.getLogFn(TranscriptExportProjectDetails.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(TranscriptExportProjectDetails.controllerId, "success");

            this.loadingSettings = false;

            this.exportProjectId = $routeParams.exportProjectId;

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.fieldList = [
                "FirstName",
                "MiddleName",
                "LastName",
                "DateOfBirth",
                "StudentId",
                "TransactionId"
            ];

            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getProject(), this.checkDates()], TranscriptExportProjectDetails.controllerId)
                .then(result => {
                    self.log("Activated Transcript Export Project Details View");
                    return result;
                });
        }

        private getAutoCompleteList(prefix: string) {
            if (!prefix) {
                return [];
            }
            var matchingFields = [];
            angular.forEach(this.fieldList, (v: string, k: any) => {
                var exportFieldKey = "[[" + v + "]]";
                var test = exportFieldKey.toLocaleLowerCase().indexOf(prefix.toLocaleLowerCase());
                if (test > -1) {
                    matchingFields.push({ name: exportFieldKey, value: exportFieldKey, score: 100, meta: "export field" });
                }
            });

            return matchingFields;
        }

        addSettings = () => {
            var exportSettingsdDialog =
                this.dialogs.create("/app/exportProjects/addExportSettings.html", "addTranscriptExportSettings", angular.copy(this.project.settings), { size: "lg" });

            exportSettingsdDialog.result.then((result: IExportSettings[]) => {
                this.$timeout(() => {
                    this.project.settings = result;
                    this.$scope.transcriptExportProjectForm.$setDirty();
                });
            });
        };

        deleteSetting = (setting: IExportSettings): void => {
            _.remove(this.project.settings, { "id": setting.id });
            this.$scope.transcriptExportProjectForm.$setDirty();
        };

        getProject = (): angular.IPromise<IExportProject> => {
            var deferred: angular.IDeferred<IExportProject>;
            if (this.$routeParams.exportProjectId.toLowerCase() === "new") {
                deferred = this.common.$q.defer<IExportProject>();
                var empty: any = {
                    id: null,
                    applicationDefinitionId: this.common.getUser().activeApplication,
                    name: null,
                    fileNamePattern: null,
                    exportType: "Transcript",
                    filter: null,
                    createdOn: moment().toDate(),
                    createdBy: null,
                    lastUpdated: moment().toDate(),
                    lastUpdatedBy: null,
                    combineEachSettingIntoOnePdfFile: false,
                    combineAllResultsIntoOnePdfFile: false,
                    folderNamePattern: null,
                    maxNumberToDownload: 50,
                    transcriptType: "None",
                    settings: []
                };
                this.project = empty;
                this.originalProject = angular.copy(this.project);
                deferred.resolve(this.project);

                return deferred.promise;
            }

            return this.Restangular.one(this.common.getUser().activeApplication).one("transcriptExportProjects", this.exportProjectId).get().then((result: any) => {
                this.project = result;
                this.originalProject = angular.copy(this.project);
                return result;
            }, (reason) => {
                this.logError("An error occurred while trying to retrieve your settings. Please try again later", reason, true);
                return;
            });
        };

        checkDates = (): angular.IPromise<boolean> => {
            if (this.$routeParams.exportProjectId.toLowerCase() != "new") {
                return this.Restangular.one(this.common.getUser().activeApplication).one("transcriptExportProjects", this.exportProjectId).one("check").get().then((result: any) => {
                    this.outOfDate = result;
                    return result;
                }, (reason) => {
                        var message = "Error checking export settings dates!";
                        if (reason.data.modelState) {
                            console.log(reason.data.modelState);
                            angular.forEach(reason.data.modelState, (value: any, key: any) => {
                                if (!_.startsWith(value, 'System.Web.'))
                                    message += "<br/>" + value;
                            });
                        }
                        this.logError(message, reason, true);
                    return;
                });
            }
        }

        updateSettings = () => {
            if (this.$routeParams.exportProjectId.toLowerCase() != "new") {
                return this.Restangular.all(this.common.getUser().activeApplication).all("transcriptExportProjects/" + this.exportProjectId).all("updateSettings").post(this.project).then((result: any) => {
                    this.logSuccess("Your transcript export project settings have been updated", result, true);
                    this.getProject();
                    this.checkDates();
                    return result;
                }, (reason) => {
                        var message = "Error updating export settings!";
                        if (reason.data.modelState) {
                            console.log(reason.data.modelState);
                            angular.forEach(reason.data.modelState, (value: any, key: any) => {
                                if (!_.startsWith(value, 'System.Web.'))
                                    message += "<br/>" + value;
                            });
                        }
                        this.logError(message, reason, true);
                    return;
                });
            }
        }

        save = () => {
            if (this.$scope.transcriptExportProjectForm.$valid) {
                this.isSaving = true;

                if (this.$routeParams.exportProjectId.toLowerCase() === "new") {
                    this.Restangular.all(this.common.getUser().activeApplication).all("transcriptExportProjects").post(this.project).then((result) => {
                        this.isSaving = false;
                        this.originalProject = angular.copy(result);
                        this.project = result;
                        this.logSuccess("Your transcript export project has been saved", result, true);
                        this.$scope.transcriptExportProjectForm.$setPristine();
                        return result;
                    }, (reason) => {
                        var message = "Error saving new project!";
                        if (reason.data.modelState) {
                            angular.forEach(reason.data.modelState, (value: any, key: number) => {
                                message += "<br/>" + value;
                            });
                        }
                        if (reason.data.exceptionMessage) {
                            message += "<br/>" + reason.data.exceptionMessage;
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                    });
                } else {
                    this.project.put().then((result) => {
                        this.logSuccess("Your transcript export project has been saved", result, true);
                        this.$scope.transcriptExportProjectForm.$setPristine();
                        this.isSaving = false;
                        this.originalProject = angular.copy(this.project);
                        return result;
                    }, (reason) => {
                        var message = "An error occurred while trying to save your project. Please try again later.";
                        if (reason.data.exceptionMessage) {
                            message += "<br/>" + reason.data.exceptionMessage;
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                        return;
                    });
                }
            }
        };
    }
    angular.module("app").controller(TranscriptExportProjectDetails.controllerId, TranscriptExportProjectDetails);
}