/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    interface IInput extends angular.IDirective {

    }

    interface IInputScope extends angular.IScope {
    }

    class Input implements IInput {
        static directiveId: string = "input";
        restrict: string = "E";
        require = "?ngModel";

        constructor(private $parse: angular.IParseService) {
        }

        link = (scope: IInputScope, element, attrs) => {
            if (attrs.ngModel && attrs.value && attrs.type !== "radio") {
                var parsed = this.$parse(attrs.value);
                var parsedValue = parsed(scope) || attrs.value;
                this.$parse(attrs.ngModel).assign(scope, parsedValue);
            }
        };
    }
    angular.module("app").directive(Input.directiveId, ["$parse", $parse =>
        new Input($parse)
    ]);
}
