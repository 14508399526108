/// <reference path="common/common.ts" />
/// <reference path="services/advsidebar.ts" />

namespace Advant.Crossroads {
    interface IApp extends angular.IModule { }

    // Create the module and define its dependencies.
    var app: IApp = angular.module("app", [
        // Angular modules 
        "ngResource",       // $resource for REST queries
        "ngAnimate",        // animations
        "ngRoute",          // routing
        "ngSanitize",
        "ngTouch",
        "ngMessages",
        "ngCookies",

        // Custom modules 
        "authentication",
        "sidebar",
        "common",
        // 3rd Party Modules
        "ui.bootstrap",
        "ui.validate",
        "ui.select",
        "ui.sortable",
        "ui.ace",
        "dialogs.main",
        "dialogs.default-translations",
        "pascalprecht.translate", // translation provider
        "monospaced.elastic", // allows for dynamic sized text areas
        "ui.tree", // drag and drop nested ui list
        "ui.calendar",
        "highcharts-ng",
        "angularMoment", // provides some functionality of moment.js to angular
        "restangular",
        "ngFileUpload",
        "ui.tinymce",
        "ngIdle",
        "mgcrea.ngStrap"
    ]);

    // Execute bootstrapping code and any dependencies.
    app.run(["$http", "$location", "$route", "$q", "$rootScope", "Restangular", "authentication", "routemediator",
        ($http: angular.IHttpService, $location: angular.ILocationService, $route, $q, $rootScope, Restangular, authentication: IAuthenticationProviderService, routemediator) => {
            // Include $route to kick start the router.
            routemediator.setRoutingHandlers();

            $rootScope.$on("$routeChangeStart", (event, next: any) => {
                appInsights.startTrackPage(next.$$route.title);

            });

            $rootScope.$on("$routeChangeSuccess", (event, current: any) => {
                var pagePath = `CrossConnect{$location.url()}`;

                appInsights.stopTrackPage(current.$$route.title, pagePath);

            });

            $http.defaults.headers.common["Cache-Control"] = "no-cache";
            $http.defaults.headers.common.Pragma = "no-cache";
            $http.defaults.withCredentials = true;
            (<any>$http.defaults.headers.common).Authorization = "Bearer " + authentication.accessToken();


            $rootScope.$on("$locationChangeStart", (event, newUrl: string) => {
                if (!authentication.authenticate() && newUrl.indexOf("/login") === -1) {
                    event.preventDefault();
                }
            });
            var user = authentication.getUser();
            if (user && user.timeZone) {
                app.constant("angularMomentConfig", {
                    timezone: user.timeZone // e.g. 'Europe/London'
                });
            }

            /* tslint:disable: no-string-literal */
            Restangular.configuration.getIdFromElem = (elem: Restangular.IElement) => {
                if (elem.hasOwnProperty("tenantName")) {
                    return elem["tenantName"];
                } else if (elem["route"] === "applicationExportSettings" || elem["route"] === "prospectExportSettings" || elem["route"] === "transcriptExportSettings") {
                    return elem["id"];
                } else if (elem.hasOwnProperty("id") && (typeof elem["id"]) === "string" && (elem["id"] as string).indexOf("/") > -1) {
                    var entityId: string = (elem["id"] as string);
                    var slashIndex = entityId.lastIndexOf("/") + 1;
                    var id = entityId.substring(slashIndex);
                    return id;
                } else if (!elem.hasOwnProperty("id") && elem.hasOwnProperty("applicationDefinitionId")) {
                    var appId: string = elem["applicationDefinitionId"];
                    var strId = appId.split("/")[5];
                    return "";
                } else {
                    return elem["id"];
                }
            };
            /* tslint:enable: no-string-literal */

        }]);

}