namespace Advant.Crossroads {
    interface IadvSidebar extends angular.IModule { }

    // Create the module and define its dependencies.
    var advSidebar: IadvSidebar = angular.module("sidebar", [
    // Angular modules 

    // Custom modules 

    // 3rd Party Modules
        "ui.bootstrap.collapse"
    ]);

    /* tslint:disable: no-empty */
    // Execute bootstrapping code and any dependencies.
    advSidebar.run(["$q", "$rootScope", ($q, $rootScope) => {

    }]);
    /* tslint:enable: no-empty */

    advSidebar.constant("navConfig", {
        closeOthers: true
    });

    export interface INavConfig {
        closeOthers: boolean;
    }

    export interface INavAttribute extends angular.IAttributes {
        closeOthers: string;
    }

    export interface IAdvNavController {
        groups: Array<any>;
        closeOthers: (openGroup: any) => void;
        addGroup: (groupScope: IAdvSideNavGroupScope) => void;
        removeGroup: (group: any) => void;
    }

    export class AdvNavController implements IAdvNavController {
        static controllerId: string = "advNavController";
        groups: Array<any> = [];

        constructor(private $scope: angular.IScope, private $attrs: INavAttribute, private navConfig: INavConfig) {
        }

        // Ensure that all the groups in this nav are closed, unless close-others explicitly says not to
        public closeOthers(openGroup: any): void {
            var closeOthers = angular.isDefined(this.$attrs.closeOthers) ? this.$scope.$eval(this.$attrs.closeOthers) : this.navConfig.closeOthers;
            if (closeOthers) {
                angular.forEach(this.groups, function (group) {
                    if (group !== openGroup) {
                        group.isOpen = false;
                    }
                });
            }
        }

        // This is called from the nav-group directive to add itself to the nav
        public addGroup(groupScope: IAdvSideNavGroupScope): void {
            var that = this;
            this.groups.push(groupScope);

            groupScope.$on("$destroy", function (event) {
                that.removeGroup(groupScope);
            });
        }

        public removeGroup(group: any): void {
            var index = this.groups.indexOf(group);
            if (index !== -1) {
                this.groups.splice(this.groups.indexOf(group), 1);
            }
        }

    }

    advSidebar.controller(AdvNavController.controllerId, ["$scope", "$attrs", "navConfig", ($scope, $attrs, navConfig) =>
        new AdvNavController($scope, $attrs, navConfig)
    ]);


    export interface IsideNav extends angular.IDirective {
    }

    // The sideNav directive simply sets up the directive controller    
    export class SideNav implements IsideNav {
        static directiveId: string = "oldadvSidenav";
        restrict: string = "AE";
        controller: string = AdvNavController.controllerId;
        replace: boolean = true;
        transclude: boolean = true;
        template: string = "<div ng-transclude></div>";

    }

    advSidebar.directive(SideNav.directiveId, [() =>
        new SideNav()
    ]);

    export interface IAdvSideNavGroup extends angular.IDirective {
    }

    export interface IAdvSideNavGroupScope extends angular.IScope {
        isOpen: boolean;
        style: string;
    }

    export interface INavGroupAttribute extends angular.IAttributes {
        isOpen: string;
    }

    export class AdvSideNavGroup implements IAdvSideNavGroup {
        static directiveId: string = "advSidenavgroup";
        setHeading: any;
        restrict: string = "E";
        require: string = "^" + SideNav.directiveId;
        replace: boolean = true;
        transclude: boolean = true;
        scope: any = { heading: "@", url: "@" };     // Create an isolated scope and interpolate the heading attribute onto this scope        
        template: string = "<div class=\"nav-menu-item\">\n" +
        "<a ng-href=\"{{url}}\" ng-click=\"isOpen = !isOpen\" adv-transclude=\"heading\">{{heading}}</a>\n" +
        "<div ng-show=\"1\" class=\"sub-nav collapse\" uib-collapse=\"!isOpen\" ng-transclude adv-hidetransclude></div>\n" +
        "</div> ";
        controller() {
            this.setHeading = function (element) {
                this.heading = element;
            };
        }

        constructor(private $parse: angular.IParseService) {

        }

        link = (scope?: IAdvSideNavGroupScope, element?, attrs?: INavGroupAttribute, navControl?: AdvNavController, transclude?: angular.ITranscludeFunction) => {
            var getIsOpen, setIsOpen;

            navControl.addGroup(scope);

            scope.isOpen = false;

            if (attrs.isOpen) {
                getIsOpen = this.$parse(attrs.isOpen);
                setIsOpen = getIsOpen.assign;

                scope.$parent.$watch(getIsOpen, function (value) {
                    scope.isOpen = !!value;
                });
            }

            scope.$watch("isOpen", function (value) {
                if (value) {
                    navControl.closeOthers(scope);
                }
                if (setIsOpen) {
                    setIsOpen(scope.$parent, value);
                }
            });
        };
    }

    advSidebar.directive(AdvSideNavGroup.directiveId, ["$parse", $parse =>
        new AdvSideNavGroup($parse)
    ]);

    export interface IAdvSidNavHeading extends angular.IDirective {
    }

    export interface IAdvSidNavHeadingScope extends angular.IScope {
        isOpen: boolean;
    }

    export class AdvSidNavHeading implements IAdvSidNavHeading {
        static directiveId: string = "advSidnavheading";
        transclude: boolean = true;
        replace: boolean = true;
        require: string = "^?" + AdvSideNavGroup.directiveId;
        restrict: string = "E";
        template: string = "";

        link = (scope?: IAdvSideNavGroupScope, element?, attrs?: INavGroupAttribute, advSidNavGroupCtrl?, transclude?: angular.ITranscludeFunction) => {
            // Pass the heading to the accordion-group controller
            // so that it can be transcluded into the right place in the template
            // [The second parameter to transclude causes the elements to be cloned so that they work in ng-repeat]
            /* tslint:disable: no-empty */
            if (advSidNavGroupCtrl) {
                advSidNavGroupCtrl.setHeading(transclude(scope, function () { }));
            }
            /* tslint:enable: no-empty */
        };
    }

    // Update the app1 variable name to be that of your module variable
    advSidebar.directive(AdvSidNavHeading.directiveId, [() =>
        new AdvSidNavHeading()
    ]);

    export interface IAdvTransclude extends angular.IDirective {
    }

    export class AdvTransclude implements IAdvTransclude {
        static directiveId: string = "advTransclude";
        require: string = "^" + AdvSideNavGroup.directiveId;
        restrict: string = "A";
        link = (scope?, element?, attr?, controller?) => {
            scope.$watch(function () { return controller[attr.advTransclude]; }, function (heading) {
                if (heading) {
                    element.html("");
                    element.append(heading);
                }
            });
        };
    }

    // Update the app1 variable name to be that of your module variable
    advSidebar.directive(AdvTransclude.directiveId, [() =>
        new AdvTransclude()
    ]);

    export interface IAdvHideTransclude extends angular.IDirective {
    }

    export class AdvHideTransclude implements IAdvHideTransclude {
        static directiveId: string = "advHidetransclude";
        restrict: string = "A";
        compile(element?, attr?, transclude?) {
            transclude(element, (clone) => {
                // If the advSidnavheading element is present then there will be at least 3 items
                // so there has to be more than just that element to show.
                var show = clone.length > 3 ? "true" : "false";
                attr.ngShow = show;
            });
            return {};
        }
    }

    // Update the app1 variable name to be that of your module variable
    advSidebar.directive(AdvHideTransclude.directiveId, [() =>
        new AdvHideTransclude()
    ]);



}
