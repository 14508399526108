/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    interface ICfncDateCompare extends ng.IDirective {

    }

    interface ICfncDateCompareScope extends ng.IScope {
        ngModel: any;
        compareDate: any;
    }

    interface ICfncDateCompareAttributes extends ng.IAttributes {
        isBefore: string;
        isAfter: string;
        isSame: string;
        ngModel: string;
    }

    class CfncDateCompare implements ICfncDateCompare {
        static directiveId: string = "cfncDateCompare";
        restrict: string = "A";
        require: string = "?ngModel";
        scope: any = {
            ngModel: "=",
            isBefore: "=",
            isAfter: "=",
            isSame: "="
        };

        constructor(private $parse: ng.IParseService, private common: ICommonService) {
        }

        link = (scope: ICfncDateCompareScope, element, attrs: ICfncDateCompareAttributes, controller: ng.INgModelController) => {

            scope.$watchGroup(["ngModel", "isBefore", "isAfter", "isSame"], (newValue, oldValue, originalScope: ICfncDateCompareScope) => {

                if (newValue[0] != null && newValue[1] != null) {
                    if (moment(newValue[0]).isBefore(newValue[1]) || moment(newValue[0]).isSame(newValue[1])) {
                        controller.$setValidity("comparedatebefore", true);
                    }
                    else {
                        controller.$setValidity("comparedatebefore", false);
                    }
                }

                if (newValue[0] != null && newValue[2] != null) {
                    if (moment(newValue[0]).isAfter(newValue[2]) || moment(newValue[0]).isSame(newValue[2])) {
                        controller.$setValidity("comparedateafter", true);
                    }
                    else {
                        controller.$setValidity("comparedateafter", false);
                    }
                }

                if (newValue[0] != null && newValue[3] != null) {
                    if (moment(newValue[0]).isSame(newValue[3])) {
                        controller.$setValidity("comparedatesame", true);
                    }
                    else {
                        controller.$setValidity("comparedatesame", false);
                    }
                }


                scope.$applyAsync();

            });
        };
    }

    angular.module("app").directive(CfncDateCompare.directiveId, ["$parse", "common", ($parse, common) =>
        new CfncDateCompare($parse, common)
    ]);
}