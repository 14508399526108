/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict"

    interface ITAAPMajors {
    }

    class TAAPMajors implements ITAAPMajors {
        static controllerId: string = "taapMajors";
        static $inject: Array<string> = ["config", "dialogs", "$routeParams", "common", "Restangular", "$uibModal"];
        private log;
        lastUpdated: any;
        test: string;
        fallHasDeadline: string;
        springHasDeadline: string;
        summerHasDeadline: string;
        fallDeadline: string;
        springDeadline: string;
        summerDeadline: string;
        organizationId: any;
        institutionMajors: any;
        taapMajors: any;
        selectedMajor: any;
        taapSchool: boolean;
        currentMajor: any;
        logSuccess: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;

        constructor(private config: Advant.Crossroads.ICrossroadsConfig,
            private dialogs: ng.dialogs.IDialogService,
            private $routeParams,
            private common: ICommonService,
            private Restangular: Restangular.IService,
            private $uibModal: ng.ui.bootstrap.IModalService) {
            this.log = common.logger.getLogFn(TAAPMajors.controllerId);
            this.logSuccess = this.common.logger.getLogFn(TAAPMajors.controllerId, "success");
            this.logError = this.common.logger.getLogFn(TAAPMajors.controllerId, "error");

            this.activate();
            common.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });
        }

        activate() {
            this.common.activateController([], TAAPMajors.controllerId)
                .then(() => {
                    this.log("TAAP Majors");
                    var app = sessionStorage.getItem("activeApplication");
                    var splitOrgId = app.split("/");
                    this.organizationId = splitOrgId[1];
                    this.checkTaapSchool();
                    //this.getInstitutionMajors();
                    this.fallHasDeadline = "";
                    this.springHasDeadline = "";
                    this.summerHasDeadline = "";
                    this.taapSchool = false;
                    this.getTaapMajors();
                });
        }

        checkTaapSchool() {
            this.Restangular.one("TaapAdmin/CheckTaapSchool/" + this.organizationId).get().then((results) => {
                console.log(results);
                this.taapSchool = results;
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        newMajor() {
            var newMajor = { id: "", organizationId: "organizations/" + this.organizationId, name: "", fallDeadline: "", fallDeadlineDate: null, springDeadline: "", springDeadlineDate: null, summerDeadline: "", summerDeadlineDate: null };
            this.openModal(newMajor);
        }

        editMajor() {
            var currentMajor = this.currentMajor;
            this.openModal(currentMajor);
        }

        openModal(major: any) {
            var modalInstance = this.$uibModal.open({
                templateUrl: '/app/taapAdministration/taapMajorModal.html',
                size: 'xlg',
                controller: 'taapMajorModal',
                backdrop: 'static',
                resolve: {
                    majorData: function () { return major; }
                }
            });
            modalInstance.result.then((val: boolean) => {
                if (val) {
                    this.getTaapMajors();
                }
            });
        }

        getInstitutionMajors = () => {
            this.Restangular.one("TaapAdmin/GetAllInstitutionMajors/" + this.organizationId).get().then((results) => {
                console.log(results);
                this.institutionMajors = results.majors;
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        getTaapMajors = () => {
            this.Restangular.one("TaapAdmin/GetTaapMajors/" + this.organizationId).get().then((results) => {
                console.log(results);
                this.taapMajors = results.majors;
            }, (reason) => {
                console.error(reason);
                this.logError("An error occurred", reason, true);
            });
        }

        deleteMajor = () => {
            var dlg = this.dialogs.confirm("Please Confirm",
                "Are you sure you want to delete this major? This action is not reversible.");
            dlg.result.then((btn) => {
                var major = this.currentMajor;
                this.Restangular.all("TaapAdmin/DeleteTaapMajor").post(major).then((results) => {
                    this.getTaapMajors();
                }, (reason) => {
                    console.error(reason);
                    this.logError("An error occurred", reason, true);
                });
            });
        }

        cancelSelection() {
            this.selectedMajor = "";
            this.fallHasDeadline = "";
            this.fallDeadline = "";
            this.springHasDeadline = "";
            this.springDeadline = "";
            this.summerHasDeadline = "";
            this.summerDeadline = "";
        }
    }
    angular.module("app").controller(TAAPMajors.controllerId, TAAPMajors);
}