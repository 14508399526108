/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IRdsStatusFilter {
        onlyStarted?: boolean;
        onlyCompleted?: boolean;
        rdsComplete?: boolean;
        startedStartDate?: string;
        startedEndDate?: string;
        completedStartDate?: string;
        completedEndDate?: string;
    }

    class ApplicantsWithRdsStatus {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingUserApplications: boolean = false;;
        userApplications: Array<UserApplicationWithRdsStatus>;
        applicationDefinition: IApplicationDefinition;
        page: number;
        pageSize: number;
        sortBy: string;
        searchFilter: string;
        sortDirection: string;
        totalCount: number;
        totalPages: number;
        startRow: number;
        endRow: number;
        showFilter: boolean;
        exporting: boolean = false;
        filter: IRdsStatusFilter = {
            rdsComplete: false
        };

        static $inject: any = ["$scope", "$location", "$http", "Restangular", "dialogs", "common", "config"];

        constructor(public $scope: angular.IScope,
            public $location: angular.ILocationService,
            public $http: angular.IHttpService,
            public Restangular: Restangular.IService,
            public dialogs: angular.dialogs.IDialogService,
            public common: ICommonService,
            public config: ICrossroadsConfig) {

            this.log = common.logger.getLogFn("applicantsWithRdsStatus");
            this.logError = common.logger.getLogFn("applicantsWithRdsStatus", "error");
            this.logSuccess = common.logger.getLogFn("applicantsWithRdsStatus", "success");

            this.page = 1;
            this.pageSize = 25;
            this.sortBy = "LastName";
            this.sortDirection = "ASC";

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.activate();
        }

        activate = () => {
            this.common.activateController([this.getApplications()], "applicantsWithRdsStatus")
                .then(result => {
                    this.log("Activated applicantsWithRdsStatus View");
                });
        };

        exportToExcel = () => {
            this.exporting = true;
            var queryParams = this.getQueryParams();
            var httpPath = this.config.apiHost + "/" + this.common.getUser().activeApplication +
                "/userApplications/withRdsStatus/export?sortBy=" + encodeURI(queryParams.sortBy) + "&sortDirection=" + queryParams.sortDirection + "&pageSize=0&page=1";



            if (queryParams.rdsComplete) {
                httpPath += "&rdsComplete=" + queryParams.rdsComplete;
            }

            if (queryParams.startedStartDate) {
                httpPath += "&startedStartDate=" + queryParams.startedStartDate;
            }

            if (queryParams.startedEndDate) {
                httpPath += "&startedEndDate=" + queryParams.startedEndDate;
            }

            if (queryParams.onlyStarted) {
                httpPath += "&onlyStarted=" + queryParams.onlyStarted;
            }

            if (queryParams.onlyCompleted) {
                httpPath += "&onlyCompleted=" + queryParams.onlyCompleted;
            }

            if (queryParams.completedStartDate) {
                httpPath += "&completedStartDate=" + queryParams.completedStartDate;
            }

            if (queryParams.completedEndDate) {
                httpPath += "&completedEndDate=" + queryParams.completedEndDate;
            }

            this.openFile(httpPath).then(() => {
                this.exporting = false;
            }, (reason) => {
                this.logError("An error occurred while saving the report to excel", reason);
            });
        };

        getApplications = (): angular.IPromise<Array<Advant.Crossroads.UserApplicationWithRdsStatus>> => {
            this.loadingUserApplications = true;
            this.userApplications = [];

            return this.Restangular.one(this.common.getUser().activeApplication).getList("userApplications/withRdsStatus", this.getQueryParams()).then((result: IRestangularResult<Advant.Crossroads.UserApplicationWithRdsStatus>) => {
                this.userApplications = result;
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingUserApplications = false;
                return result;
            }, (reason) => {
                this.loadingUserApplications = false;
                this.logError("An error occurred while getting the user applications", reason);
                return null;
            });
        };

        getQueryParams = (): any => {
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                rdsComplete: this.filter.rdsComplete ? this.filter.rdsComplete : null,
                startedStartDate: this.filter.startedStartDate ? moment(this.filter.startedStartDate).toISOString() : "",
                startedEndDate: this.filter.startedEndDate ? moment(this.filter.startedEndDate).toISOString() : "",
                onlyStarted: this.filter.onlyStarted ? this.filter.onlyStarted : null,
                onlyCompleted: this.filter.onlyCompleted ? this.filter.onlyCompleted : null,
                completedStartDate: this.filter.completedStartDate ? moment(this.filter.completedStartDate).toISOString() : "",
                completedEndDate: this.filter.completedEndDate ? moment(this.filter.completedEndDate).toISOString() : "",

            };

            return queryParams;
        };

        openFile = (httpPath) => {
            return this.$http.get<any>(httpPath, { responseType: "arraybuffer" })
                .then((response) => {
                    var octetStreamMime = "application/octet-stream";
                    var success = false;

                    // Get the headers
                    var headerValues = response.headers();

                    // Get the filename from the x-filename header or default to "download.bin"
                    var filename = headerValues["x-filename"] || "download.pdf";

                    // Determine the content type from the header or default to "application/octet-stream"
                    var contentType = headerValues["content-type"] || octetStreamMime;
                    var blob: Blob;
                    blob = new Blob([response.data], { type: contentType });

                    saveAs(blob, filename);

                }, (reason) => {
                    console.log("Request failed with status: " + status);
                });
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.getApplications();
        };

        applyFilter = () => {
            this.page = 1;
            this.getApplications();
        };

        clearFilter = () => {
            this.filter = {
                rdsComplete: false
            };
        };
    }

    angular.module("app").controller("applicantsWithRdsStatus", ApplicantsWithRdsStatus);

}