/// <reference path="../../app.ts" />
namespace Advant.Crossroads {

    interface IAdminSettings {
        authFailureLogging: AuthFailureLogging;
        requireTwoFactorAuthentication: RequireTwoFactorAuthentication;
    }

    class AuthFailureLogging {
        name: string;
        enabled: boolean;
        expires: Date;
    }

    class RequireTwoFactorAuthentication {
        name: string;
        enabled: boolean;
    }

    class AdminSettings implements IAdminSettings {
        static controllerId: string = "adminSettings";
        static $inject: any = ["$location", "$uibModal", "dialogs", "common", "config", "Restangular"];

        authFailureLogging: AuthFailureLogging;
        requireTwoFactorAuthentication: RequireTwoFactorAuthentication;

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;

        constructor(private $location: angular.ILocationService,
            private $uibModal: angular.ui.bootstrap.IModalService,
            private dialogs,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private Restangular: Restangular.IService) {
            this.log = common.logger.getLogFn(AdminSettings.controllerId);
            this.logError = common.logger.getLogFn(AdminSettings.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(AdminSettings.controllerId, "success");

            this.authFailureLogging = new AuthFailureLogging();
            this.requireTwoFactorAuthentication = new RequireTwoFactorAuthentication();
            this.activate();

        }

        private activate() {
            var self = this;
            this.common.activateController([this.getAuthFailureLoggingSetting(), this.getRequireTwoFactorAuthenticationSetting()], AdminSettings.controllerId)
                .then(result => {
                    self.log("Activated Users View", null, false);

                });
        }


        getAuthFailureLoggingSetting() {
            return this.Restangular.all("adminSettings").one("authFailureLogging").get().then((result) => {
                this.authFailureLogging.name = result.name;
                this.authFailureLogging.enabled = result.setting.enabled;
                this.authFailureLogging.expires = result.setting.expires;
            }, (reason) => {
                this.logError("An error occurred getting the authentication failure logging setting.", reason, true);
            });
        }

        toggleAuthFailureLogging(toggle: string) {
            this.authFailureLogging.enabled = !this.authFailureLogging.enabled;
            this.Restangular.all("adminSettings").one("authFailureLogging").one(toggle).get().then((result) => {
                this.logSuccess("Succesfully updated setting", null, true);
                this.authFailureLogging.enabled = result.setting.enabled;
                this.authFailureLogging.expires = result.setting.expires;
            }, (reason) => {
                this.logError("An error occurred.", reason, true)
            });
        }

        getRequireTwoFactorAuthenticationSetting() {
            return this.Restangular.all("adminSettings").one("requireTwoFactorAuthentication").get().then((result) => {
                this.requireTwoFactorAuthentication.name = result.name;
                this.requireTwoFactorAuthentication.enabled = result.setting.enabled;
            }, (reason) => {
                this.logError("An error occurred getting the require two factor authentication setting.", reason, true);
            });
        }

        toggleRequireTwoFactorAuthentication(toggle: string) {
            this.requireTwoFactorAuthentication.enabled = !this.requireTwoFactorAuthentication.enabled;
            this.Restangular.all("adminSettings").one("requireTwoFactorAuthentication").one(toggle).get().then((result) => {
                this.logSuccess("Succesfully updated setting", null, true);
                this.requireTwoFactorAuthentication.enabled = result.setting.enabled;
            }, (reason) => {
                this.logError("An error occurred.", reason, true)
            });
        }

    }

    angular.module("app").controller(AdminSettings.controllerId, AdminSettings);
}
