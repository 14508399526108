/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict"

    export interface IScheduleDetailsScope extends ng.IScope {
        vm: any;
    }

    interface IExportProjectSchedulesRouteParams extends ng.route.IRouteParamsService {
        id: string;
        key: string;
    }

    class Day {
        id: number;
        dayOfWeek: string;
        checked: boolean;
    }

    class ExportProjectSchedule {
        id: string;
        exportProjectName: string;
        exportProjectId: string;
        UserId: string;
        schedule: Schedule;
        canEdit: boolean;
    }

    class Schedule {
        key: string;
        applicationDefinitionId: string;
        name: string;
        scheduleType: string;
        startDate: Date;
        dateCreated: Date;
        dateLastModified: Date;
        startHour: number;
        startMinute: number;
        endHour: number;
        endMinute: number;
        daysOfWeek: Array<string>;
        postProcessingCommand: string;
        isNew: boolean;
        ftpEnabled: boolean;
        ftpUsername: string;
        ftpPassword: string;
        ftpAddress: string;
        ftpPath: string;
        interval: number;
        repeat: boolean;
        enabled: boolean;
    }


    export interface IScheduleDetailsController {
        log: (msg, data?, showHowl?) => void;
        days: Array<Day>;
        startTime: Date;
        endTime: Date;
        exportProjectScheduleInfo: ExportProjectSchedule;
        originalExportProjectSchedule: ExportProjectSchedule;
        startDateCalOpened: boolean;
        exportProjects: Array<any>;
    }

    export class ScheduleDetailsController implements IScheduleDetailsController {
        static controllerId: string = "scheduleDetailsController";
        static $inject = ["$scope", "$routeParams", "$location", "dialogs", "common", "config", "Restangular"];
        log: (msg, data?, showHowl?) => void;
        days: Array<Day>;
        startTime: Date;
        endTime: Date;
        exportProjectScheduleInfo: ExportProjectSchedule;
        originalExportProjectSchedule: ExportProjectSchedule;
        startDateCalOpened: boolean;
        isNew: boolean = true;
        confirmedFtpPassword: string;
        exportProjects: Array<any>;
        interval: number;
        intervalFactor: number;
        users: Array<any>;
        exportProjectFilter: any;

        constructor(
            private $scope: IScheduleDetailsScope,
            private $routeParams: IExportProjectSchedulesRouteParams,
            private $location: ng.ILocationService,
            private dialogs: ng.dialogs.IDialogService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private Restangular: Restangular.IService) {
            this.log = common.logger.getLogFn(CrossRouteController.controllerId);
            this.activate();


        }

        private activate() {
            var self = this;
            this.common.activateController([], ScheduleDetailsController.controllerId)
                .then(result => {
                    self.log("Activated CrossRoute View");
                    self.days = [
                        { id: 1, dayOfWeek: "Monday", checked: false },
                        { id: 2, dayOfWeek: "Tuesday", checked: false },
                        { id: 3, dayOfWeek: "Wednesday", checked: false },
                        { id: 4, dayOfWeek: "Thursday", checked: false },
                        { id: 5, dayOfWeek: "Friday", checked: false },
                        { id: 6, dayOfWeek: "Saturday", checked: false },
                        { id: 7, dayOfWeek: "Sunday", checked: false },
                    ];

                    self.Restangular.one("crossroute/" + sessionStorage.getItem("activeApplication") + "/exportProjects")
                        .get()
                        .then((response) => {
                            self.exportProjects = response;
                            self.users = _.uniqBy(self.exportProjects, (project) => { return project.createdBy.id });
                        }, (reason) => {
                            self.log("An error has occurred");
                            console.log(reason);
                        });

                    self.startDateCalOpened = false;

                    if (self.$routeParams.id) {
                        this.isNew = false;
                        self.getSchedule();
                    }
                    else {
                        this.startTime = new Date();
                        this.startTime.setHours(0, 0);
                        this.endTime = new Date();
                        this.endTime.setHours(23, 59);
                        this.exportProjectScheduleInfo = new ExportProjectSchedule();
                        this.exportProjectScheduleInfo.schedule = new Schedule();
                        this.exportProjectScheduleInfo.schedule.startDate = new Date();
                        this.exportProjectScheduleInfo.schedule.scheduleType = "Daily";
                        this.intervalFactor = 1;
                        this.exportProjectScheduleInfo.schedule.enabled = true;
                        this.exportProjectScheduleInfo.canEdit = true;
                    }

                });
        }

        getSchedule = () => {
            var self = this;
            this.isNew = false;
            this.Restangular
                .one("CrossRoute/ExportProjectSchedules", this.$routeParams.id)
                .one("Key", this.$routeParams.key).get()
                .then((result) => {
                    self.exportProjectScheduleInfo = result;
                    self.exportProjectScheduleInfo.schedule.startDate = new Date(result.schedule.startDate);
                    self.originalExportProjectSchedule = _.cloneDeep(this.exportProjectScheduleInfo);
                    self.startTime = new Date();
                    self.startTime.setHours(self.exportProjectScheduleInfo.schedule.startHour);
                    self.startTime.setMinutes(self.exportProjectScheduleInfo.schedule.startMinute);
                    self.endTime = new Date();
                    self.endTime.setHours(self.exportProjectScheduleInfo.schedule.endHour);
                    self.endTime.setMinutes(self.exportProjectScheduleInfo.schedule.endMinute);
                    self.confirmedFtpPassword = result.schedule.ftpPassword;
                    _.forEach(self.exportProjectScheduleInfo.schedule.daysOfWeek, (dayOfWeek) => {
                        _.find(self.days, (d) => {
                            return d.dayOfWeek == dayOfWeek;
                        }).checked = true;
                    });
                    self.intervalFactor = self.exportProjectScheduleInfo.schedule.interval % 60 > 0 ? 1 : 60;
                    self.interval = self.exportProjectScheduleInfo.schedule.interval / self.intervalFactor;

                }, (reason) => {
                    console.log(reason);
                });

        };

        addSchedule = () => {
            var selectedDays;
            this.exportProjectScheduleInfo.schedule.startDate = new Date(this.exportProjectScheduleInfo.schedule.startDate.toString());

            switch (this.exportProjectScheduleInfo.schedule.scheduleType) {
                case "Interval":
                case "Daily":
                    selectedDays = _.map(_.filter(this.days, (d) => {
                        return true;
                    }), "dayOfWeek");
                    break;
                case "Weekly":
                    selectedDays = _.map(_.filter(this.days, (d) => {
                        return d.checked;
                    }), "dayOfWeek");
                    break;
                case "Monthly":
                    selectedDays = selectedDays = _.map(_.filter(this.days, (d) => {
                        return d.id == this.exportProjectScheduleInfo.schedule.startDate.getDay();
                    }), "dayOfWeek");
                    break;
            }

            this.exportProjectScheduleInfo.schedule.startHour = this.startTime.getHours();
            this.exportProjectScheduleInfo.schedule.startMinute = this.startTime.getMinutes();
            this.exportProjectScheduleInfo.schedule.endHour = this.endTime.getHours();
            this.exportProjectScheduleInfo.schedule.endMinute = this.endTime.getMinutes();
            this.exportProjectScheduleInfo.schedule.daysOfWeek = selectedDays;
            this.exportProjectScheduleInfo.schedule.interval = this.interval * this.intervalFactor;

            var schedule = {
                name: this.exportProjectScheduleInfo.schedule.name,
                applicationDefinitionId: sessionStorage.getItem("activeApplication"),
                startDate: this.exportProjectScheduleInfo.schedule.startDate,
                startHour: this.exportProjectScheduleInfo.schedule.startHour,
                startMinute: this.exportProjectScheduleInfo.schedule.startMinute,
                endHour: this.exportProjectScheduleInfo.schedule.endHour,
                endMinute: this.exportProjectScheduleInfo.schedule.endMinute,
                scheduleType: this.exportProjectScheduleInfo.schedule.scheduleType,
                daysOfWeek: selectedDays,
                postProcessingCommand: this.exportProjectScheduleInfo.schedule.postProcessingCommand,
                ftpEnabled: this.exportProjectScheduleInfo.schedule.ftpEnabled,
                ftpAddress: this.exportProjectScheduleInfo.schedule.ftpAddress,
                ftpPath: this.exportProjectScheduleInfo.schedule.ftpPath,
                ftpUsername: this.exportProjectScheduleInfo.schedule.ftpUsername,
                ftpPassword: this.exportProjectScheduleInfo.schedule.ftpPassword,
                interval: this.exportProjectScheduleInfo.schedule.interval,
                repeat: this.exportProjectScheduleInfo.schedule.repeat,
                enabled: this.exportProjectScheduleInfo.schedule.enabled
            };

            this.Restangular.all("CrossRoute/" + this.exportProjectScheduleInfo.exportProjectId).all("saveSchedule").post(schedule).
                then((result) => {
                    this.$location.path("/crossroute");
                }, (reason) => {
                    this.common.logger.logError("An error occurred trying to create the schedule.", reason);
                });
        };

        scheduleTypeChanged = () => {
            if (this.exportProjectScheduleInfo.schedule.scheduleType == "Daily" ||
                this.exportProjectScheduleInfo.schedule.scheduleType == "Interval") {
                this.days.forEach((d) => {
                    d.checked = true;
                });
            }
            else {
                this.days.forEach((d) => {
                    d.checked = false;
                });
            }
        };

        deleteSchedule = () => {
            var schedule = {
                name: this.exportProjectScheduleInfo.schedule.name,
                applicationDefinitionId: sessionStorage.getItem("activeApplication")
            }
            var dlg = this.dialogs.confirm("Please Confirm",
                "Are you sure you want to delete this schedule?");

            dlg.result.then((btn) => {
                this.Restangular.all("CrossRoute/" + this.exportProjectScheduleInfo.exportProjectId).all("deleteSchedule").post(schedule).
                    then((result) => {
                        this.$location.path("/crossroute");
                    }, (reason) => {
                        this.common.logger.logError("An error occurred trying to delete the schedule.", reason);
                    });
            });

        };

        exportProjectFilterChanged = () => {
            console.log(this.exportProjectFilter);
            if (this.exportProjectFilter.createdBy && this.exportProjectFilter.createdBy.id == null) {
                delete this.exportProjectFilter.createdBy;
            }
        };

    }

    angular.module("app").controller(ScheduleDetailsController.controllerId, ScheduleDetailsController);
}