/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    interface ICurrentRulesetData {
        currentRuleSets: IRuleSet[];
        ruleSetFile?: RuleSetFile;
        appDefId: string;
        ruleSetType: string;
    }

    interface IRuleReferenceCache {
        ruleId: string;
        references: IRuleReference[];
    }

    interface IRuleReference {
        type: string;
        path: string;
    }

    export interface IRulesScope extends angular.IScope {
        vm: ApplicationRules;
        ruleSetForm: angular.IFormController;
    }

    export interface IApplicationRulesController {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        isSaving: boolean;
        addGroup: () => void;
        addRule: (group: IRuleGroup) => void;
        createNewRuleSet: () => void;
        getRules: () => angular.IPromise<Array<IRuleSet>>;
        getFields: () => angular.IPromise<Array<IInputField>>;

        loadingRules: boolean;
        rules: Array<IRuleSet>;
        inputFields: Array<IInputField>;
        valueFormattedFieldKeys: Array<string>;
        currentRule: IRuleSet;
        originalRules: Array<IRuleSet>;

    }

    export class ApplicationRules implements IApplicationRulesController {
        static controllerId: string = "applicationRules";
        static $inject = ["$scope", "dialogs", "Restangular", "common", "config", "helper", "$timeout"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingRules: boolean;
        inputFields: Array<IInputField>;
        valueFormattedFieldKeys: Array<string>;
        rules: Array<IRuleSet>;
        currentRule: IRuleSet;
        originalRules: Array<IRuleSet>;
        isSaving: boolean;
        ruleSetCount: number;

        equal: ICompareOperatorChoice;
        notEqual: ICompareOperatorChoice;
        contains: ICompareOperatorChoice;
        doesNotContain: ICompareOperatorChoice;
        lessThan: ICompareOperatorChoice;
        lessThanEqualTo: ICompareOperatorChoice;
        greaterThan: ICompareOperatorChoice;
        greaterThanEqualTo: ICompareOperatorChoice;
        startsWith: ICompareOperatorChoice;
        endsWith: ICompareOperatorChoice;
        isNull: ICompareOperatorChoice;
        isNotNull: ICompareOperatorChoice;

        currentRuleReferences: IRuleReference[];
        showRuleReferences: boolean = false;
        gettingReferences: boolean = false;
        allRuleReferences: IRuleReferenceCache[] = [];

        constructor(private $scope: IRulesScope,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private helper: IHelper,
            private $timeout: angular.ITimeoutService) {

            this.log = common.logger.getLogFn(ApplicationRules.controllerId);
            this.logError = common.logger.getLogFn(ApplicationRules.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(ApplicationRules.controllerId, "success");

            this.loadingRules = false;
            this.isSaving = false;

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.activate();
            this.createOperators();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getRules(), this.getFields()], ApplicationRules.controllerId)
                .then(result => {
                    self.log("Activated Rules View");
                });
        }

        private createOperators = () => {
            this.equal = {
                display: "Equals",
                value: "Equal"
            };
            this.notEqual = {
                display: "Does Not Equal",
                value: "NotEqual"
            };
            this.contains = {
                display: "Contains",
                value: "Contains"
            };
            this.doesNotContain = {
                display: "Does Not Contain",
                value: "DoesNotContain"
            };
            this.lessThan = {
                display: "Less Than",
                value: "LessThan"
            };
            this.lessThanEqualTo = {
                display: "Less Than Equal To",
                value: "LessThanEqualTo"
            };
            this.greaterThan = {
                display: "Greater Than",
                value: "GreaterThan"
            };
            this.greaterThanEqualTo = {
                display: "Greater Than Equal To",
                value: "GreaterThanEqualTo"
            };
            this.startsWith = {
                display: "Starts With",
                value: "StartsWith"
            };
            this.endsWith = {
                display: "Ends With",
                value: "EndsWith"
            };
            this.isNull = {
                display: "Is Null",
                value: "IsNull"
            };
            this.isNotNull = {
                display: "Is Not Null",
                value: "IsNotNull"
            };
        };

        addGroup = () => {
            var newGroup: any = { ruleGroupJoinOperator: "And", rules: [] };
            this.currentRule.groups.push(newGroup);
        };

        addRule = (group: IRuleGroup): void => {
            var newRule: any;
            if (group.rules.length === 0) {
                newRule = { ruleJoinOperator: "None" };
            } else {
                newRule = { ruleJoinOperator: "And" };
            }
            group.rules.push(newRule);
        };

        cancel = (): void => {
            var originalIndex = _.findIndex(this.originalRules, { "id": this.currentRule.id });
            var original = this.originalRules[originalIndex];
            angular.copy(original, this.currentRule);
            this.$scope.ruleSetForm.$setPristine();
        };

        createNewRuleSet = (): void => {
            var newRuleSet: any = {
                type: "Application",
                groups: [{ rules: [] }]
            };
            this.rules.push(newRuleSet);
            this.ruleSetCount += 1;
            this.currentRule = newRuleSet;
            this.showRuleReferences = false;
            this.gettingReferences = false;
            this.currentRuleReferences = null;
        };

        deleteRuleSet = (): void => {
            var confirm = this.dialogs.confirm("Delete Rule", "Are you sure you want to delete this rule? This action cannot be reversed.");

            confirm.result.then((button) => {
                var currentRuleRestangular: Restangular.IElement = <any>this.currentRule;
                currentRuleRestangular.remove().then(result => {
                    this.logSuccess("Your rule has been deleted", null, true);
                    this.currentRule = null;
                    this.getRules();
                }, error => {
                    if (error.data && error.data.$type === "Advant.Crossroads.Api.Models.EntityReferencedByAnotherEntityExceptionResult, Advant.Crossroads.Api") {
                        var errorMessage = "This rule is being used by the following items:<br/>";
                        _.forEach<{ type: string, key: string }>(error.data.referencedBy, (errorResult) => {
                            errorMessage += `<strong>${errorResult.type}: ${errorResult.key}</strong><br/>`;
                        });

                        errorMessage += "Please remove this rule from those items and try deleting again.";

                        this.logError(errorMessage, error, true);
                    } else {
                        this.logError("An error occurred while trying to delete the rule", error, true);
                    }
                });
            });
        };

        deleteGroup = (group: IRuleGroup): void => {
            _.remove(this.currentRule.groups, (aGroup) => {
                return aGroup === group;
            });
            this.$scope.ruleSetForm.$setDirty();
        };

        deleteRule = (rule: IRule, group: IRuleGroup): void => {
            _.remove(group.rules, (aRule) => {
                return aRule === rule;
            });
            this.$scope.ruleSetForm.$setDirty();
        };

        getOperators = (rule: IRule): Array<ICompareOperator> => {
            var operatorArray = [];
            if (!rule.codeLeftValue) {
                return operatorArray;
            }
            var inputField: IInputField = _.filter(this.inputFields, { "key": rule.codeLeftValue })[0];

            if (this.helper.isType(inputField.type, "DateField")) {
                operatorArray.push(this.lessThan, this.lessThanEqualTo, this.equal, this.notEqual, this.greaterThanEqualTo, this.greaterThan, this.isNull, this.isNotNull);
            } else if (this.helper.isType(inputField.type, "ListField") || this.helper.isType(inputField.type, "CcpEligibleField")) {
                operatorArray.push(this.lessThan, this.lessThanEqualTo, this.equal, this.notEqual,
                    this.greaterThanEqualTo, this.greaterThan, this.contains, this.doesNotContain,
                    this.startsWith, this.endsWith, this.isNull, this.isNotNull);
            } else if (this.helper.isType(inputField.type, "TextField") || this.helper.isType(inputField.type, "TextAreaField") || this.helper.isType(inputField.type, "MajorField")) {
                var textField: ITextField = <ITextField>inputField;
                if (textField.number) {
                    operatorArray.push(this.lessThan, this.lessThanEqualTo, this.equal, this.notEqual, this.greaterThanEqualTo, this.greaterThan, this.isNull, this.isNotNull);

                } else {
                    operatorArray.push(this.equal, this.notEqual, this.contains, this.doesNotContain, this.startsWith, this.endsWith, this.isNull, this.isNotNull);
                }
            } else if (this.helper.isType(inputField.type, "TermField")) {
                operatorArray.push(this.equal, this.notEqual,
                    this.contains, this.doesNotContain,
                    this.startsWith, this.endsWith, this.isNull, this.isNotNull);
            }
            else {
                operatorArray.push(this.equal, this.notEqual, this.isNull, this.isNotNull);
            }

            return operatorArray;
        };

        getFields = (): angular.IPromise<Array<IInputField>> => {
            this.inputFields = [];
            return this.Restangular.all(this.common.getUser().activeApplication).all("fields").getList().then((result) => {
                this.inputFields = result;

                var fieldKeys = _.map<IInputField, any>(this.inputFields, "key");
                for (var i = 0; i < fieldKeys.length; i++) {
                    fieldKeys[i] = `[[${fieldKeys[i]}]]`;
                }
                this.valueFormattedFieldKeys = fieldKeys;

                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the application fields.", reason);
                return this.inputFields;
            });
        };


        getRightValue = (rule: IRule, viewValue): angular.IPromise<Array<string>> => {
            var deferred = this.common.$q.defer<Array<string>>();

            var values: Array<string> = this.valueFormattedFieldKeys;

            if (rule.codeLeftValue) {
                var inputField: IInputField = _.filter(this.inputFields, { "key": rule.codeLeftValue })[0];

                if (this.helper.isType(inputField.type, "ListField")) {
                    var listField: IListField = <IListField>inputField;
                    return this.Restangular.all(this.common.getUser().activeApplication).one(listField.choiceListId).get().then((result) => {
                        var choiceList: IChoiceList = result;
                        var choices = _.map<IChoice, any>(choiceList.choices, "value");
                        values = values.concat(choices);
                        var filterValues = _.filter(values, (value) => {
                            return value.indexOf(viewValue) > -1;
                        });
                        return filterValues;
                    }, (reason) => {
                        this.logError("An error occurred while getting the list values", reason);
                        return values;
                    });
                }
            }

            values = _.filter(values, (value) => { return value.indexOf(viewValue) > -1; });
            if (values.length > 8) {
                values = _.take(values, 8);
            }
            deferred.resolve(values);

            return deferred.promise;
        };

        getRules = (): angular.IPromise<Array<IRuleSet>> => {
            this.loadingRules = true;
            this.rules = [];
            this.currentRule = null;
            return this.Restangular.all(this.common.getUser().activeApplication).all("rules").getList().then((result: IRestangularResult<IRuleSet>) => {
                this.rules = result;
                this.ruleSetCount = result.totalCount;
                this.originalRules = angular.copy(this.rules);
                this.loadingRules = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the rules", reason);
                return this.rules;
            });
        };

        save = () => {
            this.isSaving = true;
            if (!this.currentRule.id) {
                this.Restangular.all(this.common.getUser().activeApplication).all("rules").post(this.currentRule).then(result => {
                    this.logSuccess("Your new rule has been created", null, true);
                    var index = this.rules.indexOf(this.currentRule);
                    this.rules[index] = result;
                    this.currentRule = this.rules[index];
                    this.isSaving = false;
                    this.$scope.ruleSetForm.$setPristine();
                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to save the rule", error, true);
                });
            } else {
                //  Keep invalid join operators out of the first rule in a group and out of the first group
                if (this.currentRule.groups.length > 0) {
                    this.currentRule.groups[0].ruleGroupJoinOperator = "None";

                    angular.forEach(this.currentRule.groups, (group: IRuleGroup) => {
                        if (group.rules.length > 0) {
                            group.rules[0].ruleJoinOperator = "None";
                        }
                    });
                }
                var currentRuleRestangular: Restangular.IElement = <any>this.currentRule;
                currentRuleRestangular.put().then(result => {
                    this.logSuccess("Your rule has been updated", null, true);
                    this.isSaving = false;
                    var originalIndex = _.findIndex(this.originalRules, { "id": this.currentRule.id });
                    var original = this.originalRules[originalIndex];
                    angular.copy(this.currentRule, original);
                    this.$scope.ruleSetForm.$setPristine();
                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to save the rule", error, true);
                });
            }
        };

        selectRule = (rule: IRuleSet) => {

            if (this.currentRule && this.$scope.ruleSetForm.$dirty) {
                var confirm = this.dialogs.confirm("Unsaved Changes", "Are you sure you want to select another rule? Selecting another rule will cause you to lose any changes you have made to this rule.");
                
                confirm.result.then((result) => {
                    if (result == "yes") {
                        this.cancel();
                        this.currentRule = rule;
                    } else {
                        this.currentRuleReferences = null;
                        this.showRuleReferences = false;
                        this.gettingReferences = false;
                        this.currentRule = this.currentRule;
                    }

                });
            } else {
                this.currentRule = rule;
                this.showRuleReferences = false;
                this.gettingReferences = false;
                this.currentRuleReferences = null;
            }


        };

        exportRuleSet = () => {
            var ruleSet: RuleSetFile = new RuleSetFile(this.currentRule);

            var blob: Blob = new Blob([ruleSet.toJson()], { type: "application/octet-stream" });
            saveAs(blob, ruleSet.rule.name + ".json");
        };

        importRuleSet = () => {


            var currentRuleSetData: ICurrentRulesetData = {
                currentRuleSets: this.rules,
                appDefId: this.common.getUser().activeApplication,
                ruleSetType: "Application"
            };

            var importFile = this.dialogs.create("/app/rules/importRuleSet.html", "importRuleSet", currentRuleSetData, { size: "lg" });

            importFile.result.then((result: ICurrentRuleSetData) => {
                this.rules = result.currentRuleSets;
                this.currentRule = _.find(this.rules, {
                    id: result.ruleSetFile.rule.id
                });
                this.$timeout(this.save);
            });
        };

        getRuleReferences = () => {
            var self = this;
            if (this.currentRuleReferences == null) {
                var cachedReferences = _.find(this.allRuleReferences, { ruleId: this.currentRule.id });
                if (cachedReferences == null) {
                    this.gettingReferences = true;
                    this.Restangular.all(this.common.getUser().activeApplication).all(this.currentRule.id).one("allReferences").get()
                        .then((result) => {
                            self.gettingReferences = false;
                            self.showRuleReferences = true;
                            self.currentRuleReferences = result;
                            self.allRuleReferences.push({ ruleId: self.currentRule.id, references: result });

                        }, (reason) => {
                            self.currentRuleReferences = null;
                            self.gettingReferences = false;
                            self.logError("An error occurred while getting rule associations", reason, true)

                        });
                } else {
                    this.currentRuleReferences = cachedReferences.references;
                    this.showRuleReferences = true;
                }

            } else {
                this.showRuleReferences = !this.showRuleReferences;

            }
        };

        formatPath(path: string)
        {
            return path != null ? path.replace(/\//g, " > ") : "";
        }
    }


    angular.module("app").controller(ApplicationRules.controllerId, ApplicationRules);
}