/// <reference path="userapplications.ts" />
/// <reference path="../app.ts" />
namespace Advant.Crossroads {



    class StartedApplications extends UserApplications {
        static controllerId: string = "startedApplications";
        static $inject: any = ["$scope", "$location", "Restangular", "dialogs", "common", "config"];
        pageTitle: string = "In-Progress Applications";
        dataPath: string = "userApplications/started";

        constructor(public $scope: IUserApplicationsScope,
            public $location: angular.ILocationService,
            public Restangular: Restangular.IService,
            public dialogs: angular.dialogs.IDialogService,
            public common: ICommonService,
            public config: Advant.Crossroads.ICrossroadsConfig) {

            super($scope, $location, Restangular, dialogs, common, config);


            this.dataPath = "userApplications/started";
            this.dateLabel = "Started Between";
            this.showNewOnlyFilter = false;

            this.activate();
        }

        getQueryParams = (): any => {
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                termCode: this.termCode,
                startDate: this.dateStart ? moment(this.dateStart).toISOString() : "",
                endDate: this.dateEnd ? moment(this.dateEnd).add(1, "day").subtract(1, "second").toISOString() : "",
                filter: this.searchFilter,
                ruleId: this.ruleIdFilter,
                userApplicationId: this.userApplicationId
            };

            return queryParams;
        }

    }

    angular.module("app").controller(StartedApplications.controllerId, StartedApplications);
}