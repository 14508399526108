/// <reference path="common.ts" />
namespace Advant.Crossroads {
    export interface ILogger {
        getLogFn: (moduleId, fnName?) => (msg, data?, showHowl?) => void;
        log: (message, data?, source?, showHowl?) => void;
        logWarning: (message, data?, source?, showHowl?) => void;
        logSuccess: (message, data?, source?, showHowl?) => void;
        logError: (message, data?, source?, showHowl?) => void;
    }

    interface JQueryStatic {
        howl: (howler: any) => any;
    }

    export class Logger implements ILogger {
        static serviceId: string = "logger";


        constructor(private $log: angular.ILogService) {
        }

        public getLogFn(moduleId, fnName?) {
            fnName = fnName || "log";
            switch (fnName.toLowerCase()) { // convert aliases
                case "success":
                    fnName = "logSuccess"; break;
                case "error":
                    fnName = "logError"; break;
                case "warn":
                    fnName = "logWarning"; break;
                case "warning":
                    fnName = "logWarning"; break;
            }

            var logFn = this[fnName] || this.log;
            return (msg, data?, showHowl?: boolean, makeSticky?: boolean) => {
                logFn(msg, data, moduleId, (showHowl === undefined) ? false : showHowl, (makeSticky === undefined) ? false : makeSticky);
            };
        }

        public log = (message, data?, source?, showHowl?: boolean, makeSticky?: boolean) => {
            this.logIt(message, data, source, showHowl, "info", makeSticky);
        };

        public logWarning = (message, data?, source?, showHowl?: boolean, makeSticky?: boolean) => {
            (makeSticky === undefined) ? false : makeSticky;
            this.logIt(message, data, source, showHowl, "warning", makeSticky);
        };

        public logSuccess = (message, data?, source?, showHowl?: boolean, makeSticky?: boolean) => {
            (showHowl === undefined) ? true : showHowl;
            (makeSticky === undefined) ? false : makeSticky;
            this.logIt(message, data, source, showHowl, "success", makeSticky);
        };

        public logError = (message, data?, source?, showHowl?, makeSticky?) => {
            (showHowl === undefined) ? true : showHowl;
            appInsights.trackEvent("CrossConnectError", { message: message, data: data, source: source });
            (makeSticky === undefined) ? false : makeSticky;
            this.logIt(message, data, source, showHowl, "error", makeSticky);
        };

        private logIt = (message, data, source, showHowl, howlType, makeSticky) => {
            var write = (howlType === "error") ? this.$log.error : this.$log.log;
            source = source ? "[" + source + "] " : "";
            write(source, message, data);
            if (showHowl) {
                if (howlType === "error") {
                    $.howl({
                        type: "danger",
                        title: "An Error Has Occurred",
                        content: message,
                        sticky: makeSticky
                    });
                } else if (howlType === "warning") {
                    $.howl({
                        type: "warning",
                        title: "Oops",
                        content: message,
                        sticky: makeSticky
                    });
                } else if (howlType === "success") {
                    $.howl({
                        type: "success",
                        title: "Success",
                        content: message,
                        sticky: makeSticky
                    });
                } else {
                    $.howl({
                        type: "info",
                        content: message
                    });
                }
            }
        };
    }


    common.factory(Logger.serviceId, ["$log", ($log) =>
        new Logger($log)
    ]);
}