/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IApplicationsByWeekFilter {
        weekDate1?: moment.Moment;
        weekDate2?: moment.Moment;
}

    class ApplicationsByWeek {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingUserApplications: boolean = false;
        showFilter: boolean;
        chartConfig: any;
        filter: IApplicationsByWeekFilter = {
        };
        totalApplications: any[];

        static $inject: any = ["$scope", "$location", "$http", "Restangular", "common", "config"];

        constructor(public $scope: angular.IScope,
            public $location: angular.ILocationService,
            public $http: angular.IHttpService,
            public Restangular: Restangular.IService,
            public common: ICommonService,
            public config: ICrossroadsConfig) {

            this.log = common.logger.getLogFn("applicationsByWeek");
            this.logError = common.logger.getLogFn("applicationsByWeek", "error");
            this.logSuccess = common.logger.getLogFn("applicationsByWeek", "success");

           
            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.chartConfig = {
                "chart": {
                    "type": "areaspline"
                },
                "plotOptions": {
                    "series": {
                        "stacking": ""
                    }
                },
                "series": [
                    {
                        "name": "Applications Submitted",
                        "data": [],
                        "id": "series-0",
                        "connectNulls": true,
                        "type": "areaspline",
                        "color": "#0E81B2"
                    },
                    {
                        "name": "Applications Started",
                        "data": [],
                        "connectNulls": true,
                        "id": "series-1",
                        "type": "areaspline",
                        "color": "#f0ad4e"
                    },
                    {
                        "name": "Applications Submitted",
                        "data": [],
                        "id": "series-3",
                        "connectNulls": true,
                        "type": "areaspline",
                        "color": "#bedae6"
                    },
                    {
                        "name": "Applications Started",
                        "data": [],
                        "connectNulls": true,
                        "id": "series-4",
                        "type": "areaspline",
                        "color": "#f0d0a3"
                    }
                ],
                title: {
                    "text": "Applications"
                },
                subtitle: {
                    "text": ""
                },
                credits: {
                    "enabled": false
                },
                "loading": false,
                xAxis: {
                    title: "Date",
                    categories: [
                        "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
                    ]
                },
                yAxis: {
                    title: "Applications"
                },
                tooltip: {
                    shared: false,
                    useHTML: true,
                    formatter: function () {
                        return `${this.x} - ${this.point.name}<br /><span style="color:${this.point.color}">&#9679</span> ${this.series.name}: <b>${this.point.y}</b><br />`
                    }
                }
            };


            this.activate();
        }

        activate = () => {
            this.common.activateController([this.getTotalApplicationsByDay()], "applicationsByWeek")
                .then(result => {
                    this.log("Activated ApplicationsByWeek View");
                });
        };

        applyFilter = () => {
            this.getTotalApplicationsByDay();
        };

        clearFilter = () => {
            this.filter = {
            };
        };


        getTotalApplicationsByDay = (): angular.IPromise<any> => {
            var weekDate1 = this.filter.weekDate1 != null ? moment(this.filter.weekDate1) : moment();
            var weekDate2 = this.filter.weekDate2 != null ? moment(this.filter.weekDate2) : angular.copy(weekDate1).subtract(1, 'year').isoWeek(angular.copy(weekDate1).isoWeek()-1);

            var dateRange1 = this.getDateRanges(weekDate1);
            var dateRange2 = this.getDateRanges(weekDate2);

            var queryParams1 = {
                startDate: dateRange1.startDate.toDate(),
                endDate: dateRange1.endDate.toDate(),
                suppressError: true
            };

            var defer1 = this.common.$q.defer();
            var defer2 = this.common.$q.defer();
            
            this.chartConfig.series[0].data = [];
            this.chartConfig.series[1].data = [];
            this.chartConfig.series[2].data = [];
            this.chartConfig.series[3].data = [];
            this.totalApplications = [];

            this.chartConfig.title.text = `Applications - Week ${weekDate1.day(1).isoWeek()} of ${weekDate1.year()} and Week ${weekDate2.day(1).isoWeek()} of ${weekDate2.year()}`;
            this.chartConfig.subtitle.text = `${dateRange1.startDate.format("MM/DD/YYYY")} to ${dateRange1.endDate.format("MM/DD/YYYY")} and ${dateRange2.startDate.format("MM/DD/YYYY")} to ${dateRange2.endDate.format("MM/DD/YYYY")}`

            
            this.Restangular.one(this.common.getUser().activeApplication).one("userApplications/totalsByDay").get(queryParams1).then((result) => {
                this.updateChart(result, 1, { startDate: dateRange1.startDate, endDate: dateRange1.endDate });
                defer1.resolve();
            }, (reason) => {
                this.loadingUserApplications = false;
                if (reason.status != 401 && reason.status != 403) {
                    this.logError("Are you throwing this error somehow?", reason);
                }
                defer1.reject(reason);
            });

            var queryParams2 = {
                startDate: dateRange2.startDate.toDate(),
                endDate: dateRange2.endDate.toDate(),
                suppressError: true
            };

            this.Restangular.one(this.common.getUser().activeApplication).one("userApplications/totalsByDay").get(queryParams2).then((result) => {
                this.updateChart(result, 2, { startDate: dateRange2.startDate, endDate: dateRange2.endDate });
                defer2.resolve();
            }, (reason) => {
                this.loadingUserApplications = false;
                if (reason.status != 401 && reason.status != 403) {
                    this.logError("Are you throwing this error somehow?", reason);
                }
                defer2.reject(reason);
            });

            return this.common.$q.when([defer1, defer2])
        };


        updateChart = (result: any, set: number, dateRange: any) => {
            var currentDate = angular.copy(dateRange.startDate);
            var year = currentDate.year();
            var startedApps = 0;
            var submittedApps = 0;

            var firstSeries = set == 1 ? 0 : 2;
            var secondSeries = set == 1 ? 1 : 3;

            this.chartConfig.series[firstSeries].name = `Applications Submitted W${moment(currentDate).day(1).isoWeek()}/${year}`;
            this.chartConfig.series[secondSeries].name = `Applications Started W${moment(currentDate).day(1).isoWeek()}/${year}`;

            while (currentDate <= dateRange.endDate) {
                var dateString = currentDate.format("YYYY-MM-DD[T00:00:00]");
                var submittedCount = result.submittedApplications[dateString] ? result.submittedApplications[dateString] : 0;
                var startedCount = result.startedApplications[dateString] ? result.startedApplications[dateString] : 0;

                this.chartConfig.series[firstSeries].data.push({ name: currentDate.format("MM/DD/YYYY"), y: submittedCount });
                this.chartConfig.series[secondSeries].data.push({ name: currentDate.format("MM/DD/YYYY"), y: startedCount });

                submittedApps += submittedCount;
                startedApps += startedCount;
                
                currentDate = currentDate.add("days", 1);
            }

            this.totalApplications.push({
                year: dateRange.startDate.year(),
                week: angular.copy(dateRange.startDate).day(0).isoWeek(),
                startDate: dateRange.startDate.toDate(),
                endDate: dateRange.endDate.toDate(),
                startedApps,
                submittedApps
            });
        }

        getDateRanges = (weekDate: moment.Moment) => {
            var weekNumber = weekDate.week();

            return {
                startDate: angular.copy(weekDate).day(0),
                endDate: angular.copy(weekDate).day(6),
            };
        }
    }

    angular.module("app").controller("applicationsByWeek", ApplicationsByWeek);

}