/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict"

    export interface IHelpFilesScope extends ng.IScope {
    }


    export interface IHelpFilesController {
    }

    export class HelpFilesController implements IHelpFilesController {
        static controllerId: string = "helpFiles";
        static $inject = ["$scope", "$location", "common", "config", "Restangular"];

        log: (msg, data?, showHowl?) => void;

        constructor(
            private $scope: ICrossRouteDownloadScope,
            private $location: ng.ILocationService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private Restangular: Restangular.IService) {
            this.log = common.logger.getLogFn(HelpFilesController.controllerId);
            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([], HelpFilesController.controllerId)
                .then(result=> {
                    self.log("Activated Help Files View");

                });
        }

    }

    angular.module("app").controller(HelpFilesController.controllerId, HelpFilesController);
}  