/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    export class ReplacementLookupFile {

        constructor(public replacementLookup?) {

        }

        toJson = () => {
            return angular.toJson(this);
        };

        public static fromJson = (json: string): ReplacementLookupFile => {
            var importValue = angular.fromJson(json);
            var returnValue: ReplacementLookupFile = new ReplacementLookupFile();
            if (importValue) {
                if (importValue.hasOwnProperty("replacementLookup")) {
                    returnValue.replacementLookup = importValue.replacementLookup;
                }
            }

            return returnValue;
        }
    }
}