/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict"

    interface ICampusProgramsMajors {
    }

    class CampusProgramsMajors implements ICampusProgramsMajors {
        static controllerId: string = "campusProgramsMajors";
        static $inject: Array<string> = ["config", "dialogs","$routeParams", "common", "Restangular"];
        private log;
        public logError: (msg, data?, showHowl?) => void;
        organizationId: any;
        headerText: string;
        agricultureId: number;
        architectureId: number;
        artsId: number;
        businessId: number;
        eductionId: number;
        financeId: number;
        governmentId: number;
        healthId: number;
        hospitalityId: number;
        humanServicesId: number;
        informationTechnologyId: number;
        lawId: number;
        manufacturingId: number;
        marketingId: number;
        scienceId: number;
        tranportationId: number;
        institutionId: string;
        selectedMajor: any;
        selectedDegreeType: Array<string>;
        agricultureMajorsAvailable: any;
        architectureMajorsAvailable: any;
        businessMajorsAvailable: any;
        artsMajorsAvailable: any;
        educationMajorsAvailable: any;
        financeMajorsAvailable: any;
        governmentMajorsAvailable: any;
        healthMajorsAvailable: any;
        hospitalityMajorsAvailable: any;
        humanServicesMajorsAvailable: any;
        ITMajorsAvailable: any;
        lawMajorsAvailable: any;
        manufacturingMajorsAvailable: any;
        marketingMajorsAvailable: any;
        scienceMajorsAvailable: any;
        transportationMajorsAvailable: any;
        availableMajors: any;
        showPanel: boolean;
        institutionMajors: any;
        editInstitutionMajors: any;
        majorCount: number;
        pageNumber: number;
        activeCluster: number;
        institution: any;
        searchString: string;
        newMajor: boolean;
        originalMajor: any;

        sortBy: any;
        sortDirection: any;

        lastUpdated: any;

        logSuccess: (msg, data?, showHowl?) => void;

        constructor(private config: Advant.Crossroads.ICrossroadsConfig, private dialogs: ng.dialogs.IDialogService, private $routeParams, private common: ICommonService, private Restangular: Restangular.IService) {
            this.log = common.logger.getLogFn(CampusProgramsMajors.controllerId);
            this.activate();
            common.$on(config.events.applicationChanged,(event) => {
                this.activate();
            });
        }


        activate() {
            this.common.activateController([], CampusProgramsMajors.controllerId)
                .then(() => { this.log("Campus Programs and Majors"); });
            this.logSuccess = this.common.logger.getLogFn(CampusProgramsMajors.controllerId, "success");
            this.logError = this.common.logger.getLogFn(CampusProgramsMajors.controllerId, "error");
            this.selectedMajor = "";
            this.selectedDegreeType = [];
            var config = new Config();

            var app = sessionStorage.getItem("activeApplication");
            var test = app.split("/");
            this.organizationId = test[1];

            this.getInstitution();
            this.getAvailableMajors();
            this.showPanel = false;
            this.pageNumber = 1;
            this.sortBy = "Major";
            this.sortDirection = "ASC";
            this.searchString = "";
        }

        getAvailableMajors = () => {
            this.Restangular.all("ProfileSettings/GetMajorsList").getList().then((results) => {
                console.log(results);
                var clusterDict = _.findIndex(results, [ 'name', "Agriculture, Food and Natural Resources" ]);
                this.agricultureMajorsAvailable = results[clusterDict].majors;
                this.agricultureId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Architecture and Construction"]);
                this.architectureMajorsAvailable = results[clusterDict].majors;
                this.architectureId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Arts, Audio-Video Technology and Communications"]);
                this.artsMajorsAvailable = results[clusterDict].majors;
                this.artsId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Business, Management and Administration"]);
                this.businessMajorsAvailable = results[clusterDict].majors;
                this.businessId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Education and Training"]);
                this.educationMajorsAvailable = results[clusterDict].majors;
                this.eductionId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Finance"]);
                this.financeMajorsAvailable = results[clusterDict].majors;
                this.financeId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Government and Public Administration"]);
                this.governmentMajorsAvailable = results[clusterDict].majors;
                this.governmentId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Health Science"]);
                this.healthMajorsAvailable = results[clusterDict].majors;
                this.healthId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Hospitality and Tourism"]);
                this.hospitalityMajorsAvailable = results[clusterDict].majors;
                this.hospitalityId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Human Services"]);
                this.humanServicesMajorsAvailable = results[clusterDict].majors;
                this.humanServicesId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Information Technology"]);
                this.ITMajorsAvailable = results[clusterDict].majors;
                this.informationTechnologyId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Law, Public Safety, Corrections and Security"]);
                this.lawMajorsAvailable = results[clusterDict].majors;
                this.lawId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Manufacturing"]);
                this.manufacturingMajorsAvailable = results[clusterDict].majors;
                this.manufacturingId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Marketing"]);
                this.marketingMajorsAvailable = results[clusterDict].majors;
                this.marketingId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Science, Technology, Engineering and Mathematics"]);
                this.scienceMajorsAvailable = results[clusterDict].majors;
                this.scienceId = results[clusterDict].id;
                clusterDict = _.findIndex(results, ['name', "Transportation, Distribution and Logistics"]);
                this.transportationMajorsAvailable = results[clusterDict].majors;
                this.tranportationId = results[clusterDict].id;
            },(reason) => {
                    console.error(reason);
                });
        }

        swapMajorTabs(clusterId: number) {
            this.activeCluster = clusterId;
            this.pageNumber = 1;
            switch (clusterId) {
                case this.agricultureId:
                    this.availableMajors = this.agricultureMajorsAvailable;
                    this.lastUpdated = this.institution.agricultureProgramsLastUpdated;
                    break;
                case this.architectureId:
                    this.availableMajors = this.architectureMajorsAvailable;
                    this.lastUpdated = this.institution.architectureProgramsLastUpdated;
                    break;
                case this.artsId:
                    this.availableMajors = this.artsMajorsAvailable;
                    this.lastUpdated = this.institution.artsProgramsLastUpdated;
                    break;
                case this.businessId:
                    this.availableMajors = this.businessMajorsAvailable;
                    this.lastUpdated = this.institution.businessProgramsLastUpdated;
                    break;
                case this.eductionId:
                    this.availableMajors = this.educationMajorsAvailable;
                    this.lastUpdated = this.institution.educationProgramsLastUpdated;
                    break;
                case this.financeId:
                    this.availableMajors = this.financeMajorsAvailable;
                    this.lastUpdated = this.institution.financeProgramsLastUpdated;
                    break;
                case this.governmentId:
                    this.availableMajors = this.governmentMajorsAvailable;
                    this.lastUpdated = this.institution.governmentProgramsLastUpdated;
                    break;
                case this.healthId:
                    this.availableMajors = this.healthMajorsAvailable;
                    this.lastUpdated = this.institution.healthProgramsLastUpdated;
                    break;
                case this.hospitalityId:
                    this.availableMajors = this.hospitalityMajorsAvailable;
                    this.lastUpdated = this.institution.hospitalityProgramsLastUpdated;
                    break;
                case this.humanServicesId:
                    this.availableMajors = this.humanServicesMajorsAvailable;
                    this.lastUpdated = this.institution.humanServicesProgramsLastUpdated;
                    break;
                case this.informationTechnologyId:
                    this.availableMajors = this.ITMajorsAvailable;
                    this.lastUpdated = this.institution.informationTechnologyProgramsLastUpdated;
                    break;
                case this.lawId:
                    this.availableMajors = this.lawMajorsAvailable;
                    this.lastUpdated = this.institution.lawProgramsLastUpdated;
                    break;
                case this.manufacturingId:
                    this.availableMajors = this.manufacturingMajorsAvailable;
                    this.lastUpdated = this.institution.manufacturingProgramsLastUpdated;
                    break;
                case this.marketingId:
                    this.availableMajors = this.marketingMajorsAvailable;
                    this.lastUpdated = this.institution.marketingProgramsLastUpdated;
                    break;
                case this.scienceId:
                    this.availableMajors = this.scienceMajorsAvailable;
                    this.lastUpdated = this.institution.scienceProgramsLastUpdated;
                    break;
                case this.tranportationId:
                    this.availableMajors = this.transportationMajorsAvailable;
                    this.lastUpdated = this.institution.transportationProgramsLastUpdated;
                    break;
            }
            this.selectedMajor = "";
            this.selectedDegreeType = [];
            this.getInstitutionMajors();
        }

        updateLastUpdatedDate(clusterId: number) {
            switch (clusterId) {
                case this.agricultureId:
                    this.institution.agricultureProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.agricultureProgramsLastUpdated;
                    break;
                case this.architectureId:
                    this.institution.architectureProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.architectureProgramsLastUpdated;
                    break;
                case this.artsId:
                    this.institution.artsProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.artsProgramsLastUpdated;
                    break;
                case this.businessId:
                    this.institution.businessProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.businessProgramsLastUpdated;
                    break;
                case this.eductionId:
                    this.institution.educationProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.educationProgramsLastUpdated;
                    break;
                case this.financeId:
                    this.institution.financeProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.financeProgramsLastUpdated;
                    break;
                case this.governmentId:
                    this.institution.governmentProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.governmentProgramsLastUpdated;
                    break;
                case this.healthId:
                    this.institution.healthProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.healthProgramsLastUpdated;
                    break;
                case this.hospitalityId:
                    this.institution.hospitalityProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.hospitalityProgramsLastUpdated;
                    break;
                case this.humanServicesId:
                    this.institution.humanServicesProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.humanServicesProgramsLastUpdated;
                    break;
                case this.informationTechnologyId:
                    this.institution.informationTechnologyProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.informationTechnologyProgramsLastUpdated;
                    break;
                case this.lawId:
                    this.institution.lawProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.lawProgramsLastUpdated;
                    break;
                case this.manufacturingId:
                    this.institution.manufacturingProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.manufacturingProgramsLastUpdated;
                    break;
                case this.marketingId:
                    this.institution.marketingProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.marketingProgramsLastUpdated;
                    break;
                case this.scienceId:
                    this.institution.scienceProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.scienceProgramsLastUpdated;
                    break;
                case this.tranportationId:
                    this.institution.transportationProgramsLastUpdated = new Date;
                    this.lastUpdated = this.institution.transportationProgramsLastUpdated;
                    break;
            }
        }

        modifyList(name: string) {
            var found = false;
            for (var i = 0; i < this.selectedDegreeType.length; i++) {
                if (this.selectedDegreeType[i] == name) {
                    found = true;
                    this.selectedDegreeType.splice(i, 1);
                    console.log("removed " + name);
                }
            }
            if (!found) {
                this.selectedDegreeType.push(name);
                console.log("added" + name);
            }
        }

        checkList(name: string) {
            var found = false;
            for (var i in this.selectedDegreeType) {
                if (this.selectedDegreeType[i] == name)
                    found = true;
            }
            return found;
        }

        sort(sortValue: string) {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.pageNumber = 1;
            this.getInstitutionMajors();
        }

        createNewItem = (): void => {
            var newItem: any = {
                majorName: null,
                majorCipCode: null,
                degreeType: null,
                editMode: true,
                newItem: true
            };

            this.editInstitutionMajors.unshift(newItem);
        };

        cancelEdit = (itemToRemove: any, index: any): void => {
            if (!itemToRemove.newItem) {
                var originalSport = this.institutionMajors[index];
                itemToRemove = originalSport;
                itemToRemove.editMode = false;
                this.editInstitutionMajors[index] = itemToRemove;

            } else {
                this.editInstitutionMajors.splice(index, 1);
            };
        };

        getInstitution = () => {
            this.Restangular.one("ProfileSettings", this.organizationId).get().then((results) => {
                this.institutionId = results.id;
                this.institution = results;
                console.log(results);
            },(reason) => {
                    console.error(reason);
                    this.logError("An error occurred", reason, true);
                });
        }

        getInstitutionMajors = () => {
            var params = { organizationId: this.organizationId, clusterId: this.activeCluster, pageNumber: this.pageNumber, sortBy: this.sortBy, sortDirection: this.sortDirection, searchString: this.searchString };
            this.Restangular.one("ProfileSettings/GetInstitutionMajors").get(params).then((results) => {
                console.log(results);
                this.institutionMajors = results.majors;
                this.editInstitutionMajors = angular.copy(this.institutionMajors);
                this.majorCount = results.total;
            },(reason) => {
                    console.error(reason);
                    this.logError("An error occurred", reason, true);
                });
        }


        saveMajor(major: any, cipCode: any, degreeType: any, programName: any, description: any, index: any) {
            var newMajorIndex = _.findIndex(this.availableMajors, ['cipCode', cipCode]);
            var found = _.findIndex(this.institutionMajors, { 'majorCipCode': cipCode, 'degreeType': degreeType });
            var model = {
                institutionId: this.institutionId,
                name: this.availableMajors[newMajorIndex].name,
                programName: programName,
                cipCode: cipCode,
                degreeType: degreeType,
                clusterId: this.activeCluster,
                description: description

            };
            
            if (found == -1 && major.newItem) {
                this.editInstitutionMajors[index].majorName = this.availableMajors[newMajorIndex].name;

                this.Restangular.all("profileSettings").all("addInstitutionMajor").post(model).then(() => {
                    major.editMode = false;
                    this.logSuccess("Major added successfully.")
                }, (reason) => {
                    major.editMode = false;
                        this.logError("An error has occurred.", reason, true);
                    });

                
            }
            else if (!major.newItem)
            {
                this.Restangular.all("profileSettings").all("updateInstitutionMajor").post(model).then(() => {
                    major.editMode = false;
                    this.logSuccess("Major updated successfully.")

                }, (reason) => {
                    
                    this.logError("An error has occurred.", reason, true);
                });
            }
            else
            {
                this.logError("Program/major is already in the list", "", true);
            }
        }


        deleteMajor(major: any) {
            console.log(major);
            var dlg = this.dialogs.confirm("Please Confirm",
                "Are you sure you want to delete this program/major?");
            dlg.result.then((btn) => {
                var model = { InstitutionId: this.institutionId, CipCode: major.majorCipCode, DegreeType: major.degreeType, ClusterId: this.activeCluster };
                this.Restangular.all("ProfileSettings/DeleteInstitutionMajor").post(model).then((results) => {
                    this.getInstitutionMajors();
                    this.logSuccess("Your profile changes have been saved", null, true);
                    this.institution.programsMajorsLastModified = new Date;
                    this.updateLastUpdatedDate(this.activeCluster);
                },(reason) => {
                        console.error(reason);
                        this.logError("An error occurred", reason, true);
                    });
            });
        }
    }

    angular.module("app").controller(CampusProgramsMajors.controllerId, CampusProgramsMajors);
} 