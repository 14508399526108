/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    export interface IHighSchoolDetailsRouteParams extends angular.route.IRouteParamsService {
        id: string;
    }
    
    interface IHighSchoolDetails {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        isSaving: boolean;
        highSchool: IHighSchool;
        originalHighSchool: IHighSchool;
    }

    class HighSchoolDetails implements IHighSchoolDetails {
        static controllerId: string = "highSchoolDetails";
        static $inject: string[] = ["$routeParams", "Restangular", "common", "config","$location"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        isSaving: boolean;
        highSchool: IHighSchool;
        $q: ng.IQService;
        originalHighSchool: IHighSchool;
        addToCfnc: boolean;
        addToTranscripts: boolean;
        addToFafsa: boolean;
        originalAddToCfnc: boolean;
        originalAddToTranscripts: boolean;
        originalAddToFafsa: boolean;


        constructor(private $routeParams: IHighSchoolDetailsRouteParams,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private $location: ng.ILocationService) {

            this.log = common.logger.getLogFn(HighSchoolDetails.controllerId);
            this.logError = common.logger.getLogFn(HighSchoolDetails.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(HighSchoolDetails.controllerId, "success")

            this.$q = common.$q;

            this.activate();

        }

        private activate = () => {
            this.common.activateController([this.getHighSchool()], HighSchoolDetails.controllerId)
                .then(result => {
                    this.log("Activated HighSchools View");
                });
        };


        getHighSchool = (): angular.IPromise<void> => {
            var id = this.$routeParams.id;

            if (id != "new"){
                return this.Restangular.one("lookup").one("school").one("highSchools", id).get().then((result) => {
                    this.highSchool = result;
                    this.originalHighSchool = angular.copy(this.highSchool);
                    this.cfncSchoolExists();
                    this.transcriptsSchoolExists();
                    this.fafsaSchoolExists();
                }, (reason) => {
                    this.logError("An Error Occurred Getting the High School", reason, true);
                });
            } else {
                return this.$q.when();
            }
        }

        saveHighSchool = (): angular.IPromise<void> => {
            this.checkCfncSettings();
            var id = this.$routeParams.id;
            this.isSaving = true;
            if (id == "new") {
                return this.Restangular
                    .one("lookup")
                    .one("school")
                    .all("highSchools")
                    .post(this.highSchool)
                    .then((result) => {
                        this.logSuccess("High School Saved Successfully", null, true);
                        this.isSaving = false;
                        //this.$location.path("/admin/" + result.id);
                    }, (reason) => {
                        this.logError(reason.data.message, reason, true);
                        this.isSaving = false;
                    });
            } else {
                return this.highSchool.put()
                    .then((result) => {
                        this.logSuccess("High School Saved Successfully", null, true);
                        this.isSaving = false;
                        angular.copy(this.highSchool, this.originalHighSchool);
                    }, (reason) => {
                        this.logError(reason.data.message, reason, true);
                        this.isSaving = false;
                    });
            }

        }

        checkCfncSettings = () =>
        {
            if ((this.addToCfnc && !this.originalAddToCfnc) || (this.addToCfnc && this.originalAddToCfnc)) {
                this.addSchoolToCfnc();
            }
            if ((this.addToTranscripts && !this.originalAddToTranscripts) || (this.addToTranscripts && this.originalAddToTranscripts)) {
                this.addSchoolToTranscripts();
            }
            if ((this.addToFafsa && !this.originalAddToFafsa) || (this.addToFafsa && this.originalAddToFafsa)) {
                this.addSchoolToFafsa();
            }

            if ((!this.addToCfnc && this.originalAddToCfnc)) {
                this.deleteCfncSchool();
            }
            if ((!this.addToTranscripts && this.originalAddToTranscripts)) {
                this.deleteTranscriptSchool();
            }
            if ((!this.addToFafsa && this.originalAddToFafsa)) {
                this.deleteFafsaSchool();
            }
        }

        cancel = () => {
            this.highSchool = angular.copy(this.originalHighSchool);
        };

        cfncSchoolExists = () => {
            var params = { name: this.highSchool.name, ceebCode: this.highSchool.ceebCode };
            this.Restangular.one("lookup").one("school").one("cfncSchoolsExist").get(params).then((result) => {
                if (result != null) {
                    this.highSchool.crmId = result;
                    this.addToCfnc = true;
                    this.originalAddToCfnc = true;
                }
                else {
                    this.addToCfnc = false;
                    this.highSchool.crmId = null;
                }
            }, (reason) => {
                this.logError("An Error Occurred Getting the High School", reason, true);
            });
        };

        transcriptsSchoolExists = () => {
            this.Restangular.one("lookup").one("school").one("transcriptsSchoolExists", this.highSchool.ceebCode).get().then((result) => {
                this.addToTranscripts = result;
                this.originalAddToTranscripts = result;
            }, (reason) => {
                this.logError("An Error Occurred Getting the High School", reason, true);
            });
        };

        fafsaSchoolExists = () => {
            if (this.highSchool.fafsaId != null) {
                this.Restangular.one("lookup").one("school").one("fafsaSchoolExists", this.highSchool.fafsaId).get().then((result) => {
                    this.addToFafsa = result;
                    this.originalAddToFafsa = result;
                }, (reason) => {
                    this.logError("An Error Occurred Getting the High School", reason, true);
                });
            }
        };

        generateCrmId = () => {
            this.Restangular.one("lookup").one("school").one("generateCrmId").get().then((result) => {
                this.highSchool.crmId = result;
            }, (reason) => {
                this.logError("An Error Occurred Getting the High School", reason, true);
            });
        };

        addSchoolToCfnc = () => {
            var params = { name: this.highSchool.name, ceebCode: this.highSchool.ceebCode, crmId: this.highSchool.crmId };
            this.Restangular.one("lookup").one("school").all("updateCfncSchool").post(params).then((result) => {
                this.logSuccess("Added to CFNC Successfully", null, true);
                this.isSaving = false;
                //this.$location.path("/admin/" + result.id);
            }, (reason) => {
                this.logError(reason.data.message, reason, true);
                this.isSaving = false;
            });
        };

        addSchoolToTranscripts = () => {
            var params = {
                name: this.highSchool.name,
                ceebCode: this.highSchool.ceebCode,
                address1: this.highSchool.address.street1,
                address2: this.highSchool.address.street2,
                city: this.highSchool.address.city,
                state: this.highSchool.address.state,
                country: this.highSchool.address.country,
                zipCode: this.highSchool.address.zip
            };
            this.Restangular.one("lookup").one("school").all("updateTranscriptsSchool").post(params).then((result) => {
                this.logSuccess("Transcript Info Successfully", null, true);
                this.isSaving = false;
                //this.$location.path("/admin/" + result.id);
            }, (reason) => {
                this.logError(reason.data.message, reason, true);
                this.isSaving = false;
            });
        };

        addSchoolToFafsa = () => {
            var params = { schoolCode: this.highSchool.fafsaId };
            this.Restangular.one("lookup").one("school").all("updateFafsaSchool").post(params).then((result) => {
                this.logSuccess("FAFSA Saved Successfully", null, true);
                this.isSaving = false;
                //this.$location.path("/admin/" + result.id);
            }, (reason) => {
                this.logError(reason.data.message, reason, true);
                this.isSaving = false;
            });
        };

        deleteCfncSchool = () => {
            var params = { ceebCode: this.highSchool.ceebCode };
            this.Restangular.one("lookup").one("school").all("deleteCfncSchool").post(params).then((result) => {
                this.logSuccess("CFNC School Removed", null, true);
                this.isSaving = false;
                //this.$location.path("/admin/" + result.id);
            }, (reason) => {
                this.logError(reason.data.message, reason, true);
                this.isSaving = false;
            });
        };

        deleteTranscriptSchool = () => {
            var params = { ceebCode: this.highSchool.ceebCode };
            this.Restangular.one("lookup").one("school").all("deleteTranscriptSchool").post(params).then((result) => {
                this.logSuccess("Transcript School Removed", null, true);
                this.isSaving = false;
                //this.$location.path("/admin/" + result.id);
            }, (reason) => {
                this.logError(reason.data.message, reason, true);
                this.isSaving = false;
            });
        };

        deleteFafsaSchool = () => {
            var params = { schoolCode: this.highSchool.fafsaId };
            this.Restangular.one("lookup").one("school").all("deleteFafsaSchool").post(params).then((result) => {
                this.logSuccess("FAFSA School Removed", null, true);
                this.isSaving = false;
                //this.$location.path("/admin/" + result.id);
            }, (reason) => {
                this.logError(reason.data.message, reason, true);
                this.isSaving = false;
            });
        };
    }

    angular.module("app").controller(HighSchoolDetails.controllerId, HighSchoolDetails);
}