/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    export class ExportSettingsFile {

        constructor(public setting?: IExportSettings) {

        }

        toJson = () => {
            return angular.toJson(this);
        };

        public static fromJson = (json: string): ExportSettingsFile => {
            var importValue = angular.fromJson(json);
            var returnValue: ExportSettingsFile = new ExportSettingsFile();
            if (importValue) {
                if (importValue.hasOwnProperty("setting")) {
                    returnValue.setting = importValue.setting;
                }
            }

            return returnValue;
        }
    }
}