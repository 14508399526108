namespace Advant.Crossroads {
    "use strict";

    interface IProspectExportSettingsDetailsScope extends angular.IScope {
        vm: ProspectExportSettingsDetails;
        prospectExportSettingsForm: angular.IFormController;
    }

    export interface IProspectExportSettingsDetailsRouteParams extends angular.route.IRouteParamsService {
        exportSettingsId: string;
        exportSettingsType: string;
    }

    interface IProspectExportSettingsDetails {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingSettings: boolean;
        isSaving: boolean;
        setting: IExportSettings;
        originalSetting: IExportSettings;
        exportTypes: any[];
        layoutTypes: any[];
        treeOptions: any;
        headerTreeOptions: any;
        fieldCompleter: any;
        langTools: any;
        exportSettingsId: string;
        rules: Array<IRuleSet>;
        dateStart: string;
        dateEnd: string;
        ruleIdFilter: string;
        testFileId: string;
        generatingFile: boolean;
        totalExported: number;
        singleFile: boolean;

        prospectDefinition: IProspectDefinition;
        additionalFields: string[];
        source: string;
        sourceFilter: string;
        sources: string[];
        illegalFilenameCharacters: RegExp;
        isNew: boolean;
    }

    class ProspectExportSettingsDetails implements IProspectExportSettingsDetails {
        static controllerId: string = "prospectExportSettingsDetails";
        static $inject = ["$scope", "$routeParams", "$location", "$http", "dialogs", "Restangular", "common", "config", "helper", "$timeout"];

        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingSettings: boolean;
        isSaving: boolean;
        setting: IExportSettings;
        originalSetting: IExportSettings;
        exportTypes: any[];
        layoutTypes: any[];
        treeOptions: any;
        headerTreeOptions: any;
        fieldCompleter: any;
        langTools: any;
        exportSettingsId: string;
        rules: Array<IRuleSet>;
        dateStart: string;
        dateEnd: string;
        ruleIdFilter: string;
        testFileId: string;
        generatingFile: boolean;
        totalExported: number;
        singleFile: boolean;
        additionalFields: string[];
        source: string;
        sourceFilter: string;
        sources: string[];
        prospectDefinition: IProspectDefinition;
        illegalFilenameCharacters = /^[^\\/:*?<>|\"]+$/;
        isNew: boolean;
        altColors: boolean;

        constructor(private $scope: IProspectExportSettingsDetailsScope,
            private $routeParams: IProspectExportSettingsDetailsRouteParams,
            private $location: angular.ILocationService,
            private $http: angular.IHttpService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private helper: IHelper,
            private $timeout: angular.ITimeoutService) {

            this.log = common.logger.getLogFn(ProspectExportSettingsDetails.controllerId);
            this.logError = common.logger.getLogFn(ProspectExportSettingsDetails.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(ProspectExportSettingsDetails.controllerId, "success");

            this.loadingSettings = false;
            this.generatingFile = false;
            this.singleFile = false;
            var self = this;
            this.altColors = false;

            this.exportSettingsId = $routeParams.exportSettingsType + "/" + $routeParams.exportSettingsId;

            $scope.$on(config.events.applicationChanged, (event) => {
                this.$location.path("/prospect/export/settings");
            });
            this.createExportTypes();
            this.createLayoutTypes();

            this.headerTreeOptions = {
                dropped: (event) => {
                    // If anything in the tree changes make the form dirty
                    this.$scope.prospectExportSettingsForm.$setDirty();
                }
            };

            this.treeOptions = {
                dropped: (event) => {
                    // If anything in the tree changes make the form dirty
                    this.$scope.prospectExportSettingsForm.$setDirty();
                }
            };

            this.fieldCompleter = {
                getCompletions: (editor, session, pos, prefix, callback) => {
                    callback(null, self.getAutoCompleteList(prefix));

                }
            };

            this.langTools = ace.require("ace/ext/language_tools");
            this.langTools.addCompleter(this.fieldCompleter);

            this.isNew = this.$routeParams.exportSettingsId.toLowerCase() === "new";

            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getSetting(), this.getRules(), this.getSources(), this.getProspectDefinition()], ProspectExportSettingsDetails.controllerId)
                .then(result => {
                    self.log("Activated Prospect Export Settings Details View");
                });
        }

        private getAutoCompleteList(prefix: string) {
            if (!prefix) {
                return [];
            }
            var matchingFields = [];
            angular.forEach(this.setting.exportFields, (v: IExportField, k: any) => {
                var exportFieldKey = `[[${v.fieldKey}]]`;
                var test = exportFieldKey.toLocaleLowerCase().indexOf(prefix.toLocaleLowerCase());
                if (test > -1) {
                    matchingFields.push({ name: exportFieldKey, value: exportFieldKey, score: 100, meta: "export field" });
                }
                if ((<ISectionExportField>v).groups) {
                    var sectionField: ISectionExportField = (<ISectionExportField>v);
                    angular.forEach(sectionField.groups, (group) => {
                        var groupKey = `[[${group.fieldKey}]]`;
                        test = groupKey.toLocaleLowerCase().indexOf(prefix.toLocaleLowerCase());
                        if (test > -1) {
                            matchingFields.push({ name: groupKey, value: groupKey, score: 100, meta: "export group" });
                        }

                        if (group.fields) {
                            angular.forEach(group.fields, (field) => {
                                var fieldKey = `[[${field.fieldKey}]]`;
                                test = fieldKey.toLocaleLowerCase().indexOf(prefix.toLocaleLowerCase());
                                if (test > -1) {
                                    matchingFields.push({ name: fieldKey, value: fieldKey, score: 100, meta: "export field" });
                                }
                            });
                        }
                    });
                }
            });

            return matchingFields;
        }

        aceLayoutEditorLoaded = (aceEditor: AceAjax.Editor) => {
            aceEditor.setShowPrintMargin(false);
            aceEditor.setTheme("ace/theme/chrome");
            aceEditor.getSession().setMode("ace/mode/html");
            (<any>aceEditor).setOptions({
                enableSnippets: true,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true
            });
        };


        addExportFields = () => {
            angular.forEach(this.prospectDefinition.sections[0].fields, (field: IProspectField) => {
                if (field.selected) {
                    field.selected = false;
                    var newExportField: IProspectExportField = {
                        $type: "Advant.Crossroads.Core.Export.ProspectExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey(field.key),
                        prospectFieldKey: field.key,
                        title: field.title,
                        exportFormatter: null
                    };
                    this.setting.exportFields.push(newExportField);
                    this.$scope.prospectExportSettingsForm.$setDirty();
                }
            });
            angular.forEach(this.prospectDefinition.sections[1].fields, (field: IProspectField) => {
                if (field.selected) {
                    field.selected = false;
                    var newExportField: IProspectExportField = {
                        $type: "Advant.Crossroads.Core.Export.ProspectExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey(field.key),
                        prospectFieldKey: `AdditionalData.${field.key}`,
                        title: field.title,
                        exportFormatter: null
                    };
                    this.setting.exportFields.push(newExportField);
                    this.$scope.prospectExportSettingsForm.$setDirty();
                }
            });
        };

        addHeaderFields = () => {
            if (!(<IApplicationHtmlFileSettings>this.setting).headerFields) {
                (<IApplicationHtmlFileSettings>this.setting).headerFields = [];
            }

            angular.forEach(this.prospectDefinition.sections[0].fields, (field: IProspectField) => {
                if (field.selected) {
                    field.selected = false;
                    var newExportField: IProspectExportField = {
                        $type: "Advant.Crossroads.Core.Export.ProspectExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey(field.key, (<IApplicationHtmlFileSettings>this.setting).headerFields),
                        prospectFieldKey: field.key,
                        title: field.title,
                        exportFormatter: null
                    };

                    (<IApplicationHtmlFileSettings>this.setting).headerFields.push(newExportField);
                    this.$scope.prospectExportSettingsForm.$setDirty();
                }
            });
            angular.forEach(this.prospectDefinition.sections[1].fields, (field: IProspectField) => {
                if (field.selected) {
                    field.selected = false;
                    var newExportField: IProspectExportField = {
                        $type: "Advant.Crossroads.Core.Export.ProspectExportField, Advant.Crossroads.Core",
                        fieldKey: this.generateFieldKey(field.key, (<IApplicationHtmlFileSettings>this.setting).headerFields),
                        prospectFieldKey: field.key,
                        title: field.title,
                        exportFormatter: null
                    };

                    (<IApplicationHtmlFileSettings>this.setting).headerFields.push(newExportField);
                    this.$scope.prospectExportSettingsForm.$setDirty();
                }
            });
        };

        cancel() {
            this.setting = angular.copy(this.originalSetting);
            this.$scope.prospectExportSettingsForm.$setPristine();
        }

        clearFilter = () => {
            this.dateStart = null;
            this.dateEnd = null;
            this.ruleIdFilter = null;
        };

        generateFieldKey = (potentionalKey: string, fieldCollection?: IExportField[]): string => {
            var hasConflict: boolean;
            var checkCollection: IExportField[];
            if (fieldCollection) {
                checkCollection = fieldCollection;
            } else {
                checkCollection = this.setting.exportFields;
            }

            angular.forEach(checkCollection, (field) => {
                if (field.fieldKey === potentionalKey) {
                    hasConflict = true;
                }
            });

            if (!hasConflict) {
                return potentionalKey;
            }

            var appendNumber = 1;
            var lastCharacter = potentionalKey.substring(potentionalKey.length - 1);
            if (Number(lastCharacter)) {
                appendNumber = Number(lastCharacter) + 1;
                potentionalKey = potentionalKey.substring(0, potentionalKey.length - 1) + appendNumber;
            } else {
                potentionalKey = potentionalKey + appendNumber;
            }

            return this.generateFieldKey(potentionalKey, checkCollection);
        };

        createExportTypes = () => {
            this.exportTypes = [
                {
                    displayName: "Flat File",
                    typeName: "Advant.Crossroads.Core.Export.ProspectFlatFileSettings, Advant.Crossroads.Core"
                }, {
                    displayName: "PDF File",
                    typeName: "Advant.Crossroads.Core.Export.ProspectHtmlFileSettings, Advant.Crossroads.Core"
                }, {
                    displayName: "JSON File",
                    typeName: "Advant.Crossroads.Core.Export.ProspectJsonFileSettings, Advant.Crossroads.Core"
                }, {
                    displayName: "Xml File",
                    typeName: "Advant.Crossroads.Core.Export.ProspectXmlFileSettings, Advant.Crossroads.Core"
                }
            ];
        };

        createLayoutTypes = () => {
            this.layoutTypes = [
                {
                    value: "",
                    displayName: "Choose a layout.."
                }, {
                    value: "TwoColumn",
                    displayName: "Two Column"
                }, {
                    value: "ThreeColumn",
                    displayName: "Three Column"
                }, {
                    value: "FourColumn",
                    displayName: "Four Column"
                }, {
                    value: "Custom",
                    displayName: "Use a Custom layout"
                }
            ];

        };

        deleteExportField = (field) => {
            field.remove();
            this.$scope.prospectExportSettingsForm.$setDirty();
        };

        deleteHeaderField = (field: IExportField) => {
            _.remove((<IApplicationHtmlFileSettings>this.setting).headerFields, { fieldKey: field.fieldKey });
            this.$scope.prospectExportSettingsForm.$setDirty();
        };


        downloadExport = () => {
            var httpPath = this.config.apiHost + "/" + this.common.getUser().activeApplication +
                "/prospectExportSettings/" + this.exportSettingsId + "/testExport?fileId=" + encodeURI(this.testFileId);

            this.helper.openFile(httpPath);

        }

        editExportField = (field: IExportField) => {
            var exportFieldDialog = this.dialogs.create("/app/exportSettings/exportFieldDialog.html", "exportFieldDialog", field, { copy: true });

            exportFieldDialog.result.then((result: IExportField) => {
                this.$scope.prospectExportSettingsForm.$setDirty();
                field.exportFormatter = result.exportFormatter;
                field.fieldKey = result.fieldKey;
                field.title = result.title;
            });
        };

        generateTestFile = (): angular.IPromise<string> => {
            this.generatingFile = true;
            this.testFileId = null;

            return this.Restangular.one(this.common.getUser().activeApplication).one(`prospectExportSettings/${this.exportSettingsId}/testExport`)
                .get(this.getQueryParams()).then((result) => {

                    this.testFileId = result.fileId;
                    this.totalExported = result.totalExported;

                    this.generatingFile = false;
                    return result;
                }, (reason) => {
                    this.generatingFile = false;
                    this.logError("An error occurred while generating the export", reason, true);
                });
        };

        getAdditionalFields = (): angular.IPromise<string[]> => {
            return this.Restangular.one(this.common.getUser().activeApplication).all("prospects").one("sources", this.source).getList("fields").then((result) => {
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting additional fields for the source", reason);
                return [];
            });
        };

        getProspectDefinition = (): void => {
            this.prospectDefinition = {
                sections: [
                    {
                        key: "standardFields",
                        displayName: "Standard Fields",
                        fields: [],
                        selected: false
                    }, {
                        key: "dynamicFields",
                        displayName: "Dynamic Fields",
                        fields: [],
                        selected: false
                    }
                ]
            };
            var standardFields = this.prospectDefinition.sections[0];
            standardFields.fields.push({ key: "Source", title: "Source", selected: false });
            standardFields.fields.push({ key: "DateCreated", title: "DateCreated", selected: false });
            standardFields.fields.push({ key: "DateLastUpdated", title: "DateLastUpdated", selected: false });
            standardFields.fields.push({ key: "ClosedDate", title: "ClosedDate", selected: false });
            standardFields.fields.push({ key: "ReOpenDate", title: "ReOpenDate", selected: false });
            standardFields.fields.push({ key: "Email", title: "Email", selected: false });
            standardFields.fields.push({ key: "Name.First", title: "First Name", selected: false });
            standardFields.fields.push({ key: "Name.Middle", title: "Middle Name", selected: false });
            standardFields.fields.push({ key: "Name.Last", title: "Last Name", selected: false });
            standardFields.fields.push({ key: "Name.Suffix", title: "Suffix", selected: false });
            standardFields.fields.push({ key: "MailingAddress.Street1", title: "Mailing Address Street", selected: false });
            standardFields.fields.push({ key: "MailingAddress.Street2", title: "Mailing Address Street2", selected: false });
            standardFields.fields.push({ key: "MailingAddress.City", title: "Mailing Address City", selected: false });
            standardFields.fields.push({ key: "MailingAddress.State", title: "Mailing Address State", selected: false });
            standardFields.fields.push({ key: "MailingAddress.Country", title: "Mailing Address Country", selected: false });
            standardFields.fields.push({ key: "MailingAddress.Zip", title: "Mailing Address Postal Code", selected: false });
            standardFields.fields.push({ key: "PhysicalAddress.Street1", title: "Physical Address Street", selected: false });
            standardFields.fields.push({ key: "PhysicalAddress.Street2", title: "Physical Address Street2", selected: false });
            standardFields.fields.push({ key: "PhysicalAddress.City", title: "Physical Address City", selected: false });
            standardFields.fields.push({ key: "PhysicalAddress.State", title: "Physical Address State", selected: false });
            standardFields.fields.push({ key: "PhysicalAddress.Country", title: "Physical Address Country", selected: false });
            standardFields.fields.push({ key: "PhysicalAddress.Zip", title: "Physical Address Postal Code", selected: false });
        };

        getDynamicFields = (): void => {
            this.prospectDefinition.sections[1].fields = [];
            if (this.source) {
                this.getAdditionalFields().then((result) => {
                    angular.forEach(result, (value: string) => {
                        this.prospectDefinition.sections[1].fields.push({ key: value, title: value, selected: false });
                    });
                    this.prospectDefinition.sections[1].fields = _.sortBy(this.prospectDefinition.sections[1].fields, "title");
                });
            }
        };

        getQueryParams = (): any => {
            var queryParams = {
                startDate: this.dateStart ? moment(this.dateStart).toISOString() : "",
                endDate: this.dateEnd ? moment(this.dateEnd).add(1, "day").subtract(1, "second").toISOString() : "",
                source: this.sourceFilter,
                ruleId: this.ruleIdFilter,
                singleFile: this.singleFile
            };

            return queryParams;
        }

        getRules = (): angular.IPromise<Array<IRuleSet>> => {
            this.rules = [];
            return this.Restangular.one(this.common.getUser().activeApplication).all("rules").getList().then((result) => {
                this.rules = result;
                this.rules.unshift({ id: null, name: "" });
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the rules", reason);
                return this.rules;
            });
        };

        getSources = (): angular.IPromise<string[]> => {
            return this.Restangular.one(this.common.getUser().activeApplication).all("prospects").all("sources").getList().then((result) => {
                this.sources = result;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting sources", reason);
                return [];
            });
        };

        getSetting = (): angular.IPromise<IExportSettings> => {
            var deferred;
            if (this.$routeParams.exportSettingsId.toLowerCase() === "new") {
                deferred = this.common.$q.defer<IExportSettings>();
                var empty: any = {
                    $type: null,
                    id: null,
                    applicationDefinitionId: this.common.getUser().activeApplication,
                    name: null,
                    fileNamePattern: null,
                    singleFileNamePattern: null,
                    exportType: "Prospect",
                    createdOn: moment().toDate(),
                    createdBy: null,
                    lastUpdated: moment().toDate(),
                    lastUpdatedBy: null,
                    type: null,
                    exportFields: [],
                    headerFields: []
                };
                this.setting = empty;
                this.originalSetting = angular.copy(this.setting);
                deferred.resolve(this.setting);

                return deferred.promise;
            }


            return this.Restangular.one(this.common.getUser().activeApplication).one("prospectExportSettings", this.exportSettingsId).get().then((result) => {
                this.setting = result;
                this.originalSetting = angular.copy(this.setting);
                return result;
            }, (reason) => {
                this.logError("An error occurred while trying to retrieve your settings. Please try again later", reason, true);
                return this.setting;
            });

        };

        rootClass = (field) => {
            if (field.hasOwnProperty("groups")) {
                return "fa-folder";
            }
            return "fa-arrows";
        };

        save = () => {
            if (this.$scope.prospectExportSettingsForm.$valid) {
                this.isSaving = true;

                if (this.setting.id === null) {
                    this.Restangular.all(this.common.getUser().activeApplication).all("prospectExportSettings").post(this.setting).then((result) => {
                        this.isSaving = false;
                        this.originalSetting = angular.copy(result);
                        this.setting = result;
                        this.exportSettingsId = this.setting.id;
                        this.logSuccess("Your prospect export settings have been saved", result, true);
                        this.$scope.prospectExportSettingsForm.$setPristine();
                        return result;
                    }, (reason) => {
                        var message = "Error saving new setting!";
                        if (reason.data.modelState) {
                            angular.forEach(reason.data.modelState, (value: any, key: any) => {
                                message += `<br/>${value}`;
                            });
                        }
                        if (reason.data.exceptionMessage) {
                            message += "<br/>" + reason.data.exceptionMessage;
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                    });
                } else {
                    this.setting.put().then((result) => {
                        this.logSuccess("Your prospect export settings have been saved", result, true);
                        this.$scope.prospectExportSettingsForm.$setPristine();
                        this.isSaving = false;
                        this.originalSetting = angular.copy(this.setting);
                        return result;
                    }, (reason) => {
                        var message = "An error occurred while trying to save your settings. Please try again later.";
                        if (reason.data.exceptionMessage) {
                            message += "<br/>" + reason.data.exceptionMessage;
                        }
                        this.logError(message, reason, true);
                        this.isSaving = false;
                        return;
                    });
                }
            }
        };

        selectSection = (section: IProspectSection, select?) => {
            section.selected = !section.selected;

            if (select !== undefined) {
                section.selected = select;
            }

            angular.forEach(section.fields, (field) => {
                field.selected = section.selected;
            });
        };

        toggleTree(node) {
            node.toggle();
        }

        exportSetting = () => {
            var exportFile: ExportSettingsFile = new ExportSettingsFile(this.setting);

            var blob: Blob = new Blob([exportFile.toJson()], { type: "application/octet-stream" });
            saveAs(blob, this.setting.name + ".json");
        };

        importSetting = () => {
            var currentExportSettings = new ExportSettingsFile(this.setting);

            var currentSettingData: ICurrentExportSettingData = {
                currentExportSettingsFile: currentExportSettings,
                appDefId: null
            };


            var importFile = this.dialogs.create("/app/exportSettings/importSetting.html", "importSetting", currentSettingData, { size: "lg" });

            importFile.result.then((result: ExportSettingsFile) => {
                this.setting = result.setting;
                this.$timeout(this.save);
                this.isNew = false;
            });


        }
    }

    angular.module("app").controller(ProspectExportSettingsDetails.controllerId, ProspectExportSettingsDetails);
}