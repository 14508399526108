/// <reference path="../../app.ts" />
namespace Advant.Crossroads {

    export interface ICurrentAppData {
        currentFormFile: ApplicationFormFile;
        appDefId: string;
    }


    interface IImportedRuleSet extends IRuleSet {
        mapTo: string;
    }

    interface IImportedChoiceList extends IChoiceList {
        mapTo: string;
    }

    interface IImportFormScope extends angular.IScope {
        importForm: (files: Array<File>) => void;
        createOrMapMissingItems: () => void;
        cancel: () => void;
        save: () => void;
        missingChoiceLists: Array<IImportedChoiceList>;
        existingChoiceLists: Array<IChoiceList>;
        missingRules: Array<IImportedRuleSet>;
        existingRules: Array<IRuleSet>;
        data: any;
    }

    export class ImportForm {
        static controllerId: string = "importForm";
        static $inject: any = ["$scope", "$q", "common", "config", "Restangular", "helper", "instance", "data"];
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        importedFile: ApplicationFormFile;
        displayRuleHasBeenReplaced: Array<string>;
        validationRuleHasBeenReplaced: Array<string>;
        requiredIfRuleHasBeenReplace: Array<string>;
        choiceListIdHasBeenReplaced: Array<string>;

        constructor(private $scope: IImportFormScope,
            private $q: angular.IQService,
            private common: ICommonService,
            private config: Crossroads.ICrossroadsConfig,
            private Restangular: Restangular.IService,
            private helper: IHelper,
            private instance: any,
            private currentAppData?: ICurrentAppData) {
            this.log = common.logger.getLogFn(ImportForm.controllerId);
            this.logError = this.common.logger.getLogFn(ImportForm.controllerId, "error");
            this.logSuccess = this.common.logger.getLogFn(ImportForm.controllerId, "success");

            $scope.importForm = this.importForm;
            $scope.data = {};
            $scope.data.importing = false;
            if (currentAppData.currentFormFile.choiceLists) {
                currentAppData.currentFormFile.choiceLists.unshift({ id: "", name: "", choices: null });
            }
            $scope.existingChoiceLists = currentAppData.currentFormFile.choiceLists;
            $scope.existingRules = currentAppData.currentFormFile.rules;
            $scope.createOrMapMissingItems = this.createOrMapMissingItems;
            $scope.cancel = this.cancel;
            $scope.save = this.save;
        }

        importForm = (files) => {
            if (files && files.length > 0) {
                this.$scope.data.importing = true;
                this.$scope.data.progress = 0;
                var file = files[0];
                var blob = new Blob([file], { type: "application/json" });
                var reader = new FileReader();
                reader.onprogress = this.updateProgress;
                reader.onload = this.completedImportProcessing;
                reader.readAsText(blob);
            }
        };

        private cancel = () => {
            this.instance.cancel();
        };

        completedImportProcessing = (ev) => {
            this.$scope.$applyAsync((scope: IImportFormScope) => {
                scope.data.progress = 100;
            });
            this.importedFile = ApplicationFormFile.fromJson(ev.currentTarget.result);
            this.$scope.data.parsingProgress = 0;

            var compareChoicePromise = this.compareChoiceLists(this.importedFile.choiceLists, this.currentAppData.currentFormFile.choiceLists);
            var compareRulePromise = this.compareRuleList(this.importedFile.rules, this.currentAppData.currentFormFile.rules);

            compareChoicePromise.then((result: Array<IImportedChoiceList>) => {
                this.$scope.$applyAsync((scope: IImportFormScope) => {
                    scope.missingChoiceLists = result;
                });
            });
            compareRulePromise.then((result: Array<IImportedRuleSet>) => {
                this.$scope.$applyAsync((scope: IImportFormScope) => {
                    scope.missingRules = result;
                });
            });
            this.$q.all([compareChoicePromise, compareRulePromise]).then(() => {
                this.$scope.$applyAsync((scope: IImportFormScope) => {
                    scope.data.parsingProgress = 100;
                    scope.data.importing = false;
                    scope.data.parsed = true;

                    if (scope.missingRules.length > 0) {
                        scope.data.hasMissingRules = true;
                    }

                    if (scope.missingChoiceLists.length > 0) {
                        scope.data.hasMissingChoiceLists = true;
                    }

                });
            });
        };
        updateProgress = (evt) => {
            if (evt.lengthComputable) {
                var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
                // Increase the progress bar length.
                if (percentLoaded < 100) {
                    this.$scope.$applyAsync((scope: IImportFormScope) => {
                        scope.data.progress = percentLoaded;
                    });

                }
            }
        };

        compareChoiceLists = (importChoiceLists: Array<IChoiceList>, existingChoiceLists: Array<IChoiceList>) => {
            var deferred = this.$q.defer();
            var missingChoiceLists: Array<IChoiceList> = [];

            setTimeout(() => {
                angular.forEach(importChoiceLists, (value: IImportedChoiceList, key) => {
                    var match = _.find(existingChoiceLists, { "name": value.name });
                    if (!match) {
                        missingChoiceLists.push(value);
                    } else {
                        if (match.id !== value.id) {
                            value.mapTo = match.id;
                            missingChoiceLists.push(value);
                        }
                    }
                    this.$scope.$applyAsync((scope: IImportFormScope) => {
                        scope.data.parsingProgress += Math.round((1 / importChoiceLists.length) * 50);;
                    });
                });
                deferred.resolve(missingChoiceLists);
            }, 15);


            return deferred.promise;
        };

        compareRuleList = (importRules: Array<IRuleSet>, existingRules: Array<IRuleSet>) => {
            var deferred = this.$q.defer();
            var missingRules: Array<IRuleSet> = [];
            setTimeout(() => {
                angular.forEach(importRules, (value: IImportedRuleSet) => {
                    var match = _.find(existingRules, { "name": value.name });                  
                    if (!match) {
                        missingRules.push(value);
                    } else {
                        if (match.id !== value.id) {
                            value.mapTo = match.id;
                            missingRules.push(value);
                        }
                        else {
                            match = this.Restangular.stripRestangular(match);
                            value = this.Restangular.stripRestangular(value);

                            // Removing the restangular properties doesn't seem to remove the hashKey property so manaually removing it 
                            // so the isEqual function produces the correct results
                            if (match.hasOwnProperty("$$hashKey")) {
                                delete (match as any).$$hashKey;
                            }
                            // Check to see if the rule has been changed
                            if (!_.isEqual(match, value)) {
                                value.mapTo = match.id;
                                missingRules.push(value);
                            }
                        }
                    }
                    this.$scope.$applyAsync((scope: IImportFormScope) => {
                        scope.data.parsingProgress += Math.round((1 / importRules.length) * 50);;
                    });
                });
                deferred.resolve(missingRules);
            }, 20);

            return deferred.promise;
        };

        createOrMapMissingItems = () => {
            this.$scope.data.creatingMappings = true;
            this.$q.all([this.createOrMapRules(), this.createOrMapChoiceLists()]).then(() => {
                this.$scope.data.creatingMappings = false;
                this.$scope.data.hasMissingRules = false;
                this.$scope.data.hasMissingChoiceLists = false;
            });
        };

        createOrMapRules = () => {
            var promises = [];
            this.displayRuleHasBeenReplaced = [];
            this.validationRuleHasBeenReplaced = [];
            this.requiredIfRuleHasBeenReplace = [];

            angular.forEach(this.$scope.missingRules, (value) => {
                if (!value.mapTo || value.mapTo === "") {
                    var promise = this.Restangular.all(this.currentAppData.appDefId).all("rules").post(value);
                    promises.push(promise);
                    promise.then(result => {
                        value.mapTo = result.id;
                        // Since the server will try to create a rule with the same id only attempt to change the mapping
                        // if the IDs are different.
                        if (value.id !== value.mapTo) {
                            this.replaceRuleId(value);
                        }
                        this.currentAppData.currentFormFile.rules.push(value);
                    }, error => {
                        this.logError("An error occurred while trying to save the rule", error, true);
                    });
                } else {
                    var updatedRule: any = this.Restangular.copy(value);
                    updatedRule.id = value.mapTo;
                    updatedRule.applicationDefinitionId = this.currentAppData.appDefId;
                    var promise2 = this.Restangular.all(this.currentAppData.appDefId).all("rules").one("1").customPUT(updatedRule);
                    promises.push(promise2);
                    promise2.then(result => {
                        this.logSuccess("Rule was updated", result, false);
                    }, error => {
                        this.logError("An error occurred while trying to save the rule", error, true);
                    });
                    // Since the missing value comparison is on both name and id the id may not actually be different and therefore
                    // may not need to be changed.
                    if (value.id !== value.mapTo) {
                        this.replaceRuleId(value);
                    }
                }
            });

            return this.$q.all(promises);
        };

        createOrMapChoiceLists = () => {
            var promises = [];
            this.choiceListIdHasBeenReplaced = [];
            angular.forEach(this.$scope.missingChoiceLists, (value) => {
                if (!value.mapTo || value.mapTo === "") {
                    var promise = this.Restangular.all(this.currentAppData.appDefId).all("choicelists").post(value);
                    promises.push(promise);
                    promise.then(result => {
                        value.mapTo = result.id;
                        // Since the server will try to create a rule with the same id only attempt to change the mapping
                        // if the IDs are different.
                        if (value.id !== value.mapTo) {
                            this.replaceChoiceListId(value);
                        }
                        this.currentAppData.currentFormFile.choiceLists.push(value);
                    }, error => {
                        this.logError("An error occurred while trying to save the choice list", error, true);
                    });
                } else {
                    // Since the missing value comparison is on both name and id the id may not actually be different and therefore
                    // may not need to be changed.
                    if (value.id !== value.mapTo) {
                        this.replaceChoiceListId(value);
                    }
                }
            });
            return this.$q.all(promises);
        };

        replaceRuleId = (replaceRule: IImportedRuleSet) => {
            angular.forEach(this.importedFile.form.sections, (section) => {
                if (section.displayRule && section.displayRule.ruleId === replaceRule.id && this.displayRuleHasBeenReplaced.indexOf(section.name) === -1) {
                    section.displayRule.ruleId = replaceRule.mapTo;
                    this.displayRuleHasBeenReplaced.push(section.name);
                }

                angular.forEach(section.groups, (group) => {
                    if (group.displayRule && group.displayRule.ruleId === replaceRule.id && this.displayRuleHasBeenReplaced.indexOf(group.name) === -1) {
                        group.displayRule.ruleId = replaceRule.mapTo;
                        this.displayRuleHasBeenReplaced.push(group.name);
                    }

                    angular.forEach(group.fields, (field) => {
                        if (field.displayRule && field.displayRule.ruleId === replaceRule.id && this.displayRuleHasBeenReplaced.indexOf(field.key) === -1) {
                            field.displayRule.ruleId = replaceRule.mapTo;
                            this.displayRuleHasBeenReplaced.push(field.key);
                        }
                        if (this.helper.isType(field.type, "InputField") && (<IInputField>field).ruleId === replaceRule.id && this.validationRuleHasBeenReplaced.indexOf(field.key) === -1) {
                            (<IInputField>field).ruleId = replaceRule.mapTo;
                            this.validationRuleHasBeenReplaced.push(field.key);
                        }
                        if (this.helper.isType(field.type, "InputField") && (<IInputField>field).requiredIf && (<IInputField>field).requiredIf.id === replaceRule.id && this.requiredIfRuleHasBeenReplace.indexOf(field.key) === -1) {
                            (<IInputField>field).requiredIf.id = replaceRule.mapTo;
                            this.requiredIfRuleHasBeenReplace.push(field.key);
                        }
                    });
                });
            });
        };

        replaceChoiceListId = (replaceChoiceList: IImportedChoiceList) => {
            angular.forEach(this.importedFile.form.sections, (section) => {
                angular.forEach(section.groups, (group) => {
                    angular.forEach(group.fields, (field) => {
                        if (this.helper.isType(field.type, "ListField") && (<IListField>field).choiceListId === replaceChoiceList.id && this.choiceListIdHasBeenReplaced.indexOf(field.key) === -1) {
                            (<IListField>field).choiceListId = replaceChoiceList.mapTo;
                            this.choiceListIdHasBeenReplaced.push(field.key);
                        }
                    });
                });
            });
        };

        private save = () => {
            this.currentAppData.currentFormFile.form.sections = this.importedFile.form.sections;

            this.instance.confirm(this.currentAppData.currentFormFile);
        };
    }

    angular.module("app").controller(ImportForm.controllerId, ImportForm);
}