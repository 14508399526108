/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    export interface IHelper {
        isType: (entityType: Type, match: string) => boolean;
        openFile: (httpPath) => angular.IPromise<void>;

    }

    export class Helper implements IHelper {
        static serviceId: string = "helper";
        static $inject: string[] = ["$http"];

        constructor(private $http: angular.IHttpService) {
        }

        isType(entityType: Type, match: string) {
            var result = false;
            if (entityType) {
                if (entityType.name === match) {
                    result = true;
                } else if (entityType.baseType) {
                    result = this.isType(entityType.baseType, match);
                }
            }

            return result;
        }

        openFile = (httpPath) => {
            return this.$http.get<any>(httpPath, { responseType: "arraybuffer" })
                .then((response) => {
                    var octetStreamMime = "application/octet-stream";

                    // Get the headers
                    var headerValues = response.headers();

                    // Get the filename from the x-filename header or default to "download.bin"
                    var filename = headerValues["x-filename"] || "download.pdf";

                    // Determine the content type from the header or default to "application/octet-stream"
                    var contentType = headerValues["content-type"] || octetStreamMime;
                    var blob = new Blob([response.data], { type: contentType });

                    saveAs(blob, filename);

                },
                (reason) => {
                    console.log(`Request failed with status: ${status}`, reason);
                });
        };

    }

    angular.module("app").service(Helper.serviceId, Helper);
}