/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    export interface IShellScope extends angular.IScope {
        vm: Shell;
    }

    export interface IShellController {
        busyMessage: string;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        isBusy: boolean;
        showSplash: boolean;
        spinnerOptions: SpinnerOptions;
        toggleSpinner: (on: boolean) => void;
        topBarIsCollapsed: boolean;
        sideBarIsCollapsed: boolean;
        userInfo: IUserInfoModel;
        availableApplications: Array<any>;
        favorites: IFavorite[];
        favoriteSortOptions: any;
        cfncURL: string;
    }

    export class Shell implements IShellController {
        static controllerId: string = "shell";
        static $inject: any = ["$rootScope", "$location", "Restangular", "common", "config", "authentication", "Idle"];

        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        logWarn: (msg, data?, showHowl?, makeSticky?) => void;
        events: any;
        busyMessage: string;
        isBusy: boolean;
        showSplash: boolean;
        spinnerOptions: SpinnerOptions;
        topBarIsCollapsed: boolean;
        sideBarIsCollapsed: boolean;
        userInfo: IUserInfoModel;
        availableApplications: Array<any>;
        favorites: IFavorite[] = [];
        favoriteIsOpen = false;
        favoriteSortOptions: any;
        cfncURL: string;
        profile: IUserViewModel;
        currentYear: number;

        constructor(private $rootScope: ICrossRoadsRootScope,
            private $location: angular.ILocationService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private authentication: IAuthenticationProviderService,
            private Idle: angular.idle.IIdleService) {


            authentication.authenticate();

            this.userInfo = common.getUser();
            this.Idle.watch();
            this.logError = common.logger.getLogFn(Organizations.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(Shell.controllerId, "success");
            this.logWarn = common.logger.getLogFn(Shell.controllerId, "warn");
            this.events = config.events;
            this.topBarIsCollapsed = true;
            this.sideBarIsCollapsed = true;

            this.busyMessage = "Please wait ...";
            this.isBusy = true;
            this.showSplash = true;
            this.spinnerOptions = {
                radius: 30,
                lines: 15,
                length: 20,
                width: 9,
                speed: 1.5,
                corners: 1.0,
                trail: 70,
                color: "#0E81B2"
            };


            this.cfncURL = this.config.cfncUrl;
            this.currentYear = new Date().getFullYear();
            this.activate();

            this.favoriteSortOptions = {
                stop: this.sortFavorite,
                items: "li:not(.not-sortable)"
            };

            var vm = this;
            $rootScope.$on("$routeChangeStart",
                (event, next, current) => { vm.toggleSpinner(true); }
            );

            $rootScope.$on(config.events.controllerActivateSuccess,
                (data) => { vm.toggleSpinner(false); }
            );

            $rootScope.$on(config.events.spinnerToggle,
                (event, data) => {
                    var eventData: any = data; // converts the IAngularEvent back to a dynamic type so we can view the "show" property that was set by the ICommonService
                    vm.toggleSpinner(eventData.show);
                });
        }

        activate() {
            var self = this;
            this.logSuccess("Crossroads Admin Portal loaded!", null, false);
            this.common.activateController([this.loadAvailableApplications().then(() => { this.getProfile().then(() => { this.selectDefaultApplication(); }); }), this.loadFavorites()], Shell.controllerId)
                .then(() => {
                    self.showSplash = false;
                    if (moment(this.userInfo.passwordChangedOn).isBefore(moment().subtract(83, "days"))) {
                        var expiresOn = moment(this.userInfo.passwordChangedOn).add(90, "days");
                        var daysRemaining = expiresOn.diff(moment(), "days");
                        this.logWarn(`Your password expires in ${daysRemaining} days! Please <a href=\"/manage/changePassword\">click here</a> to change it.`, null, true, true);
                    }

                });
        }

        private loadAvailableApplications = (): angular.IPromise<any> => {
            return this.Restangular.all("account").all("organizations").getList().then(result => {
                this.availableApplications = [];

                angular.forEach(result, (org: IOrganization, key) => {
                    var sortedProg = _.sortBy(org.programs, "name");
                    angular.forEach(sortedProg, (prog: IProgram, progKey) => {
                        var sortedApps = _.sortBy(prog.applications, "name");
                        angular.forEach(sortedApps, (app: IApplicationDefinition, appKey) => {
                            var appView: any = {
                                applicationDefinitionId: app.applicationDefinitionId,
                                name: app.name,
                                program: null
                            };

                            if (this.userInfo.systemAdmin) {
                                appView.program = org.name + " - " + prog.name;
                            } else {
                                appView.program = prog.name;
                            }
                            this.availableApplications.push(appView);
                        });
                    });
                });
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the organizations", reason);
            });
        };

        getProfile = () => {
            return this.Restangular.all("account").one("profile").get().then((result) => {
                this.profile = result;
            }, (reason) => {
                this.logError("Failed to retrieve profile data.", reason, true);
            });
        };

        private selectDefaultApplication = (): void => {
            if ((!this.userInfo.activeApplication || this.userInfo.activeApplication === "undefined") && this.availableApplications && this.availableApplications[0]) {
                var selectedAppDefId: string;
                if (this.profile && this.profile.defaultApplicationDefinitionId) {
                    selectedAppDefId = this.profile.defaultApplicationDefinitionId;
                } else {
                    selectedAppDefId = this.availableApplications[0].applicationDefinitionId;
                }

                sessionStorage[CrossroadsStorage.activeApplication] = selectedAppDefId;
                this.common.$broadcast(this.config.events.applicationChanged, selectedAppDefId);
            }
        };

        toggleSpinner(on: boolean) { this.isBusy = on; }

        loadFavorites = (): angular.IPromise<any> => {
            return this.Restangular.one("account").all("favorites").getList().then(result => {
                this.favorites = result;
            }, (reason) => {
                this.logError("An error occurred while getting the favorites", reason);
            });
        };

        addFavorite = ($event: angular.IAngularEvent) => {
            $event.preventDefault();
            $event.stopPropagation();
            var favorite = {
                applicationDefinitionId: this.common.getUser().activeApplication,
                name: this.$rootScope.title,
                path: this.$location.path(),
                state: angular.toJson(this.$location.state()),
                sortOrder: this.favorites && this.favorites.length > 0 ? _.maxBy(this.favorites, "sortOrder").sortOrder + 1 : 1,
                isNew: true
            };

            this.favorites.push(<any>favorite);

        };

        cancelFavorite = (index) => {
            this.favorites.splice(index, 1);
        };

        goToFavorite = (favorite: IFavorite) => {
            sessionStorage[CrossroadsStorage.activeApplication] = favorite.applicationDefinitionId;
            this.common.$broadcast(this.config.events.applicationChanged, favorite.applicationDefinitionId);

            this.$location.path(favorite.path).state(angular.fromJson(favorite.state));
        };

        deleteFavorite = ($event: angular.IAngularEvent, favorite) => {
            _.remove(this.favorites, { id: favorite.id });
            favorite.remove();
            $event.preventDefault();
            $event.stopPropagation();
        };

        saveFavorite = (index) => {
            var favorite = this.favorites[index];
            this.Restangular.one("account").post("favorites", favorite).then((result) => {
                this.favorites[index] = result;
            }, (reason) => {
                this.logError("An error occurred while adding the favorite", reason);
            });
        };

        sortFavorite = (e, ui) => {
            angular.forEach(this.favorites, (value, index) => {
                value.sortOrder = index + 1;

                if (value.id) {
                    var currentSubscriptionRestangular: Restangular.IElement = <any>value;
                    currentSubscriptionRestangular.put().then((result) => {
                        this.logSuccess("favorite order has been updated", null, false);
                    }, (reason) => {
                        this.logError("An error occurred while trying to save the favorite", reason, true);
                    });
                }
            });
        };

        stopCloseOnEdit = ($event: angular.IAngularEvent) => {
            $event.preventDefault();
            $event.stopPropagation();
        };

        toggleFavoriteMenu = ($event) => {
            $event.preventDefault();
            $event.stopPropagation();
            this.favoriteIsOpen = !this.favoriteIsOpen;
        };

    }

    angular.module("app").controller(Shell.controllerId, Shell);
}