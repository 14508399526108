namespace Advant.Crossroads {
    interface IMajorsScope extends angular.IScope {
        vm: Majors;
    }

    interface IMajors {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        loadingMajors: boolean;
        isSaving: boolean;
        uploadingFile: boolean;
        majors: Array<IMajor>;
        currentMajor: IMajor;
        originalMajors: Array<IMajor>;

        page: number;
        pageSize: number;
        totalCount: number;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;
        applicationDefinitionId: string;
        isImporting: boolean;

        createNewMajor: () => void;
        getMajors: () => angular.IPromise<void | Array<IMajor>>;
    }

    class Majors implements IMajors {
        static controllerId: string = "majors";
        static $inject: any = ["$scope", "Upload", "dialogs", "Restangular", "common", "config", "helper"];
        public log: (msg, data?, showHowl?) => void;
        public logError: (msg, data?, showHowl?) => void;
        public logSuccess: (msg, data?, showHowl?) => void;
        public loadingMajors: boolean;
        public isSaving: boolean;
        public uploadingFile: boolean;
        public majors: Array<IMajor>;
        public currentMajor: IMajor;
        public originalMajors: Array<IMajor>;

        public page: number;
        public pageSize: number;
        public totalCount: number;
        public sortBy: string;
        public sortDirection: string;
        public totalPages: number;
        public startRow: number;
        public endRow: number;
        public searchFilter: string;
        public applicationDefinitionId: string;
        public isImporting: boolean;

        constructor(private $scope: IMajorsScope,
            private Upload: angular.angularFileUpload.IUploadService,
            private dialogs: angular.dialogs.IDialogService,
            private Restangular: Restangular.IService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private helper: IHelper) {


            this.log = common.logger.getLogFn(Majors.controllerId);
            this.logError = common.logger.getLogFn(Majors.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(Majors.controllerId, "success");

            this.loadingMajors = false;
            this.isSaving = false;

            this.page = 1;
            this.pageSize = 25;
            this.sortBy = "Name";
            this.sortDirection = "ASC";
            this.searchFilter = "";

            $scope.$on(config.events.applicationChanged, (event) => {
                this.activate();
            });

            this.activate();
        }

        private activate = () => {
            this.common.activateController([this.getMajors()], Majors.controllerId)
                .then(result => {
                    this.log("Activated Majors View");
                    this.applicationDefinitionId = this.common.getUser().activeApplication;
                });
        };

        createNewMajor = (): void => {
            var newMajor: any = {
                applicationDefinitionId: this.common.getUser().activeApplication,
                editMode: true
            };

            this.majors.unshift(newMajor);
            this.currentMajor = newMajor;
        };

        deleteMajor = (major: IMajor): void => {
            var confirm = this.dialogs.confirm("Delete Major", "Are you sure you want to delete this major? This action cannot be reversed.");

            confirm.result.then((button) => {
                var currentRuleRestangular: Restangular.IElement = <any>major;
                currentRuleRestangular.remove().then(result => {
                    this.logSuccess("Your major has been deleted", null, true);
                    this.getMajors();
                }, error => {
                    if (error.data && error.data.$type === "Advant.Crossroads.Api.Models.EntityReferencedByAnotherEntityExceptionResult, Advant.Crossroads.Api") {
                        var errorMessage = "This major is being used by the following major lists:<br/>";
                        _.forEach<IMajorList>(error.data.referencedBy, (majorList) => {
                            errorMessage += "<strong>" + majorList.name + "</strong><br/>";
                        });

                        errorMessage += "Please remove this major from those major lists and try deleting again.";

                        this.logError(errorMessage, error, true);
                    } else {
                        this.logError("An error occurred while trying to delete the major", error, true);
                    }
                });
            });
        };

        deleteMajors = (): void => {
            var confirm = this.dialogs.confirm("Delete All Majors", "Are you sure you want to delete all majors? This will also delete all majors from the major lists. This action cannot be reversed.");

            confirm.result.then((button) => {
                return this.Restangular.one(this.common.getUser().activeApplication).post("majors/delete").then((result: IRestangularResult<IMajor>) => {
                    this.logSuccess("The majors have been deleted", null, true);
                    this.getMajors();
                }, (reason) => {
                    if (reason.data && reason.data.$type === "Advant.Crossroads.Api.Models.EntityReferencedByAnotherEntityExceptionResult, Advant.Crossroads.Api") {
                        var errorMessage = "This major is being used by the following major lists:<br/>";
                        _.forEach<IMajorList>(reason.data.referencedBy, (majorList) => {
                            errorMessage += "<strong>" + majorList.name + "</strong><br/>";
                        });

                        errorMessage += "Please remove this major from those major lists and try deleting again.";

                        this.logError(errorMessage, reason, true);
                    } else {
                        this.logError("An error occurred while trying to delete the major", reason, true);
                    }
                });
            });
        };

        getMajors = (): angular.IPromise<void | Array<IMajor>> => {
            this.loadingMajors = true;
            this.majors = [];
            this.currentMajor = null;
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                filter: this.searchFilter
            };


            return this.Restangular.one(this.common.getUser().activeApplication).getList("majors", queryParams).then((result: IRestangularResult<IMajor>) => {
                this.majors = result;
                this.originalMajors = angular.copy(this.majors);
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingMajors = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the majors", reason);
            });
        };

        importMajors(file) {
            this.uploadingFile = true;
            this.isImporting = true;
            this.Upload.upload(<any>{
                url: this.config.apiHost + "/" + this.common.getUser().activeApplication + "/majors/import",
                method: "POST",
                file: file
            }).then((response) => {
                this.logSuccess("Finished importing majors.", null, true);
                this.uploadingFile = false;
                this.isImporting = false;
                this.getMajors();
            });
        }

        exportMajors = () => {
            var path = this.config.apiHost + "/" + this.common.getUser().activeApplication + "/majors/export"
            this.helper.openFile(path).then(() => { },
                (reason) => {
                    this.logError("An error occurred while saving the file", reason);
                });
        }

        exportChoiceLists = () => {
            var httpPath = this.config.apiHost + "/" + this.applicationDefinitionId + "/choicelists/export";

            this.helper.openFile(httpPath).then(() => {

            }, (reason) => {
                this.logError("An error occurred while saving the report to excel", reason);
            });

        };

        save = (major) => {
            this.isSaving = true;
            if (!major.id) {
                this.Restangular.all(this.common.getUser().activeApplication).all("majors").post(major).then(result => {
                    this.logSuccess("Your new major has been created", null, true);
                    var index = this.majors.indexOf(major);
                    this.majors[index] = result;
                    major = this.majors[index];
                    this.isSaving = false;
                    major.editMode = false;
                    this.originalMajors = _.cloneDeep(this.majors);
                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to save the rule", error, true);
                });
            } else {
                var currentRuleRestangular: Restangular.IElement = <any>major;
                currentRuleRestangular.put().then(result => {
                    this.logSuccess("Your major has been updated", null, true);
                    this.isSaving = false;
                    major.editMode = !major.editMode;
                }, error => {
                    this.isSaving = false;
                    this.logError("An error occurred while trying to save the rule", error, true);
                });
            }
        };

        cancelEditMajor = (major, index) => {
            if (major.id) {
                var originalMajor = _.find(this.originalMajors, { id: major.id });
                major.editMode = false;
                this.majors[index] = <IMajor>_.cloneDeep(originalMajor);

            } else {
                this.majors.splice(index, 1);
            };
        };

        searchMajors = (): void => {
            this.common.debouncedThrottle("MajorSearch", () => { this.page = 1; this.getMajors(); }, 400);
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.getMajors();
        };
    }


    angular.module("app").controller(Majors.controllerId, Majors);
}