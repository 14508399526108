/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    import Moment = moment.Moment;

    export interface IDashboardController {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;
        uiConfig: any;
        eventSources: Array<any>;
        chartConfig: any;
        pieChartConfig: any;
        startDate: moment.Moment;
        endDate: moment.Moment;
        chartStartDate: moment.Moment;
        chartEndDate: moment.Moment;
        weekRanges: any[];
        loadingUserApplications: boolean;
        userApplications: IUserApplicationViewModel[];
        submittedApplicationsCount: number;
        startedApplicationsCount: number;
        transcriptCount: number;
        recentTranscripts: ITransaction[];

        totalPayments: number;
        activate: () => void;
        totalApplicationsByDay: any;
        additionalField: any;
        rangeDisplay: string;
    }

    export class DashboardController implements IDashboardController {
        log: (msg, data?, showHowl?) => void;
        logError: (msg, data?, showHowl?) => void;
        logSuccess: (msg, data?, showHowl?) => void;

        userInfo = this.common.getUser();

        uiConfig: any;
        eventSources: Array<any>;
        chartConfig: any;
        pieChartConfig: any;

        startDate: moment.Moment;
        endDate: moment.Moment;
        chartStartDate: moment.Moment;
        chartEndDate: moment.Moment;
        weekRanges: any[];
        loadingUserApplications: boolean;
        userApplications: IUserApplicationViewModel[];
        recentTranscripts: ITransaction[] = [];

        submittedApplicationsCount = 0;
        startedApplicationsCount = 0;
        totalPayments = 0;
        totalApplicationsByDay: any;
        additionalField: any;
        transcriptCount = 0;
        recentApplicationExports: any[];
        recentTranscriptExports: any[];
        recentProspectExports: any[];

        rangeDisplay: string;

        static controllerId: string = "dashboardController";
        static $inject: any = ["$scope", "Restangular", "common", "config", "uiCalendarConfig", "$http"];

        constructor(private $scope: angular.IScope, private Restangular: Restangular.IService, private common: ICommonService, private config: ICrossroadsConfig, private uiCalendarConfig, private $http: angular.IHttpService) {

            this.log = common.logger.getLogFn(DashboardController.controllerId);
            this.logError = common.logger.getLogFn(DashboardController.controllerId, "error");
            this.logSuccess = common.logger.getLogFn(DashboardController.controllerId, "success");

            this.startDate = moment().startOf("week").utc();
            this.endDate = moment().endOf("week").utc();
            this.rangeDisplay = this.startDate.format("MMMM DD") + " - " + this.endDate.clone().subtract(1, "day").format("MMMM DD, YYYY");
            this.chartStartDate = moment().startOf("week").utc();
            this.chartEndDate = moment().endOf("week").utc();

            this.createWeekRangeList();

            if (this.common.getUser() && this.common.getUser().activeApplication) {
                this.activate();
            }

            this.uiConfig = {
                calendar: {
                    header: {
                        left: "prev,next",
                        center: "title",
                        right: "month,agendaWeek,agendaDay"
                    },
                    fixedWeekCount: false,
                    timezone: "local",
                    editable: false,
                    droppable: false
                }
            };

            this.eventSources = [this.getEvents];

            this.chartConfig = {
                "chart": {
                    "type": "areaspline"
                },
                "plotOptions": {
                    "series": {
                        "stacking": ""
                    }
                },
                "series": [
                    {
                        "name": "Applications Submitted",
                        "data": [],
                        "id": "series-0",
                        "connectNulls": true,
                        "type": "areaspline",
                        "color": "#0E81B2"
                    },
                    {
                        "name": "Applications Started",
                        "data": [],
                        "connectNulls": true,
                        "id": "series-1",
                        "type": "areaspline",
                        "color": "#f0ad4e"
                    }
                ],
                title: {
                    "text": "Applications"
                },
                credits: {
                    "enabled": false
                },
                "loading": false,
                xAxis: {
                    title: "Date",
                    categories: [
                        "04/13", "04/14", "04/15", "04/16", "04/17", "04/18",
                        "04/19"
                    ]
                },
                yAxis: {
                    title: "Applications"
                }
            };

            this.pieChartConfig = {
                "options": {
                    "chart": {
                        "type": "pie"
                    },
                    colors: [
                        "#0E81B2",
                        "#f0ad4e",
                        "#888888",
                        "#e5412d",
                        "#449922",
                        "#723f7f",
                        "#99E0FF",
                        "#e1ccff",
                        "#4ef053",
                        "#79b1f2"

                    ],


                },
                "plotOptions": {
                    "series": {
                        "stacking": "percent"
                    },
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                }, legend: {
                    layout: "vertical",
                    align: "center",
                    verticalAlign: "bottom",

                    margin: 30,
                    labelFormatter: function () {

                        return this.name.length > 30 ? this.name.slice(0, this.name.slice(0, 30).lastIndexOf(' ')) + '...' : this.name;
                    }
                },
                "series": [
                    {
                        type: "pie",
                        "name": "Applicants by entrance status",
                        "data": [
                            ["Freshman", 60.0],
                            ["Transfer", 20.0],
                            ["Nondegree", 9.0],
                            ["Returning", 11.0]
                        ],
                        "id": "series-0"
                    }
                ],
                "title": {
                    "text": "Applications"
                },
                "credits": {
                    "enabled": false
                },
                "loading": false,
                "size": {}
            };

            $scope.$on(config.events.applicationChanged, (event) => {
                this.uiCalendarConfig.calendars.myCalendar.fullCalendar("refetchEvents");
                this.activate();
            });

        }

        activate() {
            this.common.activateController([this.getApplications(), this.getTotalApplicationsByDay(), this.getPaymentTotal(), this.getAdditionalDashboardData(), this.getTranscriptsCount(), this.getTranscripts(), this.getRecentExports()], DashboardController.controllerId)
                .then(() => { this.log("Activated Dashboard View"); });
        }

        createWeekRangeList = () => {
            this.weekRanges = [];
            var i: number;
            var start: Moment;
            var end: Moment;
            var range: { display: string; startDate: Moment; endDate: Moment };

            for (i = 3; i > 0; i--) {
                start = moment(this.startDate).subtract(7 * i, "days");
                end = moment(this.endDate).subtract((7 * i), "days");
                range = {
                    display: start.format("MMMM DD") + " - " + end.clone().subtract(1, "day").format("MMMM DD, YYYY"),
                    startDate: start,
                    endDate: end
                };
                this.weekRanges.push(range);
            }

            for (i = 1; i < 3; i++) {
                start = moment(this.startDate).add(7 * i, "days");
                //  Prevent future weeks from displaying
                if (start.isAfter(moment())) {
                    break;
                }
                end = moment(this.endDate).add((7 * i), "days");
                range = {
                    display: start.format("MMMM DD") + " - " + end.clone().subtract(1, "day").format("MMMM DD, YYYY"),
                    startDate: start,
                    endDate: end
                };
                this.weekRanges.push(range);
            }
        };

        getApplications = (): angular.IPromise<Array<Crossroads.IUserApplicationViewModel>> => {
            this.userApplications = [];
            if (!this.userInfo.canViewApplicants) {
                return null;
            }
            this.loadingUserApplications = true;

            var queryParams = {
                page: 1,
                pageSize: 10,
                sortDirection: "DESC",
                sortBy: "Completed",
                suppressError: true
            };

            return this.Restangular.one(this.common.getUser().activeApplication).getList<Crossroads.IUserApplicationViewModel>("userApplications/submitted", queryParams).then((result) => {
                this.userApplications = result;
                this.loadingUserApplications = false;
                return result;
            }, (reason) => {
                this.loadingUserApplications = false;
                if (reason.status != 401 && reason.status != 403) {
                    this.logError("An error occurred while getting the user applications", reason);
                }
                return null;
            });
        };

        getEvents = (start, end, timezone, callback): void => {
            if (!start || !end) {
                return;
            }
            var queryParams = {
                beginDate: moment(start).toISOString(),
                endDate: moment(end).toISOString(),
                suppressError: true
            };

            this.Restangular.all(this.common.getUser().activeApplication).all("calendar/events").getList(queryParams).then((result: any) => {
                callback(_.map(result, this.mapToFullCalendar));
                return result.results;
            }, (reason) => {
                if (reason.status != 401 && reason.status != 403) {
                    this.logError("An error occurred while getting the events", reason);
                }
            });
        };

        getTotalApplicationsByDay = (): angular.IPromise<any> => {
            if (!this.userInfo.canViewApplicants) {
                return null;
            }

            var queryParams = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
                suppressError: true
            };

            return this.Restangular.one(this.common.getUser().activeApplication).one("userApplications/totalsByDay").get(queryParams).then((result) => {
                this.totalApplicationsByDay = result;

                this.chartConfig.xAxis.categories = [];
                this.chartConfig.series[0].data = [];
                this.chartConfig.series[1].data = [];

                this.submittedApplicationsCount = 0;
                this.startedApplicationsCount = 0;


                var currentDate = angular.copy(this.startDate);
                while (currentDate <= this.endDate) {
                    this.chartConfig.xAxis.categories.push(currentDate.format("MM/DD"));

                    var dateString = currentDate.format("YYYY-MM-DD[T00:00:00]");
                    var submitedCount = result.submittedApplications[dateString] ? result.submittedApplications[dateString] : 0;
                    this.submittedApplicationsCount += submitedCount;

                    this.chartConfig.series[0].data.push(submitedCount);

                    var startedCount = result.startedApplications[dateString] ? result.startedApplications[dateString] : 0;
                    this.startedApplicationsCount += startedCount;
                    this.chartConfig.series[1].data.push(startedCount);

                    currentDate = currentDate.add("day", 1);
                }
                return result;
            }, (reason) => {
                this.loadingUserApplications = false;
                if (reason.status != 401 && reason.status != 403) {
                    this.logError("Are you throwing this error somehow?", reason);
                }
            });
        };

        getAdditionalDashboardData = (): angular.IPromise<any> => {
            if (!this.userInfo.canViewApplicants) {
                return null;
            }
            var queryParams = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
                suppressError: true
            };

            return this.Restangular.one(this.common.getUser().activeApplication).one("userApplications/additionalDashboardField").get(queryParams).then((result) => {
                this.additionalField = result;

                this.pieChartConfig.series[0].data = [];
                this.pieChartConfig.series[0].name = `Applications by ${this.additionalField.fieldLabel}`;

                angular.forEach(this.additionalField.values, (value) => {
                    this.pieChartConfig.series[0].data.push([value.key, value.value]);
                });
            }, (reason) => {
                if (reason.status != 401 && reason.status != 403) {
                    this.logError("An error occurred while getting the additional dashboard field data", reason);
                }
            });
        };

        getPaymentTotal = (): angular.IPromise<number> => {
            if (!this.userInfo.canViewCreditCardReports) {
                return null;
            }
            var queryParams = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
                suppressError: true
            };


            return this.Restangular.one(this.common.getUser().activeApplication).one("payments/total").get(queryParams).then((result) => {
                this.totalPayments = result ? result : 0;
                return result;
            }, (reason) => {
                if (reason.status != 401 && reason.status != 403) {
                    this.logError("An error occurred while getting the payment total", reason);
                }
            });
        };

        getTranscriptsCount = (): ng.IPromise<void> => {
            if (!this.userInfo.canExportTranscripts) {
                return null;
            }

            var queryParams = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
                suppressError: true
            };


            return this.Restangular.one(this.common.getUser().activeApplication).one("transcripts/count").get(queryParams).then((result) => {
                this.transcriptCount = result ? result : 0;
            }, (reason) => {
                if (reason.status != 401 && reason.status != 403) {
                    this.logError("An error occurred while getting the transcript count", reason);
                }
            });
        };

        getTranscripts = (): ng.IPromise<void> => {
            if (!this.userInfo.canExportTranscripts) {
                return null;
            }

            var queryParams = {
                suppressError: true
            };

            return this.Restangular.one(this.common.getUser().activeApplication).one("transcripts").getList("recent", queryParams).then((result) => {
                this.recentTranscripts = result;
            }, (reason) => {
                if (reason.status != 401 && reason.status != 403) {
                    this.logError("An error occurred while getting recent transcripts", reason);
                }
            });
        };

        setCurrentWeek = (range) => {
            this.startDate = range.startDate;
            this.endDate = range.endDate;
            this.rangeDisplay = range.display;
            this.createWeekRangeList();
            this.getTranscriptsCount();
            this.getTotalApplicationsByDay();
            this.getPaymentTotal();
            this.getAdditionalDashboardData();
        };

        mapToFullCalendar = (event: IEvent): any => {
            return {
                id: event.id,
                title: event.name,
                start: event.start,
                end: event.end,
                allDay: event.allDayEvent
            };
        };

        getRecentExports = (): ng.IPromise<void> => {
            return this.Restangular.one("file").one(this.common.getUser().activeApplication).one("recentexports").get().then(result => {
                
                this.recentApplicationExports = _.filter(result, (f) => f.type == "ApplicationExportProjectFile");

                this.recentTranscriptExports = _.filter(result, (f) => f.type == "TranscriptExportProjectFile");

                this.recentProspectExports = _.filter(result, (f) => f.type == "ProspectExportProjectFile");

            });
        }

        downloadExport = (file) => {

            var httpPath = this.config.apiHost + "/" + file.applicationDefinitionId + "/" + file.exportProjectId + "/export?fileId=" + encodeURI(file.fullPath);

            this.openFile(httpPath);

        }

        openFile = (httpPath) => {
            this.$http.get<any>(httpPath, { responseType: "arraybuffer" })
                .then((response) => {
                    var octetStreamMime = "application/octet-stream";
                    var success = false;

                    // Get the headers
                    var headerValues = response.headers();

                    // Get the filename from the x-filename header or default to "download.bin"
                    var filename = headerValues["x-filename"] || "download.pdf";

                    // Determine the content type from the header or default to "application/octet-stream"
                    var contentType = headerValues["content-type"] || octetStreamMime;
                    var blob: Blob;
                    blob = new Blob([response.data], { type: contentType });

                    saveAs(blob, filename);

                }, (reason) => {
                    console.log("Request failed with status: " + status);
                });
        };
    }


    angular.module("app").controller(DashboardController.controllerId, DashboardController);
}
