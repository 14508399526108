/// <reference path="../config.ts" />
/// <reference path="../app.ts" />

namespace Advant.Crossroads {

    export interface UserResource extends angular.resource.IResourceClass<ng.resource.IResource<IUserViewModel>> {
        update(user: IUserViewModel): IUserViewModel;
        update(user: IUserViewModel, success: Function, error?: Function): IUserViewModel;
        update(params: Object, user: IUserViewModel, success?: Function, error?: Function): IUserViewModel;

        reopenAccount(user: IUserViewModel): void;
        reopenAccount(user: IUserViewModel, success: Function, error?: Function): void;
        reopenAccount(params: Object, user: IUserViewModel, success?: Function, error?: Function): void;

        restQuery(): angular.resource.IResource<IUserQueryResults>;
        restQuery(params: Object): angular.resource.IResource<IUserQueryResults>;
        restQuery(success: Function, error?: Function): angular.resource.IResource<IUserQueryResults>;
        restQuery(params: Object, success: Function, error?: Function): angular.resource.IResource<IUserQueryResults>;
        restQuery(params: Object, data: Object, success?: Function, error?: Function): angular.resource.IResource<IUserQueryResults>;

        resetPassword(user: IResetPasswordViewModel): void;
        resetPassword(user: IResetPasswordViewModel, success: Function, error?: Function): void;
        resetPassword(params: Object, user: IResetPasswordViewModel, success?: Function, error?: Function): void;
    }

    export interface IUserService {
        user: UserResource;
        availableClaims: angular.resource.IResourceClass<IClaimTypes>;
    }

    export class UserService implements IUserService {
        static serviceId: string = "userService";
        public user: UserResource;
        public availableClaims: angular.resource.IResourceClass<IClaimTypes>;

        constructor(private $http: angular.IHttpService, private $resource: angular.resource.IResourceService, private config: Advant.Crossroads.ICrossroadsConfig) {
            this.user = <UserResource>$resource(config.apiHost + "/users/:tenant/:email/:verb",
                {
                    tenant: "@tenant",
                    email: "@email"
                }, {
                    "update": { method: "PUT" },
                    "restQuery": { method: "GET", isArray: false },
                    "resetPassword": { method: "PUT", params: { verb: "resetPassword" } },
                    "reopenAccount": { method: "PUT", params: { verb: "reopen" } }
                });

            this.availableClaims = <ng.resource.IResourceClass<IClaimTypes>>$resource(config.apiHost + "/users/claims");
        }

    }


    angular.module("app").factory(UserService.serviceId, ["$http", "$resource", "config", ($http, $resource, config: Advant.Crossroads.ICrossroadsConfig) =>
        new UserService($http, $resource, config)
    ]);
}