/// <reference path="../app.ts" />
namespace Advant.Crossroads {


    interface IAdvFormField extends angular.IDirective {
    }

    interface IAdvFormFieldScope extends angular.IScope {
        field: IField;
        response: any;
        fieldKey: string;
        maskedResponse: string;
        isResponseHidden: boolean;

        showResponse(): void;
        hideResponse(): void;
    }

    class AdvFormField implements IAdvFormField {
        static directiveId: string = "advFormField";
        templateUrl = "/app/directives/advFormField.html";
        restrict: string = "E";
        scope: any = {
            field: "=",
            response: "="
        };

        constructor(private helper: IHelper) {
        }

        link = (scope: IAdvFormFieldScope, element, attrs) => {
            
            scope.$watch("field", (newValue: IField, oldValue: IField, originalScope: IAdvFormFieldScope) => {
                originalScope.fieldKey = this.toCamelCase(newValue.key);
                if (originalScope.response) {
                    if (this.helper.isType(scope.field.type, "DocumentUploadField")) {
                        if (originalScope.response[originalScope.fieldKey]) {
                            originalScope.response[originalScope.fieldKey] = "Document has been attached";
                        }
                    }
                    if (this.helper.isType(scope.field.type, "DateField")) {
                        if (originalScope.response[originalScope.fieldKey]) {
                            originalScope.response[originalScope.fieldKey] = moment.utc(originalScope.response[originalScope.fieldKey]).format("L");
                        }
                    }
                }

                scope.isResponseHidden = scope.field.maskField;
            });
            scope.$watch("response", (newValue: any, oldValue: any, originalScope: IAdvFormFieldScope) => {
                if (newValue) {
                    if (this.helper.isType(scope.field.type, "DocumentUploadField")) {
                        if (newValue[originalScope.fieldKey]) {
                            newValue[originalScope.fieldKey] = "Document has been attached";
                        }
                    }
                    if (this.helper.isType(scope.field.type, "DateField")) {
                        if (newValue[originalScope.fieldKey]) {
                            newValue[originalScope.fieldKey] = moment.utc(newValue[originalScope.fieldKey]).format("L");
                        }
                    }

                    if (scope.field.maskField) {
                        scope.isResponseHidden = true;
                        let re = new RegExp(scope.field.maskFormat, "g");
                        scope.maskedResponse = newValue[originalScope.fieldKey].replace(re, "x");
                    }
                }
            });

            scope.showResponse = () => {
                scope.isResponseHidden = false;
            };

            scope.hideResponse = () => {
                scope.isResponseHidden = true;
            }
        };

        isUpperCase = (value: string): boolean => {
            if (!isNaN(<any>value * 1)) {
                return false;
            } else {
                if (value === value.toUpperCase()) {
                    return true;
                } else {
                    return false;
                }
            }
        };

        toCamelCase = (value: string): string => {
            if (!value) {
                return value;
            }

            if (!this.isUpperCase(value.charAt(0))) {
                return value;
            }

            var chars = value.split("");

            for (var i = 0; i < chars.length; i++) {
                var hasNext = (i + 1 < chars.length);

                if (i > 0 && hasNext && !this.isUpperCase(chars[i + 1])) {
                    break;
                }

                chars[i] = chars[i].toLowerCase();
            }

            return chars.join("");
        };
    }

    angular.module("app").directive(AdvFormField.directiveId, ["helper", helper =>
        new AdvFormField(helper)
    ]);
}