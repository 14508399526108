/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    export interface IAuthInterceptor {
        responseError: (rejection) => angular.IPromise<any>;
    }

    export class AuthInterceptor implements IAuthInterceptor {
        static serviceId = "authInterceptor";


        constructor(private $q: angular.IQService, private $injector: angular.auto.IInjectorService, private $location: angular.ILocationService, private httpBuffer: IHttpBuffer) {
        }

        responseError = (rejection) => {
            var deferred = this.$q.defer();
            var config = rejection.config || {};
            if (!config.ignoreAuthModule) {
                if (rejection.status === 401) {
                    var authService = this.$injector.get<IAuthenticationProviderService>("authentication");
                    if (authService.accessToken()) {
                        this.httpBuffer.append(rejection.config, deferred);
                        this.throttledRefresh();
                    } else {
                        authService.authenticate();
                    }
                    return deferred.promise;
                } else if (rejection.status === 403) {
                    var common = this.$injector.get<ICommonService>("common");
                    var logError = common.logger.getLogFn("Authentication", "error");
                    
                    logError("You do not have permission to access this function for this application.", rejection,
                        (rejection.config.params && rejection.config.params.suppressError) ? false : true);
                    
                    deferred.reject(rejection);
                }
                else {
                    deferred.reject(rejection);
                }
            }
            return this.$q.reject(rejection);
        };

        throttledRefresh = _.throttle(this.refreshToken, 1000, { "trailing": false });


        private refreshToken(): void {
            var authService = this.$injector.get<IAuthenticationProviderService>("authentication");
            authService.refreshToken().then(() => {
                this.httpBuffer.retryAll((config) => {
                    config.headers.Authorization = `Bearer ${authService.accessToken()}`;
                    return config;
                });
            }, (reason) => {
                this.httpBuffer.rejectAll(reason);
                authService.logout();
            });
        }

    }


    angular.module("app").factory(AuthInterceptor.serviceId, [
        "$q", "$injector", "$location", "httpBuffer", ($q, $injector, $location, httpBuffer) =>
            new AuthInterceptor($q, $injector, $location, httpBuffer)
    ]);
}
