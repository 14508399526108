/// <reference path="../../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface ICommentsModalScope extends ng.IScope {
        close: () => void;
        save: () => void;
        cancel: () => void;
        comments: string;
    }

    interface ICommentsModal {

    }

    class CommentsModal implements ICommentsModal {
        static $inject: Array<string> = ["$uibModalInstance", "common", "$scope", "$resource"];
        static controllerId: string = "CommentsModal";
        private log;
        constructor(private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance, private common: ICommonService, private $scope: ICommentsModalScope, private $resource: ng.resource.IResourceService) {
            this.log = common.logger.getLogFn(CommentsModal.controllerId);
            $scope.close = () => this.close();
            $scope.save = () => this.save();
            $scope.cancel = () => this.cancel();
        }

        close() {
            this.$uibModalInstance.dismiss();
        }

        save() {
            this.$uibModalInstance.close(this.$scope.comments);
        }

        cancel() {
            this.$uibModalInstance.close('');
        }
    }

    angular.module("app").controller(CommentsModal.controllerId, CommentsModal);
} 